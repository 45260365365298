import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CompletedComponent } from './applicant/completed/completed.component';
import { ForgotPasswordComponent } from './base/forgot-password/forgot-password.component';
import { IntegrationReportDownloadComponent } from './base/integration-report-download/integration-report-download.component';
import { LoginWithAssessmentKeyComponent } from './base/login-with-assessment-key/login-with-assessment-key.component';
import { LoginWithKeyComponent } from './base/login-with-key/login-with-key.component';
import { LoginWithPartnerIdComponent } from './base/login-with-partner-id/login-with-partner-id.component';
import { LoginComponent } from './base/login/login.component';
import { UpdatePasswordComponent } from './base/update-password/update-password.component';
import { AdminGuard } from './core/auth/admin.guard';
import { ApplicantGuard } from './core/auth/applicant.guard';
import { DroplinkGuard } from './core/auth/droplink.guard';
import { VerifyOtpGuard } from './core/auth/verify-otp.guard';
import { LandingPageComponent } from './droplink/landing-page/landing-page.component';
import { SignUpComponent } from './droplink/sign-up/sign-up.component';
import { LegacyRedirectComponent } from './legacy/legacy-redirect/legacy-redirect.component';
import { SystemTranslationGuard } from './shared/system-translation.guard';

const routes: Routes = [
  /*Legacy routes*/

  //HURDLE CHECK
  {
    path: 'WebContent/ng2/applicant/check',
    component: LegacyRedirectComponent,
    data: { redirectAbsolutePath: ['/applicant/check'] },
  },
  {
    path: 'WebContent/ng/applicant/check',
    component: LegacyRedirectComponent,
    data: { redirectAbsolutePath: ['/applicant/check'] },
  },

  //Assessment paused - /WebContent/ng/applicant/assessment-paused
  {
    path: 'WebContent/ng/applicant/assessment-paused',
    component: LegacyRedirectComponent,
    data: { redirectAbsolutePath: ['/applicant/assessment-paused'] },
  },

  //window.location.href = `/WebContent/#/complete?cId=${_check.CompanyId}`;
  //TEST
  {
    path: 'WebContent/__/complete',
    component: LegacyRedirectComponent,
    data: { redirectAbsolutePath: ['/complete'] },
  },

  //https://www.odyssess.com/WebContent/ng/link/1ea8cbc1-cf57-4a56-bd94-ea65d8ad5a9e#vericred-dra-only
  //TEST
  {
    path: 'WebContent/ng/link/:droplink-uid',
    component: LegacyRedirectComponent,
    data: { redirectAbsolutePath: ['/link/<:droplink-uid>'] },
  },

  //https://www.odyssess.com/WebContent/ng/admin/account/password-update/1d52cd8a-7874-40b9-a671-10cdaf389c87/2185
  //TEST
  {
    path: 'WebContent/ng/admin/account/password-update/:uid/:accountId',
    component: LegacyRedirectComponent,
    data: {
      redirectAbsolutePath: ['/password-update/<:uid>/<:accountId>'],
    },
  },

  //https://www.odyssess.com/WebContent/ng/applicant/login/partner-id/93535c1d-3659-42b5-8ac9-c4f371ed4ea0/7601055113086
  //TEST
  {
    path: 'WebContent/ng/applicant/login/partner-id/:partner-reference/:id-number',
    component: LegacyRedirectComponent,
    data: {
      redirectAbsolutePath: [
        '/login/partner-id/<:partner-reference>/<:id-number>',
      ],
    },
  },
  /*TODO*/

  //https://www.odyssess.com/WebContent/doAssessment.html?assessmentKey=B1D4FB01-3DC2-48E2-AE2B-F5F134426A14
  //TEST
  {
    path: '_doAssessment_login_',
    component: LegacyRedirectComponent,
    data: {
      redirectAbsolutePath: ['/login-with-assessment-key/<:assessmentKey>'],
    },
  },
  //https://www.odyssess.com/WebContent/doAssessment.html?2cce7
  {
    path: 'WebContent/doAssessment.html',
    component: LegacyRedirectComponent,
    data: { redirectAbsolutePath: ['/applicant/dashboard'] },
  },
  //https://www.odyssess.com/WebContent/#/key/AA885ECA-1F55-49BF-B6A6-E9589EE4A5D8
  {
    path: 'WebContent/__/key/:key',
    component: LegacyRedirectComponent,
    data: { redirectAbsolutePath: ['/login-with-key/<:key>'] },
  },
  //https://www.odyssess.com/WebContent/#/download?key=364B47A8-EC65-4BA0-A319-69024E5BAA81&id=568D5384-2326-429B-87CC-EBB472480C6
  {
    path: 'WebContent/__/download',
    component: LegacyRedirectComponent,
    data: { redirectAbsolutePath: ['/integration-report-download'] },
  },
  //ALL OTHER FALLBACK
  {
    path: 'WebContent/ng/**',
    component: LegacyRedirectComponent,
    data: { redirectAbsolutePath: ['/login'] },
  },
  {
    path: 'WebContent/ng2/**',
    component: LegacyRedirectComponent,
    data: { redirectAbsolutePath: ['/login'] },
  },
  /*Legacy routes - end*/
  {
    path: 'verify-otp',
    canActivate: [VerifyOtpGuard],
    loadChildren: () =>
      import('./base/verify-otp/verify-otp.module').then(
        (m) => m.VerifyOtpModule
      ),
  },
  {
    path: 'admin',
    canActivate: [AdminGuard],
    loadChildren: () =>
      import('./admin/admin.module').then((m) => m.AdminModule),
  },
  {
    path: 'applicant',
    canActivate: [ApplicantGuard],
    loadChildren: () =>
      import('./applicant/applicant.module').then((m) => m.ApplicantModule),
  },
  {
    path: 'complete',
    component: CompletedComponent,
    canLoad: [SystemTranslationGuard],
  },
  {
    path: 'password-reset',
    component: ForgotPasswordComponent,
    canLoad: [SystemTranslationGuard],
  },
  {
    path: 'password-update/:uid/:accountId',
    component: UpdatePasswordComponent,
    canLoad: [SystemTranslationGuard],
    data: { isForgotPassword: true },
  },
  {
    path: 'password-update',
    component: UpdatePasswordComponent,
    canLoad: [SystemTranslationGuard],
    data: { isForgotPassword: false },
  },
  {
    path: 'login-with-key/:key',
    component: LoginWithKeyComponent,
    canLoad: [SystemTranslationGuard],
  },
  {
    path: 'login-with-assessment-key/:key',
    component: LoginWithAssessmentKeyComponent,
    canLoad: [SystemTranslationGuard],
  },
  {
    path: 'login/partner-id/:partnerOrderId/:password',
    component: LoginWithPartnerIdComponent,
    canLoad: [SystemTranslationGuard],
  },
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [SystemTranslationGuard],
    canLoad: [SystemTranslationGuard],
  },
  {
    path: 'integration-report-download',
    component: IntegrationReportDownloadComponent,
  },
  {
    path: 'link/:key',
    component: LandingPageComponent,
    canLoad: [SystemTranslationGuard],
    //canActivate: [DroplinkGuard],
  },
  {
    path: 'sign-up/:key',
    component: SignUpComponent,
    canLoad: [SystemTranslationGuard],
    canActivate: [DroplinkGuard],
  },
  {
    path: 'su-ni/:key',
    component: SignUpComponent,
    canActivate: [DroplinkGuard],
    data: { hasIdNumber: false },
  },
  { path: '**', redirectTo: 'login' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
