import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { SystemTranslationService } from './shared/system-translation.service';
import { ERootClass } from './shared/ui/ui-modal/ui-modal.component';
import { ActivatedRoute } from '@angular/router';
import { SystemStorage } from './shared/omt-lib';

export interface IToast {
  class: ERootClass;
  message: string;
  title: string;
  durationMs: number;
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {
  private static _TOASTS: IToast[] = [
    // { title: 'Test 1', message: 'Test 1', class: ERootClass.ERROR },
    // { title: 'Test 2', message: 'Test 2', class: ERootClass.WARN },
    // { title: 'Test 3', message: 'Test 3', class: ERootClass.SUCCESS },
    // { title: 'Test 4', message: 'Test 4', class: ERootClass.PRIMARY },
  ];

  public get toasts() {
    return AppComponent._TOASTS;
  }

  private static _DEFAULT_TOAST: IToast = {
    class: ERootClass.PRIMARY,
    title: '',
    message: '',
    durationMs: 3000,
  };
  public static TOAST(toast: Partial<IToast>) {
    this._TOASTS.push({ ...this._DEFAULT_TOAST, ...toast });
  }

  public static TOAST_SUCCESS(toast: Partial<IToast>) {
    this.TOAST({ ...toast, ...{ class: ERootClass.SUCCESS } });
  }

  public static TOAST_ERROR(toast: Partial<IToast>) {
    this.TOAST({ ...toast, ...{ class: ERootClass.ERROR } });
  }

  public static TOAST_WARNING(toast: Partial<IToast>) {
    this.TOAST({ ...toast, ...{ class: ERootClass.WARN } });
  }

  constructor(
    private _translate: SystemTranslationService,
    private _route: ActivatedRoute
  ) {}

  async ngOnInit() {
    const _extDarkMode = await SystemStorage.INSTANCE.session.getItem$(
      'ExternalDarkMode'
    );

    if (_extDarkMode === 'true' || window.location.href.endsWith('#dark')) {
      document.body.classList.add('_dark');
      await SystemStorage.INSTANCE.session.setItem$('ExternalDarkMode', 'true');
    } else {
      document.body.classList.remove('_dark');
      await SystemStorage.INSTANCE.session.setItem$('ExternalDarkMode', '');
    }
  }

  private _i: number | null = null;
  ngAfterViewInit() {
    this._i = window.setInterval(() => {
      const _arr: IToast[] = [];
      for (let t of this.toasts) {
        t.durationMs -= 250;
        if (t.durationMs >= -1000) {
          _arr.push(t);
        }
      }
      if (_arr.length > 0) {
        AppComponent._TOASTS = _arr;
      }
    }, 250);
  }

  ngOnDestroy(): void {
    if (this._i) {
      window.clearInterval(this._i);
      this._i = null;
    }
  }
}
