import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { IResult } from 'ua-parser-js';
import { IAdvancedTalentAssessment } from '../models/advanced-talent-assessment';
import { IApplicantForm } from '../models/applicant-form';
import { IApplicantFormExtended } from '../models/applicant-form-extended';
import { IApplicantTalentAnalytics } from '../models/applicant-talent-analytics';
import { IAssessment } from '../models/assessment';
import { IAssessmentData } from '../models/assessment-data';
import { IBasicLookup } from '../models/basic-lookup';
import { SystemStorage } from '../shared/omt-lib';
import { ERoles } from './auth/auth-role.guard';
import { OdysseyApiService } from './odyssey-api.service';

@Injectable({
  providedIn: 'root',
})
export class AssessmentService {
  constructor(
    private _httpClient: HttpClient,
    public datepipe: DatePipe,
    private _odyService: OdysseyApiService
  ) {}

  getAssessments$(search: string = '') {
    return this._httpClient
      .get(`/api/assessment/GetAssessmentList/${search}`)
      .pipe(
        map((p: any, index: number) => {
          let pr: IAssessment[] = p.map((x: any) => {
            return {
              applicantEmail: x.ApplicantEmail,
              applicant: {
                id: x.ApplicantId,
                description: x.ApplicantName,
              } as IBasicLookup,
              applicationStatus: x.ApplicationStatus,
              assessed: x.assessed,
              assessmentStatus: {
                id: x.AssessmentStatus,
                description: x.AssessmentStatusName,
              } as IBasicLookup,
              assessmentType: x.AssessmentType,
              businessUnit: x.BusinessUnit,
              company: x.Company,
              createdBy: x.CreatedBy,
              currentHurdle: x.CurrentHurdle,
              dateCreated: x.DateCreated,
              dateCompleted: this.getDateCompleted(
                x.Assessed,
                x.LastLoginDate,
                x.MailStatus
              ),
              division: x.Division,
              hurdleNumber:
                x.CurrentHurdle == null || x.TotalHurdles == null
                  ? ''
                  : x.CurrentHurdle + ' / ' + x.TotalHurdles,
              id: x.Id,
              industryType: x.IndustryType,
              lastLoginDate: x.LastLoginDate,
              mailStatus: {
                id: x.MailStatusId,
                description: x.MailStatus,
              } as IBasicLookup,
              password: x.Password,
              project: {
                id: x.Project.Id,
                description: x.Project.Description,
              } as IBasicLookup,
              province: x.Province,
              scored: x.Scored,
              totalHurdles: x.TotalHurdles,
              username: x.Username,
            } as IAssessment;
          });
          return pr;
        })
      );
  }

  updateApplicationStatus$(assessmentId: number, applicationStatus: string) {
    return this._httpClient
      .post<{ response: string }>('/api/assessment/post/', {
        Id: assessmentId,
        ApplicationStatus: applicationStatus,
      })
      .pipe(map((r) => r.response))
      .toPromise();
  }

  // /api/Assessment/GetAssessmentData/
  getAssessmentData$(assessmentId: number) {
    return this._httpClient
      .get(`/api/Assessment/GetAssessmentData/${assessmentId}`)
      .pipe(
        map((p: any, index: number) => {
          let pr: IAssessmentData[] = p.map((x: any) => {
            return {
              additionalInfo: x.AdditionalInfo,
              assessmentId: x.AssessmentId,
              description: x.Description,
              id: x.IdType,
              type: x.Type,
            } as IAssessmentData;
          });
          return pr;
        })
      );
  }

  // /api/Assessment/ClearAssessmentData/
  clearAssessmentData$(assessmentData: IAssessmentData, password: string) {
    return this._httpClient.put('/api/Assessment/ClearAssessmentData/', {
      AdditionalInfo: assessmentData.additionalInfo,
      AssessmentId: assessmentData.assessmentId,
      Description: assessmentData.description,
      IdType: assessmentData.id,
      Type: assessmentData.type,
      password: password,
    });
  }

  getTalentAnalyticsProjectDetail$(id: number) {
    return this._httpClient
      .get(`/api/Assessment/GetTalentAnalyticsDetail?projectId=${id}`)
      .pipe(
        map((p: any, index: number) => {
          let pr: IApplicantTalentAnalytics[] = p.map((x: any) => {
            return {
              id: x.id,
              average_Productivity: x.average_Productivity,
              average_Skill: x.average_Skill,
              contactNumber: x.contactNumber,
              dateAssessed: x.dateAssessed,
              email: x.email,
              firstName: x.firstName,
              gender: x.gender,
              lastName: x.lastName,
              noteCount: x.noteCount,
              qualification: x.qualification,
            } as IApplicantTalentAnalytics;
          });
          return pr;
        })
      );
  }

  getTalentAnalyticsProjectDetailById$(id: number) {
    return this._httpClient
      .get(`/api/Assessment/GetTalentAnalyticsDetailById?assessmentId=${id}`)
      .pipe(
        map((p: any, index: number) => {
          let x = JSON.parse(p);
          let pr: IApplicantTalentAnalytics = {
            id: x.id,
            average_Productivity: x.average_Productivity,
            average_Skill: x.average_Skill,
            contactNumber: x.contactNumber,
            dateAssessed: x.dateAssessed,
            email: x.email,
            firstName: x.firstName,
            gender: x.gender,
            lastName: x.lastName,
            noteCount: x.noteCount,
            qualification: x.qualification,
          } as IApplicantTalentAnalytics;
          return pr;
        })
      );
  }

  getAssessmentDetailById$(id: number) {
    return this._httpClient
      .get(`/api/assessment/GetAssessmentFullById?id=${id}`)
      .pipe(
        map((x: any, index: number) => {
          return {
            applicantEmail: x.ApplicantEmail,
            applicant: {
              id: x.ApplicantId,
              description: x.ApplicantName,
            } as IBasicLookup,
            applicationStatus: x.ApplicationStatus,
            assessed: x.assessed,
            assessmentStatus: {
              id: x.AssessmentStatus,
              description: x.AssessmentStatusName,
            } as IBasicLookup,
            assessmentType: x.AssessmentType,
            businessUnit: x.BusinessUnit,
            company: x.Company,
            createdBy: x.CreatedBy,
            currentHurdle: x.CurrentHurdle,
            dateCreated: x.DateCreated,
            dateCompleted: this.getDateCompleted(
              x.Assessed,
              x.LastLoginDate,
              x.MailStatus
            ),
            division: x.Division,
            hurdleNumber:
              x.CurrentHurdle == null || x.TotalHurdles == null
                ? ''
                : x.CurrentHurdle + ' / ' + x.TotalHurdles,
            id: x.Id,
            industryType: x.IndustryType,
            lastLoginDate: x.LastLoginDate,
            mailStatus: {
              id: x.MailStatusId,
              description: x.MailStatus,
            } as IBasicLookup,
            password: x.Password,
            project: {
              id: x.Project.Id,
              description: x.Project.Description,
            } as IBasicLookup,
            province: x.Province,
            scored: x.Scored,
            totalHurdles: x.TotalHurdles,
            username: x.Username,
          } as IAssessment;
        })
      );
  }

  createAssessmentFromProject$(
    projectId: number,
    applicantData: IApplicantForm
  ) {
    return this._httpClient.post(
      '/api/createassessmentfromproject/GenerateAssessment',
      {
        ProjectId: projectId,
        Applicant: {
          CurrentCompany: applicantData.currentCompany,
          CurrentPosition: applicantData.currentPosition,
          DateOfBirth: applicantData.dateOfBirth
            ? this.datepipe.transform(applicantData.dateOfBirth, 'yyyy-MM-dd') +
              'T00:00:00'
            : null,
          Initials: '',

          NationalityCode: null,

          Email: applicantData.email,
          Password: '',
          ContactNumber: applicantData.mobileNumber,
          DateCreated: this.datepipe.transform(new Date(), 'yyyy-MM-dd'),
          IndustryType: null,
          PositionLevel: null,
          PositionType: null,

          Id: 0,
          ReferenceNumber: applicantData.reference1,
          ReferenceNumber2: applicantData.reference2,
          DisabilityId: applicantData.disabilityId,
          DisabilityOther: applicantData.disabilityOther,
          FirstName: applicantData.firstName,
          LastName: applicantData.lastName,
          IdNumber: applicantData.idNumber,
          Gender: applicantData.genderId
            ? {
                Id: applicantData.genderId,
              }
            : null,
          Nationality: applicantData.nationalityId
            ? {
                Id: applicantData.nationalityId,
              }
            : null,
          LanguageLevel: applicantData.languageLevelId
            ? {
                Id: applicantData.languageLevelId,
              }
            : null,
          Qualification: applicantData.qualificationId
            ? {
                Id: applicantData.qualificationId,
              }
            : null,
          Language: applicantData.languageId
            ? {
                Id: applicantData.languageId,
              }
            : null,
          Race: applicantData.raceId
            ? {
                Id: applicantData.raceId,
              }
            : null,
          CostCentre: applicantData.costCentre,
        },
      }
    );
  }

  postAdvancedTalentExportFilter$(
    filterType: string,
    searchText: string,
    projectId: number,
    fromDate: Date,
    toDate: Date
  ) {
    return this._httpClient
      .post('/api/talentanalytics/PostAdvancedTalentExportFilter', {
        FilterType: filterType,
        FromDate: this.datepipe.transform(fromDate, 'yyyy-MM-dd') + 'T00:00:00',
        ToDate: this.datepipe.transform(toDate, 'yyyy-MM-dd') + 'T00:00:00',
        SearchText: searchText,
        ProjectId: projectId,
      })
      .pipe(
        map((p: any, index: number) => {
          let pr: IAdvancedTalentAssessment[] = p.map((x: any) => {
            return {
              applicantIdNumber: x.ApplicantIdNumber,
              applicantName: x.ApplicantName,
              id: x.AssessmentId,
              dateAssessed: x.DateAssessed,
              profileId: x.ProfileId,
              projectId: x.ProjectId,
              projectName: x.ProjectName,
              referenceNumber: x.ReferenceNumber,
            } as IAdvancedTalentAssessment;
          });
          return pr;
        })
      );
  }

  ///api/talentanalytics/PostAdvancedTalentExportDownload
  async postAdvancedTalentExportDownload$(
    projectAssessments: IAdvancedTalentAssessment[],
    positionProfile1: number | null = null,
    positionProfile2: number | null = null,
    positionProfile3: number | null = null
  ) {
    const _response = await this._httpClient
      .post(
        '/api/talentanalytics/PostAdvancedTalentExportDownload',
        {
          ProjectAssessments: projectAssessments.map((x) => {
            return { AssessmentId: x.id, ProjectId: x.projectId };
          }),
          positionProfile1: positionProfile1,
          positionProfile2: positionProfile2,
          positionProfile3: positionProfile3,
        },
        {
          responseType: 'arraybuffer',
          observe: 'response',
        }
      )
      .toPromise();

    ///api/report/gppp
    let excelProtect = '';
    let passPromise = await this._httpClient
      .get('/api/report/gppp')
      .toPromise()
      .then((p: any) => {
        excelProtect = p.gppp;
      });

    let errorMessage = '';
    await Promise.all([_response, passPromise]).then(
      (p) => {},
      (r) => {
        errorMessage = 'Processing failed';
      }
    );

    if (_response) {
      const type = _response.headers.get('Content-Type');
      const disposition = _response.headers.get('Content-Disposition');
      //const excelProtect = _response.headers.get('X-Custom-ExcelProtect');
      //const emptyData = _response.headers.get('X-Custom-EmptyData');
      // console.log(disposition);
      let defaultFileName = '';
      if (disposition) {
        const match = disposition.match(/.*filename=\"?([^;\"]+)\"?.*/);
        if (match && match[1]) {
          defaultFileName = match[1];
        }
      }
      defaultFileName = defaultFileName.replace(/[<>:"\/\\|?*]+/g, '_');

      const link = document.createElement('a');
      if (_response.body != null && type != null) {
        const blob = new Blob([_response.body], { type: type });
        link.href = window.URL.createObjectURL(blob);
      }
      link.download = defaultFileName;
      link.click();

      return { defaultFileName, excelProtect, errorMessage };
    } else {
      return null;
    }
  }

  //$http.put('/api/Assessment/AcceptConsentForm/' + _assessmentId, {})
  async putAcceptConsentForm$() {
    return this._httpClient
      .put<any>(`/api/Assessment/AcceptConsentForm`, {})
      .toPromise();
  }

  ///api/productassessment/ProductHurdleGroupTemplateGet
  async getProductHurdleGroupTemplateGet$() {
    return this._httpClient
      .get<any>(`/api/productassessment/ProductHurdleGroupTemplateGet`)
      .toPromise();
  }

  //'/api/productassessment/guid/' + assessmentKey
  async getProductAssessmentGuid$(assessmentKey: string) {
    return this._httpClient
      .get<any>(`/api/productassessment/guid`, { params: { assessmentKey } })
      .toPromise();
  }

  //'/api/productassessment/credentials/' + assessmentKey
  async getProductAssessmentCredentials$(assessmentKey: string) {
    return this._httpClient
      .get<{
        token: string;
        username: string;
        super: any;
        timeToComplete: string;
        companyAlias: string;
        trackingId: number;
        assessmentId: string;
        isApplicant: boolean;
        isRestricted: boolean;
        roles: string;
        displayName: string;
        assessmentLanguageId: number;
      }>(`/api/productassessment/credentials`, {
        params: { assessmentKey },
      })
      .pipe(
        tap(async (user) => {
          if (user.isApplicant) {
            SystemStorage.INSTANCE.session.setApplicant$(
              {
                //Token: !environment.production ? user.token : null,
                Type: 'applicant',
                Company: user.companyAlias || 'not-set',
                LogoUrl: null,
                DisplayName: user.displayName || 'not-set',
                Project: 'not-set',
                SponsorEmail: 'not-set',
                Roles: [ERoles.APPLICANT],
                TimeToComplete: user.timeToComplete || 'not-set',
              },
              user.username,
              user.assessmentId?.toString() || null,
              user.trackingId?.toString() || null
            );
          } else {
            await SystemStorage.INSTANCE.session.setUser$({
              //Token: !environment.production ? user.token : null,
              Type: 'account',
              DisplayName: user.displayName,
              Company: user.companyAlias,
              LogoUrl: null,
              Roles: [...user.roles.split(',').map((r) => r as ERoles)],
            });
          }
        }),
        catchError(async (e) => {
          await SystemStorage.INSTANCE.session.setUser$(undefined);
          throw e;
        })
      )
      .toPromise();
  }

  //$http.get('/api/productassessment/get/' + _assessmentId + '?type=' + _ati_selected)
  async getProductAssessment$(assessmentId: number, type: string) {
    return this._httpClient
      .get<any>(`/api/productassessment/get?type=${type}`)
      .toPromise();
  }

  async getAssessmentHealth$() {
    return this._httpClient
      .get<{ Message: string }>('/api/assessment/health')
      .toPromise();
  }

  async postAdvancedTalentExportEmail$(
    projectAssessments: IAdvancedTalentAssessment[],
    email1: string = '',
    email2: string = '',
    email3: string = '',
    positionProfile1: number | null = null,
    positionProfile2: number | null = null,
    positionProfile3: number | null = null
  ) {
    return this._httpClient
      .post('/api/talentanalytics/PostAdvancedTalentExportEmail', {
        ProjectAssessments: projectAssessments.map((x) => {
          return { AssessmentId: x.id, ProjectId: x.projectId };
        }),
        email1: email1,
        email2: email2,
        email3: email3,
        positionProfile1: positionProfile1,
        positionProfile2: positionProfile2,
        positionProfile3: positionProfile3,
      })
      .toPromise();
  }

  getByAssessmentId$(assessmentId: number) {
    return this._httpClient
      .get(`/api/applicant/GetByAssessmentId?assessmentId=${assessmentId}`)
      .pipe(
        map((x: any, index: number) => {
          return {
            Applicant: {
              archived: x.Applicant.Archived,
              mobileNumber: x.Applicant.ContactNumber,
              currentCompany: x.Applicant.CurrentCompany,
              currentPosition: x.Applicant.CurrentPosition,
              dateOfBirth: x.Applicant.DateOfBirth,
              disabilityId: x.Applicant.DisabilityId,
              disabilityOther: x.Applicant.DisabilityOther,
              email: x.Applicant.Email,
              firstName: x.Applicant.FirstName,
              genderId: x.Applicant.GenderId,
              groupId: x.Applicant.GroupId,
              id: x.Applicant.Id,
              idNumber: x.Applicant.IdNumber,
              initials: x.Applicant.Initials,
              languageId: x.Applicant.LanguageId,
              languageLevelId: x.Applicant.LanguageLevelId,
              lastName: x.Applicant.LastName,
              nationalityCode: x.Applicant.NationalityCode,
              nationalityId: x.Applicant.NationalityId,
              qualificationId: x.Applicant.QualificationId,
              password: x.Applicant.Password,
              raceId: x.Applicant.RaceId,
              reference1: x.Applicant.ReferenceNumber,
              reference2: x.Applicant.ReferenceNumber2,
              validateDetails: x.Applicant.ValidateDetails,
            } as IApplicantFormExtended,
            Assessment: {
              assessmentLanguageId: x.Assessment.AssessmentLanguageId,
              consentAcceptDate: x.Assessment.ConsentAcceptDate,
              id: x.Assessment.Id,
            } as {
              assessmentLanguageId: number;
              consentAcceptDate: Date | null;
              id: number;
            },
          };
        })
      );
  }

  getAssessment$(assessmentId: number) {
    return this._httpClient
      .get(`/api/applicant/GetAssessment?assessmentId=${assessmentId}`)
      .pipe(
        map((x: any, index: number) => {
          return {
            Applicant: {
              archived: x.Applicant.Archived,
              mobileNumber: x.Applicant.ContactNumber,
              currentCompany: x.Applicant.CurrentCompany,
              currentPosition: x.Applicant.CurrentPosition,
              dateOfBirth: x.Applicant.DateOfBirth,
              disabilityId: x.Applicant.DisabilityId,
              disabilityOther: x.Applicant.DisabilityOther,
              email: x.Applicant.Email,
              firstName: x.Applicant.FirstName,
              genderId: x.Applicant.GenderId,
              groupId: x.Applicant.GroupId,
              id: x.Applicant.Id,
              idNumber: x.Applicant.IdNumber,
              initials: x.Applicant.Initials,
              languageId: x.Applicant.LanguageId,
              languageLevelId: x.Applicant.LanguageLevelId,
              lastName: x.Applicant.LastName,
              nationalityCode: x.Applicant.NationalityCode,
              nationalityId: x.Applicant.NationalityId,
              qualificationId: x.Applicant.QualificationId,
              password: x.Applicant.Password,
              raceId: x.Applicant.RaceId,
              reference1: x.Applicant.ReferenceNumber,
              reference2: x.Applicant.ReferenceNumber2,
              validateDetails: x.Applicant.ValidateDetails,
            } as IApplicantFormExtended,
            Assessment: {
              assessmentLanguageId: x.Assessment.AssessmentLanguageId,
              consentAcceptDate: x.Assessment.ConsentAcceptDate,
              id: x.Assessment.Id,
            } as {
              assessmentLanguageId: number;
              consentAcceptDate: Date | null;
              id: number;
            },
          };
        })
      );
  }

  async createViaDroplink$(
    projectGuid: string,
    currentCompany: string,
    currentPosition: string,
    qualificationId: number | null,
    firstName: string,
    lastName: string,
    idNumber: string | null,
    dateOfBirth: string | null,
    mobileNumber: string,
    email: string,
    genderId: number | null,
    nationalityId: number | null,
    raceId: number | null,
    languageId: number | null,
    languageLevelId: number | null,
    assessmentLanguageId: number,
    disabilityId: number | null,
    disabilityOther: string | null | undefined,

    evalexPositionLevelId: number | null,
    evalexPositionTypeId: number | null,

    optionalEmail: string | null,
    addressLine1: string | null,
    addressLine2: string | null,
    addressLine3: string | null,
    provinceId: number | null,

    applicationTypeId: number | null,
    dependantsNumber: number | null,
    maritalStatusId: number | null,
    microenterpriseAge: number | null,
    ageAtAddress: number | null,
    hasBankAccount: number | null,
    transactingMethodId: number | null
  ) {
    let aEmail = email || optionalEmail || null;
    var _obj: {
      Evalex?: { [key: string]: number | null };
      Odyssey?: { [key: string]: string | number | null };
    } | null = {
      Evalex: {
        PositionLevelId: evalexPositionLevelId,
        PositionTypeId: evalexPositionTypeId,
      },
      Odyssey: {
        AddressLine1: addressLine1,
        AddressLine2: addressLine2,
        AddressLine3: addressLine3,
        ProvinceId: provinceId,

        applicationTypeId: applicationTypeId,
        dependantsNumber: dependantsNumber,
        maritalStatusId: maritalStatusId,
        microenterpriseAge: microenterpriseAge,
        ageAtAddress: ageAtAddress,
        hasBankAccount: hasBankAccount,
        transactingMethodId: transactingMethodId,
      },
    };

    // CLEAN UP additionalInfoJson
    const _cleanNulls = (obj: {
      [key: string]: string | number | null;
    }): boolean => {
      let _found = false;
      for (let k of Object.keys(obj)) {
        if (obj[k] === null) {
          delete obj[k];
        } else {
          _found = true;
        }
      }
      return _found;
    };
    // check evalex
    if (_obj?.Evalex) {
      let _found = _cleanNulls(_obj.Evalex);
      if (!_found) {
        delete _obj.Evalex;
      }
    }
    // check odyssey
    if (_obj?.Odyssey) {
      let _found = _cleanNulls(_obj.Odyssey);
      if (!_found) {
        delete _obj.Odyssey;
      }
    }

    if (!_obj?.Evalex && !_obj?.Odyssey) {
      _obj = null;
    }

    const loginResult = await this._httpClient
      .post<ILoginResult>('/api/droplink/createassessment', {
        projectGuid,
        currentCompany,
        currentPosition,
        qualificationId,
        firstName,
        lastName,
        idNumber,
        dateOfBirth,
        mobileNumber,
        email: aEmail,
        genderId,
        nationalityId,
        raceId,
        languageId,
        languageLevelId,
        assessmentLanguageId,
        disabilityId,
        disabilityOther,

        additionalInfoJson: _obj ? JSON.stringify(_obj) : null,
      })
      .toPromise();

    if (loginResult) {
      /*
    droplink-assessment/created
    droplink-assessment/not-created
    droplink-assessment/applicant-invalid
    droplink-assessment/applicant-details-restricted
    */
      switch (loginResult.code) {
        case 'droplink-assessment/created':
          if (loginResult.loginObject) {
            await SystemStorage.INSTANCE.session.setApplicant$(
              {
                // Token: !environment.production
                //   ? loginResult.loginObject.token
                //   : null,
                Type: 'applicant',
                Company: loginResult.loginObject.companyAlias || 'not-set',
                LogoUrl: null,
                DisplayName: loginResult.loginObject.displayName,
                Project: loginResult.projectName || 'not-set',
                SponsorEmail: 'not-set',
                Roles: [ERoles.APPLICANT],
                TimeToComplete:
                  loginResult.loginObject.timeToComplete || 'not-set',
              },
              loginResult.loginObject.username,
              loginResult.loginObject.assessmentId?.toString() || null,
              loginResult.loginObject.trackingId?.toString() || null
            );
          }
          break;
        case 'droplink-assessment/not-created':
          break;
        case 'droplink-assessment/applicant-in-project':
          break;
        case 'droplink-assessment/applicant-invalid':
          break;
        case 'droplink-assessment/applicant-details-restricted':
          break;
      }
      return loginResult;
    } else {
      return null;
    }
  }

  private getDateCompleted(
    assessed: string,
    lastLoginDate: string,
    mailStatus: string
  ): string | null {
    if (assessed) {
      let dateTexts = assessed.split('-');
      let assessDate = this.datepipe.transform(
        `${dateTexts[2]}-${dateTexts[1]}-${dateTexts[0]}`,
        'dd MMM yyyy'
      );
      return assessDate != '01 Jan 1900'
        ? assessDate
        : lastLoginDate != null
        ? 'ACCESSED'
        : mailStatus;
    } else {
      return null;
    }
  }
}

export interface ILoginResult extends IResult {
  loginObject: ILoginObject;
  projectName?: string;
  code?: string;
}

export interface ILoginObject {
  token: string;
  assessmentId: string;
  trackingId: string;
  isApplicant: boolean;
  super: boolean;
  isRestricted: boolean;
  username: string;
  displayName: string;
  redirectUrl?: string;
  triggerUrl?: string;
  message?: string;

  timeToComplete?: string;
  companyAlias?: string;
}
