import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SystemTranslationService } from '../../system-translation.service';

@Component({
  selector: 'app-ui-dropdown',
  templateUrl: './ui-dropdown.component.html',
  styleUrls: ['./ui-dropdown.component.scss']
})
export class UiDropdownComponent implements OnInit {
  @Input()  items: { id: number, field: string }[] = [];
  @Input()  dataId: number = 0;
  @Input()  initialSelectionId: number | undefined;
  @Input()  defaultText: string = "Please select...";
  @Output() selection = new EventEmitter<{dataId: number, selectionId:number, selection: string}>();

  public selectedItem: number | undefined;

  constructor(private _translate: SystemTranslationService,) { }

  async ngOnInit() {
    await this._translate.isReady$();
    if(this.defaultText == 'Please select...'){
      this.defaultText = await this._translate.translate$('placeholderPleaseSelect');
    }
     if(this.initialSelectionId) {
    //   this.selectedItem = this.items.find(x => x.id == this.initialSelectionId)?.field; 
      this.selectedItem = this.initialSelectionId;
    }
    
  }

  onChange(event:any| null){
    this.selectedItem = event
    if(this.selectedItem){
      this.selection.emit({
        dataId: this.dataId, 
        selectionId: this.selectedItem,
        selection: this.items.find(x => x.id == this.selectedItem)!.field
      })
    }
  }

}
