<div class="row">
  <div class="col-md-1 col-lg-2"></div>
  <div class="col">
    <div class="card">
      <div class="card-header text-center text-white bg-primary">
        <h4 class="card-title">Assessment Paused</h4>
      </div>
      <div class="card-body">
        <div class="row"><div class="col">&nbsp;</div></div>
        <p>Unfortunately, our tracking mechanism identified atypical responses from the device on which you are completing the assessment.</p>
        <p>
          Your application process has been paused and is being reviewed. We will contact you within the next 24 hours to inform you of the status of your
          application.
        </p>
      </div>
    </div>
  </div>
  <div class="col-md-1 col-lg-2"></div>
</div>
