import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/core/auth/auth.service';
import * as localforage from 'localforage';
import { SystemStorage } from 'src/app/shared/omt-lib';

@Component({
  selector: 'app-login-with-partner-id',
  templateUrl: './login-with-partner-id.component.html',
  styleUrls: ['./login-with-partner-id.component.css'],
})
export class LoginWithPartnerIdComponent implements OnInit {
  public title = '';

  constructor(
    private _auth: AuthService,
    private _router: Router,
    private _route: ActivatedRoute
  ) {}

  public hideElements = true;
  async ngOnInit() {
    SystemStorage.INSTANCE.session.getItem$('ExternalDarkMode').then((n) => {
      this.hideElements = n === 'true';
    });

    this.title = 'Loading...';

    await SystemStorage.INSTANCE.base.clear$(); //'fresh-start'

    //await logout
    await this._auth.postLogout$().toPromise();

    await SystemStorage.INSTANCE.base.setItem$('QuestionnaireItemId', null);
    await SystemStorage.INSTANCE.base.setItem$('email', null);
    await SystemStorage.INSTANCE.base.setItem$('assessmentId', null);
    // return localforage.setItem('oua-user', null).then(() => {});

    const _partnerOrderId = this._route.snapshot.params.partnerOrderId || '';
    const _password = this._route.snapshot.params.password || '';

    this.title = 'Signing In...';

    let data;
    await this._auth.checkDataPartnerId$(_partnerOrderId).then((n) => {
      data = n;
      if (
        data?.Data == false &&
        !data.DefaultDomain.includes(window.location.host)
      ) {
        window.location.href = `${data.DefaultDomain}/login/partner-id/${_partnerOrderId}/${_password}`;
      }
    });

    const _res = await this._auth
      .postLoginWithPartnerId$(_partnerOrderId, _password)
      .toPromise();

    if (_res && _res.isApplicant) {
      this.title = 'Redirecting...';

      await SystemStorage.INSTANCE.base.setItem$(
        'partner-redirect-url',
        _res.redirectUrl
      );
      await SystemStorage.INSTANCE.base.setItem$(
        'partner-trigger-url',
        _res.triggerUrl
      );

      await SystemStorage.INSTANCE.base.setItem$(
        'timeToComplete',
        _res.timeToComplete
      );
      await SystemStorage.INSTANCE.base.setItem$(
        'companyAlias',
        _res.companyAlias
      );

      let _trackingId = _res.trackingId ? _res.trackingId.toString() : null;

      await SystemStorage.INSTANCE.base.setItem$('trackingId', _trackingId);
      await SystemStorage.INSTANCE.base.setItem$('email', _res.username);
      await SystemStorage.INSTANCE.base.setItem$(
        'assessmentId',
        _res.assessmentId
      );

      if (_res.isFullyIntegratedAssessment === true) {
        await SystemStorage.INSTANCE.base.setItem$(
          '_isFullyIntegratedAssessment',
          'true'
        );
      }

      // window.location.href = '/WebContent/doAssessment.html?2e6ff';
      await this._router.navigate(['/applicant/dashboard']);
    } else if (_res.message == 'Assessment Creation Delayed.') {
      this._router.navigate(['/complete'], { queryParams: { d: '1' } });
    } else {
      this.title = 'Invalid link';
    }
  }
}
