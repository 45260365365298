import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { IBasicLookup } from '../models/basic-lookup';

@Injectable({
  providedIn: 'root',
})
export class ReportService {
  constructor(private _httpClient: HttpClient) {}

  async downloadSelectionReport$(key: string, id: string) {
    const _response = await lastValueFrom(
      this._httpClient.get(`/api/Report/selectionreport`, {
        params: { key, id },
        responseType: 'arraybuffer',
        observe: 'response',
      })
    );

    if (_response) {
      const type = _response.headers.get('Content-Type');
      const disposition = _response.headers.get('Content-Disposition');
      const pdfProtect = _response.headers.get('X-Custom-PdfProtect');
      const emptyData = _response.headers.get('X-Custom-EmptyData');
      let defaultFileName = '';
      if (disposition) {
        const match = disposition.match(/.*filename=\"?([^;\"]+)\"?.*/);
        if (match != null && match[1]) {
          defaultFileName = match[1];
        }
      }
      defaultFileName = defaultFileName.replace(/[<>:"\/\\|?*]+/g, '_');

      let blob: Blob | null = null;
      if (_response.body != null && type != null) {
        blob = new Blob([_response.body], { type: type });
      }

      if (blob) {
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = defaultFileName;
        link.click();
      }

      return { defaultFileName, pdfProtect, emptyData };
    } else {
      return null;
    }
  }

  getReportListLookup$(projectId: number) {
    return this._httpClient
      .get<any[]>(`/api/Report/LinkedReports`, { params: { projectId } })
      .pipe(
        map((p: any, index: number) => {
          let pr: IBasicLookup[] = p.map((x: any) => {
            return {
              id: x.Id,
              description: `${x.Name} - ${x.TypeName}`,
            } as IBasicLookup;
          });
          return pr;
        })
      );
  }

  getEmailTemplatesLookup$(
    SelectionEnabled: boolean,
    DevelopmentEnabled: boolean
  ) {
    return this._httpClient
      .get<any[]>(`/api/EmailReport/EmailReportsListLookup`)
      .pipe(
        map((array: any) => {
          return array.filter((p: any) => {
            return (
              (p.SelectionRequired == 1 && SelectionEnabled == true) ||
              (p.DevelopmentRequired == 1 && DevelopmentEnabled == true)
            );
          });
        }),
        map((p: any, index: number) => {
          let pr: IBasicLookup[] = p.map((x: any) => {
            return {
              id: x.TemplateId,
              description: x.Description,
            } as IBasicLookup;
          });
          return pr;
        })
      );
  }

  async downloadPDFReport$(
    assessmentId: number,
    reportTypeId: number,
    projectId: number
  ) {
    const _response = await this._httpClient
      .get(
        `/api/report/DownloadReportFromList?id=${assessmentId}&reportId=${reportTypeId}&projectId=${projectId}`,
        {
          responseType: 'arraybuffer',
          observe: 'response',
        }
      )
      .toPromise();

    if (_response) {
      const type = _response.headers.get('Content-Type');
      const disposition = _response.headers.get('Content-Disposition');
      const pdfProtect = _response.headers.get('X-Custom-PdfProtect');
      const emptyData = _response.headers.get('X-Custom-EmptyData');
      let defaultFileName = '';
      if (disposition) {
        const match = disposition.match(/.*filename=\"?([^;\"]+)\"?.*/);
        if (match != null && match[1]) {
          defaultFileName = match[1];
        }
      }
      defaultFileName = defaultFileName.replace(/[<>:"\/\\|?*]+/g, '_');

      let blob: Blob | null = null;
      if (_response.body != null && type != null) {
        blob = new Blob([_response.body], { type: type });
      }

      if (blob) {
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = defaultFileName;
        link.click();
      }

      return { defaultFileName, pdfProtect, emptyData };
    } else {
      return null;
    }
  }

  emailPDFReport$(
    assessmentId: number,
    reportId: number,
    projectId: number,
    emailAddress: string
  ) {
    return this._httpClient.put('/api/fullreportemail/EmailReport/', {
      TemplateId: reportId,
      AssessmentId: assessmentId,
      Email: emailAddress,
      ProjectId: projectId,
    });
  }
}
