import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { IAssessmentButtonAnswer } from 'src/app/models/assessment-button-answer';
import { IAssessmentResponse } from 'src/app/models/assessment-response';
import { IAssessmentSliderQuestion } from 'src/app/models/assessment-slider-question';
import { IAssessmentSliderSubquestion } from 'src/app/models/assessment-slider-subquestion';
import { ISelectorButton } from 'src/app/shared/ui/ui-selector-buttons/ui-selector-buttons.component';
import { QuestionUtils } from '../../util/question-utils';

@Component({
  selector: 'app-ui-question-sliding-scale',
  templateUrl: './ui-question-sliding-scale.component.html',
  styleUrls: ['./ui-question-sliding-scale.component.scss']
})
export class UiQuestionSlidingScaleComponent implements OnInit {

  @Input()  question!: IAssessmentSliderQuestion;
  @Output() answer = new EventEmitter<IAssessmentResponse[]>();
  @Output() isValid = new EventEmitter<boolean>();

  public timerStart: number = new Date().getTime() / 1000;
  public changes: number = 0;

  public sections : IAssessmentSliderSubquestion[] = [];
  private answers: IAssessmentButtonAnswer[] = [];
  private responses: IAssessmentResponse[] = [];

  
  constructor() { }

  ngOnInit(): void {
    this.sections = this.question.questions
    if(this.question && this.question.answers){
      this.responses = this.question.answers
      this.answer.emit(this.responses)
    }
      this.isValid.emit(this.getValidity());
      this.changes = this.question.changes;
  }

  answerByQId(questionId:number):IAssessmentResponse | undefined{
    return this.responses.find(x => x.id == questionId)
  }

  ngOnChanges(changes: SimpleChanges){
    if( changes['question'].previousValue && changes['question'].previousValue.id != changes['question'].currentValue.id){
      this.answers = [];
      this.responses = [];
      this.sections = this.question!.questions;
      if(this.question && this.question.answers){
        this.responses = this.question!.answers
        this.answer.emit(this.responses)
      }
      this.isValid.emit(this.getValidity());
      this.timerStart = new Date().getTime() / 1000;
      this.changes = this.question.changes;
    }
  }

  onAnswered(event: IAssessmentResponse) {
    if(this.responses[this.responses.findIndex(x => x.id == event.id)].response != null){
      this.changes++
    }
    this.responses[this.responses.findIndex(x => x.id == event.id)] = event;
    this.answer.emit(this.responses)
  
    this.isValid.emit(this.getValidity());

  }

  getValidity(): boolean {
    return QuestionUtils.isValidAnswer(this.responses, "Slider")
  }

  public saveTimeAndActions(){
    let endTime = new Date().getTime() / 1000
    let delta = endTime - this.timerStart
    this.question!.time += Math.floor(delta);
    this.question!.changes = this.changes;
  }
}
