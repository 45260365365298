import { IAssessmentResponse } from 'src/app/models/assessment-response';

export class QuestionUtils {
  public static isValidAnswer(
    answers: IAssessmentResponse[],
    type: 'Select' | 'Slider' | 'SingleInvoice' | 'DoubleInvoice' | 'Numbers'
  ): boolean {
    switch (type) {
      case 'Select': {
        if (answers.some((x) => x.response == null)) {
          return false;
        } else {
          return true;
        }
        break;
      }
      case 'Slider': {
        if (answers.some((x) => x.response == null)) {
          return false;
        } else {
          return true;
        }
        break;
      }
      case 'SingleInvoice': {
        if (answers.some((x) => x.response == null)) {
          return false;
        } else if (
          answers.some((x) => {
            return (
              x.response != null &&
              JSON.parse(x.response!).id == 3 &&
              JSON.parse(x.response!).text == ''
            );
          })
        ) {
          return false;
        } else {
          return true;
        }
        break;
      }
      case 'DoubleInvoice': {
        if (answers.some((x) => x.response == null)) {
          return false;
        } else if (
          answers.some(
            (x) =>
              x.response != null &&
              (JSON.parse(x.response!).id == 2 ||
                JSON.parse(x.response!).id == 3) &&
              JSON.parse(x.response!).text == ''
          )
        ) {
          return false;
        } else {
          return true;
        }
        break;
      }
      case 'Numbers': {
        if (
          answers.some(
            (x) =>
              x.response == null ||
              x.response.includes('null') ||
              x.response.includes('NaN') ||
              this.hasDuplicates(answers)
          )
        ) {
          return false;
        } else {
          return true;
        }
        break;
      }
      default: {
        return false;
        break;
      }
    }
  }

  public static hasDuplicates(array: IAssessmentResponse[]): boolean {
    // return array.some((x:IAssessmentResponse, index1:number) => {
    //     return array.some((y:IAssessmentResponse, index2:number) =>
    //         {
    //             if(index1 !== index2 && x.response != null) {

    //                 return y.response === x.response
    //             } else {
    //                 return false;
    //             }
    //         }
    //     )
    // })
    return this.findDuplicates(array).length > 0;
  }

  public static findDuplicates(array: IAssessmentResponse[]) {
    const arr: IAssessmentResponse[] = [];

    for (const a of array) {
      if (
        array.find(
          (e) =>
            e.id !== a.id &&
            e.response !== 'null' &&
            e.response !== 'NaN' &&
            e.response === a.response
        )
      ) {
        arr.push(a);
      }
    }
    return arr;
  }
}
