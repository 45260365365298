import { Directive, ElementRef, Input, OnChanges } from '@angular/core';

@Directive({
  selector: '[ev-data-tooltip]',
})
export class TooltipDirective implements OnChanges {
  @Input('ev-data-tooltip')
  tooltip!: string;

  constructor(private elRef: ElementRef) {
  }

  ngOnChanges() {
    const el = this.elRef.nativeElement as HTMLElement;
    if(this.tooltip === ''){
      el?.removeAttribute('ev-data-tooltip');
      
    }
    else{
      el?.setAttribute('ev-data-tooltip', this.tooltip);
    }
  }
}