import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { constructionTrolley } from '@igniteui/material-icons-extended';
import { TranslateService } from '@ngx-translate/core';
import { lastValueFrom } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  ETime,
  IHash,
  ISessionUser,
  ParameterisedCachedData,
  SystemStorage,
} from './omt-lib';

@Injectable({
  providedIn: 'root',
})
export class SystemTranslationService {
  private static _HTTP_CLIENT: HttpClient | null = null;
  private static readonly _HTTP = {
    getAssessmentLanguages: new ParameterisedCachedData((params: any) => {
      return SystemTranslationService._HTTP_CLIENT!.get<
        /*ILookupCode*/ { Id: number; Code: string; Description: string }[]
      >(`/api/systemtranslation/languages?droplink=${params.droplink}`)!;
    }, ETime.h24),
    getSystemTranslation: new ParameterisedCachedData((params: any) => {
      return SystemTranslationService._HTTP_CLIENT!.get<
        { K: string; V: string }[]
      >(`/api/systemtranslation/get?code=${params.languageCode}`)!;
    }, ETime.h24),
  };

  public readonly http = {
    getAssessmentLanguages$: (droplink:string | null = "") => {
      return SystemTranslationService._HTTP.getAssessmentLanguages.$get({droplink});
    },
    getSystemTranslation$: async (languageCode: string) => {
      const _translations =
        await SystemTranslationService._HTTP.getSystemTranslation.$get({
          languageCode,
        });
      const _obj: IHash<string> = {};
      _translations.forEach((t) => {
        const _key = t.K.charAt(0).toLowerCase() + t.K.slice(1);
        _obj[_key] = t.V;
      });
      return _obj;
    },
  };

  public async isReady$() {
    if (this._state === 'ready') {
      return true;
    } else {
      return new Promise<boolean>((res, rej) => {
        let _c = 0;

        const _i = window.setInterval(() => {
          if (this._state === 'ready') {
            window.clearInterval(_i);
            res(true);
          }

          if (++_c > 24) {
            window.clearInterval(_i);
            alert('system translation - not ready');
            res(false);
          }
        }, 125);
      });
    }
  }

  public async translate$(key: string, params: any = {}): Promise<string> {
    const _isReady = await this.isReady$();
    if (_isReady === true) {
      return this._translate
        .get(key, params)
        .pipe(map((d) => d.toString()))
        .toPromise();
    }

    return key;
  }

  private _state: '' | 'init' | 'ready' = '';
  private _languages: { Id: number; Code: string; Description: string }[] = [
    {
      Id: 1,
      Code: 'en',
      Description: 'English',
    },
  ];
  private _selectedLanguage = this._languages[0];

  constructor(private _translate: TranslateService, _httpClient: HttpClient) {
    if (SystemTranslationService._HTTP_CLIENT === null) {
      SystemTranslationService._HTTP_CLIENT = _httpClient;
    }
    this._init$();
  }

  private async _init$() {
    if (this._state === '') {
      this._state = 'init';

      this._languages = await this.http.getAssessmentLanguages$(/*1*/);
      const _languageCodes = this._languages.map((l) => l.Code);

      this._translate.addLangs(_languageCodes);
      // this._translate.setDefaultLang('en');

      const _languageId =
        (await SystemStorage.INSTANCE.base.getItemAsInt$('LanguageId')) || 1;

      await this.useLanguage(_languageId);
      this._state = 'ready';
    }
  }

  async useLanguage(languageId: number) {
    const _language = this._languages.find((l) => l.Id === languageId);
    if (_language) {
      this._selectedLanguage = _language;
    }

    this._translate.setTranslation(
      this._selectedLanguage.Code,
      await this.http.getSystemTranslation$(this._selectedLanguage.Code),
      false
    );
    await lastValueFrom(this._translate.use(this._selectedLanguage.Code));

    await SystemStorage.INSTANCE.base.setItem$('LanguageId', languageId);
  }

  async getLanguageId() {
    return (await SystemStorage.INSTANCE.base.getItemAsInt$('LanguageId')) || 1;
  }
}
