import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { ApplicantService } from 'src/app/core/applicant.service';
import { IApplicantEmploymentDetail } from 'src/app/models/applicant-employment-detail';
import { IApplicantEmploymentDetailLookups } from 'src/app/models/applicant-employment-detail-lookups';
import { IBasicLookup } from 'src/app/models/basic-lookup';
import { SystemTranslationService } from 'src/app/shared/system-translation.service';

@Component({
  selector: 'app-applicant-employment-details',
  templateUrl: './applicant-employment-details.component.html',
  styleUrls: ['./applicant-employment-details.component.scss'],
})
export class ApplicantEmploymentDetailsComponent implements OnInit {
  public applicantData: IApplicantEmploymentDetail = {};

  public applicantlookups: IApplicantEmploymentDetailLookups = {
    area: [],
    maritalStatus: [],
    opportunityReason: [],
    positionInterest: [],
    qualificationYear: [],
  };
  public readonly lookups = {
    bit: [
      { id: 0, description: 'No' },
      { id: 1, description: 'Yes' },
    ] as IBasicLookup[],
    bitAgree: [
      { id: 0, description: 'I don`t agree' },
      { id: 1, description: 'I agree' },
    ] as IBasicLookup[],
    qualifications: [] as IBasicLookup[],
  };
  public positionCustom: string[] = ['', '', '', '', ''];

  public areasSelection: {
    id: number;
    description: string;
    category: string;
    selected: boolean;
  }[] = [];

  public isBusy: boolean = false;

  @ViewChild('formCtrl') public formCtrl!: NgForm;

  constructor(
    private _applicantService: ApplicantService,
    private _translate: SystemTranslationService,
    private _router: Router
  ) {
    this.areasSelection = this.areasSelection.sort((a, b) =>
      a.category > b.category ? 1 : -1
    );
  }

  async ngOnInit() {
    await this._applicantService
      .getApplicantEmploymentDetailsLookup$()
      .then((n) => (this.applicantlookups = n!));

    await this._applicantService
      .getApplicantLookups$(await this._translate.getLanguageId())
      .toPromise()
      .then((n) => (this.lookups.qualifications = n!.qualifications));

    this.applicantlookups.area.forEach((ea) => {
      this.areasSelection.push({
        id: ea.id,
        description: ea.description,
        category: ea.category,
        selected: false,
      });
    });
  }

  onChanged(event: any, p: any) {
    this.areasSelection.find((x) => x.id == p.id)!.selected = event.checked;
  }

  async onContinueClicked() {
    if (!this.isBusy && this.formCtrl.valid) {
      this.isBusy = true;

      let positions = '';
      for (let i = 1; i <= 5; ++i) {
        const _val = this.positionCustom[i];
        if (_val) {
          if (i !== 1) {
            positions += '|';
          }
          positions += _val;
        }
      }

      // console.log('values', values);
      const result =
        await this._applicantService.setApplicantEmploymentDetailsLookup$(
          this.applicantData.maidenName || '',
          this.applicantData.maritalStatusId!,
          this.applicantData.physicalAddressLine1!,
          this.applicantData.physicalAddressLine2 || '',
          this.applicantData.physicalAddressLine3!,
          this.applicantData.physicalAddressLine4!,
          this.applicantData.physicalAddressLine5!,
          this.applicantData.physicalAddressLine6!,
          this.applicantData.postalAddressLine1!,
          this.applicantData.postalAddressLine2 || '',
          this.applicantData.postalAddressLine3!,
          this.applicantData.postalAddressLine4!,
          this.applicantData.postalAddressLine5!,
          this.applicantData.postalAddressLine6!,
          this.applicantData.workPermit!,
          this.applicantData.criminalRecord!,
          this.applicantData.contactAlternativeEmail || '',
          this.applicantData.contactAlternativeNumber || '',
          this.applicantData.emergencyFirstName || '',
          this.applicantData.emergencyLastName || '',
          this.applicantData.emergencyNumber || '',
          this.applicantData.emergencyAlternativeNumber || '',
          this.applicantData.opportunityReasonId!,
          this.applicantData.previousEmployer!,
          this.applicantData.previousPosition!,
          this.applicantData.qualificationId!,
          this.applicantData.qualificationYear || '',
          this.applicantData.professionalAffiliation || '',
          this.applicantData.membershipNumber || '',
          /*this.lookup.positionInterest
          .filter((e) => e.Selected === true)
          .map((e) => e.Description)
          .join(', ')*/
          positions,
          this.areasSelection
            .filter((e) => e.selected === true)
            .map((e) => e.category + ' - ' + e.description)
            .join(', '),
          this.applicantData.informationCorrectDeclaration!
        );

      if (
        result &&
        result.Code === 'applicant-employment-details/write/success'
      ) {
        this._router.navigate(['/applicant/dashboard']);
        // window.location.href = '/WebContent/doAssessment.html';
      }

      this.isBusy = false;
    }
  }
}
