import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EMPTY, firstValueFrom, lastValueFrom } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { SystemStorage } from 'src/app/shared/omt-lib';
import { environment } from 'src/environments/environment';
import { ERoles } from './auth-role.guard';

type TSystemMessage = {
  Id: number;
  MessageType: 'info' | 'warning' | 'error';
  Message: string;
  SystemLockdown: boolean;
};
@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(private _httpClient: HttpClient) {}
  getSystemMessageList$ = () => {
    return firstValueFrom(
      this._httpClient.get<TSystemMessage[]>(`/api/auth/SystemMessageList`)
    );
  };
  postLogout$ = () => {
    return this._httpClient.post(`/api/auth/logout`, {});
  };

  getRequestOtp$ = () => {
    return lastValueFrom(this._httpClient.get(`/api/auth/RequestOtp`));
  };

  postVerifyOtp$ = (otp: string, forceExpiry: boolean) => {
    return lastValueFrom(
      this._httpClient
        .post<{
          Code: number;
          Message: 'success' | 'retry' | 'failed';
          isRestricted?: boolean;
          username?: string;
          roles?: string;
          displayName?: string;
          companyAlias?: string;
        }>(`/api/auth/VerifyOtp`, {
          Otp: otp,
          ForceExpiry: forceExpiry,
        })
        .pipe(
          tap(async (user) => {
            if (user.Message === 'success') {
              await SystemStorage.INSTANCE.session.setUser$({
                //Token: !environment.production ? user.token : null,
                Type: 'account',
                DisplayName: user.displayName || 'not-set',
                Company: user.companyAlias || 'not-set',
                LogoUrl: null,
                Roles: [
                  ...(user.roles || '').split(',').map((r) => r as ERoles),
                ],
              });
            }
            return user;
          }),
          catchError(async (e) => {
            await SystemStorage.INSTANCE.session.setUser$(undefined);
            throw e;
          })
        )
    );
  };

  postLogin$ = (email: string, password: string) => {
    return this._httpClient
      .post<{
        token: string;
        username: string;
        super: any;
        timeToComplete: string;
        companyAlias: string;
        trackingId: number;
        assessmentId: string;
        isApplicant: boolean;
        isRestricted: boolean;
        roles: string;
        displayName: string;
      }>(`/api/auth/login`, {
        email: email,
        password: password,
      })
      .pipe(
        tap(async (user) => {
          if (user.isApplicant) {
            SystemStorage.INSTANCE.session.setApplicant$(
              {
                //Token: !environment.production ? user.token : null,
                Type: 'applicant',
                Company: user.companyAlias || 'not-set',
                LogoUrl: null,
                DisplayName: user.displayName || 'not-set',
                Project: 'not-set',
                SponsorEmail: 'not-set',
                Roles: [ERoles.APPLICANT],
                TimeToComplete: user.timeToComplete || 'not-set',
              },
              user.username,
              user.assessmentId?.toString() || null,
              user.trackingId?.toString() || null
            );
          } else {
            await SystemStorage.INSTANCE.session.setUser$({
              //Token: !environment.production ? user.token : null,
              Type: 'account',
              DisplayName: user.displayName,
              Company: user.companyAlias,
              LogoUrl: null,
              Roles: [...user.roles.split(',').map((r) => r as ERoles)],
            });
          }
        }),
        catchError(async (e) => {
          await SystemStorage.INSTANCE.session.setUser$(undefined);
          throw e;
        })
      );
  };
  postLoginWithKey$ = (key: string | null, password: string) => {
    return this._httpClient
      .post<{
        token: string;
        username: string;
        super: any;
        timeToComplete: string;
        companyAlias: string;
        trackingId: number;
        assessmentId: string;
        isApplicant: boolean;
        isRestricted: boolean;
        roles: string;
        displayName: string;
      }>(
        `/api/auth/loginWithKey`,
        key
          ? {
              key: key,
              password: password,
            }
          : {}
      )
      .pipe(
        tap(async (user) => {
          if (user.isApplicant) {
            await SystemStorage.INSTANCE.session.setApplicant$(
              {
                //Token: !environment.production ? user.token : null,
                Type: 'applicant',
                Company: user.companyAlias,
                LogoUrl: null,
                DisplayName: user.displayName || 'not-set',
                Project: 'not-set',
                SponsorEmail: 'not-set',
                Roles: [ERoles.APPLICANT],
                TimeToComplete: user.timeToComplete || 'not-set',
              },
              user.username,
              user.assessmentId?.toString() || null,
              user.trackingId?.toString() || null
            );
          } else {
            // USER CAN'T LOGIN WITH A KEY
            // SystemStorage.INSTANCE.session.setUser({
            //   DisplayName: user.displayName,
            //   Company: user.companyAlias,
            //   Roles: [...user.roles.split(',').map((r) => r as ERoles)],
            // });
          }
        }),
        catchError(async (e) => {
          await SystemStorage.INSTANCE.session.setUser$(undefined);
          throw e;
        })
      );
  };
  postLoginWithPartnerId$ = (partnerId: string | null, password: string) => {
    return this._httpClient
      .post<
        {
          token: string;
          username: string;
          super: any;
          timeToComplete: string;
          companyAlias: string;
          trackingId: number;
          assessmentId: string;
          isApplicant: boolean;
          isRestricted: boolean;
          roles: string;
          displayName: string;
          redirectUrl: string;
          triggerUrl: string;
        }
        | any
      >(
        `/api/auth/LoginWithPartnerId`,
        partnerId
          ? {
              Key: partnerId,
              Password: password,
            }
          : {}
      )
      .pipe(
        tap(async (user) => {
          if (user.isApplicant) {
            await SystemStorage.INSTANCE.session.setApplicant$(
              {
                //Token: !environment.production ? user.token : null,
                Type: 'applicant',
                Company: user.companyAlias,
                LogoUrl: null,
                DisplayName: user.displayName || 'not-set',
                Project: 'not-set',
                SponsorEmail: 'not-set',
                Roles: [ERoles.APPLICANT],
                TimeToComplete: user.timeToComplete || 'not-set',
              },
              user.username,
              user.assessmentId?.toString() || null,
              user.trackingId?.toString() || null
            );
          } else {
            // USER CAN'T LOGIN WITH A KEY
            // SystemStorage.INSTANCE.session.setUser({
            //   DisplayName: user.displayName,
            //   Company: user.companyAlias,
            //   Roles: [...user.roles.split(',').map((r) => r as ERoles)],
            // });
          }
        }),
        catchError(async (e) => {
          await SystemStorage.INSTANCE.session.setUser$(undefined);
          throw e;
        })
      );
  };
  postUserAgent$ = (assessmentId: string, userAgent: string) => {
    return this._httpClient.post(`/api/account/useragent`, {
      assessmentId: assessmentId,
      ua: userAgent,
    });
  };

  checkData$ = (username: string) => {
    return this._httpClient
      .get<{ DefaultDomain: string; Data: boolean }>(`/api/auth/checkData`, {
        params: { username },
      })
      .toPromise();
  };

  checkDataKey$ = (key: string | null) => {
    return this._httpClient
      .get<{ DefaultDomain: string; Data: boolean }>(`/api/auth/checkKeyData`, {
        params: key ? { key } : {},
      })
      .toPromise();
  };

  checkDataPartnerId$ = (partnerOrderId: string | null) => {
    return this._httpClient
      .get<{ DefaultDomain: string; Data: boolean }>(
        `/api/auth/CheckPartnerIdData`,
        {
          params: partnerOrderId ? { partnerOrderId } : {},
        }
      )
      .toPromise();
  };

  /*getApplicantStatus$() {
    return this._httpClient
      .get(`/api/Auth/ApplicantStatus`)
      .pipe(
        map((p: any, index: number) => {
          return p.Data as boolean;
        })
      )
      .toPromise();
  }

  getUserStatus$() {
    return this._httpClient
      .get(`/api/Auth/UserStatus`)
      .pipe(
        map((p: any, index: number) => {
          return p.Data as boolean;
        })
      )
      .toPromise();
  }*/
}
