import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ApplicantDashboardComponent } from './applicant-dashboard/applicant-dashboard.component';
import { ApplicantEmploymentDetailsComponent } from './applicant-employment-details/applicant-employment-details.component';
import { ApplicantHurdleCheckComponent } from './applicant-hurdle-check/applicant-hurdle-check.component';
import { ApplicantComponent } from './applicant.component';
import { AssessmentPausedComponent } from './assessment-paused/assessment-paused.component';
import { CompletedComponent } from './completed/completed.component';
import { GipQuestionnaireComponent } from './questionnaire/gip-questionnaire/gip-questionnaire.component';
import { SystemTranslationGuard } from '../shared/system-translation.guard';

const routes: Routes = [
  {
    path: 'questionnaire/dea',
    loadChildren: () =>
      import('./questionnaire/dea-questionnaire/dea-questionnaire.module').then(
        (m) => m.DeaQuestionnaireModule
      ),
  },
  {
    path: 'questionnaire/knockout',
    loadChildren: () =>
      import('./knockout/knockout.module').then((m) => m.KnockoutModule),
  },
  {
    path: '',
    component: ApplicantComponent,
    children: [
      {
        path: 'dashboard',
        component: ApplicantDashboardComponent,
      },
      {
        path: 'check',
        component: ApplicantHurdleCheckComponent,
        canLoad: [SystemTranslationGuard],
      },
      {
        path: 'gip/:qiid',
        component: GipQuestionnaireComponent,
      },
      {
        path: 'employment-details/form',
        component: ApplicantEmploymentDetailsComponent,
      },
      {
        path: 'assessment-paused',
        component: AssessmentPausedComponent,
      },
    ],
  },
  {
    path: '**',
    redirectTo: 'dashboard',
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ApplicantRoutingModule {}
