import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { AppComponent } from 'src/app/app.component';
import { OaiIntegrityService } from 'src/app/core/oai-integrity.service';
import { SystemTranslationService } from 'src/app/shared/system-translation.service';
import {
  ERootClass,
  UiModalComponent,
} from 'src/app/shared/ui/ui-modal/ui-modal.component';

declare var $: any;
@Component({
  selector: 'app-gip-questionnaire',
  templateUrl: './gip-questionnaire.component.html',
  styleUrls: ['./gip-questionnaire.component.scss'],
})
export class GipQuestionnaireComponent implements OnInit, AfterViewInit {
  @ViewChild('modalA') _modalInstructions!: UiModalComponent;
  @ViewChild('modalB') _modalInstructions2!: UiModalComponent;

  private wait = false;

  private get _questionnaireItemId() {
    return +this._route.snapshot.params.qiid || null;
  }

  private _questions: any[] = [];
  public get questions() {
    return this._questions;
  }

  public get safeQuestion() {
    //console.log(this.questions);
    if (!this.questions[0]?.safeStatement) {
      this.questions[0].safeStatement = this._sanitizer.bypassSecurityTrustHtml(
        this.questions[0].Statement
      );
    }
    return this.questions[0].safeStatement;
  }

  private _answers: any[] = [];
  public get answers() {
    return this._answers;
  }

  private _clearButtons(qNumber: number) {
    $('.optionA' + qNumber).removeClass('active');
    $('.optionB' + qNumber).removeClass('active');
    $('.optionC' + qNumber).removeClass('active');
    $('.optionD' + qNumber).removeClass('active');
    $('.optionE' + qNumber).removeClass('active');
  }

  onClickIntegrityAnswer(question: any, answer: any) {
    this._clearButtons(question.Number);
    $('.option' + answer + question.Number).addClass('active');
    if (question.Section == 1) {
      this._answers[(question.Number - 1) % 5] = answer;
    } else if (question.Section == 2) {
      this._answers[0] = answer;
    }
  }

  isDone() {
    if (!this._questions) this._questions = [];

    var _count = 0;
    this._answers.forEach(function (elem) {
      _count++;
    });

    return this._questions.length == _count;
  }

  async onClickContinue() {
    if (this.isDone()) {
      var result = '';
      this._answers.forEach((elem) => {
        result += elem;
      });

      var data = {
        QuestionnaireItemId: this._questionnaireItemId,
        SetId: this._questions[0].SetId,
        Answers: result,
      };

      this.wait = true;
      try {
        this._oaiIntegrity.postSaveQuestionResponse$(data).then(
          (n) => {
            this._answers = [];
            this._questions = [];
            this._getIntegrityQuestions();
          }
          // ,
          // (r) => {
          //   throw new Error(r);
          // }
        );
        // $http.post('/api/Integrity/SaveQuestionResponse', data)
        // .success(function (data, status, headers, config) {
      } catch (ex) {
        this._toaster.TOAST_ERROR({
          message: await this._translate.translate$('labelServerFailedToLoad'),
        });

        this.wait = false;
      }
      //_answers = [];
      //DO POST
    }
  }

  private _showQuestionnaire = false;
  private _showInstructions = false;
  private _showIntegrityQuestions = false;

  private _showModalInstructions() {
    this._modalInstructions.init({
      body: null,
      colourClass: ERootClass.PRIMARY,
      footer: {
        buttons: {
          left: [],
          right: [
            {
              colourClass: ERootClass.PRIMARY,
              dismiss: true,
              title: 'Continue',
              onClick() {},
            },
          ],
        },
      },
      show: true,
      size: 'lg',
      title: 'Instructions',
    });
  }

  private _showModalInstructions2() {
    this._modalInstructions2.init({
      body: null,
      colourClass: ERootClass.PRIMARY,
      footer: {
        buttons: {
          left: [],
          right: [
            {
              colourClass: ERootClass.PRIMARY,
              dismiss: true,
              title: 'Continue',
              onClick() {},
            },
          ],
        },
      },
      show: true,
      size: 'lg',
      title: 'Introduction',
    });
  }

  private _hasShownInstructions1 = false;
  private _hasShownInstructions2 = false;
  private _currentIntegritySection: any = null;
  public get currentIntegritySection() {
    return this._currentIntegritySection;
  }

  private _currentIntegritySetId: any = null;
  public get currentIntegritySetId() {
    return this._currentIntegritySetId;
  }

  private _q: any = null;
  public get q() {
    return this._q;
  }

  private async _getIntegrityQuestions() {
    this.wait = true;

    try {
      const _res = await this._oaiIntegrity.getGetQuestions$(
        this._questionnaireItemId
      );
      // $http.get('/api/Integrity/GetQuestions/' + this.QuestionnaireItemId)
      this._questions = _res.questions;

      if (this._questions.length > 0) {
        this._currentIntegritySection = this._questions[0].Section;
        this._currentIntegritySetId = this._questions[0].SetId;
        if (this._currentIntegritySetId >= 10 && !this._hasShownInstructions2) {
          // $('#modalInstructions').modal('hide');
          this._modalInstructions.hide();
          // $('#modalInstructions2').modal('show');
          this._showModalInstructions2();
          this._hasShownInstructions2 = true;
        } else {
          if (
            !this._hasShownInstructions1 &&
            this._currentIntegritySetId < 10
          ) {
            this._hasShownInstructions1 = true;
            this._showModalInstructions();
          }
        }

        this._showQuestionnaire = false;
        this._showInstructions = false;

        this._showIntegrityQuestions = true;

        this.wait = false;
      } else {
        this._showQuestionnaire = true;
        this._showInstructions = false;

        this._showIntegrityQuestions = false;

        this.wait = false;
        // window.location.reload();
        this._router.navigate(['/applicant/dashboard']);
        // $window.location.reload();
      }
    } catch (ex) {
      this._toaster.TOAST_ERROR({
        message: await this._translate.translate$('labelServerFailedToLoad'),
      });

      this.wait = false;
    }
  }

  private get _toaster() {
    return AppComponent;
  }

  constructor(
    private _oaiIntegrity: OaiIntegrityService,
    private _translate: SystemTranslationService,
    private _route: ActivatedRoute,
    private _router: Router,
    private _sanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {}

  ngAfterViewInit() {
    this._getIntegrityQuestions();
  }
}
