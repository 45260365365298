import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { IgxDropDownComponent, IgxInputGroupComponent, ISelectionEventArgs, ConnectedPositioningStrategy } from '@infragistics/igniteui-angular';
import { IAssessmentDoubleInvoiceQuestion } from 'src/app/models/assessment-double-invoice-question';
import { IAssessmentInvoiceData } from 'src/app/models/assessment-invoice-data';
import { IAssessmentResponse } from 'src/app/models/assessment-response';
import { IDropDownEventExtended } from 'src/app/models/dropdown-event-extended';
import { ApplicantApiService } from '../../applicant-api.service';
import { QuestionUtils } from '../../util/question-utils';
import { SystemTranslationService } from 'src/app/shared/system-translation.service';

@Component({
  selector: 'app-ui-question-double-invoice-check',
  templateUrl: './ui-question-double-invoice-check.component.html',
  styleUrls: ['./ui-question-double-invoice-check.component.scss']
})
export class UiQuestionDoubleInvoiceCheckComponent implements OnInit {

  @Input()  question: IAssessmentDoubleInvoiceQuestion | null = null;
  @Output() answer = new EventEmitter<IAssessmentResponse[]>();
  @Output() isValid = new EventEmitter<boolean>();

  @ViewChild(IgxDropDownComponent, { static: true }) 
  public igxDropDown!: IgxDropDownComponent;
  @ViewChild('inputGroup', { read: IgxInputGroupComponent, static: true }) 
  public inputGroup!: IgxInputGroupComponent;

  public timerStart: number = new Date().getTime() / 1000;
  public changes: number = 0;

  public items: {  id: number, field: string }[] = [];
  public itemsQuantity: {  id: number, field: string  }[] = [];
  public itemsItems: {  id: number, field: string }[] = [];
  
  public responses: IAssessmentResponse[] = [];

  public itemHeight = 24;
  public itemsMaxHeight = 80;

  public pleaseSelectQuantity: string = "Please select correct quantity..."
  public pleaseSelectItem: string = "Please select correct item..."
  
  
  private numericColumns:string[] = []
  
  constructor(private _translate: SystemTranslationService,) { 

  }

  async ngOnInit() {
    await this._translate.isReady$();

    this.pleaseSelectQuantity = await this._translate.translate$('placeholderPleaseSelectQuantity');
    this.pleaseSelectItem = await this._translate.translate$('placeholderPleaseSelectItem');

    let qty = await this._translate.translate$('contentQty');
    let unitPrice = await this._translate.translate$('contentUnitPrice');
    let total = await this._translate.translate$('contentTotal');
    let unitPriceR = await this._translate.translate$('contentUnitPriceR');
    let unitCost = await this._translate.translate$('contentUnitCost');

    this.numericColumns = [qty, unitPrice, total, unitPriceR, unitCost];

    if(this.question && this.question.answers){
      this.changes = this.question.changes;
      this.responses = this.question.answers
      this.answer.emit(this.responses)

    }

    this.items = this.question!.deliveryNote.optionsSelector;
    this.itemsQuantity = this.question!.deliveryNote.optionsSelector2;
    this.itemsItems = this.question!.deliveryNote.optionsSelector3;
    this.isValid.emit(this.getValidity())
  }

  ngOnChanges(changes: SimpleChanges){
    if( changes['question'].previousValue && changes['question'].previousValue.id != changes['question'].currentValue.id){
      this.responses = [];
      if(this.question){
        this.question.deliveryNote.mainRows.filter(x => x.isQuestion == true).forEach(
          (x) => {
            this.responses.push({id:x.id, response: null} as IAssessmentResponse)
          }
        )
        this.question.deliveryNote.footerRows.filter(x => x.isQuestion == true).forEach(
          (x) => {
            this.responses.push({id:x.id, response: null} as IAssessmentResponse)
          }
        )
        this.answer.emit(this.responses)
        this.timerStart = new Date().getTime() / 1000;
        this.changes = this.question.changes;
      }
    }

    this.items = this.question!.deliveryNote.optionsSelector;
    this.itemsQuantity = this.question!.deliveryNote.optionsSelector2;
    this.itemsItems = this.question!.deliveryNote.optionsSelector3;
  }
  
  getSelectionIdByQId(questionId: number){
    let answer = this.responses.find(x => x.id == questionId)
    if(answer && answer.response){
      let selection = JSON.parse(answer.response);
      return selection;
    } else {
      return undefined;
    }
  }

  public dropdownSelected( eventArgs: IDropDownEventExtended) {
    let id: number = eventArgs.dataId;
    let optionId: number = eventArgs.selectionId;
    if(this.responses.find(x => x.id == id && x.response != null)){
      this.changes++
    }

    if(this.responses.some(x => x.id == id))
    {
      this.responses[this.responses.findIndex(x => x.id == id)] = {id: id, response: JSON.stringify({id: optionId, text: "" })};
    } else {
      this.responses.push({id: id, response: JSON.stringify({id: optionId, text: "" })});
    }

    if(!(optionId == 2 || optionId == 3)){
      this.answer.emit(this.responses)
    }
    this.isValid.emit(this.getValidity());
  }

  public dropdownQuantitySelected(eventArgs: IDropDownEventExtended) {
    let id: number = eventArgs.dataId;
    let optionId: number = eventArgs.selectionId;

    let r = this.getRowData(id);
    if(r != undefined) {
      if(r.response != null && JSON.parse(r.response).text != ""){
        this.changes++
      }
      r.response = JSON.stringify({id:2, text: optionId})
      this.responses[this.responses.findIndex(x => x.id == id)] = r;
    }
    this.answer.emit(this.responses)
    this.isValid.emit(this.getValidity());
  }

  public dropdownItemSelected(eventArgs: IDropDownEventExtended) {
    let id: number = eventArgs.dataId;
    let optionId: number = eventArgs.selectionId;

    let r = this.getRowData(id);
    if(r != undefined) {
      if(r.response != null && JSON.parse(r.response).text != ""){
        this.changes++
      }
      r.response = JSON.stringify({id:3, text: optionId})
      this.responses[this.responses.findIndex(x => x.id == id)] = r;
    }
    this.answer.emit(this.responses)
    this.isValid.emit(this.getValidity());
  }

  public getRowData(id: number){
    return this.responses.find(x => x.id == id)
  }

  public getItemData(id: number ){
    return JSON.parse(this.responses.find(x => x.id == id)?.response as string) 
  }

  public openDropDown() {
    if (this.igxDropDown.collapsed) {
        this.igxDropDown.open({
            target: this.inputGroup.element.nativeElement,
            modal: false,
            positionStrategy: new ConnectedPositioningStrategy()
        });
    }
  }

  getValidity(): boolean {
    return QuestionUtils.isValidAnswer(this.responses, "DoubleInvoice")
  }

  public saveTimeAndActions(){
    let endTime = new Date().getTime() / 1000
    let delta = endTime - this.timerStart
    this.question!.time += Math.floor(delta);
    this.question!.changes = this.changes;
  }

  public isNumericCell(text: any){
    if(isNaN(Number(text)))
    {
      return false;
    } else {
      return true;
    }
  }

  public isNumericHeader(text: string){
    if(this.numericColumns.some(x => x == text))
    {
      return true;
    } else {
      return false;
    }
  }
}
