<app-ui-banner-top></app-ui-banner-top>
<div class="container">
  <div class="view-animate-container">
    <div class="view-animate ng-scope">
      <div class="panel-align">
        <div class="panel-float" id="lg" name="loginForm">
          <div *ngIf="companyId != null && isReady">
            <div id="lgDiv" *ngIf="showCreditDraMessage === false">
              <div class="form-signin-heading text-center">
                <img [src]="logoUrl" alt="Odyssey Logo" class="decktop" />
              </div>
              <br/>
              <div
                *ngIf="showCustomMessage === false"
                class="login-wrap _background-color-surface"
              >
                <p>
                  <span
                    [innerHTML]="
                      'contentCompletedText1'
                        | translate : { companyAlias: companyAlias }
                    "
                  ></span>
                </p>
                <p>
                  <span
                    [innerHTML]="'contentCompletedText2' | translate"
                  ></span>
                </p>
                <p *ngIf="companyMessage === ''">
                  <span
                    [innerHTML]="
                      'contentCompletedText3'
                        | translate : { companyAlias: companyAlias }
                    "
                  ></span>
                </p>
                <p *ngIf="companyMessage !== ''">
                  {{ companyMessage }}
                </p>
                <p *ngIf="!doNotShowFeedback">
                  <span
                    [innerHTML]="
                      'contentCompletedText4'
                        | translate : { companyAlias: companyAlias }
                    "
                  ></span>
                </p>
              </div>
              <div
                *ngIf="showCustomMessage === true"
                class="login-wrap _background-color-surface"
              >
                <div
                  *ngIf="customMessage !== ''"
                  [innerHTML]="customMessage"
                ></div>
              </div>
            </div>
            <div id="lgDiv" *ngIf="showCreditDraMessage === true">
              <div class="form-signin-heading text-center">
                <img [src]="logoUrl" alt="Odyssey Logo" class="_logo-image" />
              </div>
              <div class="login-wrap _background-color-surface">
                <p>
                  <span
                    [innerHTML]="'contentCompletedTextDra' | translate"
                  ></span>
                </p>
              </div>
            </div>
          </div>
          <div *ngIf="companyId == null && isReady">
            <div class="form-signin-heading text-center">
              <img [src]="logoUrl" alt="Odyssey Logo" class="decktop" />
            </div>
            <div class="login-wrap _background-color-surface">
              <ng-container *ngIf="isDelayed == false">
                <p>
                  <span
                    [innerHTML]="
                      'contentAlreadyDoneText1'
                        | translate : { companyAlias: companyAlias }
                    "
                  ></span>
                </p>
                <p>
                  <span
                    [innerHTML]="'contentAlreadyDoneText2' | translate"
                  ></span>
                </p>
                <p *ngIf="companyMessage === ''">
                  <span
                    [innerHTML]="'contentAlreadyDoneText3' | translate"
                  ></span>
                </p>
                <p *ngIf="companyMessage !== ''">
                  {{ companyMessage }}
                </p>
              </ng-container>
              <ng-container *ngIf="isDelayed == true">
                <p>
                  <span
                    [innerHTML]="
                      'contentDelayedAssessment'
                        | translate"
                  ></span>
                </p>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
