import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { DroplinkService } from '../droplink.service';

@Injectable({
  providedIn: 'root'
})
export class DroplinkGuard  {
  constructor(private _droplink: DroplinkService, private _router: Router) {}

  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Promise<boolean> {
      let valid = route.params.key/* && next.params.key !== ''*/;
      try {
        const result = await this._droplink.getStatus$(route.params.key);
        valid = result!.code === 'droplink/valid' || result!.code === 'droplink/insufficient-system-resources';
      } catch {
        valid = false;
      }
      try {
        if (!valid) {
          this._router.navigate(['/login']);
        }
      } finally {
        return valid;
      }
    }
  }

