<form #formCtrl="ngForm">


        
    <!-- 1 first name -->
    <igx-input-group *ngIf="shownFields['first-name']">
        <input
            igxInput
            type="text"
            [(ngModel)]="applicantData.firstName"
            name="first-name"
            [required]="!optionaledFields['first-name']"
            [disabled]="disabledFields['first-name']"
            autocomplete="off"
        />
        <label igxLabel for="projectName" [innerHTML]="'labelFirstName' | translate"></label>
    </igx-input-group>
    
    <!-- 2 last name -->
    <igx-input-group *ngIf="shownFields['last-name']">
        <input
            igxInput
            type="text"
            [(ngModel)]="applicantData.lastName"
            name="last-name"
            [required]="!optionaledFields['last-name']"
            [disabled]="disabledFields['last-name']"
            autocomplete="off"
        />
        <label igxLabel for="projectName" [innerHTML]="'labelLastName' | translate"></label>
    </igx-input-group>

    <!-- 3 nationality -->
    <igx-select
    *ngIf="shownFields['nationality']"
    #select
    name="nationality"
    [(ngModel)]="applicantData.nationalityId"
    [required]="!optionaledFields['nationality']"
    [disabled]="disabledFields['nationality']"
    (selectionChanging)="onNationalityChanged($event)"
     
    >
        <label igxLabel [innerHTML]="'labelform.label.nationality' | translate"></label>
        <igx-select-item
        *ngFor="let item of lookups.nationalityLookup"
        [value]="item.id"
        >
        {{ item.description }}
        </igx-select-item>
    </igx-select>

    <!-- 4 id number -->
    <igx-input-group *ngIf="shownFields['id-number']">
        <input
            #idNumberT
            igxInput
            type="text"
            [(ngModel)]="applicantData.idNumber"
            name="id-number"
            [required]="!optionaledFields['id-number']"
            autocomplete="off"
            [disabled]="disabledFields['id-number']"
            (ngModelChange)="onNgModelChange($event, idNumberT)"
        />
        <label igxLabel for="projectName">ID Number</label>
    </igx-input-group>

    <!-- 5 date of birth -->
    <igx-date-picker 
        *ngIf="shownFields['date-of-birth']" 
        name="date-of-birth" 
        [(ngModel)]="applicantData.dateOfBirth"
        [required]="!optionaledFields['date-of-birth']"
        [disabled]="disabledFields['date-of-birth']"
        [inputFormat]="'dd/MM/yyyy'"
        [placeholder]="('placeholderDdMmYyyy' | translate).toLowerCase()"
        (opening)="handleOpening($event)"
        (closing)="handleClosing($event)"
        >
        <label igxLabel [innerHTML]="'labelDateOfBirth' | translate"></label>
        <igx-picker-toggle igxSuffix>
        <igx-icon>today</igx-icon>
        </igx-picker-toggle> 
    </igx-date-picker>

    <!-- 6 email -->
    <igx-input-group *ngIf="shownFields['email']">
        <input
            igxInput
            type="email"
            [(ngModel)]="applicantData.email"
            name="email"
            [required]="!optionaledFields['email']"
            [disabled]="disabledFields['email']"
            autocomplete="off"
            email
        />
        <label igxLabel for="projectName" [innerHTML]="'labelEmail' | translate"></label>
    </igx-input-group>

    <!-- 7 mobile number -->
    <app-ui-phone-number-input
    *ngIf="shownFields['mobile-number']"
    _id="ContactNumber"
    _label="{{ 'labelMobileNumber' | translate }}"
    _placeholder="{{ 'labelMobileNumber' | translate }}"
    [initialCountry]="initialCountryCode ? initialCountryCode : 'za'"
    [(_ngModel)]="applicantData.mobileNumber!"
    [_required]="!optionaledFields['mobile-number']"
    [_disabled]="disabledFields['mobile-number']"
    ></app-ui-phone-number-input>

    <!-- 8 qualification -->
    <igx-select
    *ngIf="shownFields['qualification']"
    #select
    name="qualification"
    [(ngModel)]="applicantData.qualificationId"
    [required]="!optionaledFields['qualification']"
    [disabled]="disabledFields['qualification']"
    
    >
        <label igxLabel [innerHTML]="'labelQualification' | translate"></label>
        <igx-select-item
        *ngFor="let item of lookups.qualificationLookup"
        [value]="item.id"
        >
        {{ item.description }}
        </igx-select-item>
    </igx-select>

    <!-- 9 gender -->
    <igx-select
    *ngIf="shownFields['gender']"
    #select
    name="gender"
    [(ngModel)]="applicantData.genderId"
    [required]="!optionaledFields['gender']"
    [disabled]="disabledFields['gender']"
    >
        <label igxLabel [innerHTML]="'labelGender' | translate"></label>
        <igx-select-item
        *ngFor="let item of lookups.genderLookup"
        [value]="item.id"
        >
        {{ item.description }}
        </igx-select-item>
    </igx-select>
    
    <!-- 10 race -->
    <igx-select
    *ngIf="shownFields['race'] && nationality == 'za'"
    #select
    name="race"
    [(ngModel)]="applicantData.raceId"
    [required]="!optionaledFields['race']"
    [disabled]="disabledFields['race']"
     
    >
        <label igxLabel [innerHTML]="'labelRace' | translate"></label>
        <igx-select-item
        *ngFor="let item of lookups.raceLookup"
        [value]="item.id"
        >
        {{ item.description }}
        </igx-select-item>
    </igx-select>
    
    <!-- 11 language -->
    <igx-select
    *ngIf="shownFields['language']"
    #select
    name="language"
    [(ngModel)]="applicantData.languageId"
    [required]="!optionaledFields['language']"
    [disabled]="disabledFields['language']"
    >
        <label igxLabel [innerHTML]="'labelLanguage' | translate"></label>
        <igx-select-item
        *ngFor="let item of lookups.languageLookup"
        [value]="item.id"
        >
        {{ item.description }}
        </igx-select-item>
    </igx-select>
    
    <!-- 12 language level -->
    <igx-select
    *ngIf="shownFields['language-level']"
    #select
    name="language-level"
    [(ngModel)]="applicantData.languageLevelId"
    [required]="!optionaledFields['language-level']"
    [disabled]="disabledFields['language-level']"
    >
        <label igxLabel [innerHTML]="'labelLanguageLevel' | translate"></label>
        <igx-select-item
        *ngFor="let item of lookups.languageLevelLookup"
        [value]="item.id"
        >
        {{ item.description }}
        </igx-select-item>
    </igx-select>
    
    <!-- 13 disability -->
    <igx-select
    *ngIf="shownFields['disability']"
    #select
    name="disability"
    [(ngModel)]="applicantData.disabilityId"
    [required]="!optionaledFields['disability']"
    [disabled]="disabledFields['disability']"
    >
        <label igxLabel [innerHTML]="'labelDisability' | translate"></label>
        <igx-select-item
        *ngFor="let item of lookups.disabilityLookup"
        [value]="item.id"
        >
        {{ item.description }}
        </igx-select-item>
    </igx-select>

    <!-- disability other -->
    <igx-input-group *ngIf="shownFields['disability'] && DISABILITY_OTHER_ID == applicantData.disabilityId">
        <input
            igxInput
            type="text"
            [(ngModel)]="applicantData.disabilityOther"
            name="disability-other"
            autocomplete="off"
            [disabled]="disabledFields['disability']"
        />
        <label igxLabel for="projectName" [innerHTML]="'labelDisabilityElaborate' | translate"></label>
    </igx-input-group>    

    <!-- 14 current company -->
    <igx-input-group *ngIf="shownFields['current-company']">
        <input
            igxInput
            type="text"
            [(ngModel)]="applicantData.currentCompany"
            name="current-company"
            [required]="!optionaledFields['current-company']"
            [disabled]="disabledFields['current-company']"
            autocomplete="off"
        />
        <label igxLabel for="projectName" [innerHTML]="'labelCurrentCompany' | translate"></label>
    </igx-input-group>

    <!-- 15 current position -->
    <igx-input-group *ngIf="shownFields['current-position']">
        <input
            igxInput
            type="text"
            [(ngModel)]="applicantData.currentPosition"
            name="current-position"
            [required]="!optionaledFields['current-position']"
            [disabled]="disabledFields['current-position']"
            autocomplete="off"
        />
        <label igxLabel for="projectName" [innerHTML]="'labelCurrentPosition' | translate"></label>
    </igx-input-group>
    
    <!-- 16 evalex level -->
    <igx-select
    *ngIf="shownFields['evalex-position-level']"
    #select
    name="evalex-position-level"
    [(ngModel)]="applicantData.evalexPositionLevelId"
    [required]="!optionaledFields['evalex-position-level']"
    [disabled]="disabledFields['evalex-position-level']"
     
    >
        <label igxLabel [innerHTML]="'labelEvalexLevel' | translate"></label>
        <igx-select-item
        *ngFor="let item of lookups.evalexPositionLevelLookup"
        [value]="item.id"
        >
        {{ item.description }}
        </igx-select-item>
    </igx-select>

    <!-- 17 evalex type -->
    <igx-select
    *ngIf="shownFields['evalex-position-type']"
    #select
    name="evalex-position-type"
    [(ngModel)]="applicantData.evalexPositionTypeId"
    [required]="!optionaledFields['evalex-position-type']"
    [disabled]="disabledFields['evalex-position-type']"
     
    >
        <label igxLabel [innerHTML]="'labelEvalexType' | translate"></label>
        <igx-select-item
        *ngFor="let item of lookups.evalexPositionTypeLookup"
        [value]="item.id"
        >
        {{ item.description }}
        </igx-select-item>
    </igx-select>

    <!-- 18 address 1 -->
    <igx-input-group *ngIf="shownFields['address-line-1']">
        <input
            igxInput
            type="text"
            [(ngModel)]="applicantData.addressLine1"
            name="address-line-1"
            [required]="!optionaledFields['address-line-1']"
            [disabled]="disabledFields['address-line-1']"
            autocomplete="off"
        />
        <label igxLabel for="projectName" [innerHTML]="'labelAddressLine1' | translate"></label>
    </igx-input-group>

    <!-- 19 address 2 -->
    <igx-input-group *ngIf="shownFields['address-line-2']">
        <input
            igxInput
            type="text"
            [(ngModel)]="applicantData.addressLine2"
            name="address-line-2"
            [required]="!optionaledFields['address-line-2']"
            [disabled]="disabledFields['address-line-2']"
            autocomplete="off"
        />
        <label igxLabel for="projectName" [innerHTML]="'labelAddressLine2' | translate"></label>
    </igx-input-group>
    
    <!-- 20 address 3 -->
    <igx-input-group *ngIf="shownFields['address-line-3']">
        <input
            igxInput
            type="text"
            [(ngModel)]="applicantData.addressLine3"
            name="address-line-3"
            [required]="!optionaledFields['address-line-3']"
            [disabled]="disabledFields['address-line-3']"
            autocomplete="off"
        />
        <label igxLabel for="projectName" [innerHTML]="'labelAddressLine3' | translate"></label>
    </igx-input-group>

    <!-- 21 province -->
    <igx-select
    *ngIf="shownFields['province']"
    #select
    name="province"
    [(ngModel)]="applicantData.provinceId"
    [required]="!optionaledFields['province']"
    [disabled]="disabledFields['province']"
     
    >
        <label igxLabel [innerHTML]="'labelProvince' | translate"></label>
        <igx-select-item
        *ngFor="let item of lookups.provinceLookup"
        [value]="item.id"
        >
        {{ item.description }}
        </igx-select-item>
    </igx-select>

    <!-- 22 5.	Age applicant has live at their address (years) -->
    <igx-input-group *ngIf="shownFields['ageAtAddress']">
        <input
            igxInput
            type="text"
            [(ngModel)]="applicantData.ageAtAddress"
            name="ageAtAddress"
            autocomplete="off"
            [required]="!optionaledFields['ageAtAddress']"
            [disabled]="disabledFields['ageAtAddress']"
            pattern="\d{1,3}"
        />
        <label igxLabel for="ageAtAddress" [innerHTML]="'labelAgeApplicantLiveAtAddress' | translate"></label>
    </igx-input-group>

    <!-- 23 3.	Marital status -->
    <igx-select
    *ngIf="shownFields['maritalStatusId']"
    #select
    name="maritalStatusId"
    [(ngModel)]="applicantData.maritalStatusId"
    [required]="!optionaledFields['maritalStatusId']"
    [disabled]="disabledFields['maritalStatusId']"
    >
        <label igxLabel [innerHTML]="'labelMaritalStatus' | translate"></label>
        <igx-select-item
        *ngFor="let item of lookups.maritalStatuses"
        [value]="item.id"
        >
        {{ item.description }}
        </igx-select-item>
    </igx-select>

    <!-- 25 1.	Application type: -->
    <igx-select
    *ngIf="shownFields['applicationTypeId']"
    #select
    name="applicationTypeId"
    [(ngModel)]="applicantData.applicationTypeId"
    [required]="!optionaledFields['applicationTypeId']"
    [disabled]="disabledFields['applicationTypeId']"
    >
        <label igxLabel [innerHTML]="'labelApplicationType' | translate"></label>
        <igx-select-item
        *ngFor="let item of lookups.applicationTypes"
        [value]="item.id"
        >
        {{ item.description }}
        </igx-select-item>
    </igx-select>
    
    <!-- 27 2.	Number of dependants -->
    <igx-input-group *ngIf="shownFields['dependantsNumber']">
        <input
            igxInput
            type="text"
            [(ngModel)]="applicantData.dependantsNumber"
            name="dependantsNumber"
            autocomplete="off"
            [required]="!optionaledFields['dependantsNumber']"
            [disabled]="disabledFields['dependantsNumber']"
            pattern="\d{1,3}"
        />
        <label igxLabel for="dependantsNumber" [innerHTML]="'labelNumberOfDependants' | translate"></label>
    </igx-input-group>
    
    <!-- 28 6.	Does the applicant have a bank account? -->
    <igx-select
    *ngIf="shownFields['hasBankAccount']"
    #select
    name="hasBankAccount"
    [(ngModel)]="applicantData.hasBankAccount"
    [required]="!optionaledFields['hasBankAccount']"
    [disabled]="disabledFields['hasBankAccount']"
    >
        <label igxLabel [innerHTML]="'labelApplicantHaveBankAccount' | translate"></label>
        <igx-select-item
        *ngFor="let item of lookups.bitLookups"
        [value]="item.id"
        >
        {{ item.description }}
        </igx-select-item>
    </igx-select>
    
    <!-- 29 4.	Age of micro-enterprise (years) -->
    <igx-input-group *ngIf="shownFields['microenterpriseAge']">
        <input
            igxInput
            type="text"
            [(ngModel)]="applicantData.microenterpriseAge"
            name="microenterpriseAge"
            autocomplete="off"
            [required]="!optionaledFields['microenterpriseAge']"
            [disabled]="disabledFields['microenterpriseAge']"
            pattern="\d{1,3}"
        />
        <label igxLabel for="microenterpriseAge" [innerHTML]="'labelAgeOfMicroenterprise' | translate"></label>
    </igx-input-group>
    
    <!-- 30 7.	What method is used for transacting: -->
    <!--  -->
    <igx-select
    *ngIf="shownFields['transactingMethodId']"
    #select
    name="transactingMethodId"
    [(ngModel)]="applicantData.transactingMethodId"
    [required]="!optionaledFields['transactingMethodId']"
    [disabled]="disabledFields['transactingMethodId']"
    >
        <label igxLabel [innerHTML]="'labelMethodForTransacting' | translate"></label>
        <igx-select-item
        *ngFor="let item of lookups.transactingMethods"
        [value]="item.id"
        >
        {{ item.description }}
        </igx-select-item>
    </igx-select>    
    
    <!-- optional email -->
    <igx-input-group *ngIf="shownFields['optional-email']">
        <input
            igxInput
            type="email"
            [(ngModel)]="applicantData.optionalEmail"
            name="optional-email"
            [required]="!optionaledFields['optional-email']"
            [disabled]="disabledFields['optional-email']"
            autocomplete="off"
            email
        />
        <label igxLabel for="projectName" [innerHTML]="'labelEmailOptional' | translate"></label>
    </igx-input-group>
        
    <!-- cost centre -->
    <igx-input-group *ngIf="shownFields['cost-centre']">
        <input
            igxInput
            type="text"
            [(ngModel)]="applicantData.costCentre"
            name="cost-centre"
            [required]="!optionaledFields['cost-centre']"
            autocomplete="off"
            [disabled]="disabledFields['cost-centre']"
        />
        <label igxLabel for="cost-centre" [innerHTML]="'labelCostCentre' | translate"></label>
    </igx-input-group>

    <!-- reference 1 -->
    <igx-input-group *ngIf="shownFields['reference1']">
        <input
            igxInput
            type="text"
            [(ngModel)]="applicantData.reference1"
            name="reference1"
            autocomplete="off"
            [required]="!optionaledFields['reference1']"
            [disabled]="disabledFields['reference1']"
        />
        <label igxLabel for="projectName" [innerHTML]="'labelReference' | translate"></label>
    </igx-input-group>

    <!-- reference 2 -->
    <igx-input-group *ngIf="shownFields['reference2']">
        <input
            igxInput
            type="text"
            [(ngModel)]="applicantData.reference2"
            name="reference2"
            autocomplete="off"
            [required]="!optionaledFields['reference2']"
            [disabled]="disabledFields['reference2']"
        />
        <label igxLabel for="projectName" [innerHTML]="'labelReference2' | translate"></label>
    </igx-input-group>

    <!-- applicationTypeId: undefined,
    dependantsNumber: undefined,
    maritalStatusId: undefined,
    microenterpriseAge: undefined,
    ageAtAddress: undefined,
    hasBankAccount: undefined,
    transactingMethodId: undefined, -->

</form>
