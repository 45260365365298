<app-ui-banner-top></app-ui-banner-top>

<!-- Content -->

<div class="container-fluid big-top-margin">
  <!-- LOGIN BOX -->
  <div class="panel-float" *ngIf="uaService.status === 'ua/supported'">
    <form #f="ngForm" (ngSubmit)="onSubmit()" autocomplete="off">
      <!-- LOGO -->
      <div class="logo-container">
        <img [src]="logo" alt="Evalex" />
      </div>
      <!-- ERROR MESSAGE -->
      <div *ngIf="result.message" class="info-message">
        {{ result.message }}
      </div>
      <!-- INSTRUCTIONS -->
      <div [hidden]="isDone" class="top-margin instructions">
        <!-- <p>Please enter your email below, we will send a link to re-activate via email.</p> -->
        <p>{{ 'contentResetPassword' | translate }}</p>
      </div>

      <!-- USERNAME INPUT -->
      <div [hidden]="isDone" class="top-margin">
        <input
          id="account-email"
          name="account-email"
          type="email"
          class="text-box-input"
          placeholder="Email"
          autocomplete="username"
          [(ngModel)]="account.EmailAddress"
          required
        />
      </div>

      <!-- SEND EMAIL BUTTON -->
      <div [hidden]="isDone" class="top-margin">
        <button
          type="submit"
          class="button-big-login"
          [disabled]="!f.valid || isBusy"
        >
          {{ 'buttonResetPassword' | translate }}
        </button>
      </div>

      <!-- RETURN LOGIN BUTTON -->
      <div [hidden]="!isDone" class="top-margin">
        <button type="submit" class="button-big-login" (click)="$returnLogin()">
          {{ 'buttonReturnToLogin' | translate }}
        </button>
      </div>
    </form>
  </div>
</div>

<!-- 
<div [class]="getBrowserSupportedCss()">
  <div class="panel-float" *ngIf="uaService.status === 'ua/supported'">
    <form #f="ngForm" (ngSubmit)="onSubmit()">
      <div class="logo">
        <img [src]="logo" alt="Logo" />
      </div>
      <p>
        {{ 'contentResetPassword' | translate }}
      </p>
      <input
        class="text-box-input"
        id="account-email"
        name="account-email"
        type="email"
        [(ngModel)]="account.EmailAddress"
        required
        placeholder="Email"
      />
    
      <hr />
      <div>

        <button
          type="submit"
          class="button-big"
          [disabled]="!f.valid || isBusy"
          [hidden]="isBusy"
        >
          {{ 'buttonResetPassword' | translate }}
        </button>
      </div>
      <section *ngIf="result.message">
        <hr />
        <div class="alert alert-{{ result.colour }} text-center" role="alert">
          {{ result.message }}
        </div>
        <hr />
      </section>
    </form>
  </div>
</div> -->

<app-browser-support-message></app-browser-support-message>
