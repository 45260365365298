<div class="container-dea body-content noselect">
  <div class="col-lg-10 full-width">
    <!-- <h4 class="text-center mb-2">{{ question?.name }}</h4> -->
    <div>
      <div class="row">
        <div class="col-12">
          <div class="">
            <!-- <div class="card-header"> -->
              <!-- <div class="header-text text-lg">{{ question?.description }}</div> -->
              <!-- <div class="header-tools"><i class="fas fa-info-circle text-xl clickable" (click)="showInstructions()"></i></div> -->
            <!-- </div> -->
            <!-- /.card-header -->
            <div class="text-lg">
              <div class="options">
                <div
                  *ngFor="let o of question?.options"
                  class="option clickable"
                  [ngClass]="{ selected: o.id === selectedOptionId }"
                  (dblclick)="onDoubleClick(o.id)"
                  (click)="onClickOption(o.id)"
                >
                  <div class="option-text ody-sel-none" (dblclick)="onDoubleClick(o.id)" (click)="onClickOption(o.id)">{{ o.description }}</div>
                  <div class="option-selector">
                    <i *ngIf="o.id !== selectedOptionId" class="far fa-circle"></i>
                    <i *ngIf="o.id === selectedOptionId" class="far fa-check-circle"></i>
                  </div>
                </div>
              </div>
            </div>
            <!-- /.card-body -->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-ui-modal #modalA></app-ui-modal>
