import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UiSelectorButtonsComponent } from './ui/ui-selector-buttons/ui-selector-buttons.component';
import {
  IgxButtonModule,
  IgxDatePickerModule,
  IgxDropDownModule,
  IgxForOfModule,
  IgxIconModule,
  IgxInputGroupModule,
  IgxRippleModule,
  IgxSelectModule,
  IgxToggleModule,
} from '@infragistics/igniteui-angular';
import { UiDropdownWrapperComponent } from './ui/ui-dropdown-wrapper/ui-dropdown-wrapper.component';
import { FormsModule } from '@angular/forms';
import { ApplicantRoutingModule } from '../applicant/applicant-routing.module';
import { UiDropdownComponent } from './ui/ui-dropdown/ui-dropdown.component';
import { UiBannerTopComponent } from './ui/ui-banner-top/ui-banner-top.component';
import { UiRichTextEditorComponent } from './ui/ui-rich-text-editor/ui-rich-text-editor.component';
import { UiModalComponent } from './ui/ui-modal/ui-modal.component';
import { UiPhoneNumberInputComponent } from './ui/ui-phone-number-input/ui-phone-number-input.component';
import { UiApplicantFormComponent } from './ui/ui-applicant-form/ui-applicant-form.component';
import { SystemTranslationService } from './system-translation.service';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { httpTranslateLoader } from '../app.module';
import { HttpClient } from '@angular/common/http';
import { UiPageLoaderComponent } from './ui/ui-page-loader/ui-page-loader.component';
import { TooltipDirective } from './directive/tooltip.directive';

@NgModule({
  declarations: [
    UiSelectorButtonsComponent,
    UiDropdownWrapperComponent,
    UiDropdownComponent,
    UiBannerTopComponent,
    UiRichTextEditorComponent,
    UiPhoneNumberInputComponent,
    UiApplicantFormComponent,
    UiModalComponent,
    UiPageLoaderComponent,
    TooltipDirective,
  ],
  exports: [
    UiSelectorButtonsComponent,
    UiDropdownWrapperComponent,
    UiDropdownComponent,
    UiBannerTopComponent,
    UiRichTextEditorComponent,
    UiApplicantFormComponent,
    UiModalComponent,
    UiPhoneNumberInputComponent,
    UiPageLoaderComponent,
    IgxButtonModule,
    IgxIconModule,
    IgxInputGroupModule,
    IgxDropDownModule,
    IgxRippleModule,
    IgxToggleModule,
    IgxForOfModule,
    IgxSelectModule,
    IgxDatePickerModule,
    TooltipDirective
  ],
  imports: [
    CommonModule,
    FormsModule,
    ApplicantRoutingModule,
    IgxButtonModule,
    IgxIconModule,
    IgxInputGroupModule,
    IgxDropDownModule,
    IgxRippleModule,
    IgxToggleModule,
    IgxForOfModule,
    IgxSelectModule,
    IgxDatePickerModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpTranslateLoader,
        deps: [HttpClient],
      },
    }),
  ],
  providers: [SystemTranslationService],
})
export class SharedModule {}
