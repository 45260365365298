import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-interests-questionnaire',
  templateUrl: './interests-questionnaire.component.html',
  styleUrls: ['./interests-questionnaire.component.scss']
})
export class InterestsQuestionnaireComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
