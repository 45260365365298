import { Options, passwordStrength } from "check-password-strength";

const _defaultOptions: Options<string> = [
    {
        id: 0,
        value: 'NOT USED',
        minDiversity: 0,
        minLength: 0,
    },
];

export const checkPasswordStrength = (password: string) => {
    const res = passwordStrength(password, _defaultOptions, '^A-Za-z0-9'/*'!()-.?[]_`~;:!@#$%^&*+={}'*/) as {
        contains: ('lowercase' | 'uppercase' | 'symbol' | 'number')[];
        length: number;
    };
    let message = 'Your password needs ';
    const needs: /*('character' | 'symbol' | 'number' | 'length')*/ string[] = [];
    if (!res.contains.includes('lowercase') && !res.contains.includes('uppercase')) {
        needs.push('an <b>alpha character</b>');
    }
    if (!res.contains.includes('number')) {
        needs.push('a <b>number</b>');
    }
    if (!res.contains.includes('symbol')) {
        needs.push('a <b>special symbol</b> (e.g. !@#$%^&*)'); // eg. !@#$%^&*
    }
    if (res.length < 11) {
        needs.push('to be at least <b>11 characters</b> long');
    }

    if (needs.length === 0) {
        return null;
    } else if (needs.length === 1) {
        message += needs[0] + '.';
    } else {
        const _pre = needs.slice(0, needs.length - 1);
        const _last = needs[needs.length - 1];
        message += _pre.join(', ') + ' and ' + _last + '.';
    }

    return message;
};

