import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import * as localforage from 'localforage';
import { Observable, lastValueFrom, of } from 'rxjs';
import { AppComponent, IToast } from 'src/app/app.component';
import { ApplicantService } from 'src/app/core/applicant.service';
import { AssessmentService } from 'src/app/core/assessment.service';
import { AuthService } from 'src/app/core/auth/auth.service';
import { CompanyService } from 'src/app/core/company.service';
import { OaiIntegrityService } from 'src/app/core/oai-integrity.service';
import { OdysseyApiService } from 'src/app/core/odyssey-api.service';
import { IApplicantFormExtended } from 'src/app/models/applicant-form-extended';
import { IApplicantLookups } from 'src/app/models/applicant-lookups';
import {
  objectKeysToStartUpper,
  SystemStorage,
  wait$,
} from 'src/app/shared/omt-lib';
import { SystemTranslationService } from 'src/app/shared/system-translation.service';
import {
  ERootClass,
  UiModalComponent,
} from 'src/app/shared/ui/ui-modal/ui-modal.component';
import * as UAParser from 'ua-parser-js';
import { ApplicantHeaderComponent } from '../applicant-header/applicant-header.component';
import { IApplicantFormLookups } from 'src/app/models/applicant-form-lookups';
import { IBasicLookup } from 'src/app/models/basic-lookup';
import { UiApplicantFormComponent } from 'src/app/shared/ui/ui-applicant-form/ui-applicant-form.component';

declare var $: any;

@Component({
  selector: 'app-applicant-dashboard',
  templateUrl: './applicant-dashboard.component.html',
  styleUrls: ['./applicant-dashboard.component.scss'],
})
export class ApplicantDashboardComponent implements OnInit, AfterViewInit {
  @ViewChild('modalA') _modalApplicationTypeSelect!: UiModalComponent;
  @ViewChild('modalB') _modalConsentForm!: UiModalComponent;
  @ViewChild('modalC') _modalValidateApplicantDetails!: UiModalComponent;
  // @ViewChild('formApplicant') _formApplicant!: NgForm;
  @ViewChild('formApplicant') public _formApplicant:
    | UiApplicantFormComponent
    | undefined;
  @ViewChild('modalD') _modalHurdleTemplate!: UiModalComponent;
  @ViewChild('modalE') _modalHonestyStatement!: UiModalComponent;
  @ViewChild('modalF') _modalMobileRotate!: UiModalComponent;
  @ViewChild('modalG') _modalInstructions!: UiModalComponent;
  @ViewChild('modalH') _modalHonestyStatement2!: UiModalComponent;

  @ViewChild('modalI') _modalErrorCreatingAssessment!: UiModalComponent;

  product: any;
  ApplicantName: any;
  mobileRotate: {
    isMobileDevice: boolean;
    show$: () => Promise<void>;
    hide: () => void;
  } | null = null;

  // public get assessmentKey(): string | null {
  //   return this._route.snapshot.queryParams.assessmentKey || null;
  // }

  wait = false;
  waitAnswer = false;
  showQuestionnaire = true;
  showInstructions = false;
  showQuestions = false;
  showIntegrityQuestions = false;

  logoUrl = '/WebContent/img/odyssey-logo-banner.png';

  Applicant: IApplicantFormExtended = {};
  ConsentAcceptDate: Date | null = null;
  assessmentKey: string | null = null;

  public fields: string[] = [
    'nationality',
    'id-number',
    'current-company',
    'current-position',
    'qualification',
    'first-name',
    'last-name',
    // 'email',
    'date-of-birth',
    'mobile-number',
    'gender',
    // 'race', ?
    'language',
    'language-level',
    'disability',
    // 'evalex-position-type',
    // 'evalex-position-level',
    // 'optional-email',
    // 'address-line-1',
    // 'address-line-2',
    // 'address-line-3',
    // 'province',
  ];

  public disableFields: string[] = [
    'nationality',
    'id-number',
    // 'current-company',
    // 'current-position',
    // 'qualification',
    'first-name',
    'last-name',
    // 'email',
    // 'date-of-birth',
    'mobile-number',
    // 'gender',
    // 'race',
    // 'language',
    // 'language-level',
    // 'disability',
    // 'evalex-position-type',
    // 'evalex-position-level',
    // 'optional-email',
    // 'address-line-1',
    // 'address-line-2',
    // 'address-line-3',
    // 'province',
  ];

  public optionalFields: string[] = [
    // 'nationality',
    // 'id-number',
    // 'current-company',
    // 'current-position',
    // 'qualification',
    // 'first-name',
    // 'last-name',
    //'email',
    // 'date-of-birth',
    // 'mobile-number',
    // 'gender',
    // 'race',
    // 'language',
    // 'language-level',
    // 'disability',
    // 'evalex-position-type',
    // 'evalex-position-level',
    // 'optional-email',
    // 'address-line-1',
    // 'address-line-2',
    // 'address-line-3',
    // 'province',
  ];
  public fields$: Observable<string[]> | null = null;
  public disableFields$: Observable<string[]> | null = null;
  public optionalFields$: Observable<string[]> | null = null;

  private _trackingId: number | null = null;
  private _email: string | null = null;
  private _assessmentId: number | null = null;

  private consentFormHeading = '';
  private consentFormHtml = '';
  private honestyFormHtml = '';

  private _lookups: IApplicantLookups | null = null;
  public get lookups() {
    return this._lookups || null;
  }

  public formLookups: IApplicantFormLookups;

  private _applicationTypeInfo: {
    count: { recruitment: number; credit: number };
    companies: { credit: string; recruitment: string };
    show: {
      selection: boolean;
      ifRecruitmentSelected: boolean;
      ifCreditSelected: boolean;
      draInBoth: boolean;
      creditDraMessage: boolean | null;
    };
  } | null = null;
  public get applicationTypeInfo() {
    return this._applicationTypeInfo;
  }

  public interpolationParam: {
    creditCompany: string;
    recruitmentCompany: string;
  } = { creditCompany: '', recruitmentCompany: '' };

  private get _toaster() {
    return AppComponent;
  }

  get validForm(): boolean {
    return this._formApplicant?.validForm
      ? this._formApplicant?.validForm
      : false;
  }

  public async showModalInstructions() {
    this._modalHonestyStatement.init({
      show: true,
      size: 'lg',
      colourClass: ERootClass.PRIMARY,
      title: await this._translate.translate$('labelInstructions'),
      body: this.selectedQ?.Instruction || '',
      footer: {
        buttons: {
          left: [],
          right: [
            {
              colourClass: ERootClass.PRIMARY,
              dismiss: true,
              onClick: () => {
                if (this.selectedQ.onCloseModalInstruction) {
                  this.selectedQ.onCloseModalInstruction();
                }
              },
              title: await this._translate.translate$('buttonContinue'),
              disabled: () => {
                return false;
              },
            },
          ],
        },
      },
    });
  }

  public async showModalHonestyStatement() {
    this._modalHonestyStatement.init({
      show: true,
      size: 'lg',
      colourClass: ERootClass.WARN,
      title: await this._translate.translate$('labelHonestyStatementHeading'),
      body: this.honestyFormHtml,
      footer: {
        buttons: {
          left: [],
          right: [
            {
              colourClass: ERootClass.WARN,
              dismiss: true,
              onClick: () => {
                SystemStorage.INSTANCE.base.setItem$(
                  'honesty-statement-modal',
                  'true'
                );
                this.closeModal();
              },
              title: await this._translate.translate$(
                'buttonHonestyStatementAccept'
              ),
              disabled: () => {
                return false;
              },
            },
          ],
        },
      },
    });
  }

  public async showModalHonestyStatement2(intro: boolean = false) {
    this._modalHonestyStatement2.init({
      show: true,
      size: 'lg',
      colourClass: ERootClass.PRIMARY,
      title: await this._translate.translate$('labelHonestyStatement2Title'),
      body: await this._translate.translate$('contentHonestyStatement2Body'),
      footer: {
        buttons: {
          left: [],
          right: [
            {
              colourClass: ERootClass.PRIMARY,
              dismiss: true,
              onClick: () => {
                if (intro === true) {
                  SystemStorage.INSTANCE.base.setItem$(
                    'honesty-statement-2-modal',
                    'true'
                  );

                  this.closeModal();
                } else {
                  this.selectedQ.onCloseModalInstruction();
                }
              },
              title: await this._translate.translate$(
                'buttonHonestyStatementAccept'
              ),
              disabled: () => {
                return false;
              },
            },
          ],
        },
      },
    });
  }

  public async showModalErrorCreatingAssessment() {
    this._modalErrorCreatingAssessment.init({
      show: true,
      size: 'lg',
      colourClass: ERootClass.ERROR,
      title: await this._translate.translate$('labelAttention'),
      body: null,
      footer: {
        buttons: {
          left: [],
          right: [],
        },
      },
    });
  }

  public async showModalApplicationTypeSelect() {
    this._modalApplicationTypeSelect.init({
      body: null,
      colourClass: ERootClass.PRIMARY,
      footer: {
        buttons: {
          left: [
            {
              colourClass: ERootClass.PRIMARY,
              dismiss: true,
              onClick: () => {
                this.onClickApplicantionTypeSelect('credit');
              },
              title: await this._translate.translate$('buttonCredit'),
            },
          ],
          right: [
            {
              colourClass: ERootClass.PRIMARY,
              dismiss: true,
              onClick: () => {
                this.onClickApplicantionTypeSelect('recruitment');
              },
              title: await this._translate.translate$('buttonRecruitment'),
            },
          ],
        },
      },
      show: true,
      size: 'lg',
      title: await this._translate.translate$('labelApplicationType'),
    });
  }
  public async showModalConsentForm() {
    this._modalConsentForm.init({
      show: true,
      size: 'xl',
      colourClass: ERootClass.PRIMARY,
      title: this.consentFormHeading,
      body: this.consentFormHtml,
      footer: {
        buttons: {
          left: [],
          right: [
            {
              colourClass: ERootClass.SUCCESS,
              dismiss: true,
              onClick: () => {
                return this.acceptConsentForm$();
              },
              title: await this._translate.translate$('buttonAccept'),
              disabled: () => {
                return this.acceptingConsentForm;
              },
            },
          ],
        },
      },
    });
  }
  public async showModalValidateApplicantDetails() {
    this._modalValidateApplicantDetails.init({
      show: true,
      size: 'lg',
      colourClass: ERootClass.PRIMARY,
      title: await this._translate.translate$('labelValidateDetails'),
      body: null,
      footer: {
        buttons: {
          left: [],
          right: [
            {
              colourClass: ERootClass.SUCCESS,
              dismiss: true,
              onClick: () => {
                this.updateApplicant(this.Applicant);
              },
              title: await this._translate.translate$('buttonContinue'),
              disabled: () => {
                //console.log(this._formApplicant?.validForm)
                return this.validForm == false ? true : false;
              },
            },
          ],
        },
      },
    });
  }
  public async showModalHurdleTemplate(body: string) {
    this._modalHurdleTemplate.init({
      show: true,
      size: 'lg',
      colourClass: ERootClass.PRIMARY,
      title: await this._translate.translate$('labelIntroduction'),
      body: body,
      footer: {
        buttons: {
          left: [],
          right: [
            {
              colourClass: ERootClass.PRIMARY,
              dismiss: true,
              onClick: () => {
                this.closeModal();
              },
              title: await this._translate.translate$('buttonContinue'),
              disabled: () => {
                return false;
              },
            },
          ],
        },
      },
    });
  }

  public async showModalMobileRotate() {
    this._modalMobileRotate.init({
      show: true,
      size: 'lg',
      colourClass: ERootClass.PRIMARY,
      title: await this._translate.translate$('labelAttention'),
      body: await this._translate.translate$('contentModalMobileRotate'),
      footer: {
        buttons: {
          left: [],
          right: [
            {
              colourClass: ERootClass.PRIMARY,
              dismiss: true,
              onClick: () => {
                this.closeMobileRotateModal();
              },
              title: await this._translate.translate$('buttonContinue'),
              disabled: () => {
                return false;
              },
            },
          ],
        },
      },
    });
  }

  private async _checkUser(email: string) {
    if (email) {
      var _email = email.replace(/[\"]+/g, '');
      _email = _email.replace(/[\+]+/g, '%2B');

      try {
        const _check = await this._authApi.checkData$(_email);
        if (
          _check &&
          _check.Data === false &&
          !_check.DefaultDomain.includes(window.location.host)
        ) {
          this._logout(_check.DefaultDomain);
          window.location.href = `${_check.DefaultDomain}`;
        }
      } catch (ex) {
        await this._logout();
        this._router.navigate(['/']);
      }
    }
  }

  private async _getApplicantDetailsFromAssessmentId(assessmentId: number) {
    this.wait = true;

    try {
      const _res = await this._assessmentApi
        .getAssessment$(assessmentId)
        .toPromise();

      if (_res) {
        // $http.get('/api/applicant/GetByAssessmentId', { params: { assessmentId: assessmentId } })
        this.Applicant = _res.Applicant;
        this.ConsentAcceptDate = _res.Assessment.consentAcceptDate;

        await SystemStorage.INSTANCE.base.setItem$(
          'LanguageId',
          _res.Assessment.assessmentLanguageId
        );

        await this._translate.useLanguage(_res.Assessment.assessmentLanguageId);

        await this._init(false, false /*isFullyIntegratedAssessment*/);

        if (this.Applicant.validateDetails === true) {
          // await this._getApplicantLookups();
        }

        if (this.ConsentAcceptDate == null) {
          // $('#consentForm').modal();
          await this.showModalConsentForm();
        } else if (this.Applicant.validateDetails === true) {
          // $('#validateApplicantDetails').modal();
          await this.showModalValidateApplicantDetails();
        } else {
          try {
            const _res =
              await this._applicantApi.getApplicantEmploymentDetailsCheck$();
            // $http.get('/api/applicant/ApplicantEmploymentDetailsCheck')

            if (_res.Code === 'applicant-employment-details/check/required') {
              await this._router.navigate([
                'applicant/employment-details/form',
              ]);
            } else {
              this._showNextModal();
            }
            this.wait = false;
          } catch (ex: any) {
            // humane.error = humane.spawn({ addnCls: 'humane-jackedup-error', timeout: 2000 })
            this._toaster.TOAST_ERROR({
              message: await this._translate.translate$(
                'labelServerFailedToLoad'
              ),
            });
            this.wait = false;
          }
        }
      }

      this.wait = false;
    } catch (ex) {
      // this.wrapper = data;
      // humane.error = humane.spawn({ addnCls: 'humane-jackedup-error', timeout: 2000 })
      this._toaster.TOAST_ERROR({
        message: await this._translate.translate$('labelServerFailedToLoad'),
      });
      this.wait = false;
    }
  }

  // private async _whiteLabel() {
  //   const _data = await localforage.getItem<string>('odyssey-white-label');
  //   const _stored = JSON.parse(_data || '{}');
  //   const _now = new Date().getTime();

  //   if (_stored && _stored.expires > _now) {
  //     this.logoUrl = _stored.logoUrl;
  //     return;
  //   }

  //   const _res = await this._companyApi.getWhiteLabel$().toPromise();
  //   this.logoUrl = _res.whiteLabel ? _res.whiteLabel.LogoUrl : this.logoUrl;
  // }
  isValidEmailAddress(emailAddress: string) {
    if (emailAddress == 'n/a') return true;

    var pattern = new RegExp(
      /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?$/i
    );
    return pattern.test(emailAddress);
  }

  private async _init(getQ: boolean /* = false*/, isFullyIntegratedAssessment: boolean) {
    let languageId = 1;
    await this._translate
      .getLanguageId()
      .then((n: number) => (languageId = n as number));
    const consentForm = await this._applicantApi.consentFormGet$(languageId);
    if (consentForm?.uniqueConsentHeading && consentForm?.uniqueConsent) {
      this.consentFormHeading = consentForm.uniqueConsentHeading;
      this.consentFormHtml = consentForm.uniqueConsent;
    } else {
      // window.setTimeout(async () => {
      // alert(1);
      if (
        (await SystemStorage.INSTANCE.base.getItem$(
          'companyAlias'
        )) /*sessionStorage.companyAlias*/ !== 'VeriCred'
      ) {
        // alert(2);
        this.consentFormHeading = await this._translate.translate$(
          'labelConsentFormHeading'
        );
        this.consentFormHtml = await this._translate.translate$(
          'contentConsentFormV2Html'
        );
      } else {
        // alert(3);
        this.consentFormHeading = await this._translate.translate$(
          'labelConsentFormHeading_VCCB'
        );
        this.consentFormHtml = await this._translate.translate$(
          'contentConsentFormV2Html_VCCB'
        );
      }
    }
    this.honestyFormHtml = await this._translate.translate$(
      'contentHonestyStatementHtml'
    );
    // }, 200);

    this._assessmentId = await SystemStorage.INSTANCE.base.getItemAsInt$(
      'assessmentId'
    );
    this._email = await SystemStorage.INSTANCE.base.getItem$('email');
    await this._checkUser(this._email!);
    this._trackingId = await SystemStorage.INSTANCE.base.getItemAsInt$(
      'trackingId'
    );

    if (!this._assessmentId && !this._trackingId && this._email) {
      // if (localStorage.assessmentId !== undefined) {
      //   this._assessmentId = localStorage.assessmentId;
      //   localforage.setItem('assessmentId', this._assessmentId);
      // } else {
      //   localforage.getItem<number>('assessmentId').then((value) => {
      //     this._assessmentId = value;
      //   });
      // }
      // if (localStorage.trackingId !== undefined) {
      //   this._trackingId = localStorage.trackingId;
      // } else {
      //   localforage.getItem<number>('trackingId', (err, value) => {
      //     if (err === null) {
      //       this._trackingId = value;
      //     }
      //   });
      // }
    } else {
      await SystemStorage.INSTANCE.base.setItem$(
        'assessmentId',
        this._assessmentId
      );
      await SystemStorage.INSTANCE.base.setItem$(
        'trackingId',
        this._trackingId
      );
    }

    if (!isFullyIntegratedAssessment) {
      /**/
      if (this._trackingId) {
        this._router.navigate(['/applicant/questionnaire/knockout']);
        return;
      }
    }

    if (getQ) {
      await this._getQuestionnaires(isFullyIntegratedAssessment);
    }
  }

  private async _getQuestionnaires(isFullyIntegratedAssessment: boolean) {
    if (!this.assessmentKey) {
      //if is principa

      if (!isFullyIntegratedAssessment) {
        const _res = await this._assessmentApi.getAssessmentHealth$();
        // $http.get('/api/assessment/health')
        if (_res?.Message === 'oua/assessment-health-check/dirty') {
          this._router.navigate(['/applicant/assessment-paused']);
          return;
        }
      }

      const _assessmentId = await SystemStorage.INSTANCE.base.getItemAsInt$(
        'assessmentId'
      );

      let _ati_selected = 'credit'; //default = credit
      if (!isFullyIntegratedAssessment) {
        /*CHECK Applicantion Type*/
        const _at = await this._applicantApi.getApplicationTypeInfo$();
        // const _at = await $http.get('/api/applicant/ApplicationTypeInfo');

        this._applicationTypeInfo = _at || {
          count: { recruitment: 0, credit: 0 },
          companies: { credit: '', recruitment: '' },
          show: {
            selection: false,
            ifRecruitmentSelected: false,
            ifCreditSelected: false,
            draInBoth: false,
            creditDraMessage: null,
          },
        };

        // A credit applicantion (for
        //   {{ applicationTypeInfo?.companies?.credit }}) or a recruitment application
        //   (for {{ applicationTypeInfo?.companies?.recruitment }}).
        this.interpolationParam = {
          creditCompany: this._applicationTypeInfo.companies?.credit,
          recruitmentCompany: this._applicationTypeInfo.companies?.recruitment,
        };

        const _ati_shown =
          (await SystemStorage.INSTANCE.base.getSpecialItem$(
            '_ati-' + _assessmentId + '-shown'
          )) ||
          /*sessionStorage.getItem('_ati-' + _assessmentId + '-shown')*/ 'false';
        if (
          this._applicationTypeInfo.show.selection === true &&
          _ati_shown === 'false'
        ) {
          // $('#modalApplicationTypeSelect').modal('show');
          this.showModalApplicationTypeSelect();
        }

        _ati_selected =
          (await SystemStorage.INSTANCE.base.getSpecialItem$(
            '_ati-' + _assessmentId + '-selected'
          )) ||
          /*sessionStorage.getItem('_ati-' + _assessmentId + '-selected')*/
          'null';
        if (_ati_selected === 'credit') {
          // $('#modalHurdleTemplate').modal('hide');
          this._modalHurdleTemplate.hide();
        }

        if (
          (_ati_selected === 'credit' || _ati_selected === 'null') &&
          this._applicationTypeInfo.show.creditDraMessage === true
        ) {
          await SystemStorage.INSTANCE.base.setItem$(
            '__show-dra-credit-message',
            'true'
          );
          //sessionStorage.setItem('__show-dra-credit-message', 'true');
        } else if (this._applicationTypeInfo.show.creditDraMessage === false) {
          await SystemStorage.INSTANCE.base.setItem$(
            '__show-dra-credit-message',
            'false'
          );
          //sessionStorage.setItem('__show-dra-credit-message', 'false');
        }
      }

      //Get the Applicants questionnaires via assessment id
      try {
        const _res = await this._assessmentApi.getProductAssessment$(
          this._assessmentId!,
          _ati_selected
        );

        // $http.get('/api/productassessment/get/' + _assessmentId + '?type=' + _ati_selected)
        this.product = _res;
        this.ApplicantName = _res.ApplicantName;
        this.product.Progress = this.product.Progress.toFixed(2);
        this.wait = false;

        await SystemStorage.INSTANCE.base.setItem$(
          'reasoningScore',
          this.product.ReasoningScore
        );

        if (this.product.Progress === '100.00') {
          await this._gotoCheck$();
          return;
        }

        if (
          this.product.Progress !== '100.00' &&
          //this.product.AutoRedirectToOnlyQuestionnaire === true
          isFullyIntegratedAssessment
          && _res.Questionnaires.length === 1
        ) {
          this.beginQuestionnaire(_res.Questionnaires[0]);
          return;
        }

        // try {
        //   $('#menu').hide();
        // } catch (e) {}
      } catch (ex: any) {
        // this.wrapper = data;
        // humane.error = humane.spawn({ addnCls: 'humane-jackedup-error', timeout: 2000 })
        this._toaster.TOAST_ERROR({
          message: await this._translate.translate$('labelServerFailedToLoad'),
        });
        this.wait = false;

        // try {
        //   $('#menu').hide();
        // } catch (e) {}
      }
    } else if (this.assessmentKey) {
      var email = '';
      var password = '';

      try {
        const _res = await this._assessmentApi.getProductAssessmentCredentials$(
          this.assessmentKey
        );
        // $http({
        //     method: 'GET',
        //     url: '/api/productassessment/credentials/' + assessmentKey
        // }).then(function successCallback(response) {

        if (!_res) {
          window.location.href =
            'https://secure.evalex.com/completed.aspx?AssessmentKey=' +
            this.assessmentKey +
            '&OdyStatus=2';
          return;
        }
        this._assessmentId = _res.assessmentId ? +_res.assessmentId : null;
        this._email = _res.username;

        await SystemStorage.INSTANCE.base.setItem$(
          'assessmentId',
          this._assessmentId
        );
        await SystemStorage.INSTANCE.base.setItem$('email', this._email);

        this._trackingId = _res.trackingId ? +_res.trackingId : null;
        await SystemStorage.INSTANCE.base.setItem$(
          'trackingId',
          this._trackingId
        );
        /**/
        if (this._trackingId) {
          this._router.navigate(['/applicant/questionnaire/knockout']);
          return;
        }

        /*Consent form ordering fix*/
        // getApplicantDetailsFromAssessmentId(_assessmentId);

        //Get the Applicants questionnaires via evalex guid
        try {
          const _res = await this._assessmentApi.getProductAssessmentGuid$(
            this.assessmentKey
          );
          // $http.get('/api/productassessment/guid/' + assessmentKey)

          this.product = _res;
          this.ApplicantName = _res.ApplicantName;
          this.product.Progress = this.product.Progress.toFixed(2);
          this.wait = false;

          await SystemStorage.INSTANCE.base.setItem$(
            'reasoningScore',
            this.product.ReasoningScore
          );

          if (this.product.Progress == 100.0) {
            window.location.href =
              'https://secure.evalex.com/completed.aspx?AssessmentKey=' +
              this.assessmentKey +
              '&OdyStatus=1';
          }

          // try {
          //   $('#menu').hide();
          // } catch (e) {}
        } catch (ex) {
          window.location.href =
            'https://secure.evalex.com/completed.aspx?AssessmentKey=' +
            this.assessmentKey +
            '&OdyStatus=0';
        }
      } catch (ex) {
        alert('credentials error: ' + JSON.stringify(ex));
      }
    }
  }

  private async _getApplicantLookups() {
    this.wait = true;
    try {
      const _res = await this._applicantApi.getApplicantLookups$(1).toPromise();
      // $http.get('/api/applicant/GetLookups/')
      this._lookups = _res!;

      const _lepl = this.formLookups!.evalexPositionLevelLookup;
      const _lept = this.formLookups!.evalexPositionTypeLookup;

      this.formLookups = {
        nationalityLookup: _res!.nationalities,
        qualificationLookup: _res!.qualifications,
        evalexPositionLevelLookup: _lepl,
        evalexPositionTypeLookup: _lept,
        provinceLookup: [
          { id: 2, description: 'Eastern Cape' },
          { id: 3, description: 'Free State' },
          { id: 4, description: 'Gauteng' },
          { id: 5, description: 'KwaZulu-Natal' },
          { id: 6, description: 'Limpopo' },
          { id: 7, description: 'Mpumalanga' },
          { id: 8, description: 'Northern Cape' },
          { id: 9, description: 'North West' },
          { id: 10, description: 'Western Cape ' },
        ] as IBasicLookup[],
        genderLookup: _res!.genders,
        raceLookup: _res!.races,
        languageLookup: _res!.languages,
        languageLevelLookup: _res!.languageLevels,
        disabilityLookup: _res!.disabilities,

        applicationTypes: _res!.applicationTypes,
        bitLookups: _res!.bitLookups,
        maritalStatuses: _res!.maritalStatuses,
        transactingMethods: _res!.transactingMethods,
      };

      this.wait = false;
    } catch (ex) {
      // humane.error = humane.spawn({ addnCls: 'humane-jackedup-error', timeout: 2000 })
      this._toaster.TOAST_ERROR({
        message: await this._translate.translate$('labelServerFailedToLoad'),
      });
      this.wait = false;
    }
  }

  public closeMobileRotateModal() {
    this._modalMobileRotate.hide();
    this._showNextModal();
  }

  public closeModal() {
    this._showNextModal();
  }

  // private _hurdleModalTemplateBody: SafeHtml | null = null;
  // public get hurdleModalTemplateBody() {
  //   return this._hurdleModalTemplateBody || '<p>test</p>';
  // }

  private _modalStatus = -1;
  private async _showNextModal() {
    let firstQuestionnaire = this.product.Questionnaires.find(
      (x: any) => x.PercentageDone == 0
    );

    if (this._modalStatus === -1) {
      const value =
        (await SystemStorage.INSTANCE.base.getItem$(
          'honesty-statement-2-modal'
        )) === 'true';
      ++this._modalStatus;

      if (!value) {
        // $('#modalHonestyStatement').modal('hide');
        this._modalHonestyStatement2.hide();
        setTimeout(() => {
          // $('#modalHonestyStatement').modal('show');
          this.showModalHonestyStatement2(true);
        }, 500);
      } else {
        this._showNextModal();
      }
    } else if (this._modalStatus === 0) {
      const value =
        (await SystemStorage.INSTANCE.base.getItem$(
          'honesty-statement-modal'
        )) === 'true';
      ++this._modalStatus;

      if (
        !value &&
        firstQuestionnaire &&
        firstQuestionnaire.Id != 1100 &&
        firstQuestionnaire.Id != 1101 &&
        firstQuestionnaire.Id != 1102
      ) {

        // $('#modalHonestyStatement').modal('hide');
        this._modalHonestyStatement.hide();
        setTimeout(() => {
          // $('#modalHonestyStatement').modal('show');
          this.showModalHonestyStatement();
        }, 500);
      } else {
        this._showNextModal();
      }
    } else if (this._modalStatus === 1) {
      /*HURDLE POPUPS*/
      const _ati_selected =
        (await SystemStorage.INSTANCE.base.getSpecialItem$(
          '_ati-' + this._assessmentId + '-selected'
        )) ||
        /*sessionStorage.getItem('_ati-' + this._assessmentId + '-selected')*/ 'null';
      if (_ati_selected !== 'credit') {
        const _res =
          await this._assessmentApi.getProductHurdleGroupTemplateGet$();
        // $http.get('/api/productassessment/ProductHurdleGroupTemplateGet')
        ++this._modalStatus;
        if (_res && _res.Key) {
          const value =
            (await SystemStorage.INSTANCE.base.getSpecialItem$(
              'hurdle-modal-' + _res.Key
            )) === 'true';
          if (!value) {
            await SystemStorage.INSTANCE.base.setSpecialItem$(
              'hurdle-modal-' + _res.Key,
              'true'
            );
            this._modalHurdleTemplate.hide();
            setTimeout(() => {
              // $('#modalHurdleTemplate').modal('show');
              this.showModalHurdleTemplate(_res.TemplateBody);
            }, 500);
          } else {
            this._showNextModal();
          }
        }
      }
    }
  }

  public async updateApplicant(applicant: any) {
    applicant.validateDetails = false;

    const _assessmentId = await SystemStorage.INSTANCE.base.getItemAsInt$(
      'assessmentId'
    );
    var data = {
      Assessment: { Id: _assessmentId },
      Applicant: objectKeysToStartUpper(applicant),
    };

    data.Applicant.ContactNumber = data.Applicant.MobileNumber;
    delete data.Applicant.MobileNumber;

    //console.log(data);

    try {
      const _res = await this._applicantApi.putApplicantUpdate$(data);

      // $http.put('/api/Applicant/Update/', data)
      // humane.error = humane.spawn({ addnCls: 'humane-jackedup-success', timeout: 2000 });
      // humane.error(this._translate.translate$(('_SavedSuccessfully_'));
      AppComponent.TOAST({
        class: ERootClass.SUCCESS,
        message: await this._translate.translate$('labelSavedSuccessfully'),
      });

      // $('#validateApplicantDetails').modal('hide');
      this._modalValidateApplicantDetails.hide();

      try {
        const _res =
          await this._applicantApi.getApplicantEmploymentDetailsCheck$();
        // $http.get('/api/applicant/ApplicantEmploymentDetailsCheck')
        if (_res.Code === 'applicant-employment-details/check/required') {
          this._router.navigate(['/applicant/employment-details/form']);
          return;
        } /*else if (this.mobileRotate.isMobileDevice === true) {
                      this.mobileRotate.show();
                  } */ else {
          this._showNextModal();
        }
        this.wait = false;
      } catch (ex) {
        // this.wrapper = data;
        // humane.error = humane.spawn({ addnCls: 'humane-jackedup-error', timeout: 2000 })
        this._toaster.TOAST_ERROR({
          message: await this._translate.translate$('labelServerFailedToLoad'),
        });
        this.wait = false;
      }
    } catch (ex: any) {
      // console.error(this._translate.translate$('labelFailed') + '<br/>' + ex.ExceptionMessage);
      this._toaster.TOAST_ERROR({
        message:
          (await this._translate.translate$('labelFailed')) +
          '<br/>' +
          ex.ExceptionMessage,
      });
    }
  }
  private _acceptingConsentForm = false;
  public get acceptingConsentForm() {
    return this._acceptingConsentForm;
  }
  public async acceptConsentForm$() {
    if (this._acceptingConsentForm === false) {
      this._acceptingConsentForm = true;
      const _assessmentId = await SystemStorage.INSTANCE.base.getItemAsInt$(
        'assessmentId'
      );
      try {
        const _res = await this._assessmentApi
          .putAcceptConsentForm$
          //this._assessmentId!
          ();
        // $http.put('/api/Assessment/AcceptConsentForm/' + _assessmentId, {})
        // $('#consentForm').modal('hide');
        this._modalConsentForm.hide();
        if (this.Applicant.validateDetails === true) {
          // $('#validateApplicantDetails').modal();
          this.showModalValidateApplicantDetails();
        } else {
          try {
            const _res =
              await this._applicantApi.getApplicantEmploymentDetailsCheck$();
            // $http.get('/api/applicant/ApplicantEmploymentDetailsCheck')

            if (_res.Code === 'applicant-employment-details/check/required') {
              this._router.navigate(['/applicant/employment-details/form']);
              return;
            } /*else if (this.mobileRotate.isMobileDevice === true) {
                              this.mobileRotate.show();
                          } */ else {
              this._showNextModal();
            }
            this.wait = false;
            this._acceptingConsentForm = false;
          } catch (ex) {
            // this.wrapper = data;
            // humane.error = humane.spawn({ addnCls: 'humane-jackedup-error', timeout: 2000 });
            this._toaster.TOAST_ERROR({
              message: await this._translate.translate$(
                'labelServerFailedToLoad'
              ),
            });
            this.wait = false;
            this._acceptingConsentForm = false;
          }
        }
      } catch (ex: any) {
        // this.wrapper = data;
        // humane.error = humane.spawn({ addnCls: 'humane-jackedup-error', timeout: 2000 });
        // console.error(this._translate.translate$('labelFailed') + '<br/>' + ex.ExceptionMessage);
        this._toaster.TOAST_ERROR({
          message:
            (await this._translate.translate$('labelFailed')) +
            '<br/>' +
            ex.ExceptionMessage,
        });
        this._acceptingConsentForm = false;
      }
    }
  }

  private async _logout(defaultDomain: string | null = null) {
    // if (sessionStorage) {
    //   for (let sso of Object.keys(sessionStorage)) {
    //     if (sso.startsWith('_ati-')) {
    //       sessionStorage.removeItem(sso);
    //     }
    //   }
    // }
    // await SystemStorage.INSTANCE.base.clear$('???');

    try {
      const _res = await this._authApi.postLogout$().toPromise();
      // $http.post("/api/auth/logout")

      await SystemStorage.INSTANCE.base.setItem$('assessmentId', null);
      await SystemStorage.INSTANCE.base.setItem$('trackingId', null);
      await SystemStorage.INSTANCE.base.setItem$('email', null);

      var defaultRedirect =
        defaultDomain == null
          ? '/applicant/check'
          : `${defaultDomain}/applicant/check`;
      var _redirectUrl =
        (await SystemStorage.INSTANCE.base.getItem$('partner-redirect-url')) ||
        defaultRedirect;
      var _triggerUrl = await SystemStorage.INSTANCE.base.getItem$(
        'partner-trigger-url'
      );
      // _redirectUrl = JSON.parse(_redirectUrl);
      var _paramSep = !_redirectUrl.includes('?') ? '?' : '&';
      _redirectUrl =
        _redirectUrl + _paramSep + 'COMPLETE=1&TIMEOUT=0&REASON=COMPLETE';

      // _triggerUrl = JSON.parse(_triggerUrl!);

      //IF NEED TO TRIGGER
      if (_triggerUrl !== null && _triggerUrl !== '/applicant/check') {
        _triggerUrl = _triggerUrl + '&isComplete=true';
        try {
          const _res = this._httpClient.get(_triggerUrl).toPromise();
        } catch (ex) {}
      }

      //KEEP DATA
      // const _persistenceData = await localforage.getItem(
      //   '_persistantFieldsKey'
      // );
      // var language = localStorage.LanguageId;
      // localStorage.clear();
      // localStorage.LanguageId = language;

      // await localforage.clear();
      // await localforage.setItem('_persistantFieldsKey', _persistenceData);

      await SystemStorage.INSTANCE.base.clear$(); //logout-before-partner-redirect-url 'actual-logout'

      //DO REDIRECT
      if (!this._isRedirecting) {
        this._isRedirecting = true;
        window.location.href = _redirectUrl;
      }
    } catch (ex: any) {
      // humane.error = humane.spawn({ addnCls: 'humane-jackedup-error', timeout: 5000 })
      this._toaster.TOAST_ERROR({
        message:
          (await this._translate.translate$('labelFailed')) +
          '<br/>' +
          ex.ExceptionMessage,
        durationMs: 5000,
      });
    }
  }

  private _hasChecked = false;
  private _isRedirecting = false;

  private async _gotoCheck$() {
    if (!this._hasChecked) {
      this._hasChecked = true;

      var _redirectUrl =
        (await SystemStorage.INSTANCE.base.getItem$('partner-redirect-url')) ||
        '/applicant/check';
      // _redirectUrl = JSON.parse(_redirectUrl);
      if (_redirectUrl !== '/applicant/check') {
        this._logout(); //logout-before-partner-redirect-url
      } else {
        if (!this._isRedirecting) {
          this._isRedirecting = true;
          this._router.navigate(['/applicant/check']);
        }
      }
    }
  }

  public async onClickApplicantionTypeSelect(type: 'credit' | 'recruitment') {
    if (type === 'credit' || type === 'recruitment') {
      await SystemStorage.INSTANCE.base.setSpecialItem$(
        '_ati-' + this._assessmentId + '-shown',
        'true'
      );
      //sessionStorage.setItem('_ati-' + this._assessmentId + '-shown', 'true');
      await SystemStorage.INSTANCE.base.setSpecialItem$(
        '_ati-' + this._assessmentId + '-selected',
        type
      );
      //sessionStorage.setItem('_ati-' + this._assessmentId + '-selected', type);

      this._getQuestionnaires(false /*isFullyIntegratedAssessment*/);
      return;
    }
  }

  private _questionnaire: any = null;
  public get questionnaire() {
    return this._questionnaire;
  }

  private _questionnaireType: any = null;
  // startAssessment(q: any) {
  //   this._questionnaire = q;
  //   this._questionnaireType = q.QuestionnaireType;

  //   $('#instruct-inner').html(this._questionnaire.Instruction);
  // }

  private _selectedQ: any = null;
  public get selectedQ() {
    return this._selectedQ;
  }

  _startQuestionnaire(questionnaire: any) {
    if (questionnaire.ShowInstructions === true) {
      this._selectedQ = questionnaire;
      this._selectedQ.onCloseModalInstruction = () => {
        if (this.selectedQ.ShowHonestyStatement === true) {
          this._selectedQ.onCloseModalInstruction = () => {
            if (questionnaire.AssessmentURL) {
              window.location.href = questionnaire.AssessmentURL;
            } else {
              // OPEN POPUP
              // $('#modalErrorCreatingAssessment').modal();
              this.showModalErrorCreatingAssessment();
            }
          };
          // $('#modalHonestyStatement2').modal();
          this.showModalHonestyStatement2();
        } else {
          if (questionnaire.AssessmentURL) {
            window.location.href = questionnaire.AssessmentURL;
          } else {
            // OPEN POPUP
            // $('#modalErrorCreatingAssessment').modal();
            this.showModalErrorCreatingAssessment();
          }
        }
      };
      // $('#modalInstructions').modal();
      this.showModalInstructions();
      return;
    }

    if (questionnaire.AssessmentURL) {
      window.location.href = questionnaire.AssessmentURL;
    } else {
      // OPEN POPUP
      // $('#modalErrorCreatingAssessment').modal();
      this.showModalErrorCreatingAssessment();
    }
  }

  private _questionnaireItemId: any = null;
  private _ttsAssessmentWindow = null;

  private _isPersonality: boolean | null = null;
  public get isPersonality() {
    return this._isPersonality;
  }

  private _navigateToGip(qiid: number) {
    this._router.navigate(['../gip', qiid /*this._questionnaireItemId*/], {
      relativeTo: this._route,
    });
  }

  private _getDate() {
    const currentDate = new Date();

    const currentDayOfMonth = currentDate.getDate();
    const currentMonth = currentDate.getMonth(); // Be careful! January is 0, not 1
    const currentYear = currentDate.getFullYear();

    return currentDayOfMonth + '-' + (currentMonth + 1) + '-' + currentYear;
    // "27112020"
  }

  private _questions: any[] = [];
  public get questions() {
    return this._questions;
  }
  private _firstQ: any = null;

  public async beginQuestionnaire(questionnaire: any) {
    if (
      questionnaire.Id === 1097 ||
      questionnaire.Id === 1098 ||
      questionnaire.Id === 1100 ||
      questionnaire.Id === 1101 ||
      questionnaire.Id === 1102
    ) {
      questionnaire.StartBtn = false;

      this._startQuestionnaire(questionnaire);

      return;
    }

    this._questionnaireType = questionnaire.QuestionnaireType;

    this._questionnaireItemId = questionnaire.QuestionnaireItemId;

    await SystemStorage.INSTANCE.base.setItem$(
      'QuestionnaireItemId',
      this._questionnaireItemId
    );

    // console.log(questionnaire.QuestionnaireType)
    if (questionnaire.QuestionnaireType == 6) {
      await SystemStorage.INSTANCE.base.setItem$(
        'assessmentId',
        this._assessmentId
      );
      await SystemStorage.INSTANCE.base.setItem$(
        'trackingId',
        this._trackingId
      );

      // console.log(questionnaire.Id)
      var dateString = this._getDate();
      //Can we not get gameId and Url form the Db?
      switch (questionnaire.Id) {
        case 84:
          await SystemStorage.INSTANCE.base.setItem$('gameId', 1);
          window.location.href = `/WebContent/games/reasoning/index.html?${dateString}`;
          return;
        case 85:
          await SystemStorage.INSTANCE.base.setItem$('gameId', 2);
          window.location.href = `/WebContent/games/word_recognition/index.html?${dateString}`;
          return;
        case 87:
          await SystemStorage.INSTANCE.base.setItem$('gameId', 3);
          window.location.href = `/WebContent/games/spelling/index.html?${dateString}`;
          return;
        case 86:
          await SystemStorage.INSTANCE.base.setItem$('gameId', 4);
          window.location.href = `/WebContent/games/sentence/index.html?${dateString}`;
          return;
        case 89:
          await SystemStorage.INSTANCE.base.setItem$('gameId', 5);
          window.location.href = `/WebContent/games/checking/index.html?${dateString}`;
          return;
        case 1089:
          await SystemStorage.INSTANCE.base.setItem$('gameId', 6);
          window.location.href = `/WebContent/games/deduction/index.html?${dateString}`;
          return;
        case 1090:
          await SystemStorage.INSTANCE.base.setItem$('gameId', 7);
          window.location.href = `/WebContent/games/process/index.html?${dateString}`;
          return;
        case 1091:
          await SystemStorage.INSTANCE.base.setItem$('gameId', 8);
          window.location.href = `/WebContent/games/interests/index.html?${dateString}`;
          return;
        case 83:
          await SystemStorage.INSTANCE.base.setItem$('gameId', 9);
          window.location.href = `/WebContent/games/numeric/index.html?${dateString}`;
          return;
        case 1092:
          await SystemStorage.INSTANCE.base.setItem$('gameId', 10);
          window.location.href = `/WebContent/games/reasoning/index.html?${dateString}`;
          return;
        case 1093:
          await SystemStorage.INSTANCE.base.setItem$('gameId', 11);
          window.location.href = `/WebContent/games/preference-profiler/index.html?${dateString}`;
          return;
        case 1103:
          await SystemStorage.INSTANCE.base.setItem$('gameId', 12);
          window.location.href = `/WebContent/games/sentence-construction/index.html?${dateString}`;
          return;
        case 1193:
          await SystemStorage.INSTANCE.base.setItem$('gameId', 13);
          window.location.href = `/WebContent/games/preference-profiler-credit/index.html?${dateString}`;
          return;
        case 1185:
          await SystemStorage.INSTANCE.base.setItem$('gameId', 14);
          window.location.href = `/WebContent/games/vocabulary/index.html?${dateString}`;
          return;
      }
      return;
    }

    //DEA
    if (questionnaire.QuestionnaireType == 16) {
      await SystemStorage.INSTANCE.base.setItem$(
        'questionnaireId',
        questionnaire.Id
      );
      // await SystemStorage.INSTANCE.base.setItem$('gameId', questionnaire.Id);
      this._router.navigate(['/applicant/questionnaire/dea']);
      return;
    }

    //SAPI
    if (questionnaire.Id == 1094) {
      window.location.href = 'ng/questionnaire/sapi?2e779';
      return;
    }

    // GIP
    if (questionnaire.QuestionnaireType == 9) {
      // this._selectedQ = questionnaire;
      // $('#modalInstructions').modal();
      //this.showModalInstructions();

      //this._getIntegrityQuestions();
      this._navigateToGip(this._questionnaireItemId);

      return;
    }

    //MC 1 & 2 & 3 & 4
    if (
      questionnaire.Id == 3 ||
      questionnaire.Id == 4 ||
      questionnaire.Id == 5 ||
      questionnaire.Id == 6
    ) {
      await SystemStorage.INSTANCE.base.setItem$(
        'questionnaireId',
        questionnaire.Id
      );
      window.location.href = 'ng/questionnaire/custom?2e779';

      return;
    }

    // ELSE WOS OR STYLES

    this._isPersonality = questionnaire.QuestionnaireType == 3 ? false : true;

    this._selectedQ = questionnaire;

    var qas = {
      assessmentId: this._assessmentId,
      QuestionnaireId: questionnaire.Id,
      Name: questionnaire.Description,
    };

    //this.htmlInstructions = $compile(questionnaire.Instruction)($scope);

    // $('#modalInstructions').modal();
    this.showModalInstructions();

    this.wait = true;

    try {
      this._questions = await this._applicantApi.postQuestionAssess$(qas);
      // $http.post('/api/questionassess/', qas)

      this.showQuestionnaire = false;
      this.showInstructions = false;

      this.showQuestions = true;

      this._firstQ = '#collapse' + this._questions[0].Id.toString();

      this.wait = false;
    } catch (ex) {
      this._toaster.TOAST_ERROR({
        message: await this._translate.translate$('labelServerFailedToLoad'),
      });
      this.wait = false;
    }
  }

  async next(question: any, _index: number) {
    this.waitAnswer = true;
    question.AssessmentId = parseInt(this._assessmentId!.toString());

    if (this._questionnaireType == 3) {
      var counter = 0;

      for (let _answer of question.Answers) {
        if (_answer.AnswerSelected != 7) counter++;
      }

      // angular.forEach(question.Answers, function (value, key) {
      //     if (value.AnswerSelected != 7) counter++;
      // });

      if (counter <= 3) {
        // humane.error(this._translate.translate$(('_Select4Answers_'));
        this._toaster.TOAST_ERROR({
          message: await this._translate.translate$('labelSelect4Answers'),
        });
        this.waitAnswer = false;
        return;
      }
    } else {
      // is personality
      var counter = 0;

      for (let _answer of question.Answers) {
        if (_answer.AnswerSelected != 7) counter++;
      }

      if (counter <= 0) {
        // humane.error(this._translate.translate$(('_Select1Answer_'));
        this._toaster.TOAST_ERROR({
          message: await this._translate.translate$('labelSelect1Answer'),
        });
        //
        this.waitAnswer = false;
        return;
      }
    }

    try {
      // $http.post('/api/question/', question)
      const _res = await this._applicantApi.postQuestion$(question);

      $('#' + question.OrderBy.toString()).click();
      $('#' + question.OrderBy.toString()).prop('disabled', true);
      question.Disabled = true;
      question.QClass = 'alert-success';
      $('#collapse' + question.OrderBy.toString()).collapse('hide');

      var nextOrderBy = question.OrderBy + 1;
      const _nextQuestion = this.questions[_index + 1] || null;
      if (_nextQuestion) {
        _nextQuestion.Disabled = false;
      }

      var selector = '#' + nextOrderBy.toString();

      $(selector).prop('disabled', false);
      // $(selector).click();
      $('#collapse' + nextOrderBy.toString()).collapse('show');

      var focused = '#c-' + question.OrderBy.toString();

      $('html, body').animate({ scrollTop: $(focused).offset()?.top }, 'slow');

      if (question.BtnName == 'FINISH') {
        // humane.error(this._translate.translate$(('_QuestionnaireCompleted_'));
        this._toaster.TOAST_SUCCESS({
          message: await this._translate.translate$(
            'labelQuestionnaireCompleted'
          ),
        });

        this._selectedQ.Status = 'fa fa-check';
        var today = new Date();
        var dd =
          today.getDate() + '/' + today.getMonth() + '/' + today.getFullYear();
        this._selectedQ.DateAssessed = dd;
        this._selectedQ.Completed = true;
        this._selectedQ.PercentageDone = 100;

        this.showQuestionnaire = true;
        this.showInstructions = false;
        this.showQuestions = false;

        window.location.reload();
      }

      this.waitAnswer = false;
    } catch (ex: any) {
      // this.wrapper = data;
      // humane.error = humane.spawn({ addnCls: 'humane-jackedup-error', timeout: 2000 })
      console.error(
        this._translate.translate$('labelFailed') +
          '<br/>' +
          ex.ExceptionMessage
      );
      this.waitAnswer = false;
      return;
    }
  }

  opcs(question: any, answer: any) {
    for (let a of question.Answers) {
      if (answer.Id != a.Id) {
        a.AnswerSelected = 7;
      } else {
        a.AnswerSelected = 1;
      }
    }

    $('td').removeClass('q-select');
    $('td#c' + answer.Id).addClass('q-select');
  }

  wos(question: any, answer: any, _value: number) {
    question.Answers?.filter((a: any) => a.AnswerSelected === _value)?.forEach(
      (a: any) => (a.AnswerSelected = 7)
    );

    answer.AnswerSelected = _value;
  }

  // OLD
  // wos(question: any, answer: any, alf: any) {
  //   console.log('>>', question, answer, alf);
  //   var val = document.getElementById(alf + answer.Id) as any;

  //   for (let a of question.Answers) {
  //     if (val!.value == a.AnswerSelected) {
  //       a.AnswerSelected = 7;
  //     } //else { value.AnswerSelected = 1; }
  //   }

  //   $(':radio[value="' + val.value + '"]').attr('checked', 'false');
  //   val.checked = true;
  //   answer.AnswerSelected = val.value;
  // }

  exit() {
    window.location.reload();
  }

  center(theElement: any) {
    var myElement = theElement;
    myElement.css({
      position: 'absolute',
      left: '50%',
      'margin-left': 0 - myElement.width() / 2,
    });
  }

  getNationalityCode() {
    return (
      this.lookups?.nationalities.find(
        (e) => e.id === this.Applicant.nationalityId
      )?.code || 'za'
    );
  }

  constructor(
    private _route: ActivatedRoute,
    private _companyApi: CompanyService,
    private _authApi: AuthService,
    private _assessmentApi: AssessmentService,
    private _applicantApi: ApplicantService,
    private _translate: SystemTranslationService,
    private _httpClient: HttpClient,
    private _oaiIntegrity: OaiIntegrityService,
    private _odyApi: OdysseyApiService,
    private _router: Router,
    private _sanitizer: DomSanitizer
  ) {
    this.formLookups = {
      nationalityLookup: [],
      evalexPositionLevelLookup: [],
      evalexPositionTypeLookup: [],
      qualificationLookup: [],
      provinceLookup: [],
      genderLookup: [],
      raceLookup: [],
      languageLookup: [],
      languageLevelLookup: [],
      disabilityLookup: [],

      applicationTypes: [],
      bitLookups: [],
      maritalStatuses: [],
      transactingMethods: [],
    };
  }

  async ngOnInit() {
    // this.logoUrl;
    this.wait = true;
    await this._translate.isReady$();
    await this._getApplicantLookups();

    // const _assessmentKey = this.assessmentKey || await localforage.getItem<string>('_assessmentKey');

    // await SystemStorage.INSTANCE.base.setItem$(
    //   '_assessmentKey',
    //   this.assessmentKey
    // );

    const _email = await SystemStorage.INSTANCE.base.getItem$('email');
    this.assessmentKey = await SystemStorage.INSTANCE.base.getItem$(
      '_assessmentKey'
    );

    if (this.assessmentKey) {
      // when assesskey sent from evalex;
      ApplicantHeaderComponent.SHOW = false;
    } else {
      // !this.assessmentKey
      if (!_email) {
        window.location.href = '/';
        return;
      }
    }

    //check if is principa
    const _isFullyIntegratedAssessment =
      ((await SystemStorage.INSTANCE.base.getItem$(
        '_isFullyIntegratedAssessment'
      )) ?? '') === 'true';
    await this._init(true, _isFullyIntegratedAssessment);

    if (!this.assessmentKey) {
      const value = await SystemStorage.INSTANCE.base.getItemAsInt$(
        'assessmentId'
      );
      if (value && value != 0) {
        await this._getApplicantDetailsFromAssessmentId(value!);
      }
    }

    var _parser = new UAParser();
    var _ua = _parser.getResult();
    var _device = _ua.device;

    this.mobileRotate = {
      isMobileDevice:
        /*true || */ _device !== undefined &&
        _device.type !== undefined &&
        (_device.type == 'mobile' || _device.type == 'tablet'),
      show$: async () => {
        if (this.mobileRotate!.isMobileDevice === true) {
          const value =
            (await SystemStorage.INSTANCE.base.getItem$(
              'mobileRotateInstructionsShown'
            )) === 'true';
          if (value !== true) {
            // $('#modalMobileRotate').modal();
            await SystemStorage.INSTANCE.base.setItem$(
              'mobileRotateInstructionsShown',
              'true'
            );
            this.showModalMobileRotate();
          }
        }
      },
      hide: () => {
        // $('#modalMobileRotate').modal('hide');
        this._modalMobileRotate.hide();
      },
    };

    //Angular UI - Datepicker
    // this.showWeeks = true;
    // this.toggleWeeks = function () {
    //     this.showWeeks = !this.showWeeks;
    // };

    // this.clear = function () {
    //     this.Applicant.DateOfBirth = null;
    // };

    // this.open = function ($event) {
    //     $event.preventDefault();
    //     $event.stopPropagation();

    //     this.opened = true;
    // };

    // this.dateOptions = {
    //     'year-format': "'yy'",
    //     'starting-day': 1
    // };

    // this.formats = ['dd-MMMM-yyyy', 'yyyy/MM/dd', 'shortDate'];
    // this.format = this.formats[0];

    // //Date validator
    // Object.isDate = function (obj) {
    //     return Object.prototype.toString.call(obj) === '[object Date]';
    // }

    // $("#dob").focus(function () {
    //     $('#dob').blur();
    // });

    if (this.Applicant.nationalityId === 249) {
      this.fields.push('race');
    }

    this.fields$ = of(this.fields);
    this.optionalFields$ = of(this.optionalFields);
    this.disableFields$ = of(this.disableFields);

    try {
      const _gameData = await SystemStorage.INSTANCE.base.getItem$('GameData');
      if (_gameData != undefined && _gameData != '') {
        // localize.initLanguage();
        //while (localize.resourceFileLoaded == false) {
        // }
        // humane.error = humane.spawn({ addnCls: 'humane-jackedup-warning', timeout: 3000, clickToClose: true })
        // humane.error(localStorage.PleaseWaitText);//'Saving game results.'

        this._toaster.TOAST_WARNING({
          message: await this._translate.translate$('labelSavingGameResults'),
        });

        try {
          await this._applicantApi.postGameScore$(_gameData);
          // $http.post('/api/gamescore/', '"' + localStorage.GameData + '"').
          await SystemStorage.INSTANCE.base.setItem$('GameData', '');

          // humane.error = humane.spawn({ addnCls: 'humane-jackedup-success', timeout: 2000 })
          // humane.error(localStorage.SavedSuccessfullyText); //Game progress saved.
          // $rootScope.$broadcast('gameDataUpdated');
          this._toaster.TOAST_SUCCESS({
            message: await this._translate.translate$('labelSavedSuccessfully'),
          });
          this._getQuestionnaires(_isFullyIntegratedAssessment);
        } catch (ex) {
          // humane.error = humane.spawn({ addnCls: 'humane-jackedup-error', timeout: 2000 })
          // humane.error(localStorage.FailedText);//'Error saving game results!'
          this._toaster.TOAST_WARNING({
            message: await this._translate.translate$('labelSavedFailed'),
          });
        }
      }

      const _GameData = await SystemStorage.INSTANCE.base.getItem$('GameData');
      if (_GameData != undefined && _GameData != '') {
        this._toaster.TOAST_WARNING({
          message: await this._translate.translate$('labelSavingGameResults'),
        });

        try {
          await this._applicantApi.postGameScore$(_GameData);
          await SystemStorage.INSTANCE.base.setItem$('GameData', '');
          // $http.post('/api/gamescore/', '"' + localStorage.GameData + '"').

          // localStorage.GameData = '';
          this._toaster.TOAST_SUCCESS({
            message: await this._translate.translate$('labelSavedSuccessfully'),
          });
          this._getQuestionnaires(_isFullyIntegratedAssessment);
        } catch (ex) {
          this._toaster.TOAST_WARNING({
            message: await this._translate.translate$('labelSavedFailed'),
          });
        }
      }
    } catch (e) {}
  }

  async ngAfterViewInit() {
  }
}
