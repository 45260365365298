import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Route, RouterStateSnapshot, UrlSegment, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { SystemTranslationService } from './system-translation.service';

@Injectable({
  providedIn: 'root',
})
export class SystemTranslationGuard  {
  constructor(public _translation: SystemTranslationService) {}
  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    // console.log('here');
    // const _isReady = await this._translation.isReady$();
    // console.log('here >> ready', _isReady);
    // return _isReady;
    return this._translation.isReady$();
  }

  canLoad(route: Route, segments: UrlSegment[]) {
    //console.log('here');
    // const _isReady = await this._translation.isReady$();
    // console.log('here >> ready', _isReady);
    // return _isReady;
    return this._translation.isReady$();
  }
}
