import {
  AfterViewInit,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

export enum ERootClass {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  TERTIARY = 'tertiary',
  SUCCESS = 'success',
  ERROR = 'error',
  WARN = 'warn',
  INFO = 'info',
}

export interface IButton {
  title: string;
  colourClass: ERootClass;
  dismiss: boolean;
  onClick: () => void | Promise<void>;
  disabled?: () => boolean;
}

export interface IModal {
  show: boolean;
  colourClass: ERootClass;
  size: 'xl' | 'lg' | 'md' | 'sm' | 'xs';
  title: SafeHtml;
  body: SafeHtml | null;
  footer: {
    buttons: {
      left: IButton[];
      right: IButton[];
    };
  };
}

@Component({
  selector: 'app-ui-modal',
  templateUrl: './ui-modal.component.html',
  styleUrls: ['./ui-modal.component.scss'],
})
export class UiModalComponent implements OnInit, AfterViewInit, OnDestroy {
  // @ViewChild('copyToElement') element: ElementRef | null = null;
  private readonly EMPTY_MODAL: IModal = {
    show: false,
    colourClass: ERootClass.PRIMARY,
    size: 'xl',
    title: this._sanitizer.bypassSecurityTrustHtml(''),
    body: null, // this._sanitizer.bypassSecurityTrustHtml(''),
    footer: {
      buttons: {
        left: [] as IButton[],
        right: [] as IButton[],
      },
    },
  };

  private _model: IModal = { ...this.EMPTY_MODAL };

  private _isReady = false;
  public get isReady() {
    return this._isReady;
  }

  public get model() {
    return this._model;
  }

  /*public get hasCopiedElement() {
    return !!this._copyElement;
  }*/

  /*private _copyElement: HTMLElement | null = null;
  private _clearCopyElement() {
    if (this._copyElement && this.element) {
      while (this.element.nativeElement.hasChildNodes()) {
        const _child = this.element.nativeElement.firstChild;
        this.element.nativeElement.removeChild(_child);
        this._copyElement.appendChild(_child);
      }

      this._copyElement = null;
    }
  }*/

  /*copyToBody(elementId: string) {
    this._clearCopyElement();

    const _el = document.getElementById(elementId);

    if (_el && this.element) {
      this._copyElement = _el;

      while (this._copyElement.hasChildNodes()) {
        const _child = this._copyElement.firstChild;
        if (_child) {
          this._copyElement.removeChild(_child);
          this.element.nativeElement.appendChild(_child);
        }
      }
    }
  }*/

  public init(model: {
    show: boolean;
    colourClass: ERootClass;
    size: 'xl' | 'lg' | 'md' | 'sm' | 'xs';
    title: string;
    body: string | null;
    footer: {
      buttons: {
        left: IButton[];
        right: IButton[];
      };
    };
  }) {
    // this._clearCopyElement();

    this._model = {
      ...model,
      title: this._sanitizer.bypassSecurityTrustHtml(model.title),
      body:
        model.body !== null
          ? this._sanitizer.bypassSecurityTrustHtml(model.body)
          : null,
    };
  }

  public onClickButton(b: IButton) {
    b.onClick();
    if (b.dismiss) {
      this.hide();
    }
  }

  public show() {
    document.querySelectorAll('.modal-body').forEach(e => {
      e.scrollTop = 0;
    })
    this._model.show = true;
  }

  public hide() {
    document.querySelectorAll('.modal-body').forEach(e => {
      e.scrollTop = 0;
    })
    this._model.show = false;
  }

  public update(model: { body?: string }) {
    if (model.body) {
      // this._clearCopyElement();
      this._model.body = this._sanitizer.bypassSecurityTrustHtml(model.body);
    }
  }

  constructor(private _sanitizer: DomSanitizer) {}

  ngAfterViewInit(): void {
    setTimeout(() => {
      this._isReady = true;
    }, 100);
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    // this._clearCopyElement();
  }
}
