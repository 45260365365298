import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormGroup, NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {
  IgxDialogComponent,
  ISelectionEventArgs,
} from '@infragistics/igniteui-angular';
import { TranslateService } from '@ngx-translate/core';
import * as localforage from 'localforage';
import { ApplicantHeaderComponent } from 'src/app/applicant/applicant-header/applicant-header.component';
import { ApplicantService } from 'src/app/core/applicant.service';
import { AssessmentService } from 'src/app/core/assessment.service';
import { AuthService } from 'src/app/core/auth/auth.service';
import { DroplinkService } from 'src/app/core/droplink.service';
import { OdysseyApiService } from 'src/app/core/odyssey-api.service';
import { UseragentService } from 'src/app/core/useragent.service';
import { IApplicantForm } from 'src/app/models/applicant-form';
import { IApplicantFormLookups } from 'src/app/models/applicant-form-lookups';
import { IBasicLookup } from 'src/app/models/basic-lookup';
import { ILanguageLookup } from 'src/app/models/language-lookup';
import { ISwitch, SystemStorage, wait$ } from 'src/app/shared/omt-lib';
import { SystemTranslationService } from 'src/app/shared/system-translation.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss'],
})
export class LandingPageComponent implements OnInit {
  public formSwitch = new ISwitch();
  public readonly FORMS = {
    ApplicantCheck: 'form-check',
    UsernameLogin: 'form-username',
  };

  public applicantData = {} as IApplicantForm;
  public optionalFields = [] as string[];

  public lookups: IApplicantFormLookups;
  public passFragment = '';
  public showLanguageSelect: boolean = false;

  public nationalityId: number | undefined;
  public idNumber: string | undefined;
  public assessmentLanguageid: number | undefined;
  public username: string | undefined;
  public applicantName: string = '';

  public assessmentLanguageLookup: ILanguageLookup[] = [];

  public modalTitle: string = '';
  public modalBody: string = '';
  public modalButton: string = '';

  public companyAlias: string = 'Odyssey';
  public popupCommand: string = '';
  public timeToComplete: string = '';

  private _logo: string = environment.defaultLogo;
  public get logo() {
    return this._logo || environment.defaultLogo;
  }
  public sponsorEmail = 'info@odysseytalent.com';

  public isReady: boolean = false;
  public isBusy: boolean = false;

  private isCreditCompany: boolean = false;

  private _key: string;
  private _defaultDomain: string = '';

  private idNumberExcludeRegex: RegExp | undefined;

  // selectedNationalityId: number | null = null;
  projectUniqueIdConfig: {
    pattern: string | null;
    excludePattern: string | null;
    min: number | null;
    max: number | null;
  } = { pattern: null, excludePattern: null, min: 6, max: null };
  projectNationalityId: number | null = null;

  nationalityUniqueIdConfig:{
    pattern: string | null;
    excludePattern: string | null;
    min: number | null;
    max: number | null;
  } = { pattern: "[a-zA-Z0-9]{6,20}", excludePattern: "[^a-zA-Z0-9]", min: 6, max: 20 };

  public get isVccb() {
    return ['vericred-dra-only'].includes(
      this._route.snapshot.fragment ? this._route.snapshot.fragment : ''
    );
  }

  public get instructionTranslationType() {
    return this.isVccb
      ? 'contentdroplink.landing.modal.instructions-za-only.body'
      : 'contentdroplink.landing.modal.instructions.body';
  }

  public get idTranslationType() {
    return this.isVccb
      ? 'labeldroplink.form.label.id-number-za-only.'
      : 'labeldroplink.label.form.id-number.';
  }

  @ViewChild('modal', { read: IgxDialogComponent, static: false })
  public popup!: IgxDialogComponent;

  @ViewChild('idNumberT', { read: ElementRef, static: false })
  public idNumberInput!: ElementRef;

  @ViewChild('formCtrl') _form!: NgForm;

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    public ua: UseragentService,
    private _odyApi: OdysseyApiService,
    private _droplinkApi: DroplinkService,
    private _applicantApi: ApplicantService,
    private _translate: SystemTranslationService,
    private _auth: AuthService,
    private cdref: ChangeDetectorRef
  ) {
    this._key = this._route.snapshot.params.key;
    this.lookups = {
      nationalityLookup: [],
      evalexPositionLevelLookup: [],
      evalexPositionTypeLookup: [],
      qualificationLookup: [],
      provinceLookup: [],
      genderLookup: [],
      raceLookup: [],
      languageLookup: [],
      languageLevelLookup: [],
      disabilityLookup: [],

      applicationTypes: [],
      bitLookups: [],
      maritalStatuses: [],
      transactingMethods: [],
    };
  }
  async ngOnInit() {   
    await this._translate.isReady$();
    this.assessmentLanguageid = await this._translate.getLanguageId();
    this.isReady = false;
    this.idNumberExcludeRegex = new RegExp(this.nationalityUniqueIdConfig.excludePattern!,"g")

    //clear local store
    await SystemStorage.INSTANCE.base.clear$(); //'fresh-start'

    this._logo = await SystemStorage.INSTANCE.local.getWhiteLabelLogo$(
      this._odyApi,
      { droplinkUid: this._key }
    );

    try {
      //check free domain
      let data;
      await this._droplinkApi.checkDroplinkData$(this._key).then((n) => {
        data = n as any;
        if (
          data.Data == false &&
          !data.DefaultDomain.includes(window.location.host)
        ) {
          this._defaultDomain = data.DefaultDomain;
          this._showModal(
            'contentdroplink.landing.modal.invalid-key.title',
            {},
            'contentdroplink.landing.modal.invalid-key.body',
            { companyAlias: this.companyAlias },
            'buttonbutton.continue',
            {},
            'droplink/invalid-domain'
          );
        }
      });

      //get droplink status
      const result = await this._droplinkApi.getStatus$(this._key);

      if (result) {
        this.isCreditCompany = result.isCreditCompany;
        this.projectUniqueIdConfig = result.projectUniqueIdConfig;
        this.projectNationalityId = result.projectNationalityId || null;
        

        //time to complete
        //company alias
        //required?
        this.companyAlias = result.companyAlias;
        this.timeToComplete = result.timeToComplete;

        await SystemStorage.INSTANCE.base.setItem$(
          'timeToComplete',
          result.timeToComplete
        );

        await SystemStorage.INSTANCE.base.setItem$(
          'companyAlias',
          result.companyAlias
        );

        switch (result.code) {
          case 'droplink/valid':
            //get logo
            // this._logo = await SystemStorage.INSTANCE.local.getWhiteLabelLogo$(this._odyApi, {droplinkUid: this._key});
            /*await this._droplinkApi
              .whiteLabel$(this._key)
              .then((n) =>
                n!.whiteLabel.EnableWhiteLabel == true
                  ? (this._logo = n!.whiteLabel.LogoUrl)
                  : 'https://dev-cdn.odyssess.com/images/logos/odyssey.svg'
              );*/

            if (result.customFields !== null) {
              if (!result.customFields.split(',').includes('id-number')) {
                this._router.navigate([`../../su-ni`, this._key], {
                  fragment: this.passFragment,
                  queryParams: {
                    'sponsor-email': this.sponsorEmail,
                  },
                });
              }
            }

            await this._applicantApi
              .getApplicantLookups$(this.assessmentLanguageid)
              .toPromise()
              .then((n) => {
                this.lookups.nationalityLookup = n!.nationalities;
                this.lookups.languageLookup = n!.languages;
              });
            // if (
            //   !this.projectNationalityId &&
            //   this.isVccb &&
            //   this.lookups.nationalityLookup.find((e) => +e.id === 249)
            // ) {
            //   this.lookups.nationalityLookup = [
            //     this.lookups.nationalityLookup.find((e) => +e.id === 249)!,
            //   ];
            //   this.nationalityId = 249;
            //   this.projectUniqueIdConfig = {
            //     pattern: '[0-9]{13}',
            //     excludePattern:'[^0-9]',
            //     min: 13,
            //     max: 13,
            //   };
            //   this.projectNationalityId = 249;
            // } else if (this.projectNationalityId) {
            //   this.lookups.nationalityLookup = [
            //     this.lookups.nationalityLookup.find(
            //       (e) => +e.id === this.projectNationalityId
            //     )!,
            //   ];
            //   this.nationalityId = this.projectNationalityId;
            //   if (this.nationalityId === 249) {
            //     this.projectUniqueIdConfig = {
            //       pattern: '[0-9]{13}',
            //       excludePattern:'[^0-9]',
            //       min: 13,
            //       max: 13,
            //     };
            //   }
            // } 

            if(!this.projectNationalityId && this.isVccb){
              //set project nat to 249
              this.projectNationalityId = 249
            }

            if(this.projectNationalityId) {
              this.nationalityId = this.projectNationalityId
              //nationalityConfig function
              this.updateNationalityConfig(this.nationalityId)
            }
           

            this.showLanguageSelect =
              this._route.snapshot.fragment === 'lang' ||
              this._route.snapshot.fragment === 'langx';

            if (this.showLanguageSelect) {
              //get assessment languages
              await this._translate.http.getAssessmentLanguages$(this._key).then(
                (n) =>{
                  let mappedLanguages = n.map((ea) => {
                    return {
                      id: ea.Id,
                      description: ea.Description,
                      code: ea.Code,
                    } as ILanguageLookup;
                  });
                  this.assessmentLanguageLookup = this.getTranslatedLanguages(mappedLanguages)
            });
            }

            this.passFragment = ['abi', 'kwaden', 'vericred-dra-only'].includes(
              this._route.snapshot.fragment ? this._route.snapshot.fragment : ''
            )
              ? this._route.snapshot.fragment!
              : '';

            this.formSwitch.activate(this.FORMS.ApplicantCheck);
            // this.canShowForm = true;
            break;
          case 'droplink/insufficient-credits':
            this._showModal(
              'contentdroplink.landing.modal.insufficient-credits.title',
              {},
              'contentdroplink.landing.modal.insufficient-credits.body',
              {
                companyAlias: this.companyAlias,
                sponsorEmail: this.sponsorEmail,
              },
              'buttonbutton.continue',
              {},
              'droplink/insufficient-credits'
            );
            //this.formSwitch.deactivate(this.FORMS.ApplicantCheck);
            // this._showModal('modal.insufficient-credits.title', 'modal.insufficient-credits.innerHTML', 'invalid-continue', {
            //   sponsorEmail: this._sponsorEmail,
            // });
            break;
          case 'droplink/link-expired':
            //this.formSwitch.deactivate(this.FORMS.ApplicantCheck);
            this._showModal(
              'contentdroplink.landing.modal.link-expired.title',
              {},
              'contentdroplink.landing.modal.link-expired.body',
              {
                sponsorEmail: this.sponsorEmail,
                companyAlias: this.companyAlias,
              },
              'buttonbutton.continue',
              {},
              'droplink/link-expired'
            );
            // this._showModal('modal.link-expired.title', 'modal.link-expired.innerHTML', 'invalid-continue', {
            //   sponsorEmail: this._sponsorEmail,
            // });
            break;
          case 'droplink/invalid-key':
            this._showModal(
              'contentdroplink.landing.modal.invalid-key.title',
              {},
              'contentdroplink.landing.modal.invalid-key.body',
              { companyAlias: this.companyAlias },
              'buttonbutton.continue',
              {},
              'droplink/invalid-key'
            );
            //this.formSwitch.deactivate(this.FORMS.ApplicantCheck);
            // this._showInvalidKeyModal();
            break;
          case 'droplink/insufficient-system-resources':
            this._showModal(
              'contentDroplinkResourcesHeading',
              {},
              'contentDroplinkResourcesBody',
              {},
              'buttonbutton.continue',
              {},
              'droplink/insufficient-system-resources'
            );
            //this.formSwitch.deactivate(this.FORMS.ApplicantCheck);
            // this._showInvalidKeyModal();
            break;
        }
      }
    } catch {
      this.formSwitch.deactivate(this.FORMS.ApplicantCheck);
      //this._showInvalidKeyModal();
    }

    this.isReady = true;
  }

  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }

  //onClickLanguage
  public async onClickLanguage(event: ISelectionEventArgs) {
    await this._translate
      .useLanguage(event.newSelection.value)
      .then((n) => (this.assessmentLanguageid = event.newSelection.value));
    await this._applicantApi
      .getApplicantLookups$(this.assessmentLanguageid!)
      .toPromise()
      .then((n) => {
        this.lookups.nationalityLookup = n!.nationalities;
        this.lookups.languageLookup = n!.languages;
    });

    if (this.showLanguageSelect) {
      //get assessment languages
      await this._translate.http.getAssessmentLanguages$(this._key).then(
        (n) =>{
          let mappedLanguages = n.map((ea) => {
            return {
              id: ea.Id,
              description: ea.Description,
              code: ea.Code,
            } as ILanguageLookup;
          });
          this.assessmentLanguageLookup = this.getTranslatedLanguages(mappedLanguages)
        }
      );
    }
  }

  //onSubmitUsernameLogin
  //login and redirect
  //else incorrect

  //onSubmitApplicantCheck
  //get allpicant status
  //sponsor email
  //if code can create
  //navigate to sign up
  //incomplete
  //popup
  //complete
  //popup
  //id invalid
  //popoup
  //nationaly invalid
  //popup
  //link invalid
  //popup

  async onSubmitApplicantCheck() {
    this.isBusy = true;
    if (this._key && this.nationalityId && this.idNumber) {
      /*
      droplink-applicant/droplink-invalid
      droplink-applicant/nationality-invalid

      droplink-applicant/in-project-and-incomplete
      droplink-applicant/in-project-and-complete
      droplink-applicant/can-create
      */
      const result = await this._droplinkApi.getApplicantStatus$(
        this._key,
        String(this.nationalityId),
        this.idNumber
      );

      if (result) {
        if (result.sponsorEmail) {
          this.sponsorEmail = result.sponsorEmail;
        }

        if (result.applicantName) {
          this.applicantName = result.applicantName;
        }

        switch (result.code) {
          case 'droplink-applicant/can-create':
            if(!this.isCreditCompany){
            this._showModal(
              'contentdroplink.sign-up.modal.instructions-before-bio.title',
              {},
              'contentdroplink.sign-up.modal.instructions-before-bio.body',
              {
                timeToComplete: this.timeToComplete,
                companyAlias: this.companyAlias,
              },
              'buttonbutton.continue',
              {},
              'droplink-applicant/can-create'
            );
            } else {
              this._router.navigate([`/sign-up`, this._key], {
                fragment: this.passFragment,
                queryParams: {
                  nationality: this.nationalityId,
                  'id-number': this.idNumber,
                  'sponsor-email': this.sponsorEmail,
                },
              })
            }

            break;
          case 'droplink-applicant/in-project-and-incomplete':
            // show username popup and login
            this._showModal(
              'contentdroplink.landing.modal.in-project-and-incomplete.title',
              {},
              'contentdroplink.landing.modal.in-project-and-incomplete.body',
              {
                applicantName: this.applicantName,
                companyAlias: this.companyAlias,
                sponsorEmail: this.sponsorEmail,
              },
              'buttonbutton.continue',
              {},
              'droplink-applicant/in-project-and-incomplete'
            );
            break;
          case 'droplink-applicant/in-project-and-complete':
            // show completed popup
            if (this.isVccb) {
              this._showModal(
                'contentdroplink.landing.modal.in-project-and-complete-vccb-only.title',
                {},
                'contentdroplink.landing.modal.in-project-and-complete-vccb-only.body',
                {
                  applicantName: this.applicantName,
                  companyAlias: this.companyAlias,
                },
                'buttonbutton.continue',
                {},
                'droplink-applicant/in-project-and-complete'
              );
              break;
            } else {
              this._showModal(
                'contentdroplink.landing.modal.in-project-and-complete.title',
                {},
                'contentdroplink.landing.modal.in-project-and-complete.body',
                {
                  applicantName: this.applicantName,
                  companyAlias: this.companyAlias,
                },
                'buttonbutton.continue',
                {},
                'droplink-applicant/in-project-and-complete'
              );
              break;
            }
          case 'droplink-applicant/id-number-invalid':
            this._showModal(
              '',
              {},
              'contentdroplink.landing.modal.invalid-id.body',
              {},
              'buttonbutton.continue',
              {},
              'droplink-applicant/id-number-invalid'
            );
            //this._modal.showBasic(null, 'Invalid id number');
            break;
          case 'droplink-applicant/nationality-invalid':
            this._showModal(
              '',
              {},
              'contentdroplink.landing.modal.invalid-nationality.body',
              {},
              'buttonbutton.continue',
              {},
              'droplink-applicant/nationality-invalid'
            );
            //this._modal.showBasic(null, 'Invalid nationality');
            break;
          case 'droplink-applicant/droplink-invalid':
            this._showModal(
              'contentdroplink.landing.modal.invalid-key.title',
              {},
              'contentdroplink.landing.modal.invalid-key.body',
              {
                applicantName: this.applicantName,
                companyAlias: this.companyAlias,
              },
              'buttonbutton.continue',
              {},
              'droplink-applicant/droplink-invalid'
            );
            //this._showInvalidKeyModal();

            break;
        }
      }
    }
    this.isBusy = false;
  }

  async onSubmitUsernameLogin() {
    this.isBusy = true;
    if (this.username && this.idNumber) {
      let email = this.username;
      await this._auth.checkData$(email).then((_data) => {
        if (
          _data &&
          _data.Data === false &&
          !_data.DefaultDomain.includes(window.location.host)
        ) {
          window.location.href = `${_data.DefaultDomain}`;
        }
      });

      await this._auth
        .postLogin$(email, this.idNumber)
        .toPromise()
        .then(
          async (data) => {
            if (data) {
              // sessionStorage.super = data.super;

              await SystemStorage.INSTANCE.base.setItem$(
                'timeToComplete',
                data.timeToComplete
              );
              await SystemStorage.INSTANCE.base.setItem$(
                'companyAlias',
                data.companyAlias
              );

              let _trackingId = data.trackingId
                ? data.trackingId.toString()
                : null;

              await SystemStorage.INSTANCE.base.setItem$(
                'trackingId',
                _trackingId
              );

              if (
                _trackingId &&
                _trackingId !== null &&
                _trackingId !== 'null'
              ) {
                await SystemStorage.INSTANCE.base.setItem$('email', email);
                return this._router.navigate([
                  '/applicant/questionnaire/knockout',
                ]);
              }

              if (data /*.token*/) {
                await this._auth
                  .postUserAgent$(data.assessmentId, this.ua.ua)
                  .toPromise()
                  .then(async (success) => {
                    if (data.isApplicant) {
                      await SystemStorage.INSTANCE.base.setItem$(
                        'assessmentId',
                        data.assessmentId
                      );
                      await SystemStorage.INSTANCE.base.setItem$(
                        'email',
                        email
                      );
                      this.clearLocallyStoredGameData$(+data.assessmentId);
                      // window.location.href = '../doAssessment.html?2e779';
                      this._router.navigate(['applicant', 'dashboard']);
                    } else {
                      await SystemStorage.INSTANCE.base.setItem$(
                        'email',
                        email
                      );
                      // return localforage.setItem(
                      //   'oua-user',
                      //   JSON.stringify({
                      //     email: email,
                      //     roles: data.roles.split(','),
                      //   })
                      // );
                      await SystemStorage.INSTANCE.base.setItem$(
                        'isRestricted',
                        data.isRestricted ? 'true' : 'false'
                      );
                      // window.location.href = '../dash.html?2e779';
                      return this._router.navigate(['/admin']);
                    }
                    this.isBusy = false;
                    return;
                  });
              } else {
                // this.errorMessage = 'Authentication failed! ' + data;
                this.isBusy = false;
              }
            }
            return;
          },
          (data) => {
            if (
              data.error.Message &&
              data.error.Message == 'All Assessments Completed!'
            ) {
              //window.location.href = '../#/completed';
              this._router.navigate(['/complete']);
            } else if(            
              data.error.Message &&
              data.error.Message == 'Assessment Creation Delayed.'
            ) {
              this._router.navigate(['/complete'], { queryParams: { d: '1' } });
            } else {
              // this.errorMessage = data.error.Message;
              // this.isBusy = false;
            }
            this.isBusy = false;
          }
        );
    }
    // const username = this.username;
    // const password = this.idNumber;
    // const login = await this._odyApi.// .login(username, password);
    // if (login === true) {
    //   window.location.href = '/WebContent/doAssessment.html?2e6ff';
    // } else {
    //   // in correct username
    //   this._modal.showBasic(
    //     null,
    //     await this.translate('modal.invalid-username.innerHTML', {
    //       sponsorEmail: this._sponsorEmail,
    //     })
    //   );
    // }
  }

  modalButtonClick(command: string) {
    switch (command) {
      case 'droplink-applicant/can-create':
        this.popup.close();
        this._router.navigate([`/sign-up`, this._key], {
          fragment: this.passFragment,
          queryParams: {
            nationality: this.nationalityId,
            'id-number': this.idNumber,
            'sponsor-email': this.sponsorEmail,
          },
        });
        break;
      case 'droplink-applicant/in-project-and-incomplete':
        this.formSwitch.activate(this.FORMS.UsernameLogin);
        this.popup.close();
        break;
      case 'droplink-applicant/in-project-and-complete':
        this._router.navigate(['/complete']);
        break;
      case 'droplink/invalid-domain':
        this.formSwitch.activate(this.FORMS.UsernameLogin);
        this.popup.close();
        window.location.href = `${this._defaultDomain}/link/${this._key}`;
        break;
      default:
        this._router.navigate(['/login']);
        break;
    }
  }

  public async  onNgModelChange(value: string, el:any) {
    if (this.idNumberExcludeRegex) {
      if (value && el) {
        const rValue = await value.replace(this.idNumberExcludeRegex, '');
          el!.value = rValue;
          this.idNumber = rValue;
      }
    }
  }

  public updateNationalityConfig(nationalityId: number){
    this.nationalityUniqueIdConfig = this.lookups.nationalityLookup.find(x => x.id == nationalityId)?.uniqueIdConfigJson ?? 
    { pattern: "[a-zA-Z0-9]{6,20}", excludePattern: "[^a-zA-Z0-9]", min: 6, max: 20 };
    
      if(this.nationalityUniqueIdConfig.excludePattern){
        this.idNumberExcludeRegex = new RegExp(this.nationalityUniqueIdConfig.excludePattern, "g")
      } else {
        this.idNumberExcludeRegex = undefined;
      }
  }


  onNationalityChanged(event: any){
    this.nationalityId = event.newSelection.id
    if(this.nationalityId){
      this.updateNationalityConfig(this.nationalityId)
      this.idNumberInput.nativeElement.value = ''
      this._form.form.get('id-number')?.reset();
    }
  } 

  private async _showModal(
    titleKey: string = '',
    titleInterpolation: Object = {},
    bodyKey: string = '',
    bodyInterpolation: Object = {},
    buttonKey: string = '',
    buttonInterpolation: Object = {},
    buttonCommand: string = ''
  ) {
    this.modalTitle = '';
    this.modalBody = '';
    this.modalButton = '';

    if (titleKey != '') {
      await this._translate
        .translate$(titleKey, titleInterpolation)
        .then((n) => (this.modalTitle = n));
    }

    if (bodyKey != '') {
      await this._translate
        .translate$(bodyKey, bodyInterpolation)
        .then((n) => (this.modalBody = n));
    }

    if (buttonKey != '') {
      await this._translate
        .translate$(buttonKey, buttonInterpolation)
        .then((n) => (this.modalButton = n));
    }

    this.popupCommand = buttonCommand;
    this.popup.open();
  }

  private getTranslatedLanguages(n:ILanguageLookup[]){
    let joinedArray = this.lookups.languageLookup
    .filter(x => n.some(y => y.id === x.id))
    .map(x => ({
        ...x,
        n: n.filter(y => y.id === x.id)
    }))
    .map((z) => {
      return {
        id: z.id,
        description: z.description,
        code: z.n[0].code,
      } as ILanguageLookup;
    })
    return joinedArray
  }

  get invalidId() : boolean {
    if(
      !this.idNumber  || 
      (this.idNumber && (this.idNumber.length < this.nationalityUniqueIdConfig.min! || 
      this.idNumber.length > this.nationalityUniqueIdConfig.max! || 
      this.idNumber.match(this.nationalityUniqueIdConfig.excludePattern!) || 
      this.idNumber == null))
    )
    {
      return true;
    } else {
      return false;
    }
  }

  private async clearLocallyStoredGameData$(currentAssessmentId: number) {
    await SystemStorage.INSTANCE.base.setItem$('reasoningScore', '');
    await SystemStorage.INSTANCE.base.setItem$('GameData', '');
    await SystemStorage.INSTANCE.base.setItem$('gameId', '');
    await SystemStorage.INSTANCE.base.setItem$('G1AssessmentId', '');
    await SystemStorage.INSTANCE.base.setItem$('G2AssessmentId', '');
    await SystemStorage.INSTANCE.base.setItem$('G3AssessmentId', '');
    await SystemStorage.INSTANCE.base.setItem$('G4AssessmentId', '');
    await SystemStorage.INSTANCE.base.setItem$('G5AssessmentId', '');
    await SystemStorage.INSTANCE.base.setItem$('G6AssessmentId', '');
    await SystemStorage.INSTANCE.base.setItem$('G7AssessmentId', '');
    await SystemStorage.INSTANCE.base.setItem$('G8AssessmentId', '');
    await SystemStorage.INSTANCE.base.setItem$('G9AssessmentId', '');
    await SystemStorage.INSTANCE.base.setItem$('G10AssessmentId', '');
  }
}
