import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { IAssessmentBaseQuestion } from 'src/app/models/assessment-base-question';
import { IAssessmentResponse } from 'src/app/models/assessment-response';
import { IAssessmentSelectionQuestion } from 'src/app/models/assessment-selection-question';
import { IMultipleSelectQuestion } from 'src/app/models/multiple-select-question';
import { UiModalComponent } from 'src/app/shared/ui/ui-modal/ui-modal.component';
import { QuestionUtils } from '../../util/question-utils';


@Component({
  selector: 'app-ui-question-multiple-choice-select-one',
  templateUrl: './ui-question-multiple-choice-select-one.component.html',
  styleUrls: ['./ui-question-multiple-choice-select-one.component.scss'],
})
export class UiQuestionMultipleChoiceSelectOneComponent implements OnInit {
  private _selectedOptionId: number | null = null;
  @ViewChild('modalA') modal: UiModalComponent | null = null;
  // tslint:disable-next-line: no-output-rename
  // @Output('change') _optionChange = new EventEmitter<number>();

  @Input() _questionnaireId: number | null = null;
  @Input()  question: IAssessmentSelectionQuestion | null = null;
  @Output() selectedOption = new EventEmitter<IAssessmentResponse[]>();
  @Output() isValid = new EventEmitter<boolean>();

  public timerStart: number = new Date().getTime() / 1000;
  public changes: number = 0;

  private responses: IAssessmentResponse[] = [];

  public get selectedOptionId() {
    return this._selectedOptionId;
  }

  private _isBusy = false;
  public get isBusy() {
    return this._isBusy;
  }

  public get canShowBackButton() {
    return false;
  }

  public get canGoBack() {
    return false;
  }

  ngOnChanges(changes: SimpleChanges){
    // question
    if( changes['question'].previousValue && changes['question'].previousValue.questionId != changes['question'].currentValue.questionId){
      if (this.question && this.question.answers) {
        this._selectedOptionId = Number(this.question.answers[0].response)
        this.responses = [{
          id: this.question.answers[0].id,
          response: this.question.answers[0].response,
        }]
        this.selectedOption.emit(
          this.responses
        );
        this.timerStart = new Date().getTime() / 1000;
        this.changes = this.question.changes;
      }
    }
    this.isValid.emit(this.getValidity());
  }

  public onClickOption(option: number) {
    if (this._selectedOptionId !== option) {

      this._selectedOptionId = option;
      if(this.question && this.question.options && this._selectedOptionId){
        if(this.responses.filter(x => x.response == null).length == 0){
          this.changes++
        }
        this.responses = [{
                  id: this.question.questionId,
                  response: String(this._selectedOptionId)
          }]
        this.selectedOption.emit(
          this.responses
        );

      }
      this.isValid.emit(this.getValidity());
    }
  }

  public onDoubleClick(option: number) {
    this.onClickOption(option);
    //this.onClickContinue();
  }

  constructor(/*private api: ApiService, */ private _router: Router) {
    //super();
  }

  async ngOnInit() {
    if (this.question && this.question.answers) {
      this.changes = this.question.changes;
      this._selectedOptionId = Number(this.question.answers[0].response)
      this.responses = [{
        id: this.question.answers[0].id,
        response: this.question.answers[0].response,
      }]
      this.selectedOption.emit(
        this.responses
      );
    }
    this.isValid.emit(this.getValidity());

  }

  getValidity(): boolean {
    return QuestionUtils.isValidAnswer(this.responses, "Select")
  }

  public saveTimeAndActions(){
    let endTime = new Date().getTime() / 1000
    let delta = endTime - this.timerStart
    this.question!.time += Math.floor(delta);
    this.question!.changes = this.changes;
  }
}
