import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
} from '@angular/common/http';
import { catchError, Observable, tap, throwError } from 'rxjs';
import { PageLoaderService } from './shared/page-loader.service';

@Injectable()
export class _PageLoaderInterceptor implements HttpInterceptor {
  private readonly _excludeUrls = [
    /\/_exclude_\/$/,
    // /\/survey\/put$/,
    // /\/DigitalReport\/AssessmentResourceOptionWrite$/,
    // /\/questionnaire\/activity-log-add$/,
  ];

  private _isExcluded(test: string) {
    let _flag = false;
    for (let r of this._excludeUrls)
      if (r.test(test)) {
        _flag = true;
      }
    return _flag;
  }

  constructor(private _pageLoaderService: PageLoaderService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (!this._isExcluded(req.url)) {
      this._pageLoaderService.show();
      return next.handle(req).pipe(
        catchError((error: any /*, e: Observable<HttpEvent<any>>*/) => {
          this._pageLoaderService.hide();
          return throwError(() => error);
        }),
        tap((e) => {
          if (e instanceof HttpResponse) {
            this._pageLoaderService.hide();
          }
        })
      );
    } else {
      return next.handle(req);
    }
  }
}
