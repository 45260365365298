import { Component, OnInit } from '@angular/core';
import { UseragentService } from 'src/app/core/useragent.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-browser-support-message',
  templateUrl: './browser-support-message.component.html',
  styleUrls: ['./browser-support-message.component.scss'],
})
export class BrowserSupportMessageComponent implements OnInit {
  public readonly env = environment;
  public chromeImage: string = '';
  public edgeImage: string = '';

  constructor(public ua: UseragentService) {
    this.chromeImage = `${this.env.url.cdn}/images/browser/chrome.png`
    this.edgeImage = `${this.env.url.cdn}/images/browser/edge.png`;
  }

  ngOnInit(): void {}
}
