<div class="row">
    <div class="col-md-1 col-lg-2"></div>
    <div class="col">
      <div class="card">
        <div class="card-header text-center text-white bg-primary">
          <h4 class="card-title" [innerHTML]="'contentProcessing' | translate"></h4>
        </div>
        <div class="card-body">
          <p [innerHTML]="'contentAssessmentProcessing' | translate">
          </p>
          <!-- <p>We are currently processing your result. This operation takes about one minute to completed.</p> -->
          <div class="row">
            <div class="col-12">&nbsp;</div>
          </div>
          <!-- <p>Processing{{ dots }}</p> -->
          <div class="lds-grid">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
          <!-- <div class="row">
            <div class="col-12">&nbsp;</div>
          </div> -->
          <p [innerHTML]="'contentPleaseBePatient' | translate"></p>
        </div>
      </div>
    </div>
    <div class="col-md-1 col-lg-2"></div>
  </div>
  