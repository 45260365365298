<div class="py-2">
  <style>
    .demoImage {
      width: 100%;
      max-width: 640px;
    }
  </style>
  <div class="ori-no">
    <section class="_panel">
      <header class="panel-heading">
        <div class="row justify-content-center">
          <div class="panel-body">
            <div class="_container">
              <div class="alert alert-warning alert-dismissable text-center">
                <button
                  type="button"
                  class="close"
                  data-dismiss="alert"
                  aria-hidden="true"
                >
                  &times;
                </button>
                <strong>{{ "labelQuestionOnce" | translate }}</strong>
              </div>
            </div>
            <!--collapse start-->
            <div class="panel-group" id="accordion">
              <ng-container *ngIf="currentIntegritySection == 1">
                <div
                  class="panel panel-info"
                  *ngFor="let q of questions; let i = index"
                >
                  <div class="panel-body">
                    {{ q.Number }}. {{ q.Statement }}
                  </div>
                  <div class="panel-heading">
                    <div data-toggle="buttons">
                      <div class="btn-group btn-group-justified">
                        <label
                          class="btn"
                          [ngClass]="{
                            'btn-primary': answers[i % 5] !== 'A',
                            'btn-tertiary': answers[i % 5] === 'A'
                          }"
                          (click)="onClickIntegrityAnswer(q, 'A')"
                        >
                          Agree Fully
                        </label>
                        <label
                          class="btn"
                          [ngClass]="{
                            'btn-primary': answers[i % 5] !== 'B',
                            'btn-tertiary': answers[i % 5] === 'B'
                          }"
                          (click)="onClickIntegrityAnswer(q, 'B')"
                        >
                          Agree Somewhat
                        </label>
                        <label
                          class="btn"
                          [ngClass]="{
                            'btn-primary': answers[i % 5] !== 'C',
                            'btn-tertiary': answers[i % 5] === 'C'
                          }"
                          (click)="onClickIntegrityAnswer(q, 'C')"
                        >
                          Disagree Somewhat
                        </label>
                        <label
                          class="btn"
                          [ngClass]="{
                            'btn-primary': answers[i % 5] !== 'D',
                            'btn-tertiary': answers[i % 5] === 'D'
                          }"
                          (click)="onClickIntegrityAnswer(q, 'D')"
                        >
                          Disagree Fully
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-container>

              <ng-container *ngIf="currentIntegritySection == 2">
                <div
                  class="panel panel-info"
                  *ngFor="let q of questions; let i = index"
                >
                  <div
                    class="panel-body s2-question"
                    [innerHTML]="safeQuestion"
                  ></div>
                  <div class="panel-heading text-center">
                    <div class="btn-group" data-toggle="buttons">
                      <label
                        class="btn"
                        [ngClass]="{
                          'btn-primary': answers[i % 5] !== 'A',
                          'btn-tertiary': answers[i % 5] === 'A'
                        }"
                        (click)="onClickIntegrityAnswer(q, 'A')"
                      >
                        &nbsp;A&nbsp;
                      </label>
                      <label
                        class="btn"
                        [ngClass]="{
                          'btn-primary': answers[i % 5] !== 'B',
                          'btn-tertiary': answers[i % 5] === 'B'
                        }"
                        (click)="onClickIntegrityAnswer(q, 'B')"
                      >
                        &nbsp;B&nbsp;
                      </label>
                      <label
                        class="btn"
                        [ngClass]="{
                          'btn-primary': answers[i % 5] !== 'C',
                          'btn-tertiary': answers[i % 5] === 'C'
                        }"
                        (click)="onClickIntegrityAnswer(q, 'C')"
                      >
                        &nbsp;C&nbsp;
                      </label>
                      <label
                        class="btn"
                        [ngClass]="{
                          'btn-primary': answers[i % 5] !== 'D',
                          'btn-tertiary': answers[i % 5] === 'D'
                        }"
                        (click)="onClickIntegrityAnswer(q, 'D')"
                      >
                        &nbsp;D&nbsp;
                      </label>
                      <label
                        class="btn"
                        [ngClass]="{
                          'btn-primary': answers[i % 5] !== 'E',
                          'btn-tertiary': answers[i % 5] === 'E'
                        }"
                        (click)="onClickIntegrityAnswer(q, 'E')"
                      >
                        &nbsp;E&nbsp;
                      </label>
                    </div>
                  </div>
                </div>
              </ng-container>

              <div class="panel panel-default">
                <div class="panel-body row">
                  <div class="col-3">&nbsp;</div>
                  <div class="col-6 text-center">
                    {{ currentIntegritySetId }} of 16
                  </div>
                  <div class="col-3 text-right">
                    <button
                      type="button"
                      class="btn btn-primary"
                      (click)="onClickContinue()"
                      [disabled]="!isDone()"
                    >
                      {{ currentIntegritySetId == 16 ? "Finish" : "Continue" }}
                      <span
                        class="glyphicon glyphicon-ok"
                        aria-hidden="true"
                      ></span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <!--collapse end-->
          </div>
        </div>
      </header>
    </section>
  </div>
</div>

<app-ui-modal #modalA>
  <p>
    <b><i>Welcome</i></b> to the <b>GIP</b> assessment.
  </p>
  <p>
    The GIP questionnaire consists of two parts and a total of 50 questions.
  </p>
  <ul>
    <li>
      The first part (Section 1) consists of 45 questions. When completing this
      part of the assessment, remember that there are no
      <b><i>'right or wrong'</i></b> answers. Please answer truthfully and
      select the response that best describes how you really feel or think about
      the question being asked.
    </li>
    <li>
      The second part (Section 2) consists of 5 items. These items describe your
      <b><i>real experiences in life</i></b> and offers five responses. Choose
      the answer that describes your life experiences best. Please note that the
      first two questions in this part of the assessment consist of two
      sub-sections.
    </li>
  </ul>
  <p>
    <b>INSTRUCTIONS</b>
  </p>
  <ol>
    <li>Please complete the questionnaire in full.</li>
    <li>
      Read each question carefully and answer it directly on the computer by
      choosing one of the following responses:<br />
      <img src="/assets/img/GIPButtonsA.png" alt="" class="demoImage" /><br />
      Pick the response that describes you and your situation best, by clicking
      on the appropriate option on the screen. The colour of the selected option
      will now be green.<br />
      <img src="/assets/img/GIPButtonsA2.png" alt="" class="demoImage" /><br />
      Click 'Next' to advance to the next question.
    </li>
    <li>
      It is our experience that the GIP takes between 15 and 20 minutes to
      complete. Work quickly and do not ponder too long on any one question.
      Select the first alternative that comes to mind as your choice. Don't try
      to over analyse the questions, just answer them on face value.
    </li>
    <li>
      Please note that the assessment will end automatically after 30 minutes.
    </li>
    <li>
      Once you have chosen an answer and progressed to the next question you
      will not be able to go back and change a previous question.
    </li>
    <li>
      <b
        ><u>Please note - Very important</u><br />
        This assessment has a built-in Lie Detector Scale that <u>will</u> pick
        up the degree to which you are not open and truthful in answering the
        questions - a high score on this scale may render your test invalid and
        disqualify you from this assessment.</b
      >
    </li>
    <li>Do not speak to anyone else about the questions.</li>
  </ol>
  <p>
    Thank you for providing the information in completing this assessment. We
    hope you will enjoy it and benefit from the results.
  </p>
  <b>Click the 'CONTINUE' button to proceed.</b>
</app-ui-modal>
<app-ui-modal #modalB>
  <h2>SECTION 2</h2>
  <h3>VERIFIABLE FACTORS</h3>
  <p><b>PLEASE NOTE</b></p>
  <p>
    The questions in this section have been worded as clearly as possible to
    ensure you understand what information is being gathered, so that you can
    answer them accurately and honestly.
  </p>
  <p>
    There are <b>Five Questions</b> covered in this Section of the <b>GIP</b>.
  </p>
  <p>
    Read each question carefully and answer it directly on the computer by
    choosing the response that describes you best.
  </p>
  <p class="text-center">
    <b>Click the 'CONTINUE' button to proceed.</b>
  </p>
</app-ui-modal>
