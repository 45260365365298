import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Data, Route, RouterStateSnapshot, UrlSegment, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { ISessionUser, SystemStorage } from '../../shared/omt-lib';

export enum ERoles {
  VERIFY_OTP = 'verify-otp',

  ODYSSEY_SUPPORT = 'odyssey-support',
  COMPANY_ADMIN = 'company-admin',
  NORMAL_USER = 'normal-user',
  MIMIC_COMPANY = 'mimic-company',
  ASSUPOL_BASIC_USER = 'assupol-basic-user',
  ODYSSEY_DEVELOPER = 'odyssey-developer',
  ASSUPOL_ADMIN_USER = 'assupol-admin-user',

  APPLICANT = 'applicant',
}

@Injectable({
  providedIn: 'root',
})
export class AuthRoleGuard  {
  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ) {
    return this._can(childRoute.data);
  }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this._can(route.data);
  }

  canLoad(route: Route, segments: UrlSegment[]) {
    return this._can(route.data);
  }

  private async _can(data: Data | undefined): Promise<boolean> {
    const _roles = (data?.roles || []) as ERoles[];
    const _user = await SystemStorage.INSTANCE.session.getUser$();

    return _user?.hasRole(..._roles) || false;
  }
}
