<router-outlet></router-outlet>

<div class="ui-toast-container">
  <div
    *ngFor="let t of toasts"
    class="ui-toast {{ t.class }} {{ t.durationMs > 0 ? '' : 'fading' }}"
  >
    <div class="ui-toast-title" *ngIf="t.title">{{ t.title }}</div>
    <div class="ui-toast-body">{{ t.message }}</div>
  </div>
</div>

<app-ui-page-loader></app-ui-page-loader>
