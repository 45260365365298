import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ReportService } from 'src/app/core/report.service';

@Component({
  selector: 'app-integration-report-download',
  templateUrl: './integration-report-download.component.html',
  styleUrls: ['./integration-report-download.component.css'],
})
export class IntegrationReportDownloadComponent implements OnInit {
  private get _key(): string | null {
    return this._route.snapshot.queryParams.key ?? null;
  }
  private get _id(): string | null {
    return this._route.snapshot.queryParams.id ?? null;
  }

  constructor(private _route: ActivatedRoute, private _api: ReportService) {}

  async ngOnInit() {
    console.log(this._key, this._id);
    if (this._key && this._id) {
      const _data = await this._api.downloadSelectionReport$(
        this._key,
        this._id
      );
      console.log(_data);
    } else {
    }
  }
}
