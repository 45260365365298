import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { map } from 'rxjs/operators';
import { IApplicantEmploymentDetailLookups } from '../models/applicant-employment-detail-lookups';
import { IApplicantForm } from '../models/applicant-form';
import { IApplicantFormExtended } from '../models/applicant-form-extended';
import { IApplicantLookups } from '../models/applicant-lookups';
import { IApplicantSupport } from '../models/applicant-support';
import { IApplicantTalentAnalytics } from '../models/applicant-talent-analytics';
import { IAreaLookup } from '../models/area-lookup';
import { IBasicLookup } from '../models/basic-lookup';
import { INationalityLookup } from '../models/nationality-lookup';
import { IPrescreeningApplicant } from '../models/prescreening-applicant';
import { IPrescreeningQuestionAnswer } from '../models/prescreening-question-answer';
import { IPrescreeningUserCase } from '../models/prescreening-user-case';
import { IPrescreeningUserCaseDetail } from '../models/prescreening-user-case-detail';

@Injectable({
  providedIn: 'root',
})
export class ApplicantService {
  constructor(private _httpClient: HttpClient, public datepipe: DatePipe) {}

  consentFormGet$(languageIdAlt:number) {
    return (
      this._httpClient
        .get<{
          uniqueConsentHeading: string | null;
          uniqueConsent: string | null;
        }>(`/api/applicant/ConsentFormGet/?LanguageIdAlt=${languageIdAlt}`)
        //.pipe(map((r) => r.response))
        .toPromise()
    );
  }

  emailPasswordtoApplicant$(assessmentId: number) {
    return this._httpClient
      .post<{message: string }>('/api/email/post/', {
        assessmentId: assessmentId,
      })
      .pipe(map((r) => r.message))
      .toPromise();
  }

  getPrescreeningApplicants$(search: string = '') {
    return this._httpClient.get(`/api/Prescreening/GetList/${search}`).pipe(
      map((p: any, index: number) => {
        let pr: IPrescreeningApplicant[] = p.map((x: any) => {
          return {
            applicantEmail: x.ApplicantEmail,
            id: x.ApplicantId,
            applicantName: x.ApplicantName,
            assessmentType: x.AssessmentType,
            company: x.Company,
            created: x.Created,
            division: x.Division,
            nationalityCode: x.NationalityCode,
            password: x.Password,
            projectName: x.ProjectName,
            trackingId: x.TrackingId,
            trackingStatus: {
              id: x.TrackingStatusId,
              description: x.TrackingStatusName,
            } as IBasicLookup,
            username: x.Username,
          } as IPrescreeningApplicant;
        });
        return pr;
      })
    );
  }

  getPrescreeningQuestionAnswers$(trackingId: number) {
    return this._httpClient.get(`/api/Prescreening/Get/${trackingId}`).pipe(
      map((p: any, index: number) => {
        let pr: IPrescreeningQuestionAnswer[] = p.map((x: any) => {
          return {
            answer: x.Answer,
            otherText: x.OtherText,
            question: x.Question,
          } as IPrescreeningQuestionAnswer;
        });
        return pr;
      })
    );
  }

  getPrescreeningUserCaseList$(statusId: number) {
    return this._httpClient
      .get(`/api/prescreening/UserCaseList?statusId=${statusId}`)
      .pipe(
        map((p: any, index: number) => {
          let pr: IPrescreeningUserCase[] = p.map((x: any) => {
            return {
              applicantGroupId: x.ApplicantGroupId,
              applicantName: x.ApplicantName,
              code: x.Code,
              companyName: x.CompanyName,
              createdDate: x.CreatedDate,
              id: x.Id,
              note: x.Note,
              projectName: x.ProjectName,
              status: x.Status,
              updatedBy: x.UpdatedBy,
              updatedDate: x.UpdatedDate,
            } as IPrescreeningUserCase;
          });
          return pr;
        })
      );
  }

  getPrescreeningUserCase$(userCaseId: number) {
    return lastValueFrom(this._httpClient
      .get(`/api/prescreening/UserCaseView?userCaseId=${userCaseId}`)
      .pipe(
        map((p: any, index: number) => {
          let pr: IPrescreeningUserCaseDetail = {
            applicantDetails: {
              id: p[5][0].Id,
              idNumber: p[5][0].IdNumber,
              contactNumber: p[5][0].ContactNumber,
              names: p[5][0].Names,
            }, //d[5],
            heuristicsCode: p[0][0].Code, //d[0][0].Code as string,
            blackList: {},
            applicants: p[2].map((x: any) => {
              return {
                groupId: x.GroupId,
                name: x.Name,
                idNumber: x.IdNumber,
                nationalityCode: x.NationalityCode,
                email: x.Email,
                contactNumber: x.ContactNumber,
                dateOfBirth: x.DateOfBirth,
                currentCompany: x.CurrentCompany,
                currentPosition: x.CurrentPosition,
                dateCreated: x.DateCreated,
              };
            }),
            devices: p[3].map((x: any) => {
              return {
                groupId: x.GroupId,
                deviceTag: x.DeviceTag,
                assessmentJSON: x.AssessmentJSON,
                trackingJSON: x.TrackingJSON,
                ipAddress: x.IpAddress,
                OSFamily: x.OSFamily,
                OSVersion: x.OSVersion,
                deviceFamily: x.DeviceFamily,
                UAFamily: x.UAFamily,
                UAVersion: x.UAVersion,
                createdDate: x.CreatedDate,
              };
            }), //d[3],
            ipAddesses: p[4].map((x: any) => {
              return {
                deviceTag: x.DeviceTag,
                ipAddress: x.IpAddress,
                OSFamily: x.OSFamily,
                OSVersion: x.OSVersion,
                deviceFamily: x.DeviceFamily,
                UAFamily: x.UAFamily,
                UAVersion: x.UAVersion,
                createdDate: x.CreatedDate,
              }; //d[4],
            }),
            checking: {
              companyCount: p[6][0].Count, //+d[6][0].Count,
              applicantCount: p[7][0].Count, //+d[7][0].Count,
              matched: {
                idNumber: p[8].map((x: any) => {
                  return {
                    type: x.Type,
                    id: x.Id,
                    idNumber: x.IdNumber,
                    contactNumber: x.ContactNumber,
                    names: x.Names,
                    email: x.Email,
                  };
                }), //d[8],
                contactNumber: p[9].map((x: any) => {
                  return {
                    type: x.Type,
                    id: x.Id,
                    idNumber: x.IdNumber,
                    contactNumber: x.ContactNumber,
                    names: x.Names,
                    email: x.Email,
                  };
                }), //d[9],
                name: p[10].map((x: any) => {
                  return {
                    type: x.Type,
                    id: x.Id,
                    idNumber: x.IdNumber,
                    contactNumber: x.ContactNumber,
                    names: x.Names,
                    email: x.Email,
                  };
                }), //d[10],
                email: p[11].map((x: any) => {
                  return {
                    type: x.Type,
                    id: x.Id,
                    idNumber: x.IdNumber,
                    contactNumber: x.ContactNumber,
                    names: x.Names,
                    email: x.Email,
                  };
                }), //d[11],
              },
            },
            events: p[12].map((x: any) => {
              return {
                applicantId: x.ApplicantId,
                description: x.Description,
                name: x.Name,
                dateCreated: x.DateCreated,
              };
            }), //d[12],
            cases: {
              passed: p[13][0].Passed,
              failed: p[13][0].Failed,
              total: p[13][0].Total,
            }, //d[13][0],
            assessmentHistory: p[14].map((x: any) => {
              return {
                groupId: x.GroupId,
                assessmentType: x.AssessmentType,
                dateCreated: x.DateCreated,
                dateAssessed: x.DateAssessed,
                assessmentStatus: x.AssessmentStatus,
                projectName: x.ProjectName,
                companyName: x.CompanyName,
              };
            }), //d[14],
            caseHistory: p[15].map((x: any) => {
              return {
                applicantGroupId: x.ApplicantGroupId,
                heuristicsCheckCode: x.HeuristicsCheckCode,
                createdDate: x.CreatedDate,
                note: x.Note,
                updatedBy: x.UpdatedBy,
                status: x.Status,
                updatedDate: x.UpdatedDate,
              };
            }), //d[15],
          } as any; //IPrescreeningUserCaseDetail
          return pr;
        })
    ))
  }

  public async passUserCase$(UserCaseId: number, Note: string) {
    await this._httpClient
      .post('/api/prescreening/UserCasePass', { UserCaseId, Note })
      .toPromise();
    return true;
  }

  public async failUserCase$(UserCaseId: number, Note: string) {
    await this._httpClient
      .post('/api/prescreening/UserCaseFail', { UserCaseId, Note })
      .toPromise();
    return true;
  }

  getApplicantsSupport$(searchString: string) {
    return this._httpClient
      .get(`/api/applicant/GetApplicantsSupport?searchString=${searchString}`)
      .pipe(
        map((p: any, index: number) => {
          let pr: IApplicantSupport[] = p.map((x: any) => {
            return {
              company: x.Company,
              dateAssessed: x.DateAssessed,
              dateCreated: x.DateCreated,
              division: x.Division,
              email: x.Email,
              firstName: x.FirstName,
              id: x.Id,
              idNumber: x.IdNumber,
              lastName: x.LastName,
              nationalityCode: x.NationalityCode,
              product: x.Product,
              project: x.Project,
            } as IApplicantSupport;
          });
          return pr;
        })
      );
  }

  ///api/applicant/ApplicationTypeInfo
  getApplicationTypeInfo$() {
    return this._httpClient
      .get<{
        count: { recruitment: number; credit: number };
        companies: { credit: string; recruitment: string };
        show: {
          selection: boolean;
          ifRecruitmentSelected: boolean;
          ifCreditSelected: boolean;
          draInBoth: boolean;
          creditDraMessage: boolean | null;
        };
      }>(`/api/applicant/ApplicationTypeInfo`)
      .toPromise();
  }

  ///api/Applicant/Update/
  putApplicantUpdate$(data: any) {
    return this._httpClient
      .put<any>(`/api/Applicant/Update/`, data)
      .toPromise();
  }

  //$http.post('/api/question/', question)
  postQuestion$(question: any) {
    return lastValueFrom(this._httpClient.post<any>(`/api/question/post`, question));
  }

  //$http.post('/api/questionassess/', qas)
  postQuestionAssess$(qas: any) {
    return this._httpClient
      .post<any>(`/api/questionassess/post`, qas)
      .toPromise();
  }

  //$http.post('/api/gamescore/', '"' + localStorage.GameData + '"').
  postGameScore$(gameData: string) {
    return this._httpClient
      .post<{ Message: string }>(`/api/gamescore/post-a`, { data: gameData })
      .toPromise();
  }

  // /api/applicant/ApplicantEmploymentDetailsCheck
  getApplicantEmploymentDetailsCheck$() {
    return this._httpClient
      .get<any>(`/api/applicant/ApplicantEmploymentDetailsCheck`)
      .toPromise();
  }

  getApplicantLookups$(assessmentLanguageId: number) {
    return this._httpClient
      .get(
        `/api/applicant/getlookups?assessmentLanguageId=${assessmentLanguageId}`
      )
      .pipe(
        map((p: any, index: number) => {
          let lookups = {
            disabilities: p.disabilities.map((x: any) => {
              return {
                id: x.Id,
                description: x.Description,
              } as IBasicLookup;
            }),
            genders: p.genders.map((x: any) => {
              return {
                id: x.Id,
                description: x.Description,
              } as IBasicLookup;
            }),
            languageLevels: p.languageLevels.map((x: any) => {
              return {
                id: x.Id,
                description: x.Description,
              } as IBasicLookup;
            }),
            languages: p.languages.map((x: any) => {
              return {
                id: x.Id,
                description: x.Description,
              } as IBasicLookup;
            }),
            nationalities: p.nationalities.map((x: any) => {
              return {
                id: x.Id,
                description: x.Description,
                code: x.Code,
                uniqueIdConfigJson: {            
                  ...{ pattern: null, excludePattern: null, min: 6, max: null },
                  ...JSON.parse(x.UniqueIdConfigJson || '{}'),
                }
              } as INationalityLookup;
            }),
            qualifications: p.qualifications.map((x: any) => {
              return {
                id: x.Id,
                description: x.Description,
              } as IBasicLookup;
            }),
            races: p.races.map((x: any) => {
              return {
                id: x.Id,
                description: x.Description,
              } as IBasicLookup;
            }),
            titles: p.titles.map((x: any) => {
              return {
                id: x.Id,
                description: x.Description,
              } as IBasicLookup;
            }),

            applicationTypes: p.applicationTypes.map((x: any) => {
              return {
                id: x.Id,
                description: x.Description,
              } as IBasicLookup;
            }),
            bitLookups:  p.bitLookups.map((x: any) => {
              return {
                id: x.Id,
                description: x.Description,
              } as IBasicLookup;
            }),
            maritalStatuses:  p.maritalStatuses.map((x: any) => {
              return {
                id: x.Id,
                description: x.Description,
              } as IBasicLookup;
            }),
            transactingMethods:p.transactingMethods.map((x: any) => {
              return {
                id: x.Id,
                description: x.Description,
              } as IBasicLookup;
            }),

          } as IApplicantLookups;
          return lookups;
        })
      );
  }

  clientUpdate$(
    applicantData: IApplicantFormExtended,
    assessmentData: {
      assessmentLanguageId: number;
      consentAcceptDate: Date | null;
      id: number;
    }
  ) {
    //
    return this._httpClient
      .put<{result : string}>('/api/Applicant/ClientUpdate', {
        Applicant: {
          Archived: applicantData.archived,
          ContactNumber: applicantData.mobileNumber,
          CurrentCompany: applicantData.currentCompany,
          CurrentPosition: applicantData.currentPosition,
          DateOfBirth: applicantData.dateOfBirth
          ? this.datepipe.transform(applicantData.dateOfBirth, 'yyyy-MM-dd') +
            'T00:00:00'
          : null,
          DisabilityId: applicantData.disabilityId,
          DisabilityOther: applicantData.disabilityOther,
          Email: applicantData.email,
          FirstName: applicantData.firstName,
          GenderId: applicantData.genderId,
          GroupId: applicantData.groupId,
          Id: applicantData.id,
          IdNumber: applicantData.idNumber,
          Initials: applicantData.initials,
          LanguageId: applicantData.languageId,
          LanguageLevelId: applicantData.languageLevelId,
          LastName: applicantData.lastName,
          NationalityCode: applicantData.nationalityCode,
          NationalityId: applicantData.nationalityId,
          Password: applicantData.password,
          QualificationId: applicantData.qualificationId,
          RaceId: applicantData.raceId,
          ReferenceNumber: applicantData.reference1,
          ReferenceNumber2: applicantData.reference2,
          ValidateDetails: applicantData.validateDetails,
        },
        Assessment: {
          AssessmentLanguageId: assessmentData.assessmentLanguageId,
          ConsentAcceptDate: assessmentData.consentAcceptDate,
          Id: assessmentData.id,
        },
      })
      .toPromise();
  }

  getApplicantEmploymentDetailsLookup$() {
    return this._httpClient
      .get(`/api/applicant/ApplicantEmploymentDetailsLookups`)
      .pipe(
        map((p: any, index: number) => {
          return {
            area: p.Area.map((x: any) => {
              return {
                id: x.Id,
                description: x.Name,
                category: x.Category,
              } as IAreaLookup;
            }),
            maritalStatus: p.MaritalStatus.map((x: any) => {
              return {
                id: x.Id,
                description: x.Name,
              } as IBasicLookup;
            }),
            opportunityReason: p.OpportunityReason.map((x: any) => {
              return {
                id: x.Id,
                description: x.Name,
              } as IBasicLookup;
            }),
            positionInterest: p.PositionInterest.map((x: any) => {
              return {
                id: x.Id,
                description: x.Name,
              } as IBasicLookup;
            }),
            qualificationYear: p.QualificationYear.map((x: any) => {
              return {
                id: x.Id,
                description: x.Name,
              } as IBasicLookup;
            }),
          } as IApplicantEmploymentDetailLookups;
        })
      )
      .toPromise();
  }

  async setApplicantEmploymentDetailsLookup$(
    MaidenName: string,
    MaritalStatusId: number,
    PhysicalAddressLine1: string,
    PhysicalAddressLine2: string,
    PhysicalAddressLine3: string,
    PhysicalAddressLine4: string,
    PhysicalAddressLine5: string,
    PhysicalAddressLine6: string,
    PostalAddressLine1: string,
    PostalAddressLine2: string,
    PostalAddressLine3: string,
    PostalAddressLine4: string,
    PostalAddressLine5: string,
    PostalAddressLine6: string,
    WorkPermit: boolean,
    CriminalRecord: boolean,
    ContactAlternativeEmail: string,
    ContactAlternativeNumber: string,
    EmergencyFirstName: string,
    EmergencyLastName: string,
    EmergencyNumber: string,
    EmergencyAlternativeNumber: string,
    OpportunityReasonId: number,
    PreviousEmployer: string,
    PreviousPosition: string,
    QualificationId: number,
    QualificationYear: string,
    ProfessionalAffiliation: string,
    MembershipNumber: string,
    PositionInterestList: string,
    AreaList: string,
    InformationCorrectDeclaration: boolean
  ) {
    return await this._httpClient
      .post<{ Code: string }>(
        '/api/applicant/ApplicantEmploymentDetailsWrite',
        {
          MaidenName,
          MaritalStatusId,
          PhysicalAddressLine1,
          PhysicalAddressLine2,
          PhysicalAddressLine3,
          PhysicalAddressLine4,
          PhysicalAddressLine5,
          PhysicalAddressLine6,
          PostalAddressLine1,
          PostalAddressLine2,
          PostalAddressLine3,
          PostalAddressLine4,
          PostalAddressLine5,
          PostalAddressLine6,
          WorkPermit,
          CriminalRecord,
          ContactAlternativeEmail,
          ContactAlternativeNumber,
          EmergencyFirstName,
          EmergencyLastName,
          EmergencyNumber,
          EmergencyAlternativeNumber,
          OpportunityReasonId,
          PreviousEmployer,
          PreviousPosition,
          QualificationId,
          QualificationYear,
          ProfessionalAffiliation,
          MembershipNumber,
          PositionInterestList,
          AreaList,
          InformationCorrectDeclaration,
        }
      )
      .toPromise();
  }
}
