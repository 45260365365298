<app-ui-banner-top></app-ui-banner-top>
<div
  [hidden]="!systemMessage.show"
  class="banner-system-message {{ systemMessage.type ?? '' }}"
  [innerHTML]="systemMessage.message"
></div>
<!-- Content -->
<div [class]="getBrowserSupportedCss()">
  <div class="container-fluid big-top-margin">
    <!-- LOGIN BOX -->
    <div class="panel-float">
      <form
        [formGroup]="formGroup"
        (ngSubmit)="onSubmitLogin()"
        autocomplete="off"
      >
        <!-- LOGO -->
        <div class="logo-container">
          <img [src]="logo" alt="Evalex" />
        </div>
        <!-- ERROR MESSAGE -->
        <div *ngIf="errorMessage !== ' '" class="error-message top-margin">
          {{ errorMessage }}
        </div>
        <!-- USERNAME INPUT -->
        <div class="top-margin">
          <input
            id="username"
            name="username"
            type="email"
            class="text-box-input"
            formControlName="email"
            placeholder="{{ 'placeholderAccountUsername' | translate }}"
            required
          />
        </div>
        <!-- PASSWORD INPUT -->
        <div class="top-margin">
          <input
            id="password"
            name="password"
            type="password"
            class="text-box-input"
            formControlName="password"
            placeholder="{{ 'placeholderAccountPassword' | translate }}"
            required
          />
        </div>
        <!-- SIGN IN BUTTON -->
        <div class="top-margin">
          <button
            disabled
            type="submit"
            class="button-big-login"
            [disabled]="!formGroup.valid || isBusy || systemMessage.lockdown"
          >
            {{ 'buttonLogin' | translate }}
          </button>
        </div>
        <!-- FORGOT PASSWORD BUTTON -->
        <div class="top-margin forgotpw">
          <a routerLink="/password-reset">{{
            'labelAccountForgotPassword' | translate
          }}</a>
        </div>
      </form>
    </div>
  </div>
</div>

<app-browser-support-message></app-browser-support-message>
