<app-ui-banner-top></app-ui-banner-top>
<ng-container *ngIf="isReady">
  <div class="panel-align">
    <div class="panel-float" id="lg" name="loginForm">
      <ng-container
        *ngIf="
          ua.status === 'ua/supported' &&
          formSwitch.isActive(FORMS.ApplicantCheck)
        "
      >
        <div class="form-signin-heading text-center">
          <img [src]="logo" alt="Odyssey Logo" class="decktop" />
        </div>
        <p
          [innerHTML]="
            instructionTranslationType
              | translate : { companyAlias: companyAlias }
          "
        ></p>
        <form #formCtrl="ngForm">
          <!-- language -->
          <ng-container *ngIf="this.showLanguageSelect">
            <igx-select
              #select
              name="language"
              [(ngModel)]="assessmentLanguageid"
              required
              (selectionChanging)="onClickLanguage($event)"
            >
              <label
                igxLabel
                [innerHTML]="
                  'labeldroplink.label.assessment-language' | translate
                "
              ></label>
              <igx-select-item
                *ngFor="let item of assessmentLanguageLookup"
                [value]="item.id"
              >
                {{ item.description }}
              </igx-select-item>
            </igx-select>
          </ng-container>

          <!-- nationality -->
          <igx-simple-combo
          name="nationality"
          [data]="lookups.nationalityLookup"
          [displayKey]="'description'" 
          [valueKey]="'id'"
          [(ngModel)]="nationalityId"
          required
          [disabled]="projectNationalityId !== null"
          (selectionChanging)="onNationalityChanged($event)"
          >
            <label
            igxLabel
            [innerHTML]="'labelform.label.nationality' | translate"
          ></label>
        </igx-simple-combo>
          
          <!-- id number -->
          <igx-input-group>
            <input
              #idNumberT
              id="id-number"
              igxInput
              type="text"
              [(ngModel)]="idNumber"
              (ngModelChange)="onNgModelChange($event, idNumberT)"
              name="id-number"
              required
              [minLength]="nationalityUniqueIdConfig.min"
              [maxlength]="nationalityUniqueIdConfig.max"
              
              autocomplete="off"
            />
            <label
              igxLabel
              for="id-number"
              [innerHTML]="idTranslationType | translate"
            ></label>
          </igx-input-group>
        </form>
        <div
          igxLayout
          igxLayoutJustify="space-between"
          igxLayoutDir="column"
          class="ui-page-footer-onebtn"
        >
          <div igxLayout igxLayoutJustify="space-between" igxLayoutDir="row">
            <div class="layout-box__el">
              <button
                type="button"
                class="btn btn-primary"
                [disabled]="(!formCtrl.form.valid && !isBusy) || invalidId"
                (click)="onSubmitApplicantCheck()"
                [innerHTML]="'buttonbutton.continue' | translate"
              ></button>
            </div>
          </div>
        </div>
        <!-- </div> -->
      </ng-container>
      <ng-container
        *ngIf="
          ua.status === 'ua/supported' &&
          formSwitch.isActive(FORMS.UsernameLogin)
        "
      >
        <div class="form-signin-heading text-center">
          <img [src]="logo" alt="Odyssey Logo" class="decktop" />
        </div>
        <form #formCtrl="ngForm">
          <!-- id number -->
          <igx-input-group>
            <input
              igxInput
              type="text"
              [(ngModel)]="username"
              name="username"
              required
              autocomplete="off"
            />
            <label
              igxLabel
              for="projectName"
              [innerHTML]="'labelform.username' | translate"
            ></label>
          </igx-input-group>
        </form>
        <div
          igxLayout
          igxLayoutJustify="space-between"
          igxLayoutDir="column"
          class="ui-page-footer-onebtn"
        >
          <div igxLayout igxLayoutJustify="space-between" igxLayoutDir="row">
            <div class="layout-box__el">
              <button
                type="button"
                class="btn btn-primary"
                [disabled]="!formCtrl.form.valid"
                (click)="onSubmitUsernameLogin()"
                [innerHTML]="'buttonbutton.continue' | translate"
              ></button>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</ng-container>

<igx-dialog #modal [closeOnOutsideSelect]="false">
  <igx-dialog-title class="title-container">
    <div class="dialog-title">
      <!-- <igx-icon class="text-colour-primary">cached</igx-icon> -->
      <div
        class="dialog-title text-colour-primary"
        [innerHTML]="modalTitle"
      ></div>
    </div>
  </igx-dialog-title>
  <div class="add-edit-dialog sml">
    <div class="igx-dialog-extender-reset-assessment"></div>
    <!-- contents here -->
    <p [innerHTML]="modalBody"></p>
  </div>
  <div class="ui-page-footer-onebtn">
    <button
      type="button"
      class="btn btn-primary float-right"
      (click)="modalButtonClick(popupCommand)"
    >
      {{ modalButton }}
    </button>
  </div>
</igx-dialog>

<app-browser-support-message></app-browser-support-message>
