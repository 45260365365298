<div class="panel-align">
  <div class="panel-float" id="lg" name="loginForm">
    <form #formCtrl="ngForm" autocomplete="off">
      <h5
        [innerHTML]="
          'contentapplicant-employment-details-form.main-heading' | translate
        "
      ></h5>
      <h6
        [innerHTML]="
          'contentapplicant-employment-details-form.personal-details-heading'
            | translate
        "
      ></h6>
      <p
        [innerHTML]="
          'contentapplicant-employment-details-form.required-text' | translate
        "
      ></p>
      <!-- maiden-name -->
      <!-- <igx-input-group>
        <input
          igxInput
          type="text"
          [(ngModel)]="applicantData.maidenName"
          name="maiden-name"
          [required]="false"
          autocomplete="off"
        />
        <label
          igxLabel
          for="maiden-name"
          [innerHTML]="
            'labelapplicant-employment-details-form.maiden-name.label'
              | translate
          "
        ></label>
      </igx-input-group> -->
      <!-- maritial-status -->
      <igx-select
        #select
        name="maritial-status"
        [(ngModel)]="applicantData.maritalStatusId"
        [required]="true"
      >
        <label
          igxLabel
          [innerHTML]="
            'labelapplicant-employment-details-form.maritial-status.label'
              | translate
          "
        ></label>
        <igx-select-item
          *ngFor="let item of applicantlookups.maritalStatus"
          [value]="item.id"
        >
          {{ item.description }}
        </igx-select-item>
      </igx-select>

      <h6
        [innerHTML]="
          'contentapplicant-employment-details-form.physical-address-heading'
            | translate
        "
      ></h6>
      <!-- physical-address-line-1 -->
      <igx-input-group>
        <input
          igxInput
          type="text"
          [(ngModel)]="applicantData.physicalAddressLine1"
          name="physical-address-line-1"
          [required]="true"
          autocomplete="ignore1"
        />
        <label
          igxLabel
          for="physical-address-line-1"
          [innerHTML]="
            'labelapplicant-employment-details-form.physical-address-line-1.label'
              | translate
          "
        ></label>
      </igx-input-group>
      <!-- physical-address-line-2 -->
      <igx-input-group>
        <input
          igxInput
          type="text"
          [(ngModel)]="applicantData.physicalAddressLine2"
          name="physical-address-line-2"
          [required]="false"
          autocomplete="ignore2"
        />
        <label
          igxLabel
          for="physical-address-line-2"
          [innerHTML]="
            'labelapplicant-employment-details-form.physical-address-line-2.label'
              | translate
          "
        ></label>
      </igx-input-group>
      <!-- physical-address-line-3 -->
      <igx-input-group>
        <input
          igxInput
          type="text"
          [(ngModel)]="applicantData.physicalAddressLine3"
          name="physical-address-line-3"
          [required]="true"
          autocomplete="ignore3"
        />
        <label
          igxLabel
          for="physical-address-line-3"
          [innerHTML]="
            'labelapplicant-employment-details-form.physical-address-line-3.label'
              | translate
          "
        ></label>
      </igx-input-group>
      <!-- physical-address-line-4 -->
      <igx-input-group>
        <input
          igxInput
          type="text"
          [(ngModel)]="applicantData.physicalAddressLine4"
          name="physical-address-line-4"
          [required]="true"
          autocomplete="ignore4"
        />
        <label
          igxLabel
          for="physical-address-line-4"
          [innerHTML]="
            'labelapplicant-employment-details-form.physical-address-line-4.label'
              | translate
          "
        ></label>
      </igx-input-group>
      <!-- physical-address-line-5 -->
      <igx-input-group>
        <input
          igxInput
          type="text"
          [(ngModel)]="applicantData.physicalAddressLine5"
          name="physical-address-line-5"
          [required]="true"
          autocomplete="ignore5"
        />
        <label
          igxLabel
          for="physical-address-line-5"
          [innerHTML]="
            'labelapplicant-employment-details-form.physical-address-line-5.label'
              | translate
          "
        ></label>
      </igx-input-group>
      <!-- physical-address-line-6 -->
      <igx-input-group>
        <input
          igxInput
          type="text"
          [(ngModel)]="applicantData.physicalAddressLine6"
          name="physical-address-line-6"
          [required]="true"
          autocomplete="ignore6"
        />
        <label
          igxLabel
          for="physical-address-line-6"
          [innerHTML]="
            'labelapplicant-employment-details-form.postal-address-line-6.label'
              | translate
          "
        ></label>
      </igx-input-group>

      <h6
        [innerHTML]="
          'contentWorkLegalDocumentation'
            | translate
        "
      ></h6>
      <!-- postal-address-line-1 -->
      <!-- <igx-input-group>
        <input
          igxInput
          type="text"
          [(ngModel)]="applicantData.postalAddressLine1"
          name="postal-address-line-1"
          [required]="true"
          autocomplete="ignore7"
        />
        <label
          igxLabel
          for="postal-address-line-1"
          [innerHTML]="
            'labelapplicant-employment-details-form.postal-address-line-1.label'
              | translate
          "
        ></label>
      </igx-input-group> -->
      <!-- postal-address-line-2 -->
      <!-- <igx-input-group>
        <input
          igxInput
          type="text"
          [(ngModel)]="applicantData.postalAddressLine2"
          name="postal-address-line-2"
          [required]="false"
          autocomplete="ignore8"
        />
        <label
          igxLabel
          for="postal-address-line-2"
          [innerHTML]="
            'labelapplicant-employment-details-form.postal-address-line-2.label'
              | translate
          "
        ></label>
      </igx-input-group> -->
      <!-- postal-address-line-3 -->
      <!-- <igx-input-group>
        <input
          igxInput
          type="text"
          [(ngModel)]="applicantData.postalAddressLine3"
          name="postal-address-line-3"
          [required]="true"
          autocomplete="ignore9"
        />
        <label
          igxLabel
          for="postal-address-line-3"
          [innerHTML]="
            'labelapplicant-employment-details-form.postal-address-line-3.label'
              | translate
          "
        ></label>
      </igx-input-group> -->
      <!-- postal-address-line-4 -->
      <!-- <igx-input-group>
        <input
          igxInput
          type="text"
          [(ngModel)]="applicantData.postalAddressLine4"
          name="postal-address-line-4"
          [required]="true"
          autocomplete="ignore10"
        />
        <label
          igxLabel
          for="postal-address-line-4"
          [innerHTML]="
            'labelapplicant-employment-details-form.postal-address-line-4.label'
              | translate
          "
        ></label>
      </igx-input-group> -->
      <!-- postal-address-line-5 -->
      <!-- <igx-input-group>
        <input
          igxInput
          type="text"
          [(ngModel)]="applicantData.postalAddressLine5"
          name="postal-address-line-5"
          [required]="true"
          autocomplete="ignore11"
        />
        <label
          igxLabel
          for="postal-address-line-5"
          [innerHTML]="
            'labelapplicant-employment-details-form.postal-address-line-5.label'
              | translate
          "
        ></label>
      </igx-input-group> -->
      <!-- postal-address-line-6 -->
      <!-- <igx-input-group>
        <input
          igxInput
          type="text"
          [(ngModel)]="applicantData.postalAddressLine6"
          name="postal-address-line-6"
          [required]="true"
          autocomplete="ignore12"
        />
        <label
          igxLabel
          for="postal-address-line-6"
          [innerHTML]="
            'labelapplicant-employment-details-form.postal-address-line-6.label'
              | translate
          "
        ></label>
      </igx-input-group> -->

      <!-- work-permit -->
      <igx-select
        #select
        name="work-permit"
        [(ngModel)]="applicantData.workPermit"
        [required]="true"
      >
        <label
          igxLabel
          [innerHTML]="
            'labelapplicant-employment-details-form.work-permit.label'
              | translate
          "
        ></label>
        <igx-select-item *ngFor="let item of lookups.bit" [value]="item.id">
          {{ item.description }}
        </igx-select-item>
      </igx-select>
      <!-- chriminal-record -->
      <igx-select
        #select
        name="chriminal-record"
        [(ngModel)]="applicantData.criminalRecord"
        [required]="true"
      >
        <label
          igxLabel
          [innerHTML]="
            'labelapplicant-employment-details-form.chriminal-record.label'
              | translate
          "
        ></label>
        <igx-select-item *ngFor="let item of lookups.bit" [value]="item.id">
          {{ item.description }}
        </igx-select-item>
      </igx-select>

      <h6
        [innerHTML]="
          'contentapplicant-employment-details-form.personal-conract-details-heading'
            | translate
        "
      ></h6>
      <!-- alternative-email -->
      <igx-input-group>
        <input
          igxInput
          type="email"
          [(ngModel)]="applicantData.contactAlternativeEmail"
          name="alternative-email"
          [required]="false"
          autocomplete="off"
          email
        />
        <label
          igxLabel
          for="alternative-email"
          [innerHTML]="
            'labelapplicant-employment-details-form.alternative-email.label'
              | translate
          "
        ></label>
      </igx-input-group>
      <!-- alternative-number -->
      <igx-input-group>
        <input
          igxInput
          type="text"
          [(ngModel)]="applicantData.contactAlternativeNumber"
          name="alternative-number"
          [required]="false"
          autocomplete="off"
        />
        <label
          igxLabel
          for="alternative-number"
          [innerHTML]="
            'labelapplicant-employment-details-form.alternative-number.label'
              | translate
          "
        ></label>
      </igx-input-group>

      <h6
        [innerHTML]="
          'contentapplicant-employment-details-form.emnergency-contact-details-heading'
            | translate
        "
      ></h6>
      <!-- emergency-firstname -->
      <igx-input-group>
        <input
          igxInput
          type="text"
          [(ngModel)]="applicantData.emergencyFirstName"
          name="emergency-firstname"
          [required]="false"
          autocomplete="off"
        />
        <label
          igxLabel
          for="emergency-firstname"
          [innerHTML]="
            'labelapplicant-employment-details-form.emergency-firstname.label'
              | translate
          "
        ></label>
      </igx-input-group>
      <!-- emergency-lastname -->
      <igx-input-group>
        <input
          igxInput
          type="text"
          [(ngModel)]="applicantData.emergencyLastName"
          name="emergency-lastname"
          [required]="false"
          autocomplete="off"
        />
        <label
          igxLabel
          for="emergency-lastname"
          [innerHTML]="
            'labelapplicant-employment-details-form.emergency-lastname.label'
              | translate
          "
        ></label>
      </igx-input-group>
      <!-- emergency-number -->
      <igx-input-group>
        <input
          igxInput
          type="text"
          [(ngModel)]="applicantData.emergencyNumber"
          name="emergency-number"
          [required]="false"
          autocomplete="off"
        />
        <label
          igxLabel
          for="emergency-number"
          [innerHTML]="
            'labelapplicant-employment-details-form.emergency-number.label'
              | translate
          "
        ></label>
      </igx-input-group>
      <!-- emergency-alternative-number -->
      <igx-input-group>
        <input
          igxInput
          type="text"
          [(ngModel)]="applicantData.emergencyAlternativeNumber"
          name="emergency-alternative-number"
          [required]="false"
          autocomplete="off"
        />
        <label
          igxLabel
          for="emergency-alternative-number"
          [innerHTML]="
            'labelapplicant-employment-details-form.emergency-alternative-number.label'
              | translate
          "
        ></label>
      </igx-input-group>

      <h6
        [innerHTML]="
          'contentapplicant-employment-details-form.employment-details-heading'
            | translate
        "
      ></h6>
      <!-- opportunity-reason -->
      <!-- <igx-select
        #select
        name="opportunity-reason"
        [(ngModel)]="applicantData.opportunityReasonId"
        [required]="true"
      >
        <label
          igxLabel
          [innerHTML]="
            'labelapplicant-employment-details-form.opportunity-reason.label'
              | translate
          "
        ></label>
        <igx-select-item
          *ngFor="let item of applicantlookups.opportunityReason"
          [value]="item.id"
        >
          {{ item.description }}
        </igx-select-item>
      </igx-select> -->
      <!-- previous-employer -->
      <!-- <igx-input-group>
        <input
          igxInput
          type="text"
          [(ngModel)]="applicantData.previousEmployer"
          name="previous-employer"
          [required]="true"
          autocomplete="off"
        />
        <label
          igxLabel
          for="previous-employer"
          [innerHTML]="
            'labelapplicant-employment-details-form.previous-employer.label'
              | translate
          "
        ></label>
      </igx-input-group> -->
      <!-- previous-position -->
      <igx-input-group>
        <input
          igxInput
          type="text"
          [(ngModel)]="applicantData.previousPosition"
          name="previous-position"
          [required]="true"
          autocomplete="off"
        />
        <label
          igxLabel
          for="previous-position"
          [innerHTML]="
            'labelapplicant-employment-details-form.previous-position.label'
              | translate
          "
        ></label>
      </igx-input-group>

      <h6
        [innerHTML]="
          'contentapplicant-employment-details-form.qualification-details-heading'
            | translate
        "
      ></h6>
      <!-- qualification -->
      <igx-select
        #select
        name="qualification"
        [(ngModel)]="applicantData.qualificationId"
        [required]="true"
      >
        <label
          igxLabel
          [innerHTML]="
            'labelapplicant-employment-details-form.qualification.label'
              | translate
          "
        ></label>
        <igx-select-item
          *ngFor="let item of lookups.qualifications"
          [value]="item.id"
        >
          {{ item.description }}
        </igx-select-item>
      </igx-select>
      <!-- qualification-year -->
      <igx-select
        #select
        name="qualification-year"
        [(ngModel)]="applicantData.qualificationYear"
        [required]="false"
      >
        <label
          igxLabel
          [innerHTML]="
            'labelapplicant-employment-details-form.qualification-year.label'
              | translate
          "
        ></label>
        <igx-select-item
          *ngFor="let item of applicantlookups.qualificationYear"
          [value]="item.id"
        >
          {{ item.description }}
        </igx-select-item>
      </igx-select>

      <!-- professional-affiliation -->
      <!-- <igx-input-group>
        <input
          igxInput
          type="text"
          [(ngModel)]="applicantData.professionalAffiliation"
          name="professional-affiliation"
          [required]="false"
          autocomplete="off"
        />
        <label
          igxLabel
          for="professional-affiliation"
          [innerHTML]="
            'labelapplicant-employment-details-form.professional-affiliation.label'
              | translate
          "
        ></label>
      </igx-input-group> -->
      <!-- membership-number -->
      <!-- <igx-input-group>
        <input
          igxInput
          type="text"
          [(ngModel)]="applicantData.membershipNumber"
          name="membership-number"
          [required]="false"
          autocomplete="off"
        />
        <label
          igxLabel
          for="membership-number"
          [innerHTML]="
            'labelapplicant-employment-details-form.membership-number.label'
              | translate
          "
        ></label>
      </igx-input-group> -->

      <!-- <h6
        [innerHTML]="
          'contentapplicant-employment-details-form.future-employment-heading'
            | translate
        "
      ></h6>
      <p
        [innerHTML]="
          'contentapplicant-employment-details-form.positions-text' | translate
        "
      ></p> -->
      <!-- position-custom-1 -->
      <!-- <igx-input-group>
        <input
          igxInput
          type="text"
          [(ngModel)]="positionCustom[0]"
          name="position-custom-1"
          [required]="true"
          autocomplete="off"
        />
        <label igxLabel for="position-custom-1"></label>
      </igx-input-group> -->
      <!-- position-custom-2 -->
      <!-- <igx-input-group>
        <input
          igxInput
          type="text"
          [(ngModel)]="positionCustom[1]"
          name="position-custom-2"
          [required]="true"
          autocomplete="off"
        />
        <label igxLabel for="position-custom-2"></label>
      </igx-input-group> -->
      <!-- position-custom-3 -->
      <!-- <igx-input-group>
        <input
          igxInput
          type="text"
          [(ngModel)]="positionCustom[2]"
          name="position-custom-3"
          [required]="true"
          autocomplete="off"
        />
        <label igxLabel for="position-custom-3"></label>
      </igx-input-group> -->
      <!-- position-custom-4 -->
      <!-- <igx-input-group>
        <input
          igxInput
          type="text"
          [(ngModel)]="positionCustom[3]"
          name="position-custom-4"
          [required]="false"
          autocomplete="off"
        />
        <label igxLabel for="position-custom-4"></label> -->
      <!-- </igx-input-group> -->
      <!-- position-custom-5 -->
      <!-- <igx-input-group>
        <input
          igxInput
          type="text"
          [(ngModel)]="positionCustom[4]"
          name="position-custom-5"
          [required]="false"
          autocomplete="off"
        />
        <label igxLabel for="position-custom-5"></label>
      </igx-input-group> -->
      <!-- <p
        [innerHTML]="
          'contentapplicant-employment-details-form.areas-text' | translate
        "
      ></p>
      <div class="column_container">
        <ul class="checkbox">
          <li name="areas" *ngFor="let p of areasSelection; let index = index">
            <span
              class="areaHeading"
              *ngIf="
                !areasSelection[index - 1] ||
                p.category !== areasSelection[index - 1].category
              "
            >
              {{ p.category }}
            </span>
            <br />
            <igx-checkbox
              [checked]="p.selected"
              (change)="onChanged($event, p)"
            >
              {{ p.description }}
            </igx-checkbox>
          </li>
        </ul>
      </div> -->
      <h6
        [innerHTML]="
          'contentapplicant-employment-details-form.declaration-heading'
            | translate
        "
      ></h6>
      <p
        [innerHTML]="
          'contentapplicant-employment-details-form.information-correct-declaration.label'
            | translate
        "
      ></p>
      <igx-select
        #select
        name="information-correct-declaration"
        [(ngModel)]="applicantData.informationCorrectDeclaration"
        [required]="true"
      >
        <label
          igxLabel
          [innerHTML]="
            'buttonapplicant-employment-details-form.declaration' | translate
          "
        ></label>
        <igx-select-item
          *ngFor="let item of lookups.bitAgree"
          [value]="item.id"
        >
          {{ item.description }}
        </igx-select-item>
      </igx-select>

      <button
        type="button"
        (click)="onContinueClicked()"
        class="btn btn-primary float-right"
        [disabled]="!formCtrl.valid || isBusy"
        [innerHTML]="'buttonbutton.continue' | translate"
      ></button>
    </form>
  </div>
</div>
