<igx-input-group #inputGroup [igxToggleAction]="dropDown" class="input-group">
  <input
    #input
    class="input"
    type="text"
    igxInput
    [igxDropDownItemNavigation]="dropDown"
    readonly="true"
    placeholder="Choose an option"
    [value]="selectedItem"
    (keydown.ArrowDown)="openDropDown()"
  />
  <igx-suffix igxIconButton="flat" class="dropdownToggleButton" igxRipple>
    <igx-icon>arrow_drop{{ dropDown.collapsed ? "_down" : "_up" }}</igx-icon>
  </igx-suffix>
</igx-input-group>
<igx-drop-down
  #dropDown
  [width]="'180px'"
  (selectionChanging)="dropdownSelected($event)"
>
  <div class="drop-down-virtual-wrapper _drop-down-virtual-wrapper">
    <igx-drop-down-item
      *igxFor="
        let item of items;
        index as index;
        scrollOrientation: 'vertical';
        containerSize: 80;
        itemSize: itemHeight
      "
      [value]="item.field"
    >
      {{ item.field }}
    </igx-drop-down-item>
  </div>
</igx-drop-down>
