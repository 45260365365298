import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import intlTelInput, { Iti } from 'intl-tel-input';
import { wait$ } from '../../omt-lib';

@Component({
  selector: 'app-ui-phone-number-input',
  templateUrl: './ui-phone-number-input.component.html',
  styleUrls: ['./ui-phone-number-input.component.scss'],
})
export class UiPhoneNumberInputComponent
  implements OnInit, AfterViewInit, OnChanges, OnDestroy
{
  @Input() _label = '';
  @Input() _id = '';
  @Input() _placeholder = '';
  @Input() _required = false;
  @Input() _disabled = false;
  @Input() initialCountry = 'za';
  @Input() _ngModel = '';
  @Output() _ngModelChange = new EventEmitter<string>();
  @Output() dialingCodeChange = new EventEmitter<string>();

  private _iti: Iti | null = null;

  constructor() {}

  public onNgModelChange(value: string) {
    if (this._iti && value) {
      const rValue = value.replace(/[^0-9\+]/g, '');
      if (rValue !== value) {
        this._el!.value = rValue;
        value = rValue;
      }

      if (this._ngModel !== value) {
        this._ngModel = value;
        this._ngModelChange.emit(this._iti.getNumber());
        this._el!.value = this._iti.getNumber();
      }
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes._ngModel) {
      this.onNgModelChange(changes._ngModel.currentValue);
    }
    if (changes.initialCountry && this._iti && this._el) {
      this._iti.setCountry(this.initialCountry);
      this._el.value = this._iti!.getNumber();
    }
  }

  ngOnInit(): void {}

  private _el: HTMLInputElement | null = null;
  async ngAfterViewInit() {
    await wait$(125);
    this._el = document.querySelector<HTMLInputElement>(`#${this._id}`)!;
    this._iti = intlTelInput(this._el, {
      allowDropdown: false,
      utilsScript: '/assets/js/intl-tel-input-utils.js',
      nationalMode: false,
      formatAsYouType: false,
      formatOnDisplay: false,
      showFlags: false,
      customPlaceholder: () => '',
    });

    this._el.addEventListener('countrychange', (e) => {
      this.dialingCodeChange.emit(this._iti!.getSelectedCountryData().iso2);
    });

    this._el.addEventListener('open:countrydropdown', (e) => {});
    this._el.addEventListener('close:countrydropdown', (e) => {});

    await wait$(125);

    if (this._el.value.trim().startsWith('0') || !this._el.value) {
      this._iti.setCountry(this.initialCountry);
      this._el.value = this._iti!.getNumber();
    }

    this.onNgModelChange(this._iti!.getNumber());
  }

  ngOnDestroy(): void {
    this._iti?.destroy();
  }
}
