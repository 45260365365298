import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OdysseyApiService } from 'src/app/core/odyssey-api.service';
import { SystemStorage } from 'src/app/shared/omt-lib';
import { SystemTranslationService } from 'src/app/shared/system-translation.service';

@Component({
  selector: 'app-applicant-hurdle-check',
  templateUrl: './applicant-hurdle-check.component.html',
  styleUrls: ['./applicant-hurdle-check.component.scss'],
})
export class ApplicantHurdleCheckComponent implements OnInit, OnDestroy {
  private _maxDotCount = 3;
  private _currentDotCount = 0;
  public get dots() {
    let _d = '';
    for (let i = 0; i < this._currentDotCount; ++i) {
      _d += '.';
    }
    return _d;
  }

  private _continueSkip = 3; // default - delay for 45s // 3 x 15s
  public timer = 0;

  private _i: number | null = null;

  private _check(timeout: number = 15000) {
    ++this._maxDotCount;
    --this._continueSkip;
    setTimeout(async () => {
      try {
        const _check = await this._api.check$();

        if (_check) {
          switch (_check.Code) {
            case 'oah/applicant-hurdle-check/complete':
              if (_check.ResultStatusId === null) {
                this._router.navigate(['/complete'], {
                  queryParams: { cId: _check.CompanyId },
                });
                //window.location.href = `/WebContent/#/complete?cId=${_check.CompanyId}`;
              } else {
                this._router.navigate([`/complete`], {
                  queryParams: {
                    cId: _check.CompanyId,
                    pId: _check.ProjectId,
                    rsId: _check.ResultStatusId,
                  },
                });
                //window.location.href = `/WebContent/#/complete?cId=${_check.CompanyId}&pId=${_check.ProjectId}&rsId=${_check.ResultStatusId}`;
              }
              break;
            case 'oah/applicant-hurdle-check/wait':
              this._check();
              break;
            case 'oah/applicant-hurdle-check/continue':
              if (this._continueSkip < 0) {
                // window.location.href = '/WebContent/doAssessment.html';
                this._router.navigate(['applicant', 'dashboard']);
              } else {
                this._check();
              }
              break;
          }
        }
      } catch (ex) {
        await SystemStorage.INSTANCE.base.clear$(); //candidate-logout 'actual-logout'
        this._router.navigate(['/']);
      }
    }, timeout);
  }

  public get eCode() {
    return this._aRoute.snapshot.queryParams.eCode ?? null;
  }

  constructor(
    private _api: OdysseyApiService,
    private _aRoute: ActivatedRoute,
    private _router: Router,
    private _translate: SystemTranslationService
  ) {}

  ngOnInit() {
    if (this.eCode) {
      window.location.href = '/login?eCode=' + this.eCode;
      return;
    }

    if (
      this._aRoute.snapshot.queryParams &&
      this._aRoute.snapshot.queryParams.cs
    ) {
      this._continueSkip = +this._aRoute.snapshot.queryParams.cs;
      this._continueSkip = this._continueSkip < 3 ? 3 : this._continueSkip;
    }

    this._i = window.setInterval(() => {
      this._currentDotCount =
        this._currentDotCount < this._maxDotCount
          ? this._currentDotCount + 1
          : 0;
      ++this.timer;
    }, 1000);

    this._check(0);
  }

  ngOnDestroy() {
    if (this._i != null) window.clearInterval(this._i);
  }
}
