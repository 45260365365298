import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as localforage from 'localforage';
import { AssessmentService } from 'src/app/core/assessment.service';
import { SystemStorage } from 'src/app/shared/omt-lib';
import { SystemTranslationService } from 'src/app/shared/system-translation.service';

@Component({
  selector: 'app-login-with-assessment-key',
  templateUrl: './login-with-assessment-key.component.html',
  styleUrls: ['./login-with-assessment-key.component.css'],
})
export class LoginWithAssessmentKeyComponent implements OnInit {
  public message = 'Loading...';

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _assessmentApi: AssessmentService,
    private _translate: SystemTranslationService
  ) {}

  async ngOnInit() {
    const _assessmentKey: string = this._route.snapshot.params.key || null;
    if (_assessmentKey) {
      try {
        //LOGIN WITH GUID

        await SystemStorage.INSTANCE.base.setItem$(
          '_assessmentKey',
          _assessmentKey
        );

        const _res = await this._assessmentApi.getProductAssessmentCredentials$(
          _assessmentKey
        );

        await this._translate.isReady$();
        await this._translate.useLanguage(
          _res?.assessmentLanguageId ?? 1 /*english*/
        );

        this.message = 'Redirecting...';

        if (!_res) {
          window.location.href =
            'https://secure.evalex.com/completed.aspx?AssessmentKey=' +
            _assessmentKey +
            '&OdyStatus=2';
          return;
        }

        const _assessmentId = _res.assessmentId.toString();
        const _email = _res.username;

        await SystemStorage.INSTANCE.base.setItem$(
          'assessmentId',
          _assessmentId
        );
        await SystemStorage.INSTANCE.base.setItem$('email', _email);
        const _trackingId = _res.trackingId ? _res.trackingId.toString() : null;
        await SystemStorage.INSTANCE.base.setItem$('trackingId', _trackingId);

        if (_trackingId) {
          await this._router.navigate(['/applicant/questionnaire/knockout']);
          return;
        } else {
          await this._router.navigate(['/applicant/dashboard'], {
            queryParams: { assessmentKey: _assessmentKey },
          });
          return;
        }
      } catch (ex) {
        this.message = 'Failed';
      }
    } else {
      this.message = 'Failed';
    }
  }
}
