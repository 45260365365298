import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class OaiIntegrityService {
  //$http.post('/api/Integrity/SaveQuestionResponse', data)
  postSaveQuestionResponse$(data: any) {
    return this._httpClient
      .post(`/api/Integrity/SaveQuestionResponse`, data)
      .toPromise();
  }

  //$http.get('/api/Integrity/GetQuestions/' + this.QuestionnaireItemId)
  getGetQuestions$(questionnaireItemId: any) {
    return this._httpClient
      .get<any>(`/api/Integrity/GetQuestions/${questionnaireItemId}`)
      .toPromise();
  }

  constructor(private _httpClient: HttpClient) {}
}
