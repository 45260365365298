<app-ui-banner-top></app-ui-banner-top>

<!-- Content -->

<div class="container-fluid big-top-margin">
  <div class="panel-box">
    <div class="box with-border primary">
      <!-- HEADER -->
      <div class="box-header">
        <div class="box-title">PASSWORD EXPIRED</div>
      </div>

      <div class="box-body text-justify">
        <!-- INFO MESSAGE -->
        <p>Please enter a new account password below.</p>
        <hr />

        <form autocomplete="off"  #accountForm="ngForm">
          <div *ngIf="message" class="error-message">
            {{ message }}
          </div>
          <!-- EMAIL INPUT 
          <div class="input-group">
            <label>Email</label>
            <div>
              <input
                type="text"
                class="text-box-input"
                id="inputEmail"
                name="inputEmail"
                [(ngModel)]="account.email"
                required
                readonly
                autocomplete="off"
              />
            </div>
          </div>
          -->

          <!-- NEW PASSWORD INPUT 
          <div class="input-group">
            <label
              for="account-new-password"
              [innerHTML]="'labelNewPassword' | translate"
              >New Password</label
            >
            -->
            <div>
              <input
                type="password"
                class="text-box-input"
                id="account-new-password"
                name="account-new-password"
                [(ngModel)]="account.NewPassword"
                required
                autocomplete="new-password"
                [placeholder]="'labelNewPassword' | translate"
                (input)="this.checkPassword()"
              />
            <!-- </div> -->
          </div>

          <!-- PASSWORD REQUIREMENT MESSAGE 
          <div
            *ngIf="passwordStrength(); let strength"
            class="alert-container transition"
          >
            <div class="alert alert-info">
              <p [innerHTML]="passwordStrength()">
              </p>

              <p></p>
            </div>
          </div>
          -->
          <!-- PASSWORD CHECK -->
          <div class=" alert pwCheck">
            <div class="pwHeading">Password must contain the following:</div>
            <p><i class="{{ passwordCheck.lowercase ? 'fas fa-check-circle' : 'fas fa-times-circle' }}"></i>  A <b>lowercase</b> letter</p>
            <p><i class="{{ passwordCheck.uppercase ? 'fas fa-check-circle' : 'fas fa-times-circle' }}"></i>  A <b>capital (uppercase)</b> letter</p>
            <p><i class="{{ passwordCheck.number ? 'fas fa-check-circle' : 'fas fa-times-circle' }}"></i>  A <b>number</b></p>
            <p><i class="{{ passwordCheck.symbol ? 'fas fa-check-circle' : 'fas fa-times-circle' }}"></i>  A <b>symbol</b></p>
            <p><i class="{{ passwordCheck.length? 'fas fa-check-circle' : 'fas fa-times-circle' }}"></i>  Minimum <b>11 characters</b></p>
          </div>

          <!-- CONFIRM PASSWORD BUTTON -->
          <div class="input-group">
            <!-- <label
              for="account-confirm-password"
              [innerHTML]="'labelConfirmPassword' | translate"
              >Confirm Password</label
            >
            <div> -->
              <input
                type="password"
                class="text-box-input"
                [(ngModel)]="account.ConfirmPassword"
                name="account-confirm-password"
                id="account-confirm-password"
                required
                autocomplete="new-password"
                [placeholder]="'labelConfirmPassword' | translate"
              />
            </div>
          <!-- </div> -->

          <div>
            <button
            type="button"
            class="button-big-login"
            [disabled]="!accountForm.valid || isBusy || !this.passwordCheck.valid"
            (click)="onSubmit()"
            >
            {{ "buttonUpdate" | translate }}
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<!-- <div [class]="getBrowserSupportedCss()">
  <div
    class="card pw-reset-card card-primary"
    *ngIf="isReady && uaService.status === 'ua/supported'"
  >
    <div class="card-header">
      <h5
        *ngIf="isForgotPassword === false"
        class="text-center mb-2 ody-sel-none"
      >
        Password Expired
      </h5>
      <h5
        *ngIf="isForgotPassword === true"
        class="text-center mb-2 ody-sel-none"
      >
        Update Password
      </h5>
    </div>
    <div class="card-body">
      <form #accountForm="ngForm">
        <p *ngIf="isForgotPassword === false">
          Your account password has expired, please enter a new password below.
        </p>
        <p *ngIf="isForgotPassword === true">
          Please enter a new account password.
        </p>
        <igx-input-group>
          <input
            igxInput
            type="password"
            [(ngModel)]="account.NewPassword"
            name="account-new-password"
            required
            autocomplete="off"
            (change)="onChange()"
          />
          <label
            igxLabel
            for="account-new-password"
            [innerHTML]="'labelNewPassword' | translate"
            >New Password</label
          >
        </igx-input-group>

        <section *ngIf="passwordStrength()">
          <div class="error">
            <span [innerHTML]="passwordStrength()"></span>
          </div>
        </section>

        <igx-input-group>
          <input
            igxInput
            type="password"
            [(ngModel)]="account.ConfirmPassword"
            name="account-confirm-password"
            required
            autocomplete="off"
            (change)="onChange()"
          />
          <label
            igxLabel
            for="account-confirm-password"
            [innerHTML]="'labelConfirmPassword' | translate"
            >Confirm Password</label
          >
        </igx-input-group>
      </form>
    </div>
    <div class="ui-page-footer">
      <section *ngIf="message">
        <div class="error">
          <span>{{ message }}</span>
        </div>
      </section>
      <button type="button" class="btn btn-cancel" (click)="onClickBack()">
        {{ 'buttonBack' | translate }}
      </button>
      <button
        type="button"
        class="btn btn-primary"
        [disabled]="!accountForm.valid || isBusy || passwordStrength() !== null"
        (click)="onSubmit()"
      >
        {{ 'buttonUpdate' | translate }}
      </button>
    </div>
  </div>
</div> -->

<app-browser-support-message></app-browser-support-message>
