<!-- Orange Invoice-->
<!-- Name and Invoice Number heading (2)-->
<div class="invoices-container">
  <div class="row _gap">
    <div
      class="invoice-order-form invoice col-5 col-md-5 col-lg-5 col-xl-5 col-xxl-5 invoice-arrange ml-n1"
    >
      <table class="table-full-width">
        <tr>
          <td
            class="bold beautiful"
            colspan="2"
            [innerHTML]="question?.orderForm?.companyName"
          ></td>
          <td
            class="right-align bold"
            colspan="2"
            [innerHTML]="question?.orderForm?.invoiceName"
          ></td>
        </tr>
        <tr>
          <td
            class="table-1-3"
            [innerHTML]="question?.orderForm?.addressFromHeading"
          ></td>
          <td class="table-1-3"></td>
          <td
            class="table-1-3 right-align"
            [innerHTML]="question?.orderForm?.addressToHeading"
          ></td>
        </tr>
        <tr>
          <td [innerHTML]="question?.orderForm?.addressFrom"></td>
          <td></td>
          <td
            class="right-align"
            [innerHTML]="question?.orderForm?.addressTo"
          ></td>
        </tr>
      </table>
      <table class="table-full-width">
        <!-- header -->
        <thead>
          <th
            *ngFor="let ea of question?.orderForm?.mainHeaders"
            [ngClass]="isNumericHeader(ea)? 'number-header' : ''"
          >
          {{ "content"+ea | translate }}
          </th>
        </thead>
        <tbody>
          <tr
            *ngFor="let ea of question?.orderForm?.mainRows"
            class="alternating"
          >
            <td
              *ngFor="let ea2 of ea.text"
              [ngClass]="isNumericCell(ea2) ? 'number-column' : ''"
            >
              {{ ea2 }}
            </td>
            <td *ngIf="ea.isQuestion">
              <!-- <igx-input-group #inputGroup [igxToggleAction]="dropDown" class="input-group">
                            <input #input class="input" type="text" igxInput [igxDropDownItemNavigation]="dropDown"
                                readonly="true" placeholder="Choose an option" [value]="dropDown.selectedItem?.value"
                                (keydown.ArrowDown)="openDropDown()" />
                            <igx-suffix igxButton="icon" class="dropdownToggleButton" igxRipple>
                                <igx-icon>arrow_drop{{ dropDown.collapsed ? '_down' : '_up' }}</igx-icon>
                            </igx-suffix>
                        </igx-input-group>
                        <igx-drop-down #dropDown [width]="'180px'" (selectionChanging)="dropdownSelected(ea.id,$event)">
                            <igx-drop-down-item *ngFor="let item of items" [value]="item.field">
                                {{ item.field }}
                            </igx-drop-down-item>
                        </igx-drop-down> -->
              <!-- <igx-input-group *ngIf="getRowData(ea.id)?.response?.includes('Amount is Incorrect')">
                        <input placeholder="Enter correct amount" igxInput type="number" [(ngModel)]="answers[ea.id]" (input)="onEntered(ea.id)"/>
                    </igx-input-group>  -->
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr *ngFor="let ea of question?.orderForm?.footerRows">
            <th
              *ngFor="let ea2 of ea.text"
              [ngClass]="isNumericCell(ea2) ? 'number-column' : ''"
            >
              {{ ea2 }}
            </th>
            <td *ngIf="ea.isQuestion">
              <!-- <igx-input-group #inputGroup [igxToggleAction]="dropDown" class="input-group">
                            <input #input class="input" type="text" igxInput [igxDropDownItemNavigation]="dropDown"
                                readonly="true" placeholder="Choose an option" [value]="dropDown.selectedItem?.value"
                                (keydown.ArrowDown)="openDropDown()" />
                            <igx-suffix igxButton="icon" class="dropdownToggleButton" igxRipple>
                                <igx-icon>arrow_drop{{ dropDown.collapsed ? '_down' : '_up' }}</igx-icon>
                            </igx-suffix>
                        </igx-input-group>
                        <igx-drop-down #dropDown [width]="'180px'" (selectionChanging)="dropdownSelected(ea.id,$event)">
                            <igx-drop-down-item *ngFor="let item of items" [value]="item.field">
                                {{ item.field }}
                            </igx-drop-down-item>
                        </igx-drop-down> -->
              <!-- <igx-input-group *ngIf="getRowData(ea.id)?.response?.includes('Amount is Incorrect')">
                        <input placeholder="Enter correct amount" igxInput type="number" [(ngModel)]="answers[ea.id]" (input)="onEntered(ea.id)"/>
                    </igx-input-group>  -->
            </td>
          </tr>
        </tfoot>
      </table>
    </div>

    <!-- Blue Invoice -->
    <div
      class="invoice-delivery-note invoice col-7 col-md-7 col-lg-7 col-xl-7 col-xxl-7 invoice-arrange mr-n1"
    >
      <table class="table-full-width">
        <tr>
          <td
            class="bold beautiful"
            colspan="2"
            [innerHTML]="question?.deliveryNote?.companyName"
          ></td>
          <td
            class="right-align bold"
            colspan="2"
            [innerHTML]="question?.deliveryNote?.invoiceName"
          ></td>
        </tr>
        <tr>
          <td
            class="table-1-3"
            [innerHTML]="question?.deliveryNote?.addressFromHeading"
          ></td>
          <td class="table-1-3"></td>
          <td
            class="table-1-3 right-align"
            [innerHTML]="question?.deliveryNote?.addressToHeading"
          ></td>
        </tr>
        <tr>
          <td>{{ question?.deliveryNote?.addressFrom }}</td>
          <td></td>
          <td class="right-align">{{ question?.deliveryNote?.addressTo }}</td>
        </tr>
      </table>
      <table class="table-full-width">
        <!-- header -->
        <thead>
          <tr>
            <th
              *ngFor="let ea of question?.deliveryNote?.mainHeaders"
              [ngClass]="isNumericHeader(ea) ? 'number-header' : ''"
            >
              {{ "content"+ea | translate}}
            </th>
            <th class="dropdown-column">&nbsp;</th>
          </tr>
        </thead>
        
        <tbody>
          <tr
            *ngFor="let ea of question?.deliveryNote?.mainRows"
            class="alternating"
          >
            <td
              *ngFor="let ea2 of ea.text"
              [ngClass]="isNumericCell(ea2) ? 'number-column' : ''"
            >
              {{ ea2 }}
            </td>
            <td *ngIf="ea.isQuestion">
              <app-ui-dropdown
                [items]="items"
                [dataId]="ea.id"
                (selection)="dropdownSelected($event)"
                [initialSelectionId]="getSelectionIdByQId(ea.id)?.id"
              ></app-ui-dropdown>
              <!-- <app-ui-dropdown-wrapper
                            [items]="items" [dataId]="ea.id" (selection)="dropdownSelected($event)" [initialSelectionId]="getSelectionIdByQId(ea.id)?.id"></app-ui-dropdown-wrapper> -->

              <app-ui-dropdown
                *ngIf="getItemData(ea.id)?.id == 2"
                [items]="itemsQuantity"
                [dataId]="ea.id"
                (selection)="dropdownQuantitySelected($event)"
                [defaultText]="pleaseSelectQuantity"
                [initialSelectionId]="getSelectionIdByQId(ea.id)?.text"
              ></app-ui-dropdown>
              <!-- <app-ui-dropdown-wrapper *ngIf="getRowData(ea.id)?.response?.includes('2')"
                            [items]="itemsQuantity" [dataId]="ea.id" (selection)="dropdownQuantitySelected($event)" [initialSelectionId]="getSelectionIdByQId(ea.id)?.text"></app-ui-dropdown-wrapper> -->

              <app-ui-dropdown
                *ngIf="getItemData(ea.id)?.id == 3"
                [items]="itemsItems"
                [dataId]="ea.id"
                (selection)="dropdownItemSelected($event)"
                [defaultText]="pleaseSelectItem"
                [initialSelectionId]="getSelectionIdByQId(ea.id)?.text"
              ></app-ui-dropdown>
              <!-- <app-ui-dropdown-wrapper *ngIf="getRowData(ea.id)?.response?.includes('3')"
                            [items]="itemsItems" [dataId]="ea.id" (selection)="dropdownItemSelected($event)" [initialSelectionId]="getSelectionIdByQId(ea.id)?.text"></app-ui-dropdown-wrapper> -->
            </td>
          </tr>
        </tbody>
        <!-- <tfoot>
                <tr *ngFor="let ea of question?.deliveryNote?.footerRows">
                    <th *ngFor="let ea2 of ea.text">{{ea2}}</th>
                    <td *ngIf="ea.isQuestion">
                        <igx-input-group #inputGroup [igxToggleAction]="dropDown" class="input-group">
                            <input #input class="input" type="text" igxInput [igxDropDownItemNavigation]="dropDown"
                                readonly="true" placeholder="Choose an option" [value]="dropDown.selectedItem?.value"
                                (keydown.ArrowDown)="openDropDown()" />
                            <igx-suffix igxButton="icon" class="dropdownToggleButton" igxRipple>
                                <igx-icon>arrow_drop{{ dropDown.collapsed ? '_down' : '_up' }}</igx-icon>
                            </igx-suffix>
                        </igx-input-group>
                        <igx-drop-down #dropDown [width]="'180px'" (selectionChanging)="dropdownSelected(ea.id,$event)">
                            <igx-drop-down-item *ngFor="let item of items" [value]="item.field">
                                {{ item.field }}
                            </igx-drop-down-item>
                        </igx-drop-down>
                         <igx-input-group *ngIf="getRowData(ea.id)?.response?.includes('Amount is Incorrect')">
                        <input placeholder="Enter correct amount" igxInput type="number" [(ngModel)]="answers[ea.id]" (input)="onEntered(ea.id)"/>
                    </igx-input-group>
                    </td>
                </tr>
            </tfoot> -->
      </table>
    </div>
  </div>
</div>
