import { Component, OnInit } from '@angular/core';
import { SystemStorage } from '../shared/omt-lib';

@Component({
  selector: 'app-applicant',
  templateUrl: './applicant.component.html',
  styleUrls: ['./applicant.component.scss'],
})
export class ApplicantComponent implements OnInit {
  private static _HIDE_ELEMENTS = true;
  public static HIDE_ELEMENTS() {
    this._HIDE_ELEMENTS = true;
    document.body.classList.add('_dark');
  }
  public static SHOW_ELEMENTS() {
    this._HIDE_ELEMENTS = false;
    document.body.classList.remove('_dark');
  }
  public static RESET() {
    SystemStorage.INSTANCE.session.getItem$('ExternalDarkMode').then((n) => {
      if (n === 'true') {
        this.HIDE_ELEMENTS();
      } else {
        this.SHOW_ELEMENTS();
      }
    });
  }

  constructor() {}

  public get hideElements() {
    return ApplicantComponent._HIDE_ELEMENTS;
  }
  async ngOnInit() {
    ApplicantComponent.RESET();
  }
}
