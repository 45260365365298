import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  ConnectedPositioningStrategy,
  IgxDropDownComponent,
  IgxInputGroupComponent,
  ISelectionEventArgs,
} from '@infragistics/igniteui-angular';
import { IAssessmentInvoiceData } from 'src/app/models/assessment-invoice-data';
import { IAssessmentResponse } from 'src/app/models/assessment-response';
import { IDropDownEventExtended } from 'src/app/models/dropdown-event-extended';
import { QuestionUtils } from '../../util/question-utils';
import { SystemTranslationService } from 'src/app/shared/system-translation.service';

@Component({
  selector: 'app-ui-question-single-invoice-check',
  templateUrl: './ui-question-single-invoice-check.component.html',
  styleUrls: ['./ui-question-single-invoice-check.component.scss'],
})
export class UiQuestionSingleInvoiceCheckComponent implements OnInit {
  @Input() question!: IAssessmentInvoiceData;
  @Output() answer = new EventEmitter<IAssessmentResponse[]>();
  @Output() isValid = new EventEmitter<boolean>();

  @ViewChild(IgxDropDownComponent, { static: true })
  public igxDropDown!: IgxDropDownComponent;
  @ViewChild('inputGroup', { read: IgxInputGroupComponent, static: true })
  public inputGroup!: IgxInputGroupComponent;

  public timerStart: number = new Date().getTime() / 1000;
  public changes: number = 0;

  public items: { id: number; field: string }[] = [
    // { field: 'Correct' },
    // { field: 'Duplicate Item' },
    // { field: 'Amount is Incorrect' }
  ];
  public itemsFooter: { id: number; field: string }[] = [];

  public answers: { [key: number]: number } = {};
  public responses: IAssessmentResponse[] = [];
  public enterCorrectAmount: string = '';

  constructor(
    private _translate: SystemTranslationService,
  ) {}

  async ngOnInit() {
    await this._translate.isReady$();
    this.enterCorrectAmount = await this._translate.translate$('placeholderEnterCorrectAmount');

    if (this.question && this.question.answers) {
      this.changes = this.question.changes;
      this.responses = this.question.answers;
      this.answer.emit(this.responses);
      this.responses.forEach((x) => {
        if (x.response?.includes('3')) {
          var a = JSON.parse(x.response);
          this.answers[x.id] = Number(a.text);
        }
      });
      this.isValid.emit(this.getValidity());
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes['question'].previousValue &&
      changes['question'].previousValue.id !=
        changes['question'].currentValue.id
    ) {
      this.answers = {};
      this.responses = [];
      if (this.question && this.question.answers) {
        this.responses = this.question.answers;
        this.answer.emit(this.responses);

        this.timerStart = new Date().getTime() / 1000;
        this.changes = this.question.changes;
      }
    }
    this.items = this.question.optionsSelector;
    this.itemsFooter = this.question.optionsSelectorFooter;
    this.isValid.emit(this.getValidity());
  }

  getSelectionIdByQId(questionId: number) {
    let answer = this.responses.find((x) => x.id == questionId);
    if (answer && answer.response) {
      let selection = JSON.parse(answer.response);
      return selection;
    } else {
      return undefined;
    }
  }

  onEntered(id: number) {
    let r = this.getRowData(id);
    if (r != undefined) {
      r.response = JSON.stringify({ id: 3, text: this.answers[id] });
      this.responses[this.responses.findIndex((x) => x.id == id)] = r;
    }
    this.answer.emit(this.responses);
    this.isValid.emit(this.getValidity());
  }

  public dropdownSelected(eventArgs: IDropDownEventExtended) {
    let id: number = eventArgs.dataId;
    let optionId: number = eventArgs.selectionId;

    if (
      this.responses[this.responses.findIndex((x) => x.id == id)].response !=
      null
    ) {
      this.changes++;
    }

    if (this.responses.some((x) => x.id == id)) {
      this.responses[this.responses.findIndex((x) => x.id == id)] = {
        id: id,
        response: JSON.stringify({ id: optionId, text: '' }),
      };
    } else {
      this.responses.push({
        id: id,
        response: JSON.stringify({ id: optionId, text: '' }),
      });
    }

    if (optionId != 3) {
      this.answer.emit(this.responses);
      this.isValid.emit(this.getValidity());
    } else {
      this.isValid.emit(this.getValidity());
    }

    //this.dropdownSelectedValue.emit(selectedOption);
    //eventArgs.cancel = true;
  }

  getValidity(): boolean {
    return QuestionUtils.isValidAnswer(this.responses, 'SingleInvoice');
  }

  public getRowData(id: number) {
    return this.responses.find((x) => x.id == id);
  }

  public saveTimeAndActions() {
    let endTime = new Date().getTime() / 1000;
    let delta = endTime - this.timerStart;
    this.question!.time += Math.floor(delta);
    this.question!.changes = this.changes;
  }

  public isNumericCell(text: any){
    if(isNaN(Number(text)))
    {
      return false;
    } else {
      return true;
    }
  }

  public isNumericHeader(text: string){


    let columnNames = ['Qty','Unit Price','Total', 'Unit Price (R)', 'Unit Cost'];
    if(columnNames.some(x => x == text))
    {
      return true;
    } else {
      return false;
    }
  }
}
