import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AccountService } from 'src/app/core/account.service';
import { UseragentService } from 'src/app/core/useragent.service';
import * as localforage from 'localforage';
import { SystemTranslationService } from 'src/app/shared/system-translation.service';
import { SystemStorage } from 'src/app/shared/omt-lib';
import { OdysseyApiService } from 'src/app/core/odyssey-api.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent implements OnInit {
  // public accountForm = new FormGroup({});

  logo = environment.defaultLogo;
  public account = {
    EmailAddress: '',
  };

  result = { colour: '', message: '' };

  private _isBusy = false;
  public isDone = false;

  public get isBusy() {
    return this._isBusy;
  }

  public getBrowserSupportedCss(): string {
    if (this.uaService.status === 'ua/supported') {
      return 'panel-align';
    } else {
      return 'panel-hidden';
    }
  }

  onClickBack() {
    this._router.navigate(['/']);
  }

  // private async getWhiteLabelLogo$(
  //   droplinkUid: string | null = null,
  //   forceGet = false
  // ) {
  //   const _stored = await localforage.getItem<{
  //     logoUrl: string;
  //     expires: number;
  //   }>('odyssey-white-label');

  //   const _now = new Date().getTime();

  //   if (_stored && _stored.expires > _now) {
  //     this.logo = _stored.logoUrl;
  //   } else {
  //     this.logo = 'https://dev-cdn.odyssess.com/images/logos/odyssey.svg';
  //   }
  // }

  async $returnLogin() {
    this._router.navigate(['/']);
  }

  async onSubmit() {
    this._isBusy = true;
    this.isDone = true;
    const code = await this._api.$passwordResetRequest(
      this.account.EmailAddress
    );
    var text = '';
    await this._translate
      .translate$('contentResetPasswordEmailSent')
      .then((n) => {
        text = n;
      });
    this.result = { colour: 'success', message: text };
    // switch (code) {
    //   case 'account-password-request-reset/success':
    //     await this._translate
    //       .translate$('contentResetPasswordEmailSent')
    //       .then((n) => {
    //         text = n;
    //       });
    //     this.result = { colour: 'success', message: text };
    //     break;
    //   case 'account-password-request-reset/not-found':
    //     await this._translate
    //       .translate$('contentResetPasswordWrongEmail')
    //       .then((n) => {
    //         text = n;
    //       });
    //     this.result = {
    //       colour: 'danger',
    //       message: `${text}[${this.account.EmailAddress}].`,
    //     };
    //     this._isBusy = false;
    //     break;
    // }
    text = '';
  }

  constructor(
    private _api: AccountService,
    private _translate: SystemTranslationService,
    private _router: Router,
    public uaService: UseragentService
  ) {
    // super(translate, 'i18n.reset-password.');
  }

  async ngOnInit() {
    this.logo = await SystemStorage.INSTANCE.local.getWhiteLabelLogo$();
  }
}
