import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ApplicantApiService {
  exec = {
    dea: {
      questionResponseWrite: (
        Responses: {
          QuestionId: number;
          AnswerId: number | null;
          AnswerJson: string | null;
        }[],
        FinaliseQuestionnaire: boolean = false
      ) => {
        return this._http
          .post<{ response: { code: 200; message: 'success' } }>(
            '/api/deaquestionnaire/questionresponsewrite',
            Responses.map((e) => {
              return { ...e, FinaliseQuestionnaire };
            })
          )
          .toPromise();
      },
      questionResponseWriteV2: (
        QuestionResponses: {
          QuestionId: number;
          AnswerId: number | null;
          AnswerJson: string | null;
        }[],
        TrackingInfo: {
          Changes: number;
          Time: number;
        }
      ) => {
        return this._http
          .post<{ response: { code: 200; message: 'success' } }>(
            '/api/deaquestionnaire/questionresponsewriteV2',
            { TrackingInfo, QuestionResponses }
          )
          .toPromise();
      },
      getJson$: (questionnaireId: number) => {
        return this._http
          .get<
            [
              {
                Id: number;
                QuestionTypeId: number;
                QuestionSetNumber: number;
                Text: string;
                ConfigJson: string;
                QuestionSetConfigJson: string;
                _Config?: any;
                Answers?: { Id: number; QuestionId: number; Text: string }[];
                SelectedAnswer?: {
                  QuestionId: number;
                  AnswerId: number;
                  AnswerJson: string;
                };
              }[],
              { Id: number; QuestionId: number; Text: string }[],
              { QuestionId: number; AnswerId: number; AnswerJson: string }[],
              { QuestionnaireConfigJson: string }[],
              { QuestionSetNumber: Number; TrackingInfoJson: string }[]
            ]
          >('/api/deaquestionnaire/getjson', { params: { questionnaireId } })
          .pipe(
            map((e) => {
              const _obj = {
                ...((JSON.parse(e[3][0].QuestionnaireConfigJson || '{}') as {
                  Introduction: { Title: string; Content: string };
                  Instructions: { Title: string; Content: string };
                  Consent: { Title: string; Content: string };
                }) || []),
                currentQuestionSetNumber: 999,
                totalQuestionSets: 0,
                questionSets: [] as {
                  QuestionSetNumber: number;
                  Questions: {
                    [key: string]: any;
                    // QuestionTypeId: number;
                    SelectedAnswer?: {
                      QuestionId: number;
                      AnswerId: number;
                      AnswerJson: string; //xxx | {Response:string,Value:string}
                    };
                  }[];
                  _Config: any;
                  TrackingInfo: { Changes: number; Time: number };
                }[],
              };

              for (let q of e[0]) {
                let _qs = _obj.questionSets.find(
                  (i) => i.QuestionSetNumber === q.QuestionSetNumber
                );
                if (!_qs) {
                  _qs = {
                    QuestionSetNumber: q.QuestionSetNumber,
                    _Config: JSON.parse(q.QuestionSetConfigJson || 'null'),
                    Questions: [],
                    TrackingInfo: { Changes: 0, Time: 0 },
                  };
                  _obj.questionSets.push(_qs);
                }

                q._Config = JSON.parse(q.ConfigJson || 'null');
                if (q._Config) {
                  q._Config.Description = q.Text;
                }

                if (
                  q.QuestionTypeId === 5 /*Question 11*/ ||
                  q.QuestionTypeId === 6 /*Question 12*/
                ) {
                  q._Config.IsQuestion = true;
                }

                q.Answers = e[1].filter((i) => i.QuestionId === q.Id);
                q.SelectedAnswer = e[2].find((i) => i.QuestionId === q.Id);

                _qs.Questions.push(q);

                if (
                  q.SelectedAnswer === undefined &&
                  _obj.currentQuestionSetNumber >= _qs.QuestionSetNumber
                ) {
                  _obj.currentQuestionSetNumber = _qs.QuestionSetNumber;
                }
              }

              _obj.totalQuestionSets = _obj.questionSets.length;

              /*Add shipping line to Q11*/
              const _q11 = _obj.questionSets.find(
                (qs) =>
                  qs.Questions.find(
                    (q) => q.QuestionTypeId === 5 /*Question 11*/
                  ) !== undefined
              );
              if (_q11) {
                _q11.Questions.splice(12, 0, {
                  Text: '_q11._Config.ShippingInfo.Text',
                  _Config: {
                    Quantity: null,
                    'Unit Price': null,
                    Total: _q11._Config.ShippingInfo.Value,
                    Description: _q11._Config.ShippingInfo.Text,
                    IsQuestion: false,
                    Type: 'footer',
                  },
                });
              }

              /*Add tracking info*/
              for (const qst of e[4]) {
                const qs = _obj.questionSets.find(
                  (qs) => qs.QuestionSetNumber === qst.QuestionSetNumber
                );
                if (qs && qst.TrackingInfoJson) {
                  qs.TrackingInfo = JSON.parse(qst.TrackingInfoJson);
                }
              }

              return _obj;
            })
          )
          .toPromise();
      },

      finalizeAnswers$: (QuestionnaireId: number) => {
        return this._http
          .post<{ response: { code: 200; message: 'success' } }>(
            '/api/deaquestionnaire/FinalizeAnswers',
            QuestionnaireId
          )
          .toPromise();
      },
    },
  };
  constructor(private _http: HttpClient) {}
}
