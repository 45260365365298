import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UseragentService } from 'src/app/core/useragent.service';
import { AuthService } from 'src/app/core/auth/auth.service';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { SystemStorage } from 'src/app/shared/omt-lib';
import { SystemTranslationService } from 'src/app/shared/system-translation.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  doLoadHeader: boolean = false;
  formGroup: FormGroup;
  isReady: boolean = false;
  logo = environment.defaultLogo;
  errorMessage: string = ' ';
  isBusy: boolean = false;
  deviceType = 'decktop';

  private _systemMessage: {
    show: boolean;
    type: 'info' | 'warning' | 'error' | null;
    message: SafeHtml | null;
    lockdown: boolean;
  } = {
    show: false,
    type: null,
    message: null,
    lockdown: false,
  };
  public get systemMessage() {
    return this._systemMessage;
  }

  constructor(
    private _auth: AuthService,
    public uaService: UseragentService,
    private _router: Router,
    private _translate: SystemTranslationService,
    private _sanitizer: DomSanitizer
  ) {
    this.formGroup = new FormGroup({
      email: new FormControl('', Validators.email),
      password: new FormControl(''),
    });
  }

  async ngOnInit() {
    this._auth.getSystemMessageList$().then((_list) => {
      if (_list?.length > 0) {
        var _message = _list[0];
        this._systemMessage = {
          show: true,
          type: _message.MessageType,
          message: this._sanitizer.bypassSecurityTrustHtml(_message.Message),
          lockdown: _message.SystemLockdown,
        };
      }
    });
    this.logo = await SystemStorage.INSTANCE.local.getWhiteLabelLogo$();
    await SystemStorage.INSTANCE.base.clear$(); // 'fresh-start'

    if (this.uaService.isMobile) {
      this.deviceType = 'mobile';
    } else {
      this.deviceType = 'decktop';
    }
    //console.log(this.uaService.isMobile);
    //await whitelabel forage or API
    // await await this._localForage.getItem<{ logoUrl: string; expires: number }>('odyssey-white-label').then(
    // //await this._adminApi.exec.configuration.company.getWhiteLabel$().toPromise().then(
    //   (next) => {
    //     this.logo = next.logoUrl;
    //     //this.logo = next.whiteLabel.LogoUrl;
    //   }
    // )
    // await this.getWhiteLabelLogo$();
    //await logout
    await this._auth.postLogout$().toPromise();
    await SystemStorage.INSTANCE.base.setItem$('QuestionnaireItemId', null);
    await SystemStorage.INSTANCE.base.setItem$('email', null);
    await SystemStorage.INSTANCE.base.setItem$('assessmentId', null);

    this.isReady = true;
    //languages?

    //sessionstorage
  }

  ngAfterViewInit() {
    this.formGroup.get('email')?.setValidators([Validators.required]);
    this.formGroup.get('password')?.setValidators([Validators.required]);
  }

  async onSubmitLogin() {
    if (this.formGroup.valid) {
      this.isBusy = true;
      await this.login();
    }
  }

  private async login() {
    let email = this.formGroup.get('email')?.value;
    await this._auth.checkData$(email).then((_data) => {
      if (_data) {
        if (
          _data.Data === false &&
          !_data.DefaultDomain.includes(window.location.host)
        ) {
          window.location.href = `${_data.DefaultDomain}`;
        }
      }
    });
    this.errorMessage = ' ';

    await this._auth
      .postLogin$(email, this.formGroup.get('password')?.value)
      .toPromise()
      .then(
        async (data) => {
          if (data) {
            // sessionStorage.super = data.super;
            await SystemStorage.INSTANCE.base.setItem$(
              'timeToComplete',
              data.timeToComplete
            );
            await SystemStorage.INSTANCE.base.setItem$(
              'companyAlias',
              data.companyAlias
            );

            let _trackingId = data.trackingId
              ? data.trackingId.toString()
              : null;
            await SystemStorage.INSTANCE.base.setItem$(
              'trackingId',
              _trackingId
            );
            if (_trackingId && _trackingId !== null && _trackingId !== 'null') {
              await SystemStorage.INSTANCE.base.setItem$('email', email);
              this._router.navigate(['/applicant/questionnaire/knockout']);
            }

            if (data /*.token*/) {
              this._auth
                .postUserAgent$(data.assessmentId, this.uaService.ua)
                .toPromise()
                .then(async (success) => {
                  if (data.isApplicant) {
                    await SystemStorage.INSTANCE.base.setItem$(
                      'assessmentId',
                      data.assessmentId
                    );
                    await SystemStorage.INSTANCE.base.setItem$('email', email);
                    await this.clearLocallyStoredGameData$(+data.assessmentId);
                    this._router.navigate(['applicant', 'dashboard']);
                  } else {
                    await SystemStorage.INSTANCE.base.setItem$('email', email);

                    // return localforage.setItem(
                    //   'oua-user',
                    //   JSON.stringify({
                    //     email: email,
                    //     roles: data.roles.split(','),
                    //   })
                    // );

                    await SystemStorage.INSTANCE.base.setItem$(
                      'isRestricted',
                      data.isRestricted ? 'true' : 'false'
                    );
                    // window.location.href = '../dash.html?2e779';
                    await this._router.navigate(['/verify-otp']);
                  }
                });
            } else {
              this.errorMessage = 'Authentication failed!';
              this.isBusy = false;
            }
          }
        },
        async (data) => {
          if (
            data.error.Message &&
            data.error.Message == 'All Assessments Completed!'
          ) {
            //window.location.href = '../#/completed';
            this._router.navigate(['/complete']);
          } else if (
            data.error.Message &&
            data.error.Message == 'Assessment Creation Delayed.'
          ) {
            this._router.navigate(['/complete'], { queryParams: { d: '1' } });
          } else {
            if (
              data.error.Message ==
              'Sorry, your username and password is incorrect.'
            ) {
              this.errorMessage = await this._translate.translate$(
                'contentIncorrectPassword'
              );
            }
            //this.errorMessage = data.error.Message;
            this.isBusy = false;
          }
        }
      );
  }

  public getBrowserSupportedCss(): string {
    if (this.uaService.status === 'ua/supported') {
      return 'panel-align';
    } else {
      return 'panel-hidden';
    }
  }

  // private async getWhiteLabelLogo$(
  //   droplinkUid: string | null = null,
  //   forceGet = false
  // ) {
  //   const _stored = await localforage.getItem<{
  //     logoUrl: string;
  //     expires: number;
  //   }>('odyssey-white-label');

  //   const _now = new Date().getTime();

  //   if (_stored && _stored.expires > _now) {
  //     this.logo = _stored.logoUrl;
  //   } else {
  //     this.logo = 'https://dev-cdn.odyssess.com/images/logos/odyssey.svg';
  //   }
  // }

  private async clearLocallyStoredGameData$(currentAssessmentId: number) {
    await SystemStorage.INSTANCE.base.setItem$('reasoningScore', '');
    await SystemStorage.INSTANCE.base.setItem$('GameData', '');
    await SystemStorage.INSTANCE.base.setItem$('gameId', '');
    await SystemStorage.INSTANCE.base.setItem$('G1AssessmentId', '');
    await SystemStorage.INSTANCE.base.setItem$('G2AssessmentId', '');
    await SystemStorage.INSTANCE.base.setItem$('G3AssessmentId', '');
    await SystemStorage.INSTANCE.base.setItem$('G4AssessmentId', '');
    await SystemStorage.INSTANCE.base.setItem$('G5AssessmentId', '');
    await SystemStorage.INSTANCE.base.setItem$('G6AssessmentId', '');
    await SystemStorage.INSTANCE.base.setItem$('G7AssessmentId', '');
    await SystemStorage.INSTANCE.base.setItem$('G8AssessmentId', '');
    await SystemStorage.INSTANCE.base.setItem$('G9AssessmentId', '');
    await SystemStorage.INSTANCE.base.setItem$('G10AssessmentId', '');
  }
}
