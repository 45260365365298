<ng-container *ngIf="isReady">
  <div
    class="modal transition -evalex-radius"
    [ngClass]="{ 'ts-show': model.show, 'ts-hide': !model.show }"
  >
    <div class="overlay"></div>
    <div
      [ngClass]="{ 't-show': model.show, 't-hide': !model.show }"
      class="modal-content -radius-full transition {{ model.size }} {{
        model.colourClass
      }}"
    >
      <div class="modal-header">
        <div
          class="modal-title text-{{ model.colourClass }}-dark"
          [innerHtml]="model.title"
        ></div>
      </div>

      <div
        class="modal-body"
        [innerHtml]="model.body"
        [hidden]="!model.body"
      ></div>
      <div class="modal-body" [hidden]="model.body">
        <ng-content></ng-content>
      </div>

      <div
        class="modal-footer"
        *ngIf="
          model.footer.buttons.left.length > 0 ||
          model.footer.buttons.right.length > 0
        "
      >
        <div
          class="modal-footer-left"
          *ngIf="model.footer.buttons.left.length > 0"
        >
          <input
            *ngFor="let b of model.footer.buttons.left"
            class="btn btn-{{ b.colourClass }} {{ b.colourClass }} -radius-full"
            type="button"
            [value]="b.title"
            (click)="onClickButton(b)"
            [disabled]="b.disabled ? b.disabled() : false"
          />
        </div>
        <div
          class="modal-footer-right"
          *ngIf="model.footer.buttons.right.length > 0"
        >
          <input
            *ngFor="let b of model.footer.buttons.right"
            class="btn btn-{{ b.colourClass }} {{ b.colourClass }} -radius-full"
            type="button"
            [value]="b.title"
            (click)="onClickButton(b)"
            [disabled]="b.disabled ? b.disabled() : false"
          />
        </div>
      </div>
    </div>
  </div>
</ng-container>
