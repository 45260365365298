<!-- Progress & Button Bar -->
<div class="nav-footer ody-sel-none -radius-bottom">
  <div class="nav-button-back">
    <button
      *ngIf="canGoBack"
      class="btn box-btn -radius-bl"
      type="button"
      (click)="onClickBack()"
      [disabled]="!canGoBack"
    >
      <i *ngIf="!isBusy" class="fa fa-arrow-circle-left"></i>
      <i *ngIf="isBusy" class="fa fa-hourglass-half"></i>
      {{ "buttonBack" | translate}}
    </button>
    <!-- <button *ngIf="canSkip" class="btn box-btn" type="button" (click)="onClickSkip()">
      {{ 'Skip' }}
      <i class="fa fa-step-forward"></i>
    </button> -->
  </div>
  <div class="nav-progress-section">
    <div class="progress-label">
      {{ "labelProgress" | translate }}
    </div>
    <div>
      <div class="progress">
        <div
          class="progress-bar"
          role="progressbar"
          [ngStyle]="{ width: progress + '%' }"
        >&nbsp;</div>
        <div class="progress-text"></div>
      </div>
    </div>
    <div class="progress-label">
      {{ progress | number: "1.0-0" }} % 
    </div>
  </div>
  <div class="nav-button-next">
    <!-- <button class="btn box-btn" type="button" (click)="onClickContinue()" [disabled]="disabled && !canSkip"> -->
    <button
      class="btn box-btn -radius-br"
      type="button"
      (click)="onClickContinue()"
      [disabled]="disabled"
    >
      {{ nextText | translate }}
      <i *ngIf="!isBusy" class="fa fa-arrow-circle-right"></i>
      <i *ngIf="isBusy" class="fa fa-hourglass-half"></i>
    </button>
  </div>
</div>

<igx-dialog class="-radius-full" #reports [closeOnOutsideSelect]="false" >
  <igx-dialog-title>
    <div class="dialog-heading">
      <igx-icon class="text-colour-primary">warning</igx-icon>
      <div class="dialog-title text-colour-primary">{{ "labelWarning" | translate }}</div>
    </div>
  </igx-dialog-title>

  <div class="igx-dialog-extender" ></div>
  <div [innerHTML]="warningText"></div>

  <div class="margin-up-down">
    <button class="btn-no " igxButton="contained" (click)="closeWindow()">{{ "buttonNo" | translate }}</button>
    <button class="btn-yes " igxButton="contained" (click)="skipConfirm()">{{ "buttonYes" | translate }}</button>
  </div>
</igx-dialog>

<igx-dialog class="-radius-full" #validationPopup [closeOnOutsideSelect]="false" >
  <igx-dialog-title>
    <div class="dialog-heading">
      <igx-icon class="text-colour-primary">warning</igx-icon>
      <div class="dialog-title text-colour-primary">Warning</div>
    </div>
  </igx-dialog-title>

  <div class="igx-dialog-extender"></div>
  {{ 'contentDeaSameitems' | translate }}
  <div class="duplicate-amounts">
    <button class="btn-yes" igxButton="contained" (click)="closeValidationWindow()">{{'buttonGotIt' | translate }}</button>
  </div>
</igx-dialog>
