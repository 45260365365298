<!-- FORM A -->

<!-- Name and Invoice Number heading (2)-->
<div class="invoices-container">
<div [innerHTML]="question.formName"></div>
<div class="invoice-background col-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
    <table class="table-full-width">
        <tr>
            <td class="bold beautiful" colspan="2" [innerHTML]="question.companyName"></td>
            <td class="right-align bold" colspan="2" [innerHTML]="question.invoiceName"></td>
        </tr>
        <tr>
            <td> </td>
        </tr>
        <tr>
            <td class="table-1-3" [innerHTML]="question.addressFrom"></td>
            <td class="table-1-3"></td>
            <td class="table-1-3 right-align" [innerHTML]="question.addressTo"></td>
        </tr>
        <!-- <tr>
            <td>{{question?.addressFrom}}</td>
            <td>{{question?.addressTo}}</td>
            <td></td>
            <td></td>
        </tr> -->
    </table>
    <table class="table-full-width">
        <!-- header -->
        <thead>
            <th *ngFor="let ea of question?.mainHeaders" [ngClass]="isNumericHeader(ea) ? 'number-header' : ''">{{ "content"+ea | translate }}</th>
        </thead>
        <tbody>
            <tr *ngFor="let ea of question?.mainRows" class="alternating">
                <td *ngFor="let ea2 of ea.text" [ngClass]="isNumericCell(ea2) ? 'number-column' : ''">{{ea2}}</td>
                <td *ngIf="ea.isQuestion">
                    <app-ui-dropdown [items]="items" [dataId]="ea.id" (selection)="dropdownSelected($event)" [initialSelectionId]="getSelectionIdByQId(ea.id)?.id"></app-ui-dropdown>
                    <!-- <app-ui-dropdown-wrapper [items]="items" [dataId]="ea.id" (selection)="dropdownSelected($event)" [initialSelectionId]="getSelectionIdByQId(ea.id)?.id"s></app-ui-dropdown-wrapper> -->
                    <igx-input-group *ngIf="getRowData(ea.id)?.response?.includes('3')">
                        <input [placeholder]="enterCorrectAmount" igxInput type="number" [(ngModel)]="answers[ea.id]" (input)="onEntered(ea.id)"/>
                    </igx-input-group>
                </td>
            </tr>
        </tbody>
        <tfoot>
            <tr><td colspan="5"><hr/></td></tr>
            <tr *ngFor="let ea of question?.footerRows" class="alternating">
                <th *ngFor="let ea2 of ea.text" [ngClass]="isNumericCell(ea2) ? 'number-column' : ''">{{ea2}}</th>
                <td *ngIf="ea.isQuestion">
                    <app-ui-dropdown [items]="itemsFooter" [dataId]="ea.id" (selection)="dropdownSelected($event)" [initialSelectionId]="getSelectionIdByQId(ea.id)?.id"></app-ui-dropdown>
                    <!-- <app-ui-dropdown-wrapper [items]="items" [dataId]="ea.id" (selection)="dropdownSelected($event)" [initialSelectionId]="getSelectionIdByQId(ea.id)?.id"></app-ui-dropdown-wrapper> -->
                    <igx-input-group *ngIf="getRowData(ea.id)?.response?.includes('3')">
                        <input [placeholder]="enterCorrectAmount" igxInput type="number" [(ngModel)]="answers[ea.id]" (input)="onEntered(ea.id)"/>
                    </igx-input-group>
                </td>
            </tr>
        </tfoot>
    </table>
</div>

</div>
