import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ui-banner-top',
  templateUrl: './ui-banner-top.component.html',
  styleUrls: ['./ui-banner-top.component.scss']
})
export class UiBannerTopComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
