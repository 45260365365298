import { Injectable } from '@angular/core';

declare var $: any;

@Injectable({
  providedIn: 'root',
})
export class PageLoaderService {
  private _fullCount = 0;
  private _isShowing = false;

  constructor() {}

  show(): void {
    this._fullCount++;
    if (!this._isShowing) {
      this._isShowing = true;
      $('#loading-overlay').show();
    }
  }

  hide(): void {
    if (--this._fullCount <= 0) {
      this.reset();
    }
  }

  reset(): void {
    this._fullCount = 0;
    if (this._isShowing) {
      this._isShowing = false;
      $('#loading-overlay').hide();
    }
  }

  ngOnInit(): void {
    this.reset();
  }
}
