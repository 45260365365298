import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { SystemStorage } from 'src/app/shared/omt-lib';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class ApplicantGuard  {
  constructor(private _authService: AuthService, private _router: Router) {}

  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    const _user = await SystemStorage.INSTANCE.session.getUser$();
    if (_user && _user.user.Type === 'applicant') {
      return true;
    } else {
      this._router.navigate(['']);
      return false;
    }

    /*let result = await this._authService.getApplicantStatus$().catch((r) => {
      return false;
    });

    if (result === true) {
      return true;
    } else {
      this._router.navigate(['']);
      return false;
    }*/
  }
}
