import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { AccountService } from 'src/app/core/account.service';
import { ERoles } from 'src/app/core/auth/auth-role.guard';
import { LocalForageService } from 'src/app/core/local-forage.service';
import { UseragentService } from 'src/app/core/useragent.service';
import { SystemStorage } from 'src/app/shared/omt-lib';
import { checkPasswordStrength } from 'src/app/shared/utils';

@Component({
  selector: 'app-update-password',
  templateUrl: './update-password.component.html',
  styleUrls: ['./update-password.component.scss'],
})
export class UpdatePasswordComponent implements OnInit {
  public isReady: boolean = false;
  public deviceType = 'decktop';
  public resultMessage = '';
  public account = {
    NewPassword: '',
    ConfirmPassword: '',
    email: '',
  };

  public passwordCheck = {
    uppercase: false,
    lowercase: false,
    symbol: false,
    number: false,
    length: false,
    valid: false
  }

  private _isBusy = false;
  public get isBusy() {
    return this._isBusy;
  }

  constructor(
    private _api: AccountService,
    private _route: ActivatedRoute,
    private _router: Router,
    private _localforage: LocalForageService,
    public uaService: UseragentService
  ) {}

  async ngOnInit() {
    if (!this.isForgotPassword) {
      this.account.email = (await SystemStorage.INSTANCE.base.getItem$('email'))!;
    }
    else{
      
    }
    console.log(this.account.email);
    if (this.uaService.isMobile) {
      this.deviceType = 'mobile';
    } else {
      this.deviceType = 'decktop';
    }

    this.isReady = true;
  }

  public passwordStrength(): string | null {
    return checkPasswordStrength(this.account.NewPassword);
  }

  public getBrowserSupportedCss(): string {
    if (this.uaService.status === 'ua/supported') {
      return 'panel-align';
    } else {
      return 'panel-hidden';
    }
  }

  public get message() {
    return (
      (this.account.NewPassword.length >= 11 &&
      this.account.ConfirmPassword.length >= 11 &&
      this.account.NewPassword !== this.account.ConfirmPassword
        ? 'Password mismatch.'
        : /*this.account.NewPassword !== '' && !this.isStrongPassword
        ? `Password not strong enough (Please choose a password with atleast ten charaters
          and use lowercase, uppercase, numeric and special characters).`
        :*/ '') || this.resultMessage
    );
  }

  public get isForgotPassword() {
    return this._route.snapshot.data.isForgotPassword === true;
  }

  private get _uid() {
    return this._route.snapshot.params.uid;
  }

  private get _accountId() {
    return this._route.snapshot.params.accountId;
  }

  onChange() {
    this.resultMessage = '';
  }

  onClickBack() {
    this._router.navigate(['/']);
  }

  checkPassword() {
    
    var lowercase = /[a-z]/;
    var uppercase = /[A-Z]/;
    var symbol =  /[^0-9a-zA-Z]/;
    var number = /[0-9]/;

    this.passwordCheck.lowercase = lowercase.test(this.account.NewPassword);
    this.passwordCheck.uppercase = uppercase.test(this.account.NewPassword);
    this.passwordCheck.symbol = symbol.test(this.account.NewPassword);
    this.passwordCheck.number = number.test(this.account.NewPassword);
    this.passwordCheck.length = this.account.NewPassword.length > 10;

    this.passwordCheck.valid = this.passwordCheck.lowercase && this.passwordCheck.uppercase && this.passwordCheck.symbol && this.passwordCheck.number && this.passwordCheck.length;
  }

  async onSubmit() {
    this._isBusy = true;
    if (this.isForgotPassword === false) {
      const code = await this._api.passwordUpdate$(
        this.account.NewPassword,
        this.account.ConfirmPassword
      );
      switch (code) {
        case 'account-password-update/success':
          this._router.navigate(['/admin']);
          break;
        case 'account-password-update/failed':
          this.resultMessage = 'Error trying to update password.';
          this._isBusy = false;
          break;
        case 'account-password-update/same-password':
          this.resultMessage =
            'Password matches current password, please enter a new password.';
          this._isBusy = false;
          break;
        case 'account-password-update/password-mismatch':
          this.resultMessage = `Passwords don't match`;
          this._isBusy = false;
          break;
        case 'account-password-update/password-not-strong':
          this.resultMessage = `Password not strong enough (Please choose a password with atleast ten charaters
            and use lowercase, uppercase, numeric and special characters).`;
          this._isBusy = false;
          break;
        case 'in-history':
          this.resultMessage = `Password has been used before, please choose a different password.`;
          this._isBusy = false;
          break;
      }
    } else {
      const result = await this._api.forgotPasswordUpdate$(
        this._accountId,
        this._uid,
        this.account.NewPassword,
        this.account.ConfirmPassword
      );

      if (result) {
        // await SystemStorage.INSTANCE.base.setItem$(
        //   'timeToComplete',
        //   result.TimeToComplete
        // );
        // await SystemStorage.INSTANCE.base.setItem$(
        //   'companyAlias',
        //   result.CompanyAlias
        // );

        switch (result.Code) {
          case 'account-forgot-password-update/success':
            // this._localforage.clearLocallyStoredData();
            // await SystemStorage.INSTANCE.base.clear$('fresh-start');

            // await SystemStorage.INSTANCE.base.setItem$('email', result.Email);

            await SystemStorage.INSTANCE.session.setUser$({
              Type: 'account',
              Company: result.CompanyAlias || 'not-set',
              DisplayName: result.DisplayName || 'not-set',
              LogoUrl: 'not-set',
              Project: 'not-set',
              Roles: [...result.Roles.split(',').map((r) => r as ERoles)],
              SponsorEmail: 'not-set',
              TimeToComplete: 'not-set',
            });
            await SystemStorage.INSTANCE.base.setItem$('isRestricted', 'false');

            // await this._localforage.setEmail$(result.Email);
            // await this._localforage.setItem$('oua-user', {
            //   email: result.Email,
            //   roles: (result.Roles || '').split(','),
            //   displayName: result.DisplayName,
            // });

            // window.sessionStorage.super = false;

            await this._router.navigate(['/verify-otp']);
            break;
          case 'account-forgot-password-update/failed':
            this.resultMessage = 'Error trying to update password.';
            this._isBusy = false;
            break;
          case 'account-forgot-password-update/no-request-found':
            this.resultMessage =
              'The reset link expired. Please request a new link.';
            this._isBusy = false;
            break;
          case 'account-forgot-password-update/same-password':
            this.resultMessage =
              'Password matches current password, please enter a new password.';
            this._isBusy = false;
            break;
          case 'account-forgot-password-update/password-mismatch':
            this.resultMessage = `Passwords don't match`;
            this._isBusy = false;
            break;
          case 'account-forgot-password-update/password-not-strong':
            this.resultMessage = `Password not strong enough (Please choose a password with atleast ten charaters
              and use lowercase, uppercase, numeric and special characters).`;
            this._isBusy = false;
            break;
          case 'in-history':
            this.resultMessage = `Password has been used before, please choose a different password.`;
            this._isBusy = false;
            break;
        }
      }
    }
  }
}
