import { Component, OnDestroy } from '@angular/core';
import * as localforage from 'localforage';
import * as moment from 'moment';
import {
  BehaviorSubject,
  from,
  lastValueFrom,
  Observable,
  Subject,
  Subscription,
} from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ERoles } from '../core/auth/auth-role.guard';
import { OdysseyApiService } from '../core/odyssey-api.service';
import * as ExcelJS from 'exceljs';
import * as zip from '@zip.js/zip.js';
import { IDataGridField } from '../admin/ui/admin-ui-data-grid/admin-ui-data-grid.component';
import { GridColumnDataType } from '@infragistics/igniteui-angular';

export class IHash<T> {
  public static EMPTY<U>() {
    return {} as IHash<U>;
  }
  [uid: string]: T;
}

export class IHashN<T> {
  public static EMPTY<U>() {
    return {} as IHashN<U>;
  }
  [uid: number]: T;
}

export class ISwitch {
  private _flags: IHash<boolean> = {};
  public deactivate(key: string) {
    this._flags[key] = false;
  }
  public activate(key: string) {
    for (const f of iHashToArray(this._flags)) {
      if (f.key !== key) {
        this._flags[f.key] = false;
      }
    }
    this._flags[key] = true;
  }
  public isActive(key: string) {
    return this._flags[key] ? this._flags[key] : false;
  }
}

export const iHashToArray = <T>(iHash: IHash<T>) => {
  const res: { key: string; value: T }[] = [];
  Object.keys(iHash).forEach((key) => {
    res.push({ key: key, value: iHash[key] });
  });
  return res;
};

@Component({ template: '' })
export abstract class SubscriptionHandlerComponent implements OnDestroy {
  private _subscriptions: IHash<Subscription>;

  constructor() {
    this._subscriptions = {};
  }

  ngOnDestroy() {
    for (const sub of iHashToArray(this._subscriptions)) {
      sub.value.unsubscribe();
    }
    this._subscriptions = {};
  }

  push(key: string, subscripion: Subscription): SubscriptionHandlerComponent {
    this.clear(key);
    this._subscriptions[key] = subscripion;

    return this;
  }

  clear(key: string) {
    if (this._subscriptions[key]) {
      this._subscriptions[key].unsubscribe();
    }
  }
}

export const util = {
  excel: (filename: string, data: any[], framework: IDataGridField[]) => {
    const wb = new ExcelJS.Workbook();
    const ws = wb.addWorksheet('Export');

    // Populate Cell Values
    let colI = 0;
    for (const _f of framework) {
      let rowI = 1;
      // Header
      const _hCell = ws.getRow(rowI++).getCell(++colI);
      _hCell.value = _f.header || _f.field;
      _hCell.style = { font: { bold: true } };
      _hCell.border = {
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' },
      };
      _hCell.font = {
        name: 'Calibri',
        color: { argb: 'ffffff' },
        family: 2,
        size: 12,
        bold: true,
      };
      _hCell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '064a60' },
      };
      // Data
      for (const _d of data) {
        const _dCell = ws.getRow(rowI++).getCell(colI);
        if (
          _f.dataType !== GridColumnDataType.Date &&
          _f.dataType !== GridColumnDataType.DateTime
        ) {
          _dCell.value = util._getDataValue(_d, _f.field);
        } else {
          if (util._getDataValue(_d, _f.field) === null) {
            _dCell.value = '';
          } else {
            _dCell.value = moment(util._getDataValue(_d, _f.field)).format(
              'DD MMM YYYY'
            );
          }
        }
        _dCell.border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' },
        };

        _dCell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'f7f7f7' },
        };
      }
    }
    //Set the Column Width
    colI = 0;
    for (const _f of framework) {
      const column = ws.getColumn(++colI);

      column.width = (_f.width ?? 300) / 12;

      if (_f.align) {
        column.alignment = { horizontal: 'center' };
      }
      if (_f.wrap) {
        column.alignment = { wrapText: true };
      }
    }

    wb.xlsx.writeBuffer().then((buffer) => {
      util.zip.createAndDownloadBlobFile(buffer, filename);
    });
  },
  copy$: async (pwd: string) => {
    try {
      if (navigator.clipboard) {
        await navigator.clipboard.writeText(pwd);
        return true;
      } else {
        return util._fallbackCopy(pwd);
      }
    } catch (ex) {
      return util._fallbackCopy(pwd);
    }
  },
  _fallbackCopy: (pwd: string) => {
    const input = document.createElement('input');
    try {
      // input.id = '--evalex-pci';
      input.style.position = 'fixed';
      input.style.left = '0';
      input.style.top = '0';
      input.style.opacity = '0';
      input.style.zIndex = '-1';
      input.value = pwd;
      document.body.appendChild(input);
      input.focus();
      input.select();
      return document.execCommand('copy');
    } catch (ex) {
      // console.log('ex-cfb', ex);
      return false;
    } finally {
      document.body.removeChild(input);
    }
  },

  _getDataValue(d: any, key: string) {
    let _d = { ...d };
    for (let k of key.split('.') || []) {
      _d = _d[k];
    }
    return _d;
  },

  zip: {
    async createAndDownloadBlobFile(
      body: ExcelJS.Buffer,
      filename: string,
      password?: string,
      extension = 'xlsx'
    ) {
      filename =  'Odyssey ' + filename + ' ' + util.date.timestamp();

      const blob = new Blob([body]);

      let zipFilename = `${filename}.${extension}`;

      if (!password) {
        password = await SystemStorage.INSTANCE.session.getItem$(
          'session-password'
        );
        if (password === undefined) {
          password = util.generatePassword();
          await SystemStorage.INSTANCE.session.setItem$(
            'session-password',
            password
          );
        }
      }

      await util.copy$(password ?? '');
      // console.log('pwd >> ', password);
      zip.configure({
        useWebWorkers: false,
      });
      const zipWriter = new zip.ZipWriter(
        new zip.Data64URIWriter('application/zip'),
        { password: password, zipCrypto: true }
      );
      await zipWriter.add(zipFilename, new zip.BlobReader(blob));

      zipFilename = `${filename}.zip`;

      const dataURI = await zipWriter.close();
      // console.log('>> dataURI', dataURI);

      const link = document.createElement('a');
      // Browsers that support HTML5 download attribute
      if (link.download !== undefined) {
        // const url = URL.createObjectURL(dataURI);
        link.setAttribute('href', dataURI);
        link.setAttribute('download', zipFilename);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    },
  },
  date: {
    timestamp: () => {
      return moment().format('YYYYMMDDHHmm');
    },
  },
  generatePassword: () => {
    const specials = '!@#$%^&*()_+{}:"<>?|[];\',./`~';
    const lowercase = 'abcdefghijklmnopqrstuvwxyz';
    const uppercase = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const numbers = '0123456789';

    const all = specials + lowercase + uppercase + numbers;

    function generatePassword() {
      let password = '';

      password += pick(password, specials, 1, 3);
      password += pick(password, lowercase, 1, 3);
      password += pick(password, uppercase, 1, 3);
      password += pick(password, all, 11);

      return shuffle(password);
    }

    function pick(exclusions: string, string: string, min: number, max = min) {
      let n: number,
        chars = '';

      n = min + Math.floor(Math.random() * (max - min + 1));

      let i = 0;
      while (i < n) {
        const character = string.charAt(
          Math.floor(Math.random() * string.length)
        );
        if (exclusions.indexOf(character) < 0 && chars.indexOf(character) < 0) {
          chars += character;
          i++;
        }
      }

      return chars;
    }

    // Credit to @Christoph: http://stackoverflow.com/a/962890/464744
    function shuffle(string: string) {
      const array = string.split('');
      let tmp: string,
        current: number,
        top = array.length;

      if (top) {
        while (--top) {
          current = Math.floor(Math.random() * (top + 1));
          tmp = array[current];
          array[current] = array[top];
          array[top] = tmp;
        }
      }

      return array.join('');
    }

    return generatePassword();
  },
};

export enum ETime {
  s1 = 1000,
  s5 = 5 * s1,
  s10 = 10 * s1,
  s15 = 15 * s1,
  s30 = 30 * s1,
  s45 = 45 * s1,
  m1 = 60 * s1,
  m5 = 5 * m1,
  m10 = 10 * m1,
  m15 = 15 * m1,
  m30 = 30 * m1,
  m45 = 45 * m1,
  h1 = 60 * m1,
  h2 = 2 * h1,
  h24 = 24 * h1,
}

export function currentTimeMillis() {
  return moment().valueOf();
}

export function wait$(forMs: number) {
  return new Promise<void>((res, rej) => {
    const _i = window.setTimeout(() => {
      window.clearTimeout(_i);
      res();
    }, forMs);
  });
}

export function objectKeysToStartUpper(obj: any) {
  const _obj: any = {};
  for (let k of Object.keys(obj)) {
    if (k.length > 0 && k.charAt(0) !== k.charAt(0).toUpperCase()) {
      const _k = k.charAt(0).toUpperCase() + k.substring(1);
      _obj[_k] = obj[k];
    }
  }
  return _obj;
}

class CachedData<T> {
  private _lastRunTime = 0;
  private _$cache: Promise<T> | null = null;

  constructor(
    private _$fetchData: (params: any) => Observable<T>,
    private _validForMillis: number = ETime.m30
  ) {}

  $get(params: any) {
    const currentTime = currentTimeMillis();

    if (currentTime - this._validForMillis >= this._lastRunTime) {
      this._$cache = lastValueFrom(this._$fetchData(params));
      this._lastRunTime = currentTime;
    }
    return this._$cache!;
  }

  forceFetch() {
    this._lastRunTime = 0;
  }
}

export class ParameterisedCachedData<T> {
  private _iHash: IHash<CachedData<T>> = {};

  private static _generateKey(params: any) {
    const keys = Object.keys(params).sort();
    let key = '';
    for (const k of keys) {
      const value = params[k];
      if (
        typeof value === 'string' ||
        typeof value === 'number' ||
        typeof value === 'boolean'
      ) {
        key += '|' + params[k].toString();
      }
    }
    return key;
  }

  constructor(
    private _$fetchData: (params: any) => Observable<T>,
    private _validForMillis: number = ETime.m30
  ) {}

  $get(params: any = {}, forceRefresh: boolean = false) {
    const key = ParameterisedCachedData._generateKey(params);
    if (!this._iHash[key] || forceRefresh === true) {
      this._iHash[key] = new CachedData<T>(
        this._$fetchData,
        this._validForMillis
      );
    }
    return this._iHash[key].$get(params);
  }
}

export class SessionUser<T extends ISessionUser> {
  public static GET<T extends ISessionUser>(_user: T) {
    return new SessionUser<T>(_user);
  }

  public get user() {
    return this._user;
  }

  public hasRole(...roles: ERoles[]) {
    for (let r of roles) {
      if (this.user.Roles.includes(r)) {
        return true;
      }
    }
    return false;
  }

  private constructor(private _user: T) {}
}

export interface ISessionUser {
  // Token: string | null;
  Type: 'applicant' | 'account';
  DisplayName: string;
  Company: string;
  LogoUrl: string | null;
  Roles: ERoles[];
}

export interface ISessionApplicant extends ISessionUser {
  Project: string;
  TimeToComplete: string;
  SponsorEmail: string;
}

type _LocalSystemStoreType = number | string /* | undefined*/;
type _SessionSystemStoreType =
  | number
  | string
  | ISessionUser
  | ISessionApplicant
  | undefined;
/*| undefined*/

// export type SystemStorageKeys = 'odyssey-white-label';
export interface ILocalSystemStore {
  [key: string]: _LocalSystemStoreType; // LanguageId: string
}
export interface ISessionSystemStore {
  user: ISessionUser | ISessionApplicant;
  [key: string]: _SessionSystemStoreType;
}

export type TBaseStorageKey =
  | '__key'
  | '__show-dra-credit-message'
  | '_isFullyIntegratedAssessment'
  | 'companyAlias'
  | 'timeToComplete'
  | 'isRestricted'
  | 'odyssey-font-size'
  | 'assessmentId'
  | 'trackingId'
  | 'QuestionnaireItemId'
  | 'questionnaireId'
  | 'email'
  | 'dea-currentQuestionId'
  | 'displayName'
  //| 'languageId'
  | 'LanguageId'
  | 'partner-redirect-url'
  | 'partner-trigger-url'
  | 'honesty-statement-modal'
  | 'honesty-statement-2-modal'
  | '_assessmentKey'
  | 'mobileRotateInstructionsShown'
  /*GAME ITEMS*/
  | 'reasoningScore'
  | 'GameData'
  | 'gameId'
  | 'G1AssessmentId'
  | 'G2AssessmentId'
  | 'G3AssessmentId'
  | 'G4AssessmentId'
  | 'G5AssessmentId'
  | 'G6AssessmentId'
  | 'G7AssessmentId'
  | 'G8AssessmentId'
  | 'G9AssessmentId'
  | 'G10AssessmentId';

export class SystemStorage {
  private static _INSTANCE: SystemStorage | null = null;

  public static get INSTANCE() {
    if (this._INSTANCE === null) {
      this._STATE = 'set';
      this._INSTANCE = new SystemStorage();
    }
    return this._INSTANCE;
  }

  private static _STATE: 'unset' | 'set' | 'init' | 'ready' = 'unset';

  public static CLEAR() {
    this._INSTANCE?.session._user$$.next(undefined);
    this._INSTANCE = null;
    this._STATE = 'unset';
    this._IS_READY$ = null;
  }

  private static _IS_READY$: Promise<boolean> | null = null;
  public static get IS_READY$() {
    if (this._IS_READY$ === null) {
      // console.log('CREATE NEW PROMISE');
      this._IS_READY$ = new Promise((res, rej) => {
        const _i = window.setInterval(() => {
          if (this._STATE === 'ready') {
            // console.log('RESOLVE PROMISE');
            window.clearInterval(_i);
            res(true);
          }
        }, 125);
      });
    }
    return this._IS_READY$;
  }

  private _local: Partial<ILocalSystemStore> = {};
  private _session: Partial<ISessionSystemStore> = {};

  private _localKey = currentTimeMillis();
  private _sessionKey = currentTimeMillis();

  private constructor() {
    this._init$();
  }

  private async _init$() {
    if (SystemStorage._STATE === 'set') {
      SystemStorage._STATE = 'init';

      this._localKey =
        (await localforage.getItem<number>('__key')) || this._localKey;
      this._sessionKey =
        +(sessionStorage.getItem('__key') || '0') || this._sessionKey;

      this._local = JSON.parse(
        (await localforage.getItem<string>(this._localKey.toString())) || '{}'
      );
      this._session = JSON.parse(
        sessionStorage.getItem(this._sessionKey.toString()) || '{}'
      );

      // CLEAN UP LATE - XXX
      // TODO need to work on this - when user is switched over to storage service
      // await localforage.clear();
      // sessionStorage.clear();

      await localforage.setItem<number>('__key', this._localKey);
      sessionStorage.setItem('__key', this._sessionKey.toString());

      await localforage.setItem(
        this._localKey.toString(),
        JSON.stringify(this._local)
      );

      sessionStorage.setItem(
        this._sessionKey.toString(),
        JSON.stringify(this._session)
      );

      // SETTING USER WHICH WAS STORED
      if (this._session.user) {
        this.session._user$$.next(this._session.user);
      } else {
        this.session._user$$.next(undefined);
      }

      SystemStorage._STATE = 'ready';
    }
  }

  public async clear$() {
    this._local = {};
    this._session = {};

    sessionStorage.setItem(
      this._sessionKey.toString(),
      JSON.stringify(this._session)
    );

    await localforage.setItem(
      this._localKey.toString(),
      JSON.stringify(this._local)
    );
  }

  private _base = {
    persistantFields: [
      /*SYSTEM STORE DATA*/
      '__key',
      /*GAME DATA*/
      'reasoningScore',
      'GameData',
      'gameId',
      'G1AssessmentId',
      'G2AssessmentId',
      'G3AssessmentId',
      'G4AssessmentId',
      'G5AssessmentId',
      'G6AssessmentId',
      'G7AssessmentId',
      'G8AssessmentId',
      'G9AssessmentId',
      'G10AssessmentId',
      /*OTHRE*/
      //'languageId',
      'LanguageId',
      'odyssey-white-label',
    ] as TBaseStorageKey[],
  };

  public base = {
    clear$: async () => {
      await SystemStorage.IS_READY$;

      for (let i = 0; i < localStorage?.length || 0; ++i) {
        const _key = localStorage.key(i) as TBaseStorageKey;
        if (
          !this._base.persistantFields.includes(_key)
          /*&&
          !_key.startsWith('_ati-')*/
        ) {
          localStorage.removeItem(_key);
        }
      }

      const _lfk = await localforage.getItem('__key');
      for (let _key of ((await localforage?.keys()) ||
        []) as TBaseStorageKey[]) {
        if (
          !this._base.persistantFields.includes(_key) &&
          /*!_key.startsWith('_ati-') &&*/
          _key !== _lfk
        ) {
          await localforage.removeItem(_key);
        }
      }

      const _ssk = sessionStorage.getItem('__key');
      for (let i = 0; i < sessionStorage?.length || 0; ++i) {
        const _key = sessionStorage.key(i) as TBaseStorageKey;
        if (
          !this._base.persistantFields.includes(_key) &&
          /*!_key.startsWith('_ati-') &&*/
          _key !== _ssk
        ) {
          sessionStorage.removeItem(_key);
        }
      }
    },
    getItem$: async (key: TBaseStorageKey): Promise<string | null> => {
      return this.base.getSpecialItem$(key);
    },
    getSpecialItem$: async (key: string): Promise<string | null> => {
      await SystemStorage.IS_READY$;
      const _ssv = sessionStorage?.getItem(key);
      if (_ssv) {
        return _ssv;
      }

      const _lfv = await localforage?.getItem<string>(key);
      if (_lfv) {
        return _lfv;
      }

      const _lsv = localStorage?.getItem(key);
      if (_lsv) {
        return _lsv;
      }

      return null;
    },
    getItemAsInt$: async (key: TBaseStorageKey): Promise<number | null> => {
      await SystemStorage.IS_READY$;
      const _value = await this.base.getItem$(key);
      if (_value) {
        return Number.parseInt(_value);
      } else {
        return null;
      }
    },
    setItem$: async (
      key: TBaseStorageKey,
      value: string | number | null | undefined
    ) => {
      return this.base.setSpecialItem$(key, value);
    },
    setSpecialItem$: async (
      key: string,
      value: string | number | null | undefined
    ) => {
      await SystemStorage.IS_READY$;
      if (value !== null && value !== undefined) {
        localStorage?.setItem(key, value.toString());
        await localforage?.setItem(key, value);
        sessionStorage?.setItem(key, value.toString());
      } else {
        localStorage?.removeItem(key);
        await localforage?.removeItem(key);
        sessionStorage?.removeItem(key);
      }
    },
  };

  public local = {
    setItem$: async (
      key: keyof ILocalSystemStore,
      value: _LocalSystemStoreType | undefined
    ) => {
      await SystemStorage.IS_READY$;
      if (value !== undefined) {
        this._local[key] = value;
      } else {
        delete this._local[key];
      }

      await localforage.setItem(
        this._localKey.toString(),
        JSON.stringify(this._local)
      );
    },
    getItem$: async <T extends _LocalSystemStoreType>(
      key: keyof ILocalSystemStore
    ): Promise<T> => {
      await SystemStorage.IS_READY$;
      return this._local[key] as T;
    },
    getWhiteLabelLogo$: async (
      /*TODO: Need to change SystemStorage to a service to inject the OdysseySApiService*/
      api?: OdysseyApiService,
      fields: {
        loginKey?: string;
        cId?: number;
        droplinkUid?: string;
      } | null = null
    ) => {
      await SystemStorage.IS_READY$;
      let _stored = await this.local._getWhiteLabelLogo$();
      if (!_stored && api && fields) {
        const _res = await api.whiteLabel$(fields);
        if (_res?.whiteLabel?.EnableWhiteLabel || false) {
          _stored = _res.whiteLabel.LogoUrl;
        }
      }
      await this.local._setWhiteLabelLogo$(_stored || environment.defaultLogo);
      return _stored || environment.defaultLogo;
    },
    _setWhiteLabelLogo$: async (logoUrl: string): Promise<void> => {
      await SystemStorage.IS_READY$;
      const _now = new Date().getTime();
      const _expires = _now + 7 * 24 * 60 * 60 * 1000;
      await this.local.setItem$('whiteLabel.expires', _expires);
      await this.local.setItem$('whiteLabel.logoUrl', logoUrl);

      //legacy for the games
      this.base.setSpecialItem$(
        'odyssey-white-label',
        JSON.stringify({ expires: _expires, logoUrl })
      );
    },
    _getWhiteLabelLogo$: async (): Promise<string | null> => {
      await SystemStorage.IS_READY$;
      const _expires = this._local['whiteLabel.expires']
        ? +this._local['whiteLabel.expires']
        : null;
      const _logoUrl = (
        this._local['whiteLabel.logoUrl']
          ? this._local['whiteLabel.logoUrl']
          : null
      ) as string | null;

      if (_expires !== null) {
        const _now = new Date().getTime();

        if (_expires > _now) {
          return _logoUrl;
        }
      }
      return null;
    },
  };

  public legacy = {
    setItem$: async (key: string, value: any) => {
      if (localStorage) {
        localStorage.setItem(key, JSON.stringify(value));
      }
      if (localforage) {
        await localforage.setItem(key, JSON.stringify(value));
      }
    },
  };

  public session = {
    setItem$: async (
      key: keyof ISessionSystemStore,
      value: _SessionSystemStoreType | undefined
    ) => {
      await SystemStorage.IS_READY$;
      if (value !== undefined) {
        this._session[key] = value;
      } else {
        delete this._session[key];
      }
      sessionStorage.setItem(
        this._sessionKey.toString(),
        JSON.stringify(this._session)
      );
      return;
    },
    getItem$: async <T extends _SessionSystemStoreType>(
      key: keyof ISessionSystemStore
    ): Promise<T> => {
      await SystemStorage.IS_READY$;
      return this._session[key] as T;
    },

    _user$$: new BehaviorSubject<ISessionUser | ISessionApplicant | undefined>(
      undefined
    ),
    user$() {
      const o = from(SystemStorage.IS_READY$);
      return o.pipe(take(1)).pipe(
        switchMap(() => {
          return this._user$$.pipe(
            map((_u) => {
              if (_u) {
                return SessionUser.GET(_u);
              } else {
                return null;
              }
            })
          );
        })
      );
    },
    async getUser$() {
      return this.user$().pipe(take(1)).toPromise();

      // this.setUser$(await this.getItem$<T>('user'))

      // const _u = await this.getItem$<T>('user');
      // if (_u) {
      //   return SessionUser.GET<T>(_u);
      // } else {
      //   return null;
      // }
    },
    async setApplicant$(
      _user: ISessionApplicant | undefined,
      email: string,
      assessmentId: string | null,
      trackingId: string | null
    ) {
      await this.setUser$(_user);
      if (_user) {
        await SystemStorage.INSTANCE.base.setItem$('email', email || '');
        await SystemStorage.INSTANCE.base.setItem$(
          'assessmentId',
          assessmentId
        );
        await SystemStorage.INSTANCE.base.setItem$('trackingId', trackingId);
        await SystemStorage.INSTANCE.base.setItem$(
          'displayName',
          _user.DisplayName
        );

        await SystemStorage.INSTANCE.base.setItem$(
          'timeToComplete',
          _user.TimeToComplete
        );

        await SystemStorage.INSTANCE.base.setItem$(
          'companyAlias',
          _user.Company
        );
      }
    },
    async setUser$(_user: ISessionUser | ISessionApplicant | undefined) {
      this._user$$.next(_user);
      await this.setItem$('user', _user);
    },
  };
}
