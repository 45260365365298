<app-ui-banner-top></app-ui-banner-top>
<div
  [hidden]="!systemMessage.show"
  class="banner-system-message {{ systemMessage.type ?? '' }}"
  [innerHTML]="systemMessage.message"
></div>

<div [class]="getBrowserSupportedCss()">
  <div class="container-fluid big-top-margin">
    <!-- LOGIN BOX -->
    <div
      class="panel-float"
      *ngIf="isReady && uaService.status === 'ua/supported'"
    >
      <form [formGroup]="formGroup" (ngSubmit)="onSubmitLogin()">
        <!-- LOGIN BOX -->
        <div logo class="logo-container">
          <img [src]="logo" alt="Logo" />
        </div>
        <!-- ERROR MESSAGE -->
        <div [hidden]="!errorMessage" class="error-message top-margin">
          <ng-container
            *ngIf="errorMessage != 'Assessment Creation Delayed.'"
          >
            <span >{{ errorMessage }}</span>
          </ng-container>
          <ng-container
            *ngIf="errorMessage == 'Assessment Creation Delayed.'"
          >
            <span [innerHTML]="'contentDelayedAssessment' | translate"></span>
          </ng-container>
        </div>
        <!-- LOGIN MESSAGE -->
        <p
          class="otpMessage top-margin"
          [innerHTML]="'contentLoginWithLinkPrompt' | translate"
        ></p>
        <!-- PASSWORD INPUT -->
        <div text-margin class="top-margin">
          <input
            class="text-box-input"
            type="password"
            formControlName="password"
            placeholder="{{
              'labeldroplink.label.form.id-number.' | translate
            }}"
            required
          />
        </div>

        <!-- <app-form-input id="email" type="email" required="true" [formGroup]="formGroup"></app-form-input>
        <app-form-input id="password" type="password" required="true" [formGroup]="formGroup"></app-form-input> -->
        <div text-margin class="top-margin">
          <button
            type="submit"
            class="button-big-login"
            [disabled]="!formGroup.valid || isBusy || systemMessage.lockdown"
          >
            {{ 'buttonLogin' | translate }}
          </button>
        </div>

        <hr />
        <!-- <a href="/WebContent/ng/admin/account/password-reset">{{
        "labelAccountForgotPassword" | translate
      }}</a> -->
        <!-- <a routerLink="/password-reset">{{
        "labelAccountForgotPassword" | translate
      }}</a> -->
      </form>
    </div>
  </div>
</div>

<app-browser-support-message></app-browser-support-message>
