import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ConnectedPositioningStrategy, IgxDropDownComponent, IgxInputGroupComponent, ISelectionEventArgs } from '@infragistics/igniteui-angular';


@Component({
  selector: 'app-ui-dropdown-wrapper',
  templateUrl: './ui-dropdown-wrapper.component.html',
  styleUrls: ['./ui-dropdown-wrapper.component.scss']
})
export class UiDropdownWrapperComponent implements OnInit {
  @Input()  items: { id: number, field: string }[] = [];
  @Input()  dataId: number = 0;
  @Input()  itemHeight: number = 24;
  //@Input()  itemsMaxHeight: number = 80;
  @Input()  initialSelectionId: number | undefined;
  @Output() selection = new EventEmitter<{dataId: number, selectionId:number, selection: string}>();

  @ViewChild(IgxDropDownComponent, { static: true })
  public igxDropDown!: IgxDropDownComponent;
  @ViewChild('inputGroup', { read: IgxInputGroupComponent, static: true })
  public inputGroup!: IgxInputGroupComponent;

  public selectedItem: string | undefined;
  constructor() {

  }

  ngOnInit(): void {
    if(this.initialSelectionId) {
      this.selectedItem = this.items.find(x => x.id == this.initialSelectionId)?.field;
    }
  }

  public dropdownSelected(eventArgs: ISelectionEventArgs) {
    this.selectedItem = String(eventArgs.newSelection.value);
    this.selection.emit({
      dataId: this.dataId,
      selectionId: this.items.find(x => x.field == eventArgs.newSelection.value)!.id,
      selection: String(eventArgs.newSelection.value)
    })
  }

  public openDropDown() {
    if (this.igxDropDown.collapsed) {
        this.igxDropDown.open({
            target: this.inputGroup.element.nativeElement,
            modal: false,
            positionStrategy: new ConnectedPositioningStrategy()
        });
    }
  }
}
