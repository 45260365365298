import { AfterContentChecked, AfterViewChecked, AfterViewInit, ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
// import * as intlTelInput from 'intl-tel-input';
import { Observable } from 'rxjs';
import { IApplicantForm } from 'src/app/models/applicant-form';
import { IApplicantFormLookups } from 'src/app/models/applicant-form-lookups';
import { SystemTranslationService } from '../../system-translation.service';
import { ElementRef } from '@angular/core';
import { ISelectionEventArgs, IgxDatePickerComponent } from '@infragistics/igniteui-angular';
import { IBaseCancelableBrowserEventArgs } from '@infragistics/igniteui-angular/lib/core/utils';

@Component({
  selector: 'app-ui-applicant-form',
  templateUrl: './ui-applicant-form.component.html',
  styleUrls: ['./ui-applicant-form.component.scss']
})
export class UiApplicantFormComponent implements OnInit, OnChanges, AfterViewInit, AfterContentChecked {

  
  //fields to show
  //@Input 
  //data
  @Input() applicantData: IApplicantForm
  @Input() lookups: IApplicantFormLookups
  @Input() showFields: string[] | null = null
  @Input() disableFields: string[] | null = null
  @Input() optionalFields: string[] | null = null
  @Input() initialCountryCode: string | undefined = 'za'
  @ViewChild('formCtrl') public formCtrl: NgForm | undefined;
  //@ViewChild('formPopup') set _formPopup(c : NgForm){this.popup.form = c}
  @ViewChild('idNumberT', { read: ElementRef, static: false })
  public idNumberInput!: ElementRef;
  


  public readonly DISABILITY_OTHER_ID = 6;

  public shownFields: {[key: string]: boolean} = {
    nationality: false,
    'id-number': false,
    'current-company': false,
    'current-position': false,
    qualification: false,
    'first-name': false,
    'last-name': false,
    email: false,
    'date-of-birth': false,
    'mobile-number': false,
    gender: false,
    race: false,
    language: false,
    'language-level': false,
    disability: false,
    'evalex-position-type': false,
    'evalex-position-level': false,
    'optional-email': false,
    'address-line-1': false,
    'address-line-2': false,
    'address-line-3': false,
    province: false,
    reference1: false,
    reference2: false,
    applicationTypeId: false,
    dependantsNumber: false,
    maritalStatusId: false,
    microenterpriseAge: false,
    ageAtAddress: false,
    hasBankAccount: false,
    transactingMethodId: false,
  }

  public disabledFields: {[key: string]: boolean} = {
    nationality: false,
    'id-number': false,
    'current-company': false,
    'current-position': false,
    qualification: false,
    'first-name': false,
    'last-name': false,
    email: false,
    'date-of-birth': false,
    'mobile-number': false,
    gender: false,
    race: false,
    language: false,
    'language-level': false,
    disability: false,
    'evalex-position-type': false,
    'evalex-position-level': false,
    'optional-email': false,
    'address-line-1': false,
    'address-line-2': false,
    'address-line-3': false,
    province: false,
    reference1: false,
    reference2: false,
    applicationTypeId: false,
    dependantsNumber: false,
    maritalStatusId: false,
    microenterpriseAge: false,
    ageAtAddress: false,
    hasBankAccount: false,
    transactingMethodId: false,
  }

  public optionaledFields: {[key: string]: boolean} = {
    nationality: false,
    'id-number': false,
    'current-company': false,
    'current-position': false,
    qualification: false,
    'first-name': false,
    'last-name': false,
    email: false,
    'date-of-birth': false,
    'mobile-number': false,
    gender: false,
    race: false,
    language: false,
    'language-level': false,
    disability: false,
    'evalex-position-type': false,
    'evalex-position-level': false,
    'optional-email': false,
    'address-line-1': false,
    'address-line-2': false,
    'address-line-3': false,
    province: false,
    reference1: false,
    reference2: false,
    applicationTypeId: false,
    dependantsNumber: false,
    maritalStatusId: false,
    microenterpriseAge: false,
    ageAtAddress: false,
    hasBankAccount: false,
    transactingMethodId: false,
  }

  // private _iti: intlTelInput.Plugin | undefined = undefined;

  private idNumberExcludeRegex: RegExp | undefined;

  // selectedNationalityId: number | null = null;
  projectUniqueIdConfig: {
    pattern: string | null;
    excludePattern: string | null;
    min: number | null;
    max: number | null;
  } = { pattern: null, excludePattern: null, min: 6, max: null };
  projectNationalityId: number | null = null;

  nationalityUniqueIdConfig:{
    pattern: string | null;
    excludePattern: string | null;
    min: number | null;
    max: number | null;
  } = { pattern: "[a-zA-Z0-9]{6,20}", excludePattern: "[^a-zA-Z0-9]", min: 6, max: 20 };

  initialDateDisplay:Date = new Date(2000, 0, 1);

  constructor(
    private _translate: SystemTranslationService,
    private cdref: ChangeDetectorRef,
  ) { 
    this.applicantData = {
      id: -1,
      currentCompany: "",
      currentPosition: "",
      qualificationId: -1,
      firstName: "",
      lastName: "",
      idNumber: "",
      dateOfBirth: "",
      mobileNumber: "",
      email: "",
      genderId: -1,
      nationalityId: -1,
      raceId: -1,
      languageId: -1,
      languageLevelId: -1,
      assessmentLanguageId: -1,
      disabilityId: -1,
      disabilityOther: "",

      evalexPositionLevelId: -1,
      evalexPositionTypeId: -1,

      optionalEmail: "",
      addressLine1: "",
      addressLine2: "",
      addressLine3: "",
      provinceId: -1,

      reference1: "",
      reference2: "",

      applicationTypeId: undefined,
      dependantsNumber: undefined,
      maritalStatusId: undefined,
      microenterpriseAge: undefined,
      ageAtAddress: undefined,
      hasBankAccount: undefined,
      transactingMethodId: undefined,
    }

    this.lookups = {
      nationalityLookup: [],
      evalexPositionLevelLookup: [],
      evalexPositionTypeLookup: [],
      qualificationLookup: [],
      provinceLookup: [],
      genderLookup: [],
      raceLookup: [],
      languageLookup: [],
      languageLevelLookup: [],
      disabilityLookup: [],
      
      applicationTypes: [],
      bitLookups: [],
      maritalStatuses: [],
      transactingMethods: [],
    }
  }

  ngOnInit(): void {
    this.buildShownFields()
    this.buildDisabledFields()
    this.buildOptionaledFields()
    this.updateNationalityConfig(this.applicantData.nationalityId ?? -1)

    // setTimeout(() => {
    //   this._initContactNumber('#mobile-number');
    // }, 1000);
  }

  ngOnChanges(changes: SimpleChanges){
    this.buildShownFields()
    this.buildDisabledFields()
    this.buildOptionaledFields()
  }

  ngAfterViewInit(){
    this.buildShownFields()
    this.buildDisabledFields()
    this.buildOptionaledFields()
  }
  
  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }

  buildShownFields(){
    this.resetDictionary(this.shownFields)
    for (const f of this.showFields ?? []) {
      this.shownFields[f] = true;
    }
  }

  buildDisabledFields(){
    this.resetDictionary(this.disabledFields)
    for (const f of this.disableFields ?? []) {
      this.disabledFields[f] = true;
    }
  }

  buildOptionaledFields(){
    this.resetDictionary(this.optionaledFields)
    for (const f of this.optionalFields ?? []) {
      this.optionaledFields[f] = true;
    }
  }

  onNationalityChanged(event: ISelectionEventArgs){
    this.applicantData.nationalityId = event.newSelection.value
    if(this.applicantData.nationalityId){
      this.updateNationalityConfig(event.newSelection.value)
      this.idNumberInput.nativeElement.value = ''
      this.applicantData.idNumber = ''
    }
  }
  
  public async  onNgModelChange(value: string, el:any) {
    if (this.idNumberExcludeRegex) {
      if (value && el) {
        const rValue = await value.replace(this.idNumberExcludeRegex, '');
          el!.value = rValue;
          this.applicantData.idNumber = rValue;
      }
    }
  }
  
  public updateNationalityConfig(nationalityId: number){
    this.nationalityUniqueIdConfig = this.lookups.nationalityLookup.find(x => x.id == nationalityId)?.uniqueIdConfigJson ?? 
    { pattern: "[a-zA-Z0-9]{6,20}", excludePattern: "[^a-zA-Z0-9]", min: 6, max: 20 };
    
      if(this.nationalityUniqueIdConfig.excludePattern){
        this.idNumberExcludeRegex = new RegExp(this.nationalityUniqueIdConfig.excludePattern, "g")
      } else {
        this.idNumberExcludeRegex = undefined;
      }
  }

  get nationality(){
    if(this.nationalityCode){
      return this.nationalityCode;
    } else {
      return this.initialCountryCode;
    }
  }

  get validForm(){
    return this.formCtrl?.status == 'VALID' ? true : false
  }

  get nationalityText(){
    return this.lookups.nationalityLookup.find(x => x.id == this.applicantData.nationalityId)?.description
  }

  get nationalityCode(){
    return this.lookups.nationalityLookup.find(x => x.id == this.applicantData.nationalityId)?.code
  }

  private resetDictionary(dictionary: {[key: string]: boolean},defValue: boolean = false){
    for (const f in dictionary) {
      dictionary[f] = defValue;
    }
  }

  // private _initContactNumber(elemId: string) {
  //   const input = document.querySelector<HTMLInputElement>(elemId);
  //   // console.log(this.nationality.Code);
  //   if(input){
  //   this._iti = intlTelInput(input, {
  //     // any initialisation options go here
  //     initialCountry: this.nationalityCode,
  //     separateDialCode: true,
  //     utilsScript: 'assets/js/intl-tel-input-utils.js',
  //     });
  //   }
  // }

  handleOpening(event:IBaseCancelableBrowserEventArgs){
    //console.log((event.owner as IgxDatePickerComponent))
    if(this.applicantData.dateOfBirth == undefined){
      this._isDateSelected = false;
      (event.owner as IgxDatePickerComponent).value = (new Date().getFullYear() - 24) + "-01-01"
    } else {
      this._isDateSelected = true;
    }
  }

  private _isDateSelected:boolean = false;
  handleClosing(event:IBaseCancelableBrowserEventArgs){
    //console.log(event, "closing")
    if(event.event != undefined && this._isDateSelected == false){
      (event.owner as IgxDatePickerComponent).value = ""
    }
  }
}