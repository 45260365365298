import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IgxBadgeComponent } from '@infragistics/igniteui-angular';
import { map } from 'rxjs/operators';
import { IBasicLookup } from '../models/basic-lookup';
import { IExpiringDroplink } from '../models/expiring-droplink';

@Injectable({
  providedIn: 'root',
})
export class DroplinkService {
  constructor(
    private _httpClient: HttpClient,
    private _route: ActivatedRoute
  ) {}

  getStatus$(key: string) {
    return this._httpClient
      .get<{
        code:
          | 'droplink/valid'
          | 'droplink/insufficient-credits'
          | 'droplink/link-expired'
          | 'droplink/invalid-key'
          | 'droplink/insufficient-system-resources';
        sponsorEmail: string;
        customFields: string;
        timeToComplete: string;
        companyAlias: string;
        projectNationalityId: number;
        // projectUniqueIdPattern: string;
        // clientLogo: string;
        projectUniqueIdConfigJson: string;
        isCreditCompany: boolean;
      }>(`/api/droplink/getstatus`, {
        params: { key: key },
      })
      .pipe(
        map((o) => {
          const _o: {
            code:
              | 'droplink/valid'
              | 'droplink/insufficient-credits'
              | 'droplink/link-expired'
              | 'droplink/invalid-key'
              | 'droplink/insufficient-system-resources';
            sponsorEmail: string;
            customFields: string;
            timeToComplete: string;
            companyAlias: string;
            projectNationalityId: number;
            // clientLogo: string;
            projectUniqueIdConfig: {
              pattern: string | null;
              excludePattern: string | null;
              min: number | null;
              max: number | null;
            };
            isCreditCompany: boolean;
          } = { ...o } as any;
          _o.projectUniqueIdConfig = {
            ...{ pattern: null, excludePattern: null, min: 6, max: null },
            ...JSON.parse(o.projectUniqueIdConfigJson || '{}'),
          };
          return _o;
        })
      )
      .toPromise();
  }

  getApplicantStatus$(key: string, nationalityId: string, idNumber: string) {
    return this._httpClient
      .get<IResultMessage>(`/api/droplink/getapplicantstatus`, {
        params: {
          key: key,
          nationalityId: nationalityId,
          idNumber: idNumber,
        },
      })
      .toPromise();
  }

  checkDroplinkData$(droplink: string) {
    return this._httpClient
      .get(`/api/auth/checkDroplinkData`, { params: { droplink } })
      .toPromise();
  }

  /*whiteLabel$(droplinkUid: string | null = null) {
    let _params = {};
    if (droplinkUid) {
      _params = { droplinkUid };
    }
    return this._httpClient
      .get<{ whiteLabel: { EnableWhiteLabel: boolean; LogoUrl: string } }>(
        '/api/company/whitelabel',
        { params: _params }
      )
      .toPromise();
  }*/

  positionTypeList$(droplinkUid: string) {
    return this._httpClient
      .get<{ PositionTypeId: number; PositionTypeName: string }[]>(
        '/api/AssessmentIntegration/EvalexPositionTypeList',
        {
          params: { uid: droplinkUid },
        }
      )
      .pipe(
        map((list) => {
          return list.map((e) => {
            return {
              id: e.PositionTypeId,
              description: e.PositionTypeName,
            } as IBasicLookup;
          });
        })
      )
      .toPromise();
  }

  positionLevelList$(droplinkUid: string) {
    return this._httpClient
      .get<{ PositionLevelId: number; PositionLevelName: string }[]>(
        '/api/AssessmentIntegration/EvalexPositionLevelList',
        {
          params: { uid: droplinkUid },
        }
      )
      .pipe(
        map((list) => {
          return list.map((e) => {
            return {
              id: e.PositionLevelId,
              description: e.PositionLevelName,
            } as IBasicLookup;
          });
        })
      )
      .toPromise();
  }

  getExpiringDroplinks$() {
    return this._httpClient
      .get(`/api/droplink/GetExpiringDroplinks`)
      .pipe(
        map((p: any, index: number) => {
          let pr: IExpiringDroplink[] = p.map((x: any) => {
            return {
              projectId: x.ProjectId,
              companyName: x.CompanyName,
              projectName: x.ProjectName,
              droplinkGuid: x.DroplinkGuid,
              dropLinkExpiryDate: x.DropLinkExpiryDate,
              daysLeft: x.DaysLeft,
              currentCredits: x.CurrentCredits,
              dropLinkCredits: x.DropLinkCredits,
              creditsLeft: x.CreditsLeft,
              reason: x.Reason
            } as IExpiringDroplink;
          });
          return pr;
        })
      );
  }
}
export interface IResultMessage {
  code: string;
  sponsorEmail?: string;
  applicantName?: string;
}

export interface IResult {
  code: string;
  // message?: string;
}
