import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IgxDialogComponent } from '@infragistics/igniteui-angular';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { ApplicantHeaderComponent } from 'src/app/applicant/applicant-header/applicant-header.component';
import { ApplicantService } from 'src/app/core/applicant.service';
import { AssessmentService } from 'src/app/core/assessment.service';
import { DroplinkService } from 'src/app/core/droplink.service';
import { LocalForageService } from 'src/app/core/local-forage.service';
import { OdysseyApiService } from 'src/app/core/odyssey-api.service';
import { IApplicantForm } from 'src/app/models/applicant-form';
import { IApplicantFormLookups } from 'src/app/models/applicant-form-lookups';
import { IBasicLookup } from 'src/app/models/basic-lookup';
import { INationalityLookup } from 'src/app/models/nationality-lookup';
import { SystemStorage } from 'src/app/shared/omt-lib';
import { SystemTranslationService } from 'src/app/shared/system-translation.service';
import { UiApplicantFormComponent } from 'src/app/shared/ui/ui-applicant-form/ui-applicant-form.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss'],
})
export class SignUpComponent implements OnInit {
  @ViewChild('form') public applicantForm: UiApplicantFormComponent | undefined;
  @ViewChild('modal', { read: IgxDialogComponent, static: false })
  public popup!: IgxDialogComponent;


  public get isZaOnly() {
    return ['vericred-dra-only'].includes(
      this._route.snapshot.fragment ? this._route.snapshot.fragment : ''
    );
  }

  public get passOnFragment() {
    return this._route.snapshot.fragment ?? undefined;
  }

  applicantData = {
    // id: -1,
    // currentCompany: "",
    // currentPosition: "",
    // qualificationId: undefined,
    // firstName: "",
    // lastName: "",
    // idNumber: "",
    //dateOfBirth: "2000-01-01",
    // mobileNumber: "",
    // email: "",
    // genderId: -1,
    // nationalityId: -1,
    // raceId: -1,
    // languageId: -1,
    // languageLevelId: -1,
    // assessmentLanguageId: -1,
    // disabilityId: -1,
    // disabilityOther: "",
    // evalexPositionLevelId: -1,
    // evalexPositionTypeId: -1,
    // optionalEmail: "",
    // addressLine1: "",
    // addressLine2: "",
    // addressLine3: "",
    // provinceId: -1,
    // reference1:"",
    // reference2:"",
  } as IApplicantForm;

  public fields: string[] = [
    // 'nationality',
    // 'id-number',
    // 'current-company',
    // 'current-position',
    // 'qualification',
    // 'first-name',
    // 'last-name',
    // 'email',
    // 'date-of-birth',
    // 'mobile-number',
    // 'gender',
    // 'race',
    // 'language',
    // 'language-level',
    // 'disability',
    // 'evalex-position-type',
    // 'evalex-position-level',
    // 'optional-email',
    // 'address-line-1',
    // 'address-line-2',
    // 'address-line-3',
    // 'province',
  ];
  public optionalFields = [
    // 'applicationTypeId',
    // 'dependantsNumber',
    // 'maritalStatusId',
    // 'microenterpriseAge',
    // 'ageAtAddress',
    // 'hasBankAccount',
    // 'transactingMethodId',
  ] as string[];
  public lookups: IApplicantFormLookups;

  private _logo: string = environment.defaultLogo;
  public get logo() {
    return this._logo || environment.defaultLogo;
  }

  public nationality: INationalityLookup | null = null;
  public idNumber: string | null = '';
  public isReady: boolean = false;
  public get key() {
    return this._route.snapshot.params.key || null;
  }
  public isBusy: boolean = false;
  public readonly DISABILITY_OTHER_ID = 6;

  public modalTitle: string = '';
  public modalBody: string = '';
  public modalButton: string = '';
  public modalCommand: string = '';

  public timeToComplete: string = '';
  public companyAlias: string = '';

  private _sponsorEmail = '';
  private _languageId: number = 1;
  private _showGeneralInstructions: boolean = true;

  public get hasIdNumber() {
    return this._route.snapshot.data.hasIdNumber !== false;
  }

  constructor(
    private _applicantApi: ApplicantService,
    private _localForage: LocalForageService,
    private _droplinkApi: DroplinkService,
    private _route: ActivatedRoute,
    private _router: Router,
    private _assessmentApi: AssessmentService,
    private _translate: SystemTranslationService,
    private _odyApi: OdysseyApiService,
    private cdref: ChangeDetectorRef,
    public datepipe: DatePipe
  ) {
    // this.key = this._route.snapshot.params.key;
    this.lookups = {
      nationalityLookup: [],
      evalexPositionLevelLookup: [],
      evalexPositionTypeLookup: [],
      qualificationLookup: [],
      provinceLookup: [],
      genderLookup: [],
      raceLookup: [],
      languageLookup: [],
      languageLevelLookup: [],
      disabilityLookup: [],

      applicationTypes: [],
      bitLookups: [],
      maritalStatuses: [],
      transactingMethods: [],
    };
  }

  async ngOnInit() {
    this._logo = await SystemStorage.INSTANCE.local.getWhiteLabelLogo$(
      this._odyApi,
      { droplinkUid: this.key }
    );

    this._languageId =
      (await SystemStorage.INSTANCE.base.getItemAsInt$('LanguageId')) || 1;

    // await this._localForage
    //   .getLanguageId$()
    //   .then((n) =>
    //     n != null ? (this._languageId = n) : (this._languageId = 1)
    //   );

    await this._applicantApi
      .getApplicantLookups$(this._languageId)
      .toPromise()
      .then((n) => {
        if (n) {

          const _lepl = this.lookups.evalexPositionLevelLookup;
          const _lept = this.lookups.evalexPositionTypeLookup;

          this.lookups = {
            nationalityLookup: n.nationalities,
            qualificationLookup: n.qualifications,
            evalexPositionLevelLookup: _lepl,
            evalexPositionTypeLookup: _lept,
            provinceLookup: [
              { id: 2, description: 'Eastern Cape' },
              { id: 3, description: 'Free State' },
              { id: 4, description: 'Gauteng' },
              { id: 5, description: 'KwaZulu-Natal' },
              { id: 6, description: 'Limpopo' },
              { id: 7, description: 'Mpumalanga' },
              { id: 8, description: 'Northern Cape' },
              { id: 9, description: 'North West' },
              { id: 10, description: 'Western Cape ' },
            ] as IBasicLookup[],
            genderLookup: n.genders,
            raceLookup: n.races,
            languageLookup: n.languages,
            languageLevelLookup: n.languageLevels,
            disabilityLookup: n.disabilities,

            applicationTypes: n.applicationTypes,
            bitLookups: n.bitLookups,
            maritalStatuses: n.maritalStatuses,
            transactingMethods: n.transactingMethods,
          };
        }
      });

    // await this._droplinkApi
    //   .whiteLabel$(this.key)
    //   .then((n) =>
    //     n!.whiteLabel.EnableWhiteLabel == true
    //       ? (this.logo = n!.whiteLabel.LogoUrl)
    //       : 'https://dev-cdn.odyssess.com/images/logos/odyssey.svg'
    //   );

    const queryParams = this._route.snapshot.queryParams;
    if (this.hasIdNumber) {
      if (
        queryParams &&
        queryParams['nationality'] &&
        queryParams['id-number'] &&
        queryParams['sponsor-email']
      ) {
        let nationality = this.lookups.nationalityLookup.find(
          (x) => x.id == queryParams['nationality']
        );
        if (nationality) {
          this.nationality = nationality;
        }

        this.idNumber = queryParams['id-number'];
        this._sponsorEmail = queryParams['sponsor-email'];

        if(nationality && nationality.code == 'za' && this.idNumber){
          //get date parts from id
          let lateDate  = Number('20'+this.idNumber.substring(0,2))
          let earlyDate = Number('19'+this.idNumber.substring(0,2))
          let month = this.idNumber.substring(2,4)
          let day = this.idNumber.substring(4,6)
          //create dob
          let lateDob = new Date(lateDate+'-'+month+'-'+day)
          let earlyDob = new Date(earlyDate+'-'+month+'-'+day)

          //check if dob or components are valid
          if(
            Number.isNaN(lateDate) || 
            Number.isNaN(earlyDate) ||  
            Number.isNaN(month) ||  
            Number.isNaN(day) ||
            isNaN(lateDob.getTime()) ||
            isNaN(earlyDob.getTime())
          ){
            //default date
            // this.applicantData.dateOfBirth = '2000-01-01'
            this.applicantData.dateOfBirth = undefined
          } else {
            //check which century to use
            if(lateDate <= new Date().getFullYear() - 10){
              this.applicantData.dateOfBirth = lateDate+'-'+month+'-'+day
            }
            else{
              this.applicantData.dateOfBirth = earlyDate+'-'+month+'-'+day
            }
          } 
        } else {
          this.applicantData.dateOfBirth = undefined
        }
      } else {
        window.location.href = '/';
      }
    } else {
      if (queryParams && queryParams['sponsor-email']) {
        this.nationality = null;
        this.idNumber = null;
        this._sponsorEmail = queryParams['sponsor-email'];
      } else {
        window.location.href = '/';
      }
    }

    if (this._route.snapshot.fragment) {
      this._showGeneralInstructions = !['abi', 'kwaden'].includes(
        this._route.snapshot.fragment
      );
      if (this._showGeneralInstructions) {
        if (['vericred-dra-only'].includes(this._route.snapshot.fragment)) {
          this._showModal(
            'contentmodal.instructions-before-bio-vericred-dra-only.title',
            {},
            'contentmodal.instructions-before-bio-vericred-dra-only.innerHTML',
            { timeToComplete: this.timeToComplete },
            'buttonbutton.continue',
            {},
            'vericred-dra-only'
            //   'contentdroplink.sign-up.modal.instructions-before-bio-vericred-dra-only.title',
            //   {},
            //   'contentdroplink.sign-up.modal.instructions-before-bio-vericred-dra-only.innerHTML',
            //   {timeToComplete:this.timeToComplete, companyAlias:this.companyAlias},
            //   'buttondroplink.continue',
            //   {},
            //   'droplink-applicant/can-create'
          );
        } else {
          this._showModal(
            'modal.instructions-before-bio.title',
            'modal.instructions-before-bio.innerHTML',
            'instructions-before-bio.continue'
          );
        }
      }
    }

    this.optionalFields$ = of(this.optionalFields);
  }

  async ngAfterViewInit() {
    await this._droplinkApi.getStatus$(this.key).then(async (_res) => {
      if (_res) {
        await SystemStorage.INSTANCE.base.setItem$(
          'timeToComplete',
          _res.timeToComplete
        );
        this.timeToComplete = _res.timeToComplete;
        await SystemStorage.INSTANCE.base.setItem$(
          'companyAlias',
          _res.companyAlias
        );
        this.companyAlias = _res.companyAlias;

        if (_res.code === 'droplink/valid') {
          const _customFields =
            _res.customFields ||
            // tslint:disable-next-line: max-line-length
            'id-number,current-company,current-position,qualification,first-name,last-name,email,date-of-birth,mobile-number,gender,race,language,language-level,disability';
          for (const f of _customFields.split(',')) {
            //this.fields[f] = true;
            if (f !== 'id-number' && f !== 'nationality') {
              this.fields.push(f);
            }
          }
          this.fields$ = of(this.fields);
        } else if (_res.code === 'droplink/insufficient-system-resources'){
          this._showModal(
            'contentDroplinkResourcesHeading',
            {},
            'contentDroplinkResourcesBody',
            {},
            'buttonbutton.continue',
            {},
            'droplink/insufficient-system-resources'
          );
        } else {
          window.location.href = '/';
        }
      }
    });
    // 'evalex-position-type',
    // 'evalex-position-level',
    if (this.fields.includes('evalex-position-level')) {
      await this._droplinkApi
        .positionLevelList$(this.key)
        .then((n) => (this.lookups.evalexPositionLevelLookup = n!));
    }

    if (this.fields.includes('evalex-position-type')) {
      await this._droplinkApi
        .positionTypeList$(this.key)
        .then((n) => (this.lookups.evalexPositionTypeLookup = n!));
    }
    this.isReady = true;
  }

  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }

  get validForm(): boolean {
    return this.applicantForm?.validForm
      ? this.applicantForm?.validForm
      : false;
  }

  async onContinueClicked() {
    if (!this.isBusy) {
      this.isBusy = true;
      //this._isLoading = true;
      //const value = this.signUpForm.value;

      // let _mobileNumber = this._iti.getNumber() || null;
      // if (_mobileNumber && _mobileNumber.length >= 14) {
      //   if (_mobileNumber.substring(1, 3) === _mobileNumber.substring(3, 5)) {
      //     _mobileNumber = '+' + _mobileNumber.substring(3);
      //   } else if (_mobileNumber.substring(1, 4) === _mobileNumber.substring(4, 7)) {
      //     _mobileNumber = '+' + _mobileNumber.substring(4);
      //   }
      // }
      const loginResult = await this._assessmentApi.createViaDroplink$(
        this.key,
        this.applicantData.currentCompany!,
        this.applicantData.currentPosition || '',
        this.applicantData.qualificationId || null,
        this.applicantData.firstName!,
        this.applicantData.lastName!,
        this.idNumber || null,
        this.applicantData.dateOfBirth
            ? this.datepipe.transform(this.applicantData.dateOfBirth,'yyyy-MM-dd') + 
              'T00:00:00'
          : null,
        this.applicantData.mobileNumber!, // value['mobile-number'],
        this.applicantData.email!,
        this.applicantData.genderId || null,
        this.nationality?.id ? this.nationality.id : null,
        this.applicantData.raceId || null,
        this.applicantData.languageId || null,
        this.applicantData.languageLevelId || null,
        this._languageId,
        this.applicantData.disabilityId || null,
        (this.applicantData.disabilityId! || null) === this.DISABILITY_OTHER_ID
          ? this.applicantData.disabilityOther
          : null,

        this.applicantData.evalexPositionLevelId || null,
        this.applicantData.evalexPositionTypeId || null,

        this.applicantData.optionalEmail || null,
        this.applicantData.addressLine1 || null,
        this.applicantData.addressLine2 || null,
        this.applicantData.addressLine3 || null,
        this.applicantData.provinceId || null,

        this.applicantData.applicationTypeId || null,
        this.applicantData.dependantsNumber ?? null,
        this.applicantData.maritalStatusId || null,
        this.applicantData.microenterpriseAge ?? null,
        this.applicantData.ageAtAddress ?? null,
        this.applicantData.hasBankAccount ?? null,
        this.applicantData.transactingMethodId || null
      );

      switch (loginResult!.code) {
        case 'droplink-assessment/created':
          if (loginResult!.loginObject) {
            const _res = await this._odyApi.hasSpecialEmptyKnockout$();
            if (_res === false) {
              this._showModal(
                'contentdroplink.sign-up.modal.instructions-after-bio.title',
                {},
                'contentdroplink.sign-up.modal.instructions-after-bio.innerHTML',
                {
                  username: loginResult!.loginObject.username,
                  sponsorEmail: this._sponsorEmail,
                  companyAlias: this.companyAlias,
                },
                'buttonbutton.continue',
                {},
                'droplink-assessment/created'
              );
            } else {
              ///WebContent/ng2/applicant/dashboard
              //window.location.href = '/WebContent/doAssessment.html?2e6ff';
              this._router.navigate(['/applicant/dashboard'], {
                fragment: this.passOnFragment,
              });
            }
          } else {
            this._showModal(
              'contentdroplink.sign-up.modal.instructions-project-linked.title',
              {},
              'contentdroplink.sign-up.modal.instructions-project-linked.innerHTML',
              {
                projectName: loginResult!.projectName,
              },
              'buttonbutton.continue',
              {},
              'instructions-project-linked'
            );
            //complete
          }
          break;
        case 'droplink-assessment/in-project-and-incomplete':
          //popup
          this._showModal(
            'contentdroplink.landing.modal.in-project-and-incomplete.title',
            {},
            'contentdroplink.landing.modal.in-project-and-incomplete.body',
            {
              applicantName: this.applicantData.firstName,
              companyAlias: this.companyAlias,
              sponsorEmail: this._sponsorEmail,
            },
            'buttondroplink.continue',
            {},
            'droplink-assessment/in-project-and-incomplete'
          );
          //login
          break;
        case 'droplink-assessment/in-project-and-complete':
          //popup
          this._showModal(
            'contentdroplink.landing.modal.in-project-and-complete.title',
            {},
            'contentdroplink.landing.modal.in-project-and-complete.body',
            {
              applicantName: this.applicantData.firstName,
              companyAlias: this.companyAlias,
            },
            'buttondroplink.continue',
            {},
            'droplink-assessment/in-project-and-complete'
          );
          //login
          break;
        case 'droplink-assessment/applicant-details-restricted':
          //popup
          this._showModal(
            'contentdroplink.sign-up.modal.applicant-details-restricted.title',
            {},
            'contentdroplink.sign-up.modal.applicant-details-restricted.innerHTML',
            {},
            'buttonbutton.continue',
            {},
            'droplink-assessment/applicant-details-restricted'
          );
          //login
          break;
        default:
          this._showModal(
            'contentdroplink.sign-up.modal.assessment-not-created.title',
            {},
            'contentdroplink.sign-up.modal.assessment-not-created.innerHTML',
            {
              sponsorEmail: this._sponsorEmail,
            },
            '',
            {},
            ''
          );
          break;
      }

      this.isBusy = false;
      //this._isLoading = false;
    }
  }

  modalButtonClick(command: string) {
    switch (command) {
      case 'vericred-dra-only':
        this.popup.close();
        break;
      case 'droplink-assessment/created':
        this.popup.close();
        this._router.navigate(['/applicant/dashboard'], {
          fragment: this.passOnFragment,
        });
        break;
      case 'instructions-project-linked':
        // this.formSwitch.activate(this.FORMS.UsernameLogin);
        this.popup.close();
        this._router.navigate(['/login']);
        break;
      case 'droplink-assessment/in-project-and-incomplete':
        //popup
        //login
        this.popup.close();
        this._router.navigate(['/login']);
        break;
      case 'droplink-assessment/in-project-and-complete':
        //popup
        //complete
        this.popup.close();
        this._router.navigate(['/complete'], {
          fragment: this.passOnFragment,
        });
        break;
      case 'droplink-assessment/applicant-details-restricted':
        this.popup.close();
        this._router.navigate(['/login']);
        break;
      default:
        this.popup.close();
        this._router.navigate(['/login']);
        break;
    }
  }

  private async _showModal(
    titleKey: string = '',
    titleInterpolation: Object = {},
    bodyKey: string = '',
    bodyInterpolation: Object = {},
    buttonKey: string = '',
    buttonInterpolation: Object = {},
    buttonCommand: string = ''
  ) {
    this.modalTitle = '';
    this.modalBody = '';
    this.modalButton = '';

    if (titleKey != '') {
      await this._translate
        .translate$(titleKey, titleInterpolation)
        .then((n) => (this.modalTitle = n));
    }

    if (bodyKey != '') {
      await this._translate
        .translate$(bodyKey, bodyInterpolation)
        .then((n) => (this.modalBody = n));
    }

    if (buttonKey != '') {
      await this._translate
        .translate$(buttonKey, buttonInterpolation)
        .then((n) => (this.modalButton = n));
    }

    this.modalCommand = buttonCommand;
    this.popup.open();
  }

  //private _fields = new BehaviorSubject<string[]>([])
  public fields$: Observable<string[]> | null = null; //this.fields.asObservable<string[]>()
  public optionalFields$: Observable<string[]> | null = null;
}
