<!-- <div class="ui-strip"></div> -->
<ng-container *ngIf="show">
  <div class="head_style head-thin">
    <div class="block one"></div>
    <div class="block two"></div>
    <div class="block three"></div>
    <div class="block four"></div>
    <div class="block five"></div>
  </div>
  <div class="ui-header" *ngIf="_isMobile === false">
    <img [src]="logo" alt="odyssey logo" />
    <div class="ui-header-user text-xl"></div>
  </div>
  <!-- <div class="ui-banner"></div> -->
  <div class="head_style head-wide">
    <div class="ui-header-banner-strip-buttons">
      <div class="ui-header-banner-strip-buttons-right">
        <ng-container>
          <button
            *ngIf="isFullscreenSupported"
            class="btn btn-fab btn-toolbar"
            (click)="fullscreen()"
          >
            <i [class]="fullscreenIcon"></i>
          </button>
          <button
            class="btn btn-fab btn-toolbar"
            (click)="clickDecreaseFontSize()"
          >
            <i class="fas fa-minus"></i>
          </button>
          <i class="fas fa-font font-icon"></i>
          <button
            class="btn btn-fab btn-toolbar"
            (click)="clickIncreaseFontSize()"
          >
            <i class="fas fa-plus"></i>
          </button>
        </ng-container>
      </div>
    </div>
  </div>
</ng-container>
