import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { IBasicLookup } from '../models/basic-lookup';
import { ICompany } from '../models/company';
import { ICompanyPermissions } from '../models/company-permissions';
import { IMimicCompany } from '../models/mimic-company';
import { ICompanyCustomConfig } from '../models/company-custom-config';
import { ICompanyCredits } from '../models/company-credits';

@Injectable({
  providedIn: 'root'
})
export class CompanyService {

  constructor(private _httpClient: HttpClient) { 

  }

  getSponsorEmail$ (projectId : number){
    return this._httpClient
      .get<{ email: string }>(`/api/Project/GetProjectSponsorEmail/${projectId}`)
  }

  getWhiteLabel$(companyId: number = -1) { 
    return this._httpClient.get<any>(`/api/company/whitelabel?cId=${companyId}`);
  } 

  getCompanyPermissions$ (){
    return this._httpClient
      .get("/api/company/GetCompanyPermissions/")
      .pipe(
        map(
          ((p:any, index:number) =>
          {
            return {
              canEdit: p.CanEdit,
              canDelete: p.CanDelete,
              canExport: p.CanExport, 
              enableDevelopmentReport: p.EnableDevelopmentReport,
              enableSelectionReport: p.EnableSelectionReport,
              canCloseProject: p.CanCloseProject,
              emailProjectReport: p.EmailProjectReport,
              isSuperEvalexAdmin: p.IsSuperEvalexAdmin,
              captureApplicantEmploymentDetails: p.CaptureApplicantEmploymentDetails,
            } as ICompanyPermissions       
          }
        )
      )
    )
  }

  getCompany$(){
    return this._httpClient
      .get("/api/company/get")
      .pipe(
        map(
          ((p:any, index:number) =>
          {
            return {
              canCloseProject: p[0].CanCloseProject,
              canDelete: p[0].CanDelete,
              canEdit: p[0].CanEdit,
              canExport: p[0].CanExport,
              captureApplicantEmploymentDetails: p[0].CaptureApplicantEmploymentDetails,
              cutLine: p[0].CutLine,
              cutOffHigh: p[0].CutOffHigh,
              cutOffLow: p[0].CutOffLow,
              cutOffMid: p[0].CutOffMid,
              dateCreated: p[0].DateCreated,
              description: p[0].Description,
              emailProjectReport: p[0].EmailProjectReport,
              enableDevelopmentReport: p[0].EnableDevelopmentReport,
              enableSelectionReport: p[0].EnableSelectionReport,
              id: p[0].Id,
              pdfProtectionPassword: p[0].PdfProtectionPassword,
              enableWhitelabel: false,//p.EnableWhitelabel,
              whitelabelLogoUrl: "",//p.WhitelabelLogoUrl,
            } as ICompany       
          }
        )
      )
    )
  }

  updateCompanyConfig$(company: ICompany){
    return this._httpClient.put(
      '/api/company/UpdateCompanyConfig',
      {
          Id:company.id,
          Description:company.description,
          DateCreated:company.dateCreated,


          CanEdit:company.canEdit,
          CanDelete:company.canDelete,
          CanExport:company.canExport,


          CutOffHigh:company.cutOffHigh,
          CutOffMid:company.cutOffMid,
          CutOffLow:company.cutOffLow,
          CutLine:company.cutLine,

          EnableDevelopmentReport:company.enableDevelopmentReport,
          EnableSelectionReport:company.enableSelectionReport,

          CanCloseProject:company.canCloseProject,
          EmailProjectReport:company.emailProjectReport,

          PdfProtectionPassword:company.pdfProtectionPassword,

          CaptureApplicantEmploymentDetails:company.captureApplicantEmploymentDetails,
      }
    );     
  }

  getMimicCompanyList$(){
    return this._httpClient
    .get(`/api/account/MimicCompanyList`)
    .pipe(
      map(
        ((p:any, index:number) =>
        {
          let pr : IMimicCompany[] = p.map((x:any) => {
            return {
              company: {id: x.CompanyId, description: x.CompanyName } as IBasicLookup,
              selected: x.Selected
          } as IMimicCompany
          })
          return pr;
        }
      )
    )
   )
  }

  setMimicCompany$(companyId:number){
    return this._httpClient.put(
      '/api/account/MimicCompanySet',
      {
          companyId:companyId
      }
    );  
  }

  getCompanyCompletedPageConfiguration$(companyId:number){
    return this._httpClient
      .get("/api/company/GetCompanyCompletedPageConfiguration",
      {
        params: { companyId: companyId },
      })
      .pipe(
        map(
          ((p:any, index:number) =>
          {
            return {
              doNotShowFeedback: Boolean(Number(p.DoNotShowFeedback))
            } as {doNotShowFeedback:boolean}       
          }
        )
      )
    )
  }
  getCompanyCustomConfig$(companyId:number){
    return this._httpClient
    .get(`/api/company/GetCompanyCustomConfigList`, {params: {companyId:companyId}})
    .pipe(
      map(
        ((p:any, index:number) =>
        {
          let pr : ICompanyCustomConfig[] = p.map((x:any) => {
            var value = x.ConfigValue;
            if(x.ConfigDataType == "Boolean"){
              value = x.ConfigValue != null ? (x.ConfigValue == "1" ? true : false) : false
            } else if (x.ConfigDataType == "String"){
              value = x.ConfigValue != null ? x.ConfigValue : ""
            }

            return {
                level: x.ConfigLevel,
                configKey: x.ConfigKey,
                configKeyDescription: x.ConfigKeyDescription,
                dataType: x.ConfigDataType,
                configValue: value,
                configTypeId: x.ConfigTypeId,
                configTypeName: x.ConfigTypeName
            } as ICompanyCustomConfig
          })
          return pr;
        }
      )
    )
  )
  }

  setCompanyCustomConfig$(companyId: number, configItems:ICompanyCustomConfig[]){
    let newConfigItems = configItems.map(x => {
      let value = x.configValue

      if(x.dataType == "Boolean"){
        value = x.configValue == true ? "1" : "0"
      }

      return {
        level: x.level,
        configKey: x.configKey,
        configKeyDescription: x.configKeyDescription,
        dataType: x.dataType,
        configValue: value,
        configTypeId: x.configTypeId,
        configTypeName: x.configTypeName
      } as ICompanyCustomConfig
    })
    
    let items = JSON.stringify(newConfigItems)

    return this._httpClient.post(
      '/api/company/UpdateCompanyCustomConfig',
      {
        companyId:companyId,configItems:items
      },
    );  
  }

  getCompanyCredits$(){
    return this._httpClient
      .get(`/api/company/GetCompanyCredits`)
      .pipe(
        map(
          ((p:any, index:number) =>
          {
            return {
              total: p[0].Total,
              limit: p[0].Limit,
              completed: p[0].Completed
            } as ICompanyCredits 
          }      
        )
      )
    )
  }
}
