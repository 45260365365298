<!-- <input
  type="tel"
  [id]="_id"
  minLength="9"
  maxLength="14"
  [required]="_required"
  [placeholder]="_placeholder"
  [ngModel]="_ngModel"
  [disabled]="_disabled"
  autocomplete="off"
  (ngModelChange)="onNgModelChange($event)"
/> -->
<igx-input-group>
  <input
    igxInput
    type="tel"
    [minlength]="8"
    [maxlength]="15"
    [ngModel]="_ngModel"
    (ngModelChange)="onNgModelChange($event)"
    [id]="_id"
    [name]="_id"
    [required]="_required"
    autocomplete="off"
    [disabled]="_disabled"
  />
  <label igxLabel [for]="_id">{{ _label }}</label>
</igx-input-group>
