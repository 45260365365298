import { Component, OnDestroy, OnInit } from '@angular/core';
import { OdysseyApiService } from 'src/app/core/odyssey-api.service';
import { SystemStorage } from 'src/app/shared/omt-lib';
import { environment } from 'src/environments/environment';
import * as UAParser from 'ua-parser-js';

@Component({
  selector: 'app-applicant-header',
  templateUrl: './applicant-header.component.html',
  styleUrls: ['./applicant-header.component.scss'],
})
export class ApplicantHeaderComponent implements OnInit, OnDestroy {
  public static SHOW = true;
  public get show() {
    return ApplicantHeaderComponent.SHOW;
  }

  public _isMobile: boolean = false;

  get isFullscreenSupported(){
    if (document.fullscreenEnabled) {
      return true
    } else {
      return false
    }
  }

  async clickDecreaseFontSize() {
    let size = parseInt(
      (await SystemStorage.INSTANCE.base.getItem$(
        'odyssey-font-size'
      )) /*sessionStorage.getItem('odyssey-font-size')*/ || '0',
      10
    );

    size -= 1;

    if (size > 3) {
      size = 3;
    } else if (size < -1) {
      size = -1;
    }
    this._setFontSize(size);
  }

  async clickIncreaseFontSize() {
    let size = parseInt(
      (await SystemStorage.INSTANCE.base.getItem$(
        'odyssey-font-size'
      )) /*sessionStorage.getItem('odyssey-font-size')*/ || '0',
      10
    );

    size += 1;

    if (size > 3) {
      size = 3;
    } else if (size < -1) {
      size = -1;
    }
    this._setFontSize(size);
  }

  isMobile() {
    const _deviceType = new UAParser().getDevice().type;
    // console.log('devicetype',_deviceType)
    const _os = new UAParser().getOS().name;
    //console.log('os',_os)
    if (_os?.includes('Windows' || 'Mac Os')) {
      this._isMobile = false;
    }
    if (_deviceType?.includes('mobile' || 'tablet')) {
      this._isMobile = true;
    }
    // console.log('isMobile' , this._isMobile);
  }

  private async _setFontSize(size: number) {
    await SystemStorage.INSTANCE.base.setItem$(
      'odyssey-font-size',
      size.toString()
    );
    //sessionStorage.setItem('odyssey-font-size', size.toString());
    document.documentElement.style.setProperty(
      '--font-size',
      `calc(var(--font-size-original) + ${size}px)`
    );
  }

  fullscreen(){
    if(!document.fullscreenElement){
      document.documentElement.requestFullscreen();
    } else {
      document.exitFullscreen();
    }
  }

  get fullscreenIcon(){
    if(!document.fullscreenElement){
      return "fas fa-expand"
    } else {
      return "fas fa-compress"
    }
  }

  public logo = environment.defaultLogo;

  constructor() {}

  async ngOnInit() {
    this.logo = await SystemStorage.INSTANCE.local.getWhiteLabelLogo$();
    this.isMobile();
    this._setFontSize(
      parseInt(
        (await SystemStorage.INSTANCE.base.getItem$(
          'odyssey-font-size'
        )) /*sessionStorage.getItem('odyssey-font-size')*/ || '0',
        10
      )
    );
  }

  ngOnDestroy(): void {
    ApplicantHeaderComponent.SHOW = true;
  }
}
