import { Component, OnInit } from '@angular/core';
import { SystemStorage } from 'src/app/shared/omt-lib';

@Component({
  selector: 'app-assessment-paused',
  templateUrl: './assessment-paused.component.html',
  styleUrls: ['./assessment-paused.component.css'],
})
export class AssessmentPausedComponent implements OnInit {
  constructor() {}

  async ngOnInit() {
    await SystemStorage.INSTANCE.base.clear$();
  }
}
