<app-ui-banner-top></app-ui-banner-top>
<ng-container > 
  <div class="panel-align" [hidden]="!isReady" >
    <div class="panel-float" id="lg" name="loginForm">
      <div class="form-signin-heading text-center">
        <img [src]="logo" alt="Odyssey Logo" class="decktop" />

        <p>
          <label
            ><strong
              [innerHTML]="'labelform.label.nationality' | translate"
            ></strong></label
          ><br />
          {{ nationality?.description }}
        </p>
        <p>
          <label
            ><strong
              [innerHTML]="'labeldroplink.label.form.id-number.' | translate"
            ></strong
          ></label>
          <br />
          {{ idNumber }}
        </p>
        <a
          [routerLink]="['/link', key]"
          [fragment]="passOnFragment"
          [innerHTML]="'labelform.label.not-me' | translate"
        ></a>
      </div>
      <igx-divider></igx-divider>
      <div class="panel-body">
        <app-ui-applicant-form
          #form
          [applicantData]="applicantData"
          [showFields]="fields$ | async"
          [optionalFields]="optionalFields$ | async"
          [lookups]="lookups"
          [initialCountryCode]="nationality?.code"
        ></app-ui-applicant-form>
      </div>
      <div class="ui-page-footer-onebtn">
        <button
          type="button"
          (click)="onContinueClicked()"
          class="btn btn-primary float-right"
          [disabled]="isBusy || !validForm"
          [innerHTML]="'buttonbutton.continue' | translate"
        ></button>
      </div>
    </div>
  </div>
</ng-container>

  <igx-dialog #modal [closeOnOutsideSelect]="false">
    <igx-dialog-title class="title-container">
      <div class="dialog-title">
        <!-- <igx-icon class="text-colour-primary">cached</igx-icon> -->
        <div
          class="dialog-title text-colour-primary"
          [innerHTML]="modalTitle"
        ></div>
      </div>
    </igx-dialog-title>
    <div class="add-edit-dialog sml gridDialog">
      <div class="igx-dialog-extender-reset-assessment"></div>
      <!-- contents here -->
      <p [innerHTML]="modalBody"></p>
    </div>
    <div class="ui-page-footer-onebtn">
        <button
          *ngIf="modalButton != ''"
          type="button"
          class="btn btn-primary"
          (click)="modalButtonClick(modalCommand)"
        >
          {{ modalButton }}
        </button>
    </div>
  </igx-dialog>
