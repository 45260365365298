<style>
  #landscape-only-overlay {
    position: absolute;
    min-width: 100%;
    min-height: 100vh;
    top: 0;
    left: 0;
    background-color: #ffffff;
    display: none;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 50000;
    padding: 4rem;
    text-align: justify;
    overflow: auto;
  }

  #landscape-only-overlay .-ody-logo {
    margin-bottom: 1rem;
    text-align: center;
  }

  #landscape-only-overlay .-ody-logo > img {
    width: 60vw;
  }

  #landscape-only-overlay ol {
    padding-left: 2rem;
  }

  @media screen and (orientation: portrait) {
    #landscape-only-overlay {
      display: flex;
    }
  }

  @media screen and (orientation: landscape) {
    #landscape-only-overlay {
      display: none;
    }
  }
</style>
<div ng-controller="MainCtrl">
  <div id="landscape-only-overlay">
    <div class="-ody-logo">
      <img [src]="logoUrl" alt="Odyssey Logo" />
    </div>
    <div [innerHTML]="modalMobileRotate">
      <!-- <p>
        We notice that you are using your device in portrait mode to access this
        assessment. <b>Please rotate your device in landscape mode before
        continuing your assessment.</b>
      </p>
      <p>If you encounter any issues, please follow the steps below:</p>
      <p>
        <b>For Android devices (smartphones/tablets):</b>
      </p>
      <ol>
        <li>Open your device's "Settings" app.</li>
        <li>
          Scroll down and tap Display. It's in the "Device" section of the menu.
        </li>
        <li>
          Scroll down and tap "Auto-rotate screen". It's near the bottom of the
          menu.
        </li>
        <li>Tap Rotate screen contents.</li>
        <li>Hold your device horizontally for landscape mode.</li>
      </ol>
      <p>
        <b>For Apple devices (iPhones/iPads):</b>
      </p>
      <ol>
        <li>Make sure that "Portrait Orientation Lock" is turned off.</li>
        <li>To check, open Control Center.</li>
        <li>Tap the Screen Rotation Lock button.</li>
        <li>
          If the button used to show a padlock, the padlock should disappear
          from the button after tapping it.
        </li>
      </ol> -->
    </div>
  </div>
</div>
