import { JsonPipe } from '@angular/common';
import { Injectable } from '@angular/core';
import * as localForage from 'localforage';
import { IHash, SystemStorage } from '../shared/omt-lib';
import { ERoles } from './auth/auth-role.guard';

@Injectable({
  providedIn: 'root',
})
export class LocalForageService {
  // private readonly _PERSISTANT_FIELDS_KEY = '_persistantFieldsKey';
  // private _persistantFields: IHash<boolean> = {
  //   [this._PERSISTANT_FIELDS_KEY]: true,
  // };

  // constructor() {
  //   this._onInit();
  // }

  // private async _onInit() {
  //   const _persistantStore = await this.getItem<IHash<boolean>>(
  //     this._PERSISTANT_FIELDS_KEY
  //   );
  //   const _persistant = {
  //     ..._persistantStore,
  //     ...this._persistantFields,
  //   };
  //   this._persistantFields = _persistant;
  // }

  // public async user$() {
  //   const _ = await localForage.getItem<string>('oua-user');
  //   return new LocalForageUser(JSON.parse(_ || '{}'));
  // }

  // public getQuestionnaireId$() {
  //   return localForage.getItem<number>('gameId');
  // }

  // public setQuestionnaireId$(value: number) {
  //   return localForage.setItem('gameId', value);
  // }

  // public getCurrentQuestionId$() {
  //   return localForage.getItem<number>('currentQuestionId');
  // }

  // public setCurrentQuestionId$(value: number) {
  //   return localForage.setItem('currentQuestionId', value);
  // }

  // public getLanguageId$() {
  //   return localForage.getItem<number>('LanguageId');
  // }

  // public setLanguageId$(value: number) {
  //   return localForage.setItem('LanguageId', value);
  // }

  // public getItem$(keyName: string) {
  //   return localForage.getItem(keyName);
  // }
  // public setItem$(keyName: string, value: any) {
  //   return localForage.setItem(keyName, value);
  // }

  // public getEmail$() {
  //   return localForage.getItem<string>('email');
  // }
  // public setEmail$(value: string) {
  //   return localForage.setItem('email', value);
  // }

  // public getAssessmentId$() {
  //   return localForage.getItem<number>('assessmentId');
  // }
  // public setAssessmentId$(value: number | null) {
  //   return localForage.setItem('assessmentId', value);
  // }

  // public getTrackingId$() {
  //   return localForage.getItem<number>('trackingId');
  // }
  // public setTrackingId$(value: number | null) {
  //   return localForage.setItem('trackingId', value);
  // }

  // public getDisplayName$() {
  //   return localForage.getItem<string>('displayName');
  // }
  // public setDisplayName$(value: string) {
  //   return localForage.setItem('displayName', value);
  // }
  // // setEmail$(email || '');
  // // setAssessmentId$(+assessmentId);
  // // setTrackingId$(+trackingId);
  // // setDisplayName$(displayName);

  // private async getItem<T>(key: string) {
  //   if (localForage) {
  //     const _v = await localForage.getItem<string>(key);
  //     try {
  //       return JSON.parse(_v!) as T;
  //     } catch (ex) {
  //       return JSON.parse('"' + _v + '"') as T;
  //     }
  //   } else if (localStorage && localStorage.getItem(key) != null) {
  //     return JSON.parse(localStorage.getItem(key)!) as T;
  //   } else {
  //     return null;
  //   }
  // }

  // public async clearLocallyStoredData(currentAssessmentId?: number) {
  //   // const LFAssessmentId = await this.getItem<number>('assessmentId');

  //   // if (currentAssessmentId !== LFAssessmentId) {
  //   //   this._clear();
  //   // }

  //   await SystemStorage.INSTANCE.base.clear$();
  // }

  // // private _clear() {
  // //   this._clearLocalStorage();
  // //   this._clearLocalForage();
  // // }

  // private async _clearLocalForage() {
  //   if (localForage) {
  //     for (const key of await localForage.keys()) {
  //       if (
  //         !this._persistantFields[key] ||
  //         this._persistantFields[key] !== true
  //       ) {
  //         await localForage.removeItem(key);
  //       }
  //     }
  //   }
  // }

  // private _clearLocalStorage() {
  //   if (localStorage) {
  //     for (let i = 0; i < localStorage.length; i++) {
  //       const key = localStorage.key(i);
  //       if (
  //         key != null &&
  //         (!this._persistantFields[key] || this._persistantFields[key] !== true)
  //       ) {
  //         localStorage.removeItem(key);
  //       }
  //     }
  //   }
  // }
}

// export interface ILocalForageUser {
//   email: string;
//   roles: ERoles[];
//   displayName: string;
//   companyAlias: string;
// }

// export class LocalForageUser {
//   constructor(public data: ILocalForageUser) {}
//   public hasAnyRole(roles: ERoles[]) {
//     for (let r of roles) {
//       if (this.data.roles.includes(r)) {
//         return true;
//       }
//     }
//     return false;
//   }
// }
