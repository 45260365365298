import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { IgxDialogComponent } from '@infragistics/igniteui-angular';
import { TranslateService } from '@ngx-translate/core';
import { SystemTranslationService } from 'src/app/shared/system-translation.service';

@Component({
  selector: 'app-ui-progress-nav-bar',
  templateUrl: './ui-progress-nav-bar.component.html',
  styleUrls: ['./ui-progress-nav-bar.component.scss'],
})
export class UiProgressNavBarComponent implements OnInit {
  @ViewChild('reports', { read: IgxDialogComponent, static: true })
  public reportsDialog!: IgxDialogComponent;
  @ViewChild('validationPopup', { read: IgxDialogComponent, static: true })
  public validationDialog!: IgxDialogComponent;

  @Input() disabled = false;
  @Input() progress = 0;
  @Input() canSkip = true;
  @Input() isBusy = false;
  @Input() canGoBack = false;
  @Input() isValid = false;
  @Input() validation: {code: 'valid' | 'invalid' | 'duplicateAmounts', answerIds?: number[]} = {code: 'invalid'};

  @Input() finalAnswer = false;

  @Output() back = new EventEmitter();
  @Output() continue = new EventEmitter();
  @Output() skip = new EventEmitter();

  public forwardDialog: string = ''
  public backDialog: string = ''

  private navigationType: string = '';

  public onClickBack() {
    this.navigationType = "back"
    // if (!this.isValid) {
    //   this.openWindow();
    if(this.validation.code === 'duplicateAmounts'){
      this.openValidationWindow();
    }
    else {
      this.back.emit();
    }
  }

  public onClickContinue() {
    this.navigationType = "forward"
    if(this.validation.code === 'duplicateAmounts'){
      this.openValidationWindow();
    }
    else if (!this.isValid) {
      this.openWindow();
    } else {
      this.continue.emit();
    }
  }

  public onClickSkip() {
    this.openWindow();
    this.navigationType = 'skip';
  }

  closeWindow() {
    this.reportsDialog.close();
  }

  closeValidationWindow(){
    this.validationDialog.close();
  }

  openWindow() {
    this.reportsDialog.open();
  }
  openValidationWindow() {
    this.validationDialog.open();
  }

  skipConfirm() {
    this.closeWindow();
    if(this.navigationType == "back"){
      this.back.emit();
    } else if (this.navigationType == "forward"){
      this.continue.emit();
    }
  }

  get warningText(){
    if (this.navigationType == "forward") {
      return this.forwardDialog
    } else if (this.navigationType == "back"){
      return this.backDialog
    } else {
      return ""
    }
  }

  get nextText(){
    if(this.finalAnswer){
      return "buttonContinue"
    } else {
      return "buttonNext"
    }
  }
  constructor(
    private _translate: SystemTranslationService,
  ) {}

  async ngOnInit() {
    await this._translate.isReady$();

    this.forwardDialog = await this._translate.translate$('contentDeaSkipDialog');
    this.backDialog = await this._translate.translate$('contentDeaBackDialog');
  }
}
