<!-- id: number
name: string
description: string
orderBy: number
leftText: string
middleText: string
rightText: string
options: ISelectorButton[] -->
<!--
    @Input() buttons : ISelectorButton[] = []
    @Output() buttonSelectedValue = new EventEmitter<string>();
-->
<div class="entire-section">
    <!-- text here (center)-->
    <div *ngIf="question?.name != ''" class="slider-header">{{question.name}}</div>
    <!-- description here (left)-->
    <div class="question-description">{{question.description}}</div>
    <!-- box here (full)-->
    <div>
        <!-- text row here (left/center/right)-->
        <div class="slider-labels">

            <div>{{question.middleText}}</div>

        </div>

        <!-- buttons here (spread)-->
        <div class="sliding-row">
            <div class="bold-left">{{question.leftText}}</div>
            <app-ui-selector-buttons [buttons]="question.options" [isJustified]="true" (buttonSelectedValue)="onSelected($event)"></app-ui-selector-buttons>
            <div class="bold-right">{{question.rightText}}</div>
        </div>
    </div>
</div>
