<div class="wrapper">
    <div class="form-container">
    <table>
        <!-- header -->
        <thead>
            <th *ngFor="let ea of question?.headerText" [innerHTML]="ea"></th>
        </thead>
        <tbody>
            <tr *ngFor="let ea of question?.items" class="alternating">
                <td>{{ea.text}}</td>
                <td>
                    <igx-input-group>
                        <input [ngClass]="{duplicate: isDuplicate(ea.id)}" igxInput type="number" [placeholder]="pleaseTypeAmountHerePlaceholder" (change)="onChangeInput()" [(ngModel)]="answers[ea.id]" (input)="onEntered()"  />
                    </igx-input-group> 
                </td>
            </tr>
        </tbody>
        <tfoot>
            <tr>
                <th>{{question?.footerText}}</th>
                <td>{{getTotal | number:'1.2-2'}}</td>
            </tr>
        </tfoot>
    </table>
    </div>
</div>
