import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { lastValueFrom } from 'rxjs';
import { CompanyService } from 'src/app/core/company.service';
import { LocalForageService } from 'src/app/core/local-forage.service';
import { OdysseyApiService } from 'src/app/core/odyssey-api.service';
import { ProjectService } from 'src/app/core/project.service';
import { SystemStorage } from 'src/app/shared/omt-lib';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-completed',
  templateUrl: './completed.component.html',
  styleUrls: ['./completed.component.scss'],
})
export class CompletedComponent implements OnInit {
  public showCreditDraMessage: boolean = false;
  public isReady: boolean = false;
  public logoUrl = environment.defaultLogo;

  public companyMessage: string = '';
  public showCustomMessage: boolean | null = null;
  public customMessage: string | null = null;
  public doNotShowFeedback: boolean = false;

  public companyAlias = 'Odyssey';
  public isDelayed = false;

  public get companyId(): number | null {
    return +this._route.snapshot.queryParams?.cId || null;
  }
  private get _rsId(): number | null {
    return +this._route.snapshot.queryParams?.rsId || null;
  }
  private get _pId(): number | null {
    return +this._route.snapshot.queryParams?.pId || null;
  }
  private get _d(): number | null {
    return +this._route.snapshot.queryParams?.d || null;
  }
  // public companyId: number | null = null;

  constructor(
    private _localForage: LocalForageService,
    private _companyApi: CompanyService,
    private _odyApi: OdysseyApiService,
    private _projectApi: ProjectService,
    private _route: ActivatedRoute
  ) {}

  async ngOnInit() {
    this.logoUrl = await SystemStorage.INSTANCE.local.getWhiteLabelLogo$(
      this._odyApi,
      { cId: this.companyId || undefined }
    );

    const _companyAlias = await SystemStorage.INSTANCE.base.getItem$(
      'companyAlias'
    );
    if (_companyAlias /*sessionStorage.companyAlias*/) {
      this.companyAlias = _companyAlias;
    }

    this.showCreditDraMessage =
      (await SystemStorage.INSTANCE.base.getItem$(
        '__show-dra-credit-message'
      )) === /*sessionStorage.getItem('__show-dra-credit-message')*/ 'true';

    await SystemStorage.INSTANCE.base.clear$(); //candidate-logout 'actual-logout'

    // await this.getWhiteLabelLogo$();

    if(this._d){
      this.isDelayed = true;
    }

    if (this.companyId === 1000020 /*SBSA*/) {
      this.companyMessage =
        'Please note that the Standard Bank Talent Acquisition Team will be in contact with you in due course.';
    } else if (this.companyId === 1001693 /*VeriCred*/) {
      this.companyMessage =
        'Please note that you will be contacted in due course.';
    }

    if (this._pId && this._rsId !== null) {
      this.showCustomMessage = true;
      this.customMessage = '';

      await this._projectApi
        .getCustomMessage$(this._pId, this._rsId)
        .toPromise()
        .then(
          (n: any) => {
            if (n.CustomMessage !== null) {
              this.customMessage = n.CustomMessage;
            } else {
              this.showCustomMessage = false;
            }
          },
          (r: any) => {
            this.showCustomMessage = false;
          }
        );
    } else {
      this.showCustomMessage = false;
      if(this.companyId){
        await lastValueFrom(await this._companyApi.getCompanyCompletedPageConfiguration$(this.companyId))
          .then((n) => {
            this.doNotShowFeedback = n.doNotShowFeedback
          }
        )
      }
    }
    this.isReady = true;
  }

  // $scope._showCreditDraMessage = false;

  // if (sessionStorage) {
  //     $scope._showCreditDraMessage = sessionStorage.getItem('__show-dra-credit-message') === 'true';

  //     for (let sso of Object.keys(sessionStorage)) {
  //         if (sso.startsWith('_ati-')) {
  //             sessionStorage.removeItem(sso);
  //         }
  //     }
  // }

  // private async getWhiteLabelLogo$(droplinkUid: string | null = null, forceGet = false) {
  //   // loca.getItem<{logoUrl: string, expires: number}>('')
  //   let _stored:any = undefined;
  //   await this._localForage.getItem$('odyssey-white-label').then(
  //     n => _stored = n
  //   );
  //   const _now = new Date().getTime();

  //   if (_stored && _stored.expires > _now) {
  //     this.logoUrl = _stored.logoUrl;
  //   } else {
  //     await this._companyApi.getWhiteLabel$(this.companyId ? this.companyId : -1).toPromise().then(
  //       n => this.logoUrl = n.whiteLabel.LogoUrl
  //     )
  //   }
  //   //   const _res = await this._api.exec.company.whiteLabel$(droplinkUid);
  //   //   this.logo = _res.whiteLabel.LogoUrl || this.logo;

  //   //   await this._localForage.setItem('odyssey-white-label', { logoUrl: this.logo, expires: _now + 7 * 24 * 60 * 60 * 1000 }, true);
  //   // }
  // }
}
