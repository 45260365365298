import { Injectable } from '@angular/core';
import { UAParser } from 'ua-parser-js';

@Injectable({
  providedIn: 'root',
})
export class UseragentService {
  private _parser = new UAParser();

  private _status = '';
  get status() {
    return this._status;
  }
  private _ua: UAParser.IResult = this._parser.getResult();
  get ua() {
    return this._ua ? this._ua.ua : '';
  }

  constructor() {
    this._onInit();
  }

  private _onInit() {
    // this._ua = this._parser.getResult();
    const checkBrowserResult = this._checkBrowser(
      this._ua.device,
      this._ua.browser
    );

    switch (checkBrowserResult) {
      case 0:
        this._status = 'ua/supported';
        break;
      case 1:
        this._status = 'ua/unsupported-version';
        break;
      case 2:
        this._status = 'ua/unsupported-browser';
        break;
    }
  }

  public get isMobile() {
    return (
      this._ua &&
      (this._ua.device.type === 'mobile' || this._ua.device.type === 'tablet')
    );
  }

  public get isIos() {
    return (
      this.isMobile &&
      this._ua.device &&
      this._ua.device.vendor &&
      this._ua.device.vendor.toLowerCase().includes('apple')
    );
  }

  private _checkBrowser(device: UAParser.IDevice, browser: UAParser.IBrowser) {
    // console.log('device.type', device.type);
    // console.log('browser.name', browser.name);
    // console.log('browser.version', browser.version);
    // if (device.type === 'mobile' || device.type === 'tablet') {
    //   if (browser.name === 'Chrome' || browser.name === 'Edge') {
    //     return 0;
    //   } else {
    //     return 2;
    //   }
    // } else {
    //   if (browser.name === 'Chrome') {
    //     // if (+browser.major > 0) {
    //     if (+(browser.version?.split('.')[0] || 0) > 0) {
    //       return 0; // FINE
    //     } else {
    //       return 1; // VERSION
    //     }
    //   } else if (browser.name === 'Edge') {
    //     // if (+browser.major > 0) {
    //     if (+(browser.version?.split('.')[0] || 0) > 0) {
    //       return 0; // FINE
    //     } else {
    //       return 1; // VERSION
    //     }
    //   } else {
    //     return 2; // BROWSER
    //   }
    // }
    return 0;
  }
}
