import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { SystemTranslationService } from 'src/app/shared/system-translation.service';

@Component({
  selector: 'app-landscape-only-overlay',
  templateUrl: './landscape-only-overlay.component.html',
  styleUrls: ['./landscape-only-overlay.component.scss'],
})
export class LandscapeOnlyOverlayComponent implements OnInit {
  logoUrl = 'https://cdn.odyssess.com/images/logos/odyssey.svg';

  public readonly env = environment;

  public modalMobileRotate:string = '';
  constructor(private _translate: SystemTranslationService,) {
    this.logoUrl = this.env.defaultLogo
  }

  async ngOnInit() {
    await this._translate.isReady$();
    this.modalMobileRotate = await this._translate.translate$('contentModalMobileRotate');
  }
}
