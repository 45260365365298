import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
declare var $: any;

@Component({
  selector: 'app-ui-rich-text-editor',
  templateUrl: './ui-rich-text-editor.component.html',
  styleUrls: ['./ui-rich-text-editor.component.scss'],
  // encapsulation: ViewEncapsulation.None,
})
export class UiRichTextEditorComponent
  implements OnInit, AfterViewInit, OnDestroy, OnChanges
{
  // https://summernote.org/getting-started/

  @Input() _id = '';
  // @Input() name: string | null = '';
  @Input() label: string | undefined = undefined;
  @Input() _placeholder = '';
  @Input() _ngModel = '';
  @Output() _ngModelChange = new EventEmitter<string>();

  _onNgModelChange(value: string) {
    if (this._ngModel !== value) {
      this._ngModel = value;
      this._ngModelChange.emit(value);
    }

    const _el = $(`[_id=${this._id}] div.note-editable.card-block`)[0];
    if (_el && _el.innerHTML !== this._ngModel) {
      _el.innerHTML = this._ngModel;
    }
  }

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    this._onNgModelChange(changes._ngModel.currentValue);
  }

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    $(`#${this._id}`).summernote({
      placeholder: this._placeholder,
      tabsize: 2,
      height: 300, // set editor height
      minHeight: null, // set minimum height of editor
      maxHeight: null, // set maximum height of editor
      focus: false, // set focus to editable area after initializing summernote
      spellCheck: true,
      disableGrammar: false,
      toolbar: [
        // ['style', ['style']],
        ['font', ['bold', 'underline', 'clear']],
        // ['color', ['color']],
        ['para', ['ul', 'ol', 'paragraph']],
        // ['table', ['table']],
        // ['insert', ['link', 'picture', 'video']],
        ['insert', ['link']],
        // ['view', ['fullscreen', 'codeview', 'help']],
        ['view', ['undo', 'redo']],
      ],
      callbacks: {
        onChange: (contents: string, $editable: any) => {
          this._onNgModelChange(contents);
          // console.log('onChange:', contents, $editable);
        },
      },
    });

    this._onNgModelChange(this._ngModel);

    // $(`#${this.id}`).summernote('reset');
    // if (this._ngModel) {
    // $(`#${this.id}`).summernote('insertText', this._ngModel);
    // }
  }

  ngOnDestroy(): void {
    $(`#${this._id}`).summernote('destroy');
  }
}
