import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';

export interface ISelectorButton {
  text: string;
  data: string;
  icon: string;
  selected?: boolean;
}
@Component({
  selector: 'app-ui-selector-buttons',
  templateUrl: './ui-selector-buttons.component.html',
  styleUrls: ['./ui-selector-buttons.component.scss']
})
export class UiSelectorButtonsComponent implements OnInit {

  // public buttons : ISelectorButton[] = [
  //   {
  //     text: "1",
  //     data: "1",
  //     icon: "",
      
  //   },
  //   {
  //     text: "",
  //     data: "add",
  //     icon: "add",
  //     selected: true
  //   },
  //   {
  //     text: "Delete",
  //     data: "delete",
  //     icon: "delete"
  //   },
  // ]


  @Input() buttons : ISelectorButton[] = []
  @Input() isJustified : boolean = false;
  @Input() selectedValue : string = "";
  @Output() buttonSelectedValue = new EventEmitter<ISelectorButton>();
  public selectedButtonIndex = -1; 

  constructor() { 
    
  }

  ngOnInit(): void {
    this.selectedButtonIndex = this.buttons.findIndex(
      x => x.data == this.selectedValue || (this.selectedValue == "" && x.selected == true)
    )    
  }

  ngOnChanges(changes:SimpleChanges):void{
    if(changes['selectedValue']){
      this.selectedButtonIndex = this.buttons.findIndex(
        x => x.data == changes['selectedValue'].currentValue
      )    
    }
  }

  public getPressedStatus(index: number): string{
    if(index === this.selectedButtonIndex){
      return 'contained';
    } else {
      return 'outlined';
    }
  }

  public onClicked(index: number){
    this.selectedButtonIndex = index;
    this.buttonSelectedValue.emit(this.buttons[index]);
  }
}
