import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { map } from 'rxjs/operators';
import { SystemStorage } from '../shared/omt-lib';
import { LocalForageService } from './local-forage.service';

@Injectable({
  providedIn: 'root',
})
export class OdysseyApiService {
  constructor(
    private _localForage: LocalForageService,
    private _httpClient: HttpClient
  ) {}

  // async login$(
  //   token: string,
  //   assessmentId: string,
  //   trackingId: string,
  //   email: string,
  //   displayName: string,
  //   timeToComplete: string,
  //   companyAlias: string
  // ) {
  //   // await this._localForage.clearLocallyStored(+assessmentId);
  //   await SystemStorage.INSTANCE.base.clear$('fresh-start');
  //   await SystemStorage.INSTANCE.base.setItem$('email', email || '');
  //   await SystemStorage.INSTANCE.base.setItem$('assessmentId', assessmentId);
  //   await SystemStorage.INSTANCE.base.setItem$('trackingId', trackingId);
  //   await SystemStorage.INSTANCE.base.setItem$('displayName', displayName);

  //   await SystemStorage.INSTANCE.base.setItem$(
  //     'timeToComplete',
  //     timeToComplete
  //   );

  //   await SystemStorage.INSTANCE.base.setItem$('companyAlias', companyAlias);
  // }

  // async logout$() {
  //   // await this._localForage.clearLocallyStoredData();
  //   await SystemStorage.INSTANCE.base.clear$('logout');
  // }

  check$() {
    return this._httpClient
      .get<{
        Code:
          | 'oah/applicant-hurdle-check/continue'
          | 'oah/applicant-hurdle-check/wait'
          | 'oah/applicant-hurdle-check/complete';
        CompanyId: number;
        ProjectId: number;
        ResultStatusId: number;
      }>('/api/applicant/HurdleCheck')
      .toPromise();
  }

  hasSpecialEmptyKnockout$() {
    return this._httpClient
      .get<{ result: boolean }>('/api/prescreening/HasSpecialEmptyKnockout', {})
      .pipe(map((d) => d.result))
      .toPromise();
  }

  whiteLabel$(fields: {
    loginKey?: string;
    cId?: number;
    droplinkUid?: string;
  }) {
    return lastValueFrom(
      this._httpClient.get<{
        whiteLabel: { EnableWhiteLabel: boolean; LogoUrl: string };
      }>('/api/Company/WhiteLabel', { params: fields })
    );
  }
}
