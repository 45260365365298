import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { IAssessmentMultipleNumbers } from 'src/app/models/assessment-multiple-numbers';
import { IAssessmentResponse } from 'src/app/models/assessment-response';
import { QuestionUtils } from '../../util/question-utils';
import { SystemTranslationService } from 'src/app/shared/system-translation.service';


@Component({
  selector: 'app-ui-question-input-numpad',
  templateUrl: './ui-question-input-numpad.component.html',
  styleUrls: ['./ui-question-input-numpad.component.scss']
})
export class UiQuestionInputNumpadComponent implements OnInit {

  @Input()  question: IAssessmentMultipleNumbers | null = null;
  @Output() answer = new EventEmitter<IAssessmentResponse[]>();
  @Output() isValid = new EventEmitter<boolean>();
  @Output() validation = new EventEmitter<{code: 'valid' | 'invalid' | 'duplicateAmounts', answerIds?: number[]}>();

  public timerStart: number = new Date().getTime() / 1000;
  public changes: number = 0;

  public answers : {[key: number] : number} = {};
  public previousAnswers : {[key: number] : number} = {};
  public responses: IAssessmentResponse[] = [];

  public _hasDuplicates: boolean = false;
  public pleaseTypeAmountHerePlaceholder:string = '';


  private _duplicateAnswerIds: number[] = [];

  public onChangeInput(){
    for (let key in this.answers) {
      if(this.answers[key] ){
        this.answers[key] = Math.abs(Number.parseFloat(this.answers[key]?.toFixed(2)));
      }
    }

  }

  constructor(private _translate: SystemTranslationService,) { 
    
  }

  async ngOnInit() {
    await this._translate.isReady$();
    this.pleaseTypeAmountHerePlaceholder = await this._translate.translate$('placeholderPleaseTypeAmountHere');

    if(this.question && this.question.answers){
      this.responses = this.question.answers
      this.answer.emit(this.responses)
    }

    this.responses.forEach((x) => {
      if(x.response != null)
        this.answers[x.id] = Number(x.response)
        this.previousAnswers[x.id] = Number(x.response)
    })

    if(this.responses.some(x => x.response == null || x.response == 'NaN' )){
      this.isValid.emit(false)
      this._emitValidation(false)
    } else {
      this.isValid.emit(true)
      this._emitValidation(true)
    }
  }

  ngOnChanges(changes: SimpleChanges){
    if( changes['question'].previousValue && changes['question'].previousValue.id != changes['question'].currentValue.id){
      this.answers = {};
      this.responses = [];
      if(this.question && this.question.answers){
        this.responses = this.question.answers
        this.answer.emit(this.responses)
      }

      this.responses.forEach((x) => {
        if(x.response != null)
          this.answers[x.id] = Number(x.response)
          this.previousAnswers[x.id] = Number(x.response)
      })

      if(this.responses.some(x => x.response == null)){
        this.isValid.emit(false)
        this._emitValidation(false)
      } else {
        this.isValid.emit(true)
        this._emitValidation(true)
      }
      this.timerStart = new Date().getTime() / 1000;
    }
  }

  get getTotal():number{
    let sum : number = 0;
    for (let key in this.answers) {
      sum += this.answers[key] ? this.answers[key] : 0;
    }
    this.answers
    return sum;
  }

  onEntered(){
    //this.answer.emit(this.answers)
    for (let key in this.answers) {
      if(this.responses.some(x => x.id == Number(key)))
      {
        this.responses[this.responses.findIndex(x => x.id == Number(key))] = {id: Number(key),response: String(this.answers[key])};
      } else {
        this.responses.push({id: Number(key),response: String(this.answers[key])});
      }
    }
    this.answer.emit(this.responses)
    this.isValid.emit(this.getValidity())
    this._emitValidation(this.getValidity())

  }

  private _emitValidation(valid: boolean){
    const _array = QuestionUtils.findDuplicates(this.responses)
    this._duplicateAnswerIds = _array.map(a => a.id)
    if(_array.length > 0){
      this.validation.emit({code: 'duplicateAmounts', answerIds: this._duplicateAnswerIds})
    }
    else if(valid){
      this.validation.emit({code: 'valid' })
    }
    else{
      this.validation.emit({code: 'invalid' })
    }
  }

  public isDuplicate(AnswerId: number){
    return this._duplicateAnswerIds.includes(AnswerId);
  }

  // onBlur(event:any){
  //   //console.log(event)
  //   this.onChangeInput();
  // }

  getValidity(): boolean {
    return QuestionUtils.isValidAnswer(this.responses, "Numbers")
  }




  public saveTimeAndActions(){
    let endTime = new Date().getTime() / 1000
    let delta = endTime - this.timerStart
    this.question!.time += Math.floor(delta);
  }
}
