import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';

@Component({
  selector: 'app-legacy-redirect',
  templateUrl: './legacy-redirect.component.html',
  styleUrls: ['./legacy-redirect.component.css'],
})
export class LegacyRedirectComponent implements OnInit {
  constructor(private _router: Router, private _route: ActivatedRoute) {}

  ngOnInit(): void {
    const _redirectTo: string[] = this._route.snapshot.data
      .redirectAbsolutePath || ['/'];

    const _params = {
      ...(this._route.snapshot.params || {}),
      ...(this._route.snapshot.queryParams || {}),
    };

    // console.log(
    //   'redirecting >> ',
    //   _redirectTo,
    //   this._route.snapshot.fragment,
    //   _params
    // );

    // const __params: Params = {};
    const __redirectTo: string[] = [];
    for (let r of _redirectTo) {
      for (let k of Object.keys(_params)) {
        const _k = `<:${k}>`;
        if (r.includes(_k)) {
          r = r.replace(_k, _params[k]);
        } else {
          //__params[k] = _params[k];
        }
      }
      __redirectTo.push(r);
    }

    /*console.log(
      'redirecting',
      __redirectTo,
      this._route.snapshot.fragment,
      _params
    );*/

    //https://localhost:44463/WebContent/#/key/AA885ECA-1F55-49BF-B6A6-E9589EE4A5D8?asd=asd#sss

    this._router.navigate(__redirectTo, {
      fragment: this._route.snapshot.fragment || undefined,
      queryParams: _params,
    });
  }
}
