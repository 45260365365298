import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ApplicantRoutingModule } from './applicant-routing.module';
import { ApplicantComponent } from './applicant.component';
import { ApplicantHeaderComponent } from './applicant-header/applicant-header.component';
import { UiQuestionMultipleChoiceSelectOneComponent } from './ui/ui-question-multiple-choice-select-one/ui-question-multiple-choice-select-one.component';
import { UiQuestionSlidingScaleComponent } from './ui/ui-question-sliding-scale/ui-question-sliding-scale.component';
import { UiQuestionInputNumpadComponent } from './ui/ui-question-input-numpad/ui-question-input-numpad.component';

import { UiProgressNavBarComponent } from './ui/ui-progress-nav-bar/ui-progress-nav-bar.component';
import { UiQuestionSliderSectionComponent } from './ui/ui-question-slider-section/ui-question-slider-section.component';
import { SharedModule } from '../shared/shared.module';
import {
  IgxDatePickerModule,
  IgxCheckboxModule,
  IgxDialogModule,
  IgxDropDownModule,
  IgxForOfModule,
  IgxIconModule,
  IgxInputGroupModule,
  IgxRippleModule,
  IgxSelectModule,
  IgxToggleModule,
} from '@infragistics/igniteui-angular';
import { FormsModule } from '@angular/forms';
import { UiQuestionSingleInvoiceCheckComponent } from './ui/ui-question-single-invoice-check/ui-question-single-invoice-check.component';
import { BrowserModule, HammerModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { UiQuestionDoubleInvoiceCheckComponent } from './ui/ui-question-double-invoice-check/ui-question-double-invoice-check.component';
import { ApplicantApiService } from './applicant-api.service';
import { ApplicantDashboardComponent } from './applicant-dashboard/applicant-dashboard.component';
import { StylesQuestionnaireComponent } from './questionnaire/styles-questionnaire/styles-questionnaire.component';
import { InterestsQuestionnaireComponent } from './questionnaire/interests-questionnaire/interests-questionnaire.component';
import { GipQuestionnaireComponent } from './questionnaire/gip-questionnaire/gip-questionnaire.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { httpTranslateLoader } from '../app.module';
import { HttpClient } from '@angular/common/http';
import { SystemTranslationService } from '../shared/system-translation.service';
import { SystemTranslationGuard } from '../shared/system-translation.guard';
import { CompletedComponent } from './completed/completed.component';
import { ApplicantHurdleCheckComponent } from './applicant-hurdle-check/applicant-hurdle-check.component';
import { LandscapeOnlyOverlayComponent } from './content/landscape-only-overlay/landscape-only-overlay.component';
import { ApplicantEmploymentDetailsComponent } from './applicant-employment-details/applicant-employment-details.component';
import { AssessmentPausedComponent } from './assessment-paused/assessment-paused.component';

@NgModule({
  declarations: [
    ApplicantComponent,
    ApplicantHeaderComponent,
    UiQuestionMultipleChoiceSelectOneComponent,
    UiQuestionSlidingScaleComponent,
    UiQuestionInputNumpadComponent,
    UiProgressNavBarComponent,
    UiQuestionSliderSectionComponent,
    UiQuestionSingleInvoiceCheckComponent,
    UiQuestionDoubleInvoiceCheckComponent,
    ApplicantDashboardComponent,
    StylesQuestionnaireComponent,
    InterestsQuestionnaireComponent,
    GipQuestionnaireComponent,
    CompletedComponent,
    ApplicantHurdleCheckComponent,
    LandscapeOnlyOverlayComponent,
    ApplicantEmploymentDetailsComponent,
    AssessmentPausedComponent,

  ],
  exports: [
    UiQuestionMultipleChoiceSelectOneComponent,
    UiProgressNavBarComponent,
    ApplicantHeaderComponent,
    UiQuestionSlidingScaleComponent,
    UiQuestionInputNumpadComponent,
    UiQuestionSingleInvoiceCheckComponent,
    UiQuestionDoubleInvoiceCheckComponent,
  ],
  imports: [
    CommonModule,
    ApplicantRoutingModule,
    SharedModule,
    IgxInputGroupModule,
    FormsModule,
    IgxDropDownModule,
    IgxRippleModule,
    IgxIconModule,
    IgxToggleModule,
    IgxForOfModule,
    IgxDialogModule,
    IgxSelectModule,
    IgxDatePickerModule,
    HammerModule,
    IgxCheckboxModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpTranslateLoader,
        deps: [HttpClient],
      },
    }),
  ],
  providers: [ApplicantApiService, SystemTranslationService],
})
export class ApplicantModule {}
