<section id="container" class="">
  <section id="main-content">
    <section
      id="assessmentWrapper"
      class="{{ assessmentKey ? '' : 'wrapper' }}"
    >
      <div
        class="row justify-content-center mobile-padding"
        *ngIf="showQuestionnaire"
      >
        <div class="col-xl-8 col-md-11 col-12">
          <div class="-questionnaire-heading col-12">
            {{ "labelPleaseDoQuestionnaire" | translate }}
          </div>
        </div>
      </div>

      <div
        class="row justify-content-center mobile-padding"
        *ngIf="showQuestionnaire"
      >
        <div class="col-xl-8 col-md-11 col-12">
          <section class="panel -questionnaires col-12">
            <div class="panel-body">
              <div >
                <!-- BEGIN PAGE CONTENT-->
                <div class="col-lg-4" *ngIf="wait">
                  {{ "labelPleaseWait" | translate }}
                  <div class="progress progress-striped active progress-xs">
                    <div
                      class="progress-bar progress-bar-info w-100"
                      role="progressbar"
                      aria-valuenow="100"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    ></div>
                  </div>
                </div>

                <div>
                  <div class="flx-table-row table-header-decor">
                    <div class="flx-table-5">{{ "labelQuestionnaire" | translate }}</div>
                    <div class="flx-table-5 is-small-screen-hide-table-cell is-mobile-hide-table-cell">{{ "labelProgress" | translate }}
                    </div>
                    <div class="flx-table-2"></div>
                  </div>
                
                  <div>
                    <div *ngFor="let q of product?.Questionnaires; let index = index" [ngClass]="{
                                      'flx-table-row table-row-decor': true,
                                      'odd-rows': 0 === index % 2,
                                      'even-rows': 1 === index % 2
                                    }">
                      <div class="flx-table-5">{{ q.Description }}</div>
                      <div class="flx-table-5 is-small-screen-hide-table-cell is-mobile-hide-table-cell">
                        <div class="progress progress">
                          <div class="progress-bar progress-bar-{{q.ProgressColor}}" role="progressbar" aria-valuenow="q.PercentageDone"
                            aria-valuemin="0" aria-valuemax="100" [ngStyle]="{ width: q.PercentageDone + '%' }">
                            <span class="">{{ q.PercentageDone }}%
                              {{ "labelComplete" | translate }}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div class="flx-table-2 align-right">
                        <button *ngIf="false || (!q.Completed && q.StartBtn)" [disabled]="true && !q.StartBtn"
                          class="btn btn-secondary btn-xs pull-right py-2 px-3" href="javascript:;" (click)="beginQuestionnaire(q)">
                          <i class="fa fa-arrow-circle-right"></i>
                          {{ "labelStart" | translate }}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- END PAGE CONTENT-->
              </div>
            </div>
          </section>
        </div>
      </div>

      <!--to include here...-->
      <!-- <div ng-include="'question.html?2e779'"></div> -->

      <div class="row" *ngIf="showQuestions">
        <div class="col-lg-12 ori-no">
          <section class="panel py-3 px-0">
            <header class="panel-heading">
              <div class="row justify-content-center">
                <div class="panel-body">
                  <div class="-question-container">
                    <div
                      class="alert alert-warning alert-dismissable text-center"
                    >
                      <button
                        type="button"
                        class="close"
                        data-dismiss="alert"
                        aria-hidden="true"
                      >
                        &times;
                      </button>
                      <strong>{{ "labelQuestionOnce" | translate }}</strong>
                    </div>
                  </div>
                  <!--collapse start-->
                  <div
                    class="panel-group accordion m-bot20 _temp_accordion"
                    id="accordion"
                  >
                    <div
                      class="panel panel-default card _temp_card"
                      *ngFor="let q of questions; let _i = index"
                    >
                      <!-- <div id="c-{{ q.OrderBy }}"> -->
                      <div
                        class="panel-heading card-header"
                        id="c-{{ q.OrderBy }}"
                      >
                        <h5 class="alert {{ q.QClass }}">
                          <!-- <h5 class="panel-title alert {{ q.QClass }} fade in"> -->
                          <!-- <button
                              id="{{ q.OrderBy }}"
                              class="accordion-toggle"
                              data-toggle="collapse"
                              data-parent="#accordion"
                              href="#collapse{{ q.Id }}"
                              [disabled]="q.Disabled"
                            > -->
                          <!-- <button
                            id="{{ q.OrderBy }}"
                            class="btn btn-block text-left"
                            type="button"
                            data-toggle="collapse"
                            [attr.data-target]="'#collapse' + q.Id"
                            aria-expanded="true"
                            [attr.aria-controls]="'collapse' + q.Id"
                            [disabled]="q.Disabled"
                          >
                            {{ q.OrderBy }}. {{ q.QuestionText }}
                          </button> -->
                          <button
                            id="{{ q.OrderBy }}"
                            class="btn btn-block text-left"
                            type="button"
                            [disabled]="q.Disabled"
                          >
                            {{ q.OrderBy }}. {{ q.QuestionText }}
                          </button>
                        </h5>
                      </div>
                      <div
                        id="collapse{{ q.Id }}"
                        class="panel-collapse {{ q.InClass }} {{
                          q.Disabled ? 'collapse' : ''
                        }}"
                      >
                        <div class="panel-body">
                          <table
                            id="tbl"
                            class="table table-advance table-bordered"
                          >
                            <thead>
                              <tr>
                                <th class="h5 pad8">
                                  {{ "labelAnswer" | translate }}
                                </th>
                                <th
                                  class="text-center h5 pad8"
                                  *ngIf="!isPersonality"
                                  width="100px"
                                >
                                  {{ "labelMost" | translate }}
                                </th>
                                <th
                                  class="text-center h5 pad8"
                                  *ngIf="!isPersonality"
                                  width="100px"
                                >
                                  {{ "labelNextMost" | translate }}
                                </th>
                                <th
                                  class="text-center h5 pad8"
                                  *ngIf="!isPersonality"
                                  width="100px"
                                >
                                  {{ "labelNextLeast" | translate }}
                                </th>
                                <th
                                  class="text-center h5 pad8"
                                  *ngIf="!isPersonality"
                                  width="100px"
                                >
                                  {{ "labelLeast" | translate }}
                                </th>
                                <!--<th *ngIf="isPersonality">Select</th>-->
                              </tr>
                            </thead>
                            <tbody>
                              <!-- <tr
                                  *ngIf="!isPersonality"
                                  ng-repeat="a in q.Answers | orderBy : Id"
                                > -->
                              <ng-container *ngIf="!isPersonality">
                                <tr *ngFor="let a of q.Answers">
                                  <td class="pad6">{{ a.AnswerText }}</td>
                                  <td
                                    class="text-center pad6"
                                    (click)="wos(q, a, a.Most)"
                                  >
                                    <!-- <img
                                      *ngIf="a.AnswerSelected != a.Most"
                                      alt="off"
                                      src="img/checkbox/unselected-64x64.png"
                                    /> -->
                                    <i
                                      class="far fa-circle _temp_icon"
                                      *ngIf="a.AnswerSelected != a.Most"
                                      alt="on"
                                    ></i>
                                    <i
                                      class="far fa-check-circle _temp_icon"
                                      *ngIf="a.AnswerSelected == a.Most"
                                      alt="off"
                                    ></i>
                                    <input
                                      type="radio"
                                      id="a{{ a.Id }}"
                                      [(ngModel)]="a.AnswerSelected"
                                      value="{{ a.Most }}"
                                      class="d-none"
                                    />
                                  </td>
                                  <td
                                    class="text-center pad6"
                                    (click)="wos(q, a, a.TowardMost)"
                                  >
                                    <i
                                      class="far fa-circle _temp_icon"
                                      *ngIf="a.AnswerSelected != a.TowardMost"
                                      alt="on"
                                    ></i>
                                    <i
                                      class="far fa-check-circle _temp_icon"
                                      *ngIf="a.AnswerSelected == a.TowardMost"
                                      alt="off"
                                    ></i>
                                    <input
                                      type="radio"
                                      id="b{{ a.Id }}"
                                      [(ngModel)]="a.AnswerSelected"
                                      value="{{ a.TowardMost }}"
                                      class="d-none"
                                    />
                                  </td>
                                  <td
                                    class="text-center pad6"
                                    (click)="wos(q, a, a.TowardLeast)"
                                  >
                                    <i
                                      class="far fa-circle _temp_icon"
                                      *ngIf="a.AnswerSelected != a.TowardLeast"
                                      alt="on"
                                    ></i>
                                    <i
                                      class="far fa-check-circle _temp_icon"
                                      *ngIf="a.AnswerSelected == a.TowardLeast"
                                      alt="off"
                                    ></i
                                    ><input
                                      type="radio"
                                      id="d{{ a.Id }}"
                                      [(ngModel)]="a.AnswerSelected"
                                      value="{{ a.TowardLeast }}"
                                      class="d-none"
                                    />
                                  </td>
                                  <td
                                    class="text-center pad6"
                                    (click)="wos(q, a, a.Least)"
                                  >
                                    <i
                                      class="far fa-circle _temp_icon"
                                      *ngIf="a.AnswerSelected != a.Least"
                                      alt="on"
                                    ></i>
                                    <i
                                      class="far fa-check-circle _temp_icon"
                                      *ngIf="a.AnswerSelected == a.Least"
                                      alt="off"
                                    ></i>
                                    <input
                                      type="radio"
                                      id="c{{ a.Id }}"
                                      [(ngModel)]="a.AnswerSelected"
                                      value="{{ a.Least }}"
                                      class="d-none"
                                    />
                                  </td>
                                </tr>
                              </ng-container>
                              <!-- <tr
                                  *ngIf="isPersonality"
                                  ng-repeat="a in q.Answers | orderBy : Id"
                                > -->
                              <ng-container *ngIf="isPersonality">
                                <tr *ngFor="let a of q.Answers">
                                  <td
                                    id="c{{ a.Id }}"
                                    class="opcs"
                                    (click)="opcs(q, a)"
                                  >
                                    <label class="lbl-thin" for="{{ a.Id }}">
                                      <!-- <input
                                        type="radio"
                                        id="{{ a.Id }}"
                                        [(ngModel)]="a.AnswerSelected"
                                        value="0"
                                      /> -->
                                      {{ a.AnswerText }}
                                    </label>
                                    <i
                                      *ngIf="a.AnswerSelected !== 1"
                                      class="far fa-lg fa-circle"
                                    ></i>
                                    <i
                                      *ngIf="a.AnswerSelected === 1"
                                      class="far fa-lg fa-check-circle"
                                    ></i>
                                  </td>
                                </tr>
                              </ng-container>
                            </tbody>
                          </table>
                          <div class="_buttonContainer">
                            <button
                              (click)="exit()"
                              class="btn btn-danger --float-left"
                              type="button"
                            >
                              {{ "buttonExit" | translate }}
                            </button>
                            <button
                              (click)="next(q, _i)"
                              class="btn btn-success --float-right"
                              type="button"
                            >
                              {{ "label" + q.BtnName | translate }}
                              <!-- <span data-i18n="_{{ q.BtnName }}_"></span> -->
                            </button>
                          </div>
                          <div class="col-lg-4 float-right" *ngIf="waitAnswer">
                            <div
                              class="progress progress-striped active progress-xs"
                            >
                              <div
                                class="progress-bar progress-bar-info w-100"
                                role="progressbar"
                                aria-valuenow="100"
                                aria-valuemin="0"
                                aria-valuemax="100"
                              ></div>
                            </div>
                          </div>
                        </div>
                        <!--<pre>model = {{q | json}}</pre>-->
                      </div>
                      <!-- </div> -->
                    </div>
                  </div>
                  <!--collapse end-->
                </div>
              </div>
            </header>
          </section>
        </div>
      </div>
    </section>
  </section>
</section>
<app-landscape-only-overlay></app-landscape-only-overlay>

<app-ui-modal #modalC>
  <div class="col-lg-12" *ngIf="wait">
    <div class="progress progress-striped active progress-xs">
      <div
        class="progress-bar progress-bar-info w-100"
        role="progressbar"
        aria-valuenow="100"
        aria-valuemin="0"
        aria-valuemax="100"
      ></div>
    </div>
    <hr />
  </div>

  <app-ui-applicant-form
    #formApplicant
    [applicantData]="Applicant"
    [showFields]="fields$ | async"
    [optionalFields]="optionalFields$ | async"
    [disableFields]="disableFields$ | async"
    [lookups]="formLookups"
    [initialCountryCode]="Applicant.nationalityCode"
  >

  </app-ui-applicant-form>

  <!-- <form
    #formApplicant="ngForm"
    name="formApplicant"
    class="form-horizontal py-0 px-2"
    role="form"
  >
    <section>
      <igx-input-group>
        <input
          igxInput
          type="text"
          [(ngModel)]="Applicant.currentCompany"
          name="CurrentCompany"
          [required]="true"
          autocomplete="off"
          [disabled]="false"
        />
        <label igxLabel for="CurrentCompany">{{
          'labelCurrentCompany' | translate
        }}</label>
      </igx-input-group>
      <igx-input-group>
        <input
          igxInput
          type="text"
          [(ngModel)]="Applicant.currentPosition"
          name="CurrentPosition"
          [required]="true"
          autocomplete="off"
          [disabled]="false"
        />
        <label igxLabel for="CurrentPosition">{{
          'labelCurrentPosition' | translate
        }}</label>
      </igx-input-group>
      <igx-select
        name="Qualification"
        [(ngModel)]="Applicant.qualificationId"
        [required]="true"
        [disabled]="false"
      >
        <label igxLabel>{{ 'labelQualification' | translate }}</label>
        <igx-select-item
          *ngFor="let l of lookups?.qualifications"
          [value]="l.id"
        >
          {{ l.description }}
        </igx-select-item>
      </igx-select>
      <igx-input-group>
        <input
          igxInput
          type="text"
          [(ngModel)]="Applicant.firstName"
          name="FirstName"
          [required]="true"
          autocomplete="off"
          [disabled]="false"
        />
        <label igxLabel for="FirstName">{{
          'labelFirstName' | translate
        }}</label>
      </igx-input-group>
      <igx-input-group>
        <input
          igxInput
          type="text"
          [(ngModel)]="Applicant.lastName"
          name="LastName"
          [required]="true"
          autocomplete="off"
          [disabled]="false"
        />
        <label igxLabel for="LastName">{{ 'labelLastName' | translate }}</label>
      </igx-input-group>
      <igx-select
        name="Nationality"
        [(ngModel)]="Applicant.nationalityId"
        [required]="true"
        [disabled]="true"
      >
        <label igxLabel>{{ 'labelNationality' | translate }}</label>
        <igx-select-item
          *ngFor="let l of lookups?.nationalities"
          [value]="l.id"
        >
          {{ l.description }}
        </igx-select-item>
      </igx-select>
      <igx-input-group>
        <input
          igxInput
          type="text"
          [(ngModel)]="Applicant.idNumber"
          name="IdNumber"
          [required]="true"
          autocomplete="off"
          [disabled]="true"
        />
        <label igxLabel for="IdNumber">{{ 'labelIDNumber' | translate }}</label>
      </igx-input-group>

      <igx-date-picker
        name="DateOfBirth"
        [(ngModel)]="Applicant.dateOfBirth"
        [required]="true"
        [disabled]="false"
        [mode]="'dropdown'"
      >
        <label igxLabel>{{ 'labelDateOfBirth' | translate }}</label>
        <igx-picker-toggle igxSuffix>
          <igx-icon>today</igx-icon>
        </igx-picker-toggle>
      </igx-date-picker>

      <app-ui-phone-number-input
        _id="ContactNumber"
        _label="{{ 'labelMobileNumber' | translate }}"
        _placeholder="{{ 'labelMobileNumber' | translate }}"
        [(_ngModel)]="Applicant.mobileNumber!"
        [initialCountry]="getNationalityCode()"
        [_disabled]="true"
        [_required]="true"
      ></app-ui-phone-number-input>
      <igx-select
        name="Gender"
        [(ngModel)]="Applicant.genderId"
        [required]="true"
        [disabled]="false"
      >
        <label igxLabel>{{ 'labelGender' | translate }}</label>
        <igx-select-item *ngFor="let l of lookups?.genders" [value]="l.id">
          {{ l.description }}
        </igx-select-item>
      </igx-select>

      <igx-select
        *ngIf="Applicant.nationalityId === 249"
        name="Race"
        [(ngModel)]="Applicant.raceId"
        [required]="Applicant.nationalityId === 249"
        [disabled]="false"
      >
        <label igxLabel>{{ 'labelRace' | translate }}</label>
        <igx-select-item *ngFor="let l of lookups?.races" [value]="l.id">
          {{ l.description }}
        </igx-select-item>
      </igx-select>

      <igx-select
        *ngIf="false"
        name="Language"
        [(ngModel)]="Applicant.languageId"
        [required]="true"
        [disabled]="false"
      >
        <label igxLabel>{{ 'labelLanguage' | translate }}</label>
        <igx-select-item *ngFor="let l of lookups?.languages" [value]="l.id">
          {{ l.description }}
        </igx-select-item>
      </igx-select>

      <igx-select
        name="LanguageLevel"
        [(ngModel)]="Applicant.languageLevelId"
        [required]="true"
        [disabled]="false"
      >
        <label igxLabel>{{ 'labelLanguageLevel' | translate }}</label>
        <igx-select-item
          *ngFor="let l of lookups?.languageLevels"
          [value]="l.id"
        >
          {{ l.description }}
        </igx-select-item>
      </igx-select>

      <igx-select
        name="DisabilityId"
        [(ngModel)]="Applicant.disabilityId"
        [required]="true"
        [disabled]="false"
        (selectionChanging)="Applicant.disabilityOther = ''"
      >
        <label igxLabel>{{ 'labelDisability' | translate }}</label>
        <igx-select-item *ngFor="let l of lookups?.disabilities" [value]="l.id">
          {{ l.description }}
        </igx-select-item>
      </igx-select>

      <igx-input-group *ngIf="Applicant.disabilityId === 6">
        <input
          igxInput
          type="text"
          [(ngModel)]="Applicant.disabilityOther"
          name="DisabilityOther"
          [required]="true"
          autocomplete="off"
          [disabled]="false"
        />
        <label igxLabel for="DisabilityOther">{{
          'labelDisabilityOther' | translate
        }}</label>
      </igx-input-group>
    </section>
  </form> -->
</app-ui-modal>

<app-ui-modal #modalF> </app-ui-modal>

<app-ui-modal #modalI>
  <p>
    {{ 'contentDashboardError' | translate}}
  </p>
</app-ui-modal>

<app-ui-modal #modalB> </app-ui-modal>

<app-ui-modal #modalG> </app-ui-modal>

<app-ui-modal #modalD> </app-ui-modal>

<app-ui-modal #modalE> </app-ui-modal>

<app-ui-modal #modalH> </app-ui-modal>

<app-ui-modal #modalA>
  <p>
    {{ 'contentMultipleApplications' | translate}}
   <span
      *ngIf="applicationTypeInfo?.show?.draInBoth"
      >
      {{'contentBothApplicationsOnlyOne' | translate}}
    </span>
    {{'contentWhichApplication' | translate}}
  </p>
  <p>
    {{'contentCreditOrRecruitment' | translate:interpolationParam}}
  </p>

  <p *ngIf="applicationTypeInfo?.show?.ifCreditSelected">
    {{'contentIfChooseCreditLogin' | translate}}
  </p>
  <p *ngIf="!applicationTypeInfo?.show?.ifCreditSelected">
    {{'contentIfChooseCreditNoRecruitment' | translate}}
  </p>

  <p *ngIf="applicationTypeInfo?.show?.ifRecruitmentSelected">
    {{'contentIfChooseRecruitmentLogin' | translate}}
  </p>
  <p *ngIf="!applicationTypeInfo?.show?.ifRecruitmentSelected">
    {{'contentIfChooseRecruitmentNoCredit' | translate}}
  </p>
</app-ui-modal>
