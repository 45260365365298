import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { IAssessmentButtonAnswer } from 'src/app/models/assessment-button-answer';
import { IAssessmentResponse } from 'src/app/models/assessment-response';
import { IAssessmentSliderSubquestion } from 'src/app/models/assessment-slider-subquestion';
import { ISelectorButton } from 'src/app/shared/ui/ui-selector-buttons/ui-selector-buttons.component';

@Component({
  selector: 'app-ui-question-slider-section',
  templateUrl: './ui-question-slider-section.component.html',
  styleUrls: ['./ui-question-slider-section.component.scss']
})
export class UiQuestionSliderSectionComponent implements OnInit {

  @Input()  question!: IAssessmentSliderSubquestion;
  @Input() selectedAnswer:IAssessmentResponse | undefined;
  @Output() selectedAnswerChange = new EventEmitter<IAssessmentResponse>();


  constructor() { }

  ngOnInit(): void {
    this.question.options.forEach((x) => {
      x.selected = false;
    })

    if(this.selectedAnswer && this.selectedAnswer.response != null){
      this.question.options[this.question.options.findIndex(x => x.data == this.selectedAnswer?.response)].selected = true;
    }

  }

  // ngOnChanges(changes: SimpleChanges){
  //   // question
  //   // if( changes['question'].previousValue && changes['question'].previousValue.questionId != changes['question'].currentValue.questionId){
  //   //   if(this.selectedAnswer && this.selectedAnswer.response != null){
  //   //     this.question.options[this.question.options.findIndex(x => x.data == this.selectedAnswer?.response)].selected = true;
  //   //   }
  //   // }
  // }

  onSelected(event: ISelectorButton){
    this.selectedAnswer = {id: this.question.id, response: event.data};
    this.selectedAnswerChange.emit(this.selectedAnswer); 
  }

}
