import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Route, Router, RouterStateSnapshot, UrlSegment, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { SystemStorage } from 'src/app/shared/omt-lib';
import { ERoles } from './auth-role.guard';

@Injectable({
  providedIn: 'root',
})
export class VerifyOtpGuard  {
  constructor(private _router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this._can();
  }
  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this._can();
  }
  canLoad(
    route: Route,
    segments: UrlSegment[]
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this._can();
  }

  private async _can() {
    const _user = await SystemStorage.INSTANCE.session.getUser$();
    if (
      _user &&
      _user.user.Type === 'account' &&
      _user.hasRole(ERoles.VERIFY_OTP)
    ) {
      return true;
    } else {
      this._router.navigate(['']);
      return false;
    }
  }
}
