import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
} from '@angular/common/http';
import { map, Observable, switchMap, take } from 'rxjs';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { SystemStorage } from './shared/omt-lib';

@Injectable()
export class _HttpInterceptor implements HttpInterceptor {
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {

    const copiedReq = req;//.clone({ url: req.url, withCredentials: true });
    return next.handle(copiedReq);

    // if (!environment.production) {
    //   let _url = req.url;

    //   // _url = _url.replace('/api/', 'https://localhost:7190/api/');
    //   //alert(_url);

    //   return SystemStorage.INSTANCE.session.user$().pipe(take(1), switchMap(u => {
    //     if (u && u.user.Token) {
    //       const copiedReq = req.clone({
    //         url: _url,
    //         withCredentials: true,
    //         //headers: req.headers.append('OUA-Token', u.user.Token),
    //       });
    //       return next.handle(copiedReq);
    //     } else {
    //       const copiedReq = req.clone({ url: _url, withCredentials: true });
    //       return next.handle(copiedReq);
    //     }
    //   }));

    //   // SystemStorage.INSTANCE.session.getUser$().then((u) => {
    //   //   if (u && u.user.Token) {
    //   //     const copiedReq = req.clone({
    //   //       url: _url,
    //   //       withCredentials: true,
    //   //       headers: req.headers.append('OUA-Token', u.user.Token),
    //   //     });
    //   //     return next.handle(copiedReq);
    //   //   } else {
    //   //     const copiedReq = req.clone({ url: _url, withCredentials: true });
    //   //     return next.handle(copiedReq);
    //   //   }
    //   // }).catch(()=>{
    //   //   const copiedReq = req.clone({ url: _url, withCredentials: true });
    //   //   return next.handle(copiedReq);
    //   // })
    // } else {
    //   const copiedReq = req.clone({ url: req.url, withCredentials: true });
    //   return next.handle(copiedReq);
    // }
  }
}
