import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  HttpClient,
  HttpClientModule,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
import { CommonModule, DatePipe } from '@angular/common';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { SystemTranslationService } from './shared/system-translation.service';
import { LoginComponent } from './base/login/login.component';
import { LoginWithKeyComponent } from './base/login-with-key/login-with-key.component';
import { ForgotPasswordComponent } from './base/forgot-password/forgot-password.component';
import { BrowserSupportMessageComponent } from './base/browser-support-message/browser-support-message.component';
import { UseragentService } from './core/useragent.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from './shared/shared.module';
import { LandingPageComponent } from './droplink/landing-page/landing-page.component';
import {
  IgxDialogModule,
  IgxDividerModule,
  IgxSelectModule,
  IgxSimpleComboModule,
} from '@infragistics/igniteui-angular';
import { IgxIconModule } from '@infragistics/igniteui-angular';
import { SignUpComponent } from './droplink/sign-up/sign-up.component';
import { _HttpInterceptor } from './_http-interceptor';
import { LegacyRedirectComponent } from './legacy/legacy-redirect/legacy-redirect.component';
import { LoginWithPartnerIdComponent } from './base/login-with-partner-id/login-with-partner-id.component';
import { UpdatePasswordComponent } from './base/update-password/update-password.component';
import { IgxTooltipModule } from 'igniteui-angular';
import { ApplicantModule } from './applicant/applicant.module';
import { LoginWithAssessmentKeyComponent } from './base/login-with-assessment-key/login-with-assessment-key.component';
import { _PageLoaderInterceptor } from './_page-loader.interceptor';
import { IntegrationReportDownloadComponent } from './base/integration-report-download/integration-report-download.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    LoginWithKeyComponent,
    LoginWithPartnerIdComponent,
    LoginWithAssessmentKeyComponent,
    ForgotPasswordComponent,
    BrowserSupportMessageComponent,
    LandingPageComponent,
    SignUpComponent,
    LegacyRedirectComponent,
    UpdatePasswordComponent,
    IntegrationReportDownloadComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    IgxSelectModule,
    CommonModule,
    IgxDialogModule,
    IgxDividerModule,
    IgxIconModule,
    IgxTooltipModule,
    ApplicantModule,
    IgxSimpleComboModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpTranslateLoader,
        deps: [HttpClient],
      },
    }),
  ],
  providers: [
    DatePipe,
    UseragentService,
    SystemTranslationService,
    { provide: HTTP_INTERCEPTORS, useClass: _HttpInterceptor, multi: true },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: _PageLoaderInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

// AOT compilation support
export function httpTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
