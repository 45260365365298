import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { map } from 'rxjs/operators';
import { IAccount } from '../models/account';
import { IAccountDivision } from '../models/account-division';
import { IBasicLookup } from '../models/basic-lookup';

@Injectable({
  providedIn: 'root',
})
export class AccountService {
  constructor(private _httpClient: HttpClient) {}

  getPasswordExpired$() {
    return lastValueFrom(
      this._httpClient.get<{
        Code:
          | 'account-password-expired/not-expired'
          | 'account-password-expired/has-expired';
      }>('/api/account/PasswordExpired')
    );
  }

  getCompanyAccountsList$() {
    return this._httpClient.get(`/api/account/getlist`).pipe(
      map((p: any, index: number) => {
        let pr: IAccount[] = p.map((x: any) => {
          return {
            accountType: {
              id: x.AccountTypeId,
              description: x.AccountTypeName,
            } as IBasicLookup,
            archived: x.Archived,
            cellPhone: x.CellPhone,
            companyName: x.CompanyName,
            displayName: x.DisplayName,
            division: {
              id: x.DivisionId,
              description: x.DivisionName,
            } as IBasicLookup,
            email: x.Email,
            hRManagerName: x.HRManagerName,
            id: x.Id,
            isRestrictedAccess: x.IsRestrictedAccess,
            username: x.Username,
          } as IAccount;
        });
        return pr;
      })
    );
  }

  getCompanyAccountById$(accountId: number | undefined) {
    return this._httpClient.get(`/api/account/getsingle?id=${accountId}`).pipe(
      map((x: any, index: number) => {
        return {
          accountType: {
            id: x.AccountTypeId,
            description: x.AccountTypeName,
          } as IBasicLookup,
          archived: x.Archived,
          cellPhone: x.CellPhone,
          companyName: x.CompanyName,
          displayName: x.DisplayName,
          division: {
            id: x.DivisionId,
            description: x.DivisionName,
          } as IBasicLookup,
          email: x.Email,
          hRManagerName: x.HRManagerName,
          id: x.Id,
          isRestrictedAccess: x.IsRestrictedAccess,
          username: x.Username,
        } as IAccount;
      })
    );
  }

  getAccountDivisions$(accountId: number) {
    return this._httpClient
      .get(`/api/account/getdivisionlist?accountId=${accountId}`)
      .pipe(
        map((p: any, index: number) => {
          let pr: IAccountDivision[] = p.map((x: any) => {
            return {
              authorised: x.Authorised,
              division: {
                id: x.DivisionId,
                description: x.DivisionName,
              } as IBasicLookup,
              editable: x.Editable,
            } as IAccountDivision;
          });
          return pr;
        })
      );
  }

  getAccountTypeList$() {
    return this._httpClient.get(`/api/account/getaccounttypelist`).pipe(
      map((p: any, index: number) => {
        let pr: IBasicLookup[] = p.map((x: any) => {
          return {
            id: x.Id,
            description: x.Name,
          } as IBasicLookup;
        });
        return pr;
      })
    );
  }

  getDivisionList$() {
    return this._httpClient.get(`/api/division/getlist`).pipe(
      map((p: any, index: number) => {
        let pr: IBasicLookup[] = p.map((x: any) => {
          return {
            id: x.Id,
            description: x.Name,
          } as IBasicLookup;
        });
        return pr;
      })
    );
  }

  setAccountDivisions$(
    accountId: string,
    authorised: boolean,
    divisionId: number
  ) {
    return this._httpClient.put('/api/account/divisionset', {
      AccountId: accountId,
      Authorised: authorised,
      DivisionId: divisionId,
    });
  }

  createUpdateAccount$(
    accountTypeId: number,
    cellPhone: string,
    displayName: string,
    divisionId: number,
    email: string,
    HRManagerName: string,
    id: number | null
  ) {
    return this._httpClient.put('/api/account/set', {
      AccountTypeId: accountTypeId,
      CellPhone: cellPhone,
      DisplayName: displayName,
      DivisionId: divisionId,
      Email: email,
      HRManagerName: HRManagerName,
      Id: id,
    });
  }

  $passwordResetRequest = (email: string) => {
    return this._httpClient
      .post<{ code: string }>(`/api/account/PasswordResetRequest`, { email })
      .pipe(
        map((d) => {
          return d.code;
        })
      )
      .toPromise();
  };

  forgotPasswordUpdate$(
    accountId: number,
    uid: string,
    newPassword: string,
    confirmPassword: string
  ) {
    return this._httpClient
      .put<{
        Code: string;
        Email: string;
        Roles: string;
        DisplayName: string;
        TimeToComplete: string;
        CompanyAlias: string;
      }>(`/api/account/ForgotPasswordUpdate`, {
        accountId,
        uid,
        newPassword,
        confirmPassword,
      })
      .toPromise();
  }

  passwordUpdate$(newPassword: string, confirmPassword: string) {
    return this._httpClient
      .put<{ Code: string }>(`/api/account/PasswordUpdate`, {
        newPassword,
        confirmPassword,
      })
      .pipe(
        map((d) => {
          return d.Code;
        })
      )
      .toPromise();
  }

  getAccountCompany$() {
    return this._httpClient
      .get<{
        CompanyId: string;
        CompanyName: string;
      }>(`/api/account/GetSelectedCompany`);
  }

  archiveAccount$(id: number) {
    return this._httpClient
      .put(
        `/api/account/Archive`,
        {
          AccountId: id,
        }
      )
  }
}
