<div *ngIf="ua.status === 'ua/unsupported-version'" class="row justify-content-md-center _overlay">
  <div class="col-md-8 col-lg-6">
    <div class="browser-message-card">
      <div class="text-center">
        <p>
          {{ 'contentUnsupportedVersion' | translate }}
        </p>
        <br />
        <a target="_blank" href="https://www.google.com/chrome/"><img alt="Chrome Logo" src="{{ chromeImage }}" /></a>
        <a target="_blank" href="https://www.microsoft.com/en-us/windows/microsoft-edge"><img alt="Microsoft Edge Logo" src="{{ edgeImage }}" /></a>
        <!-- <a target="_blank" href="https://windows.microsoft.com/en-US/internet-explorer/download-ie"
          ><img alt="Internet Explorer Logo" src="assets/img/browser/ie.png"
        /></a> -->
        <!--<a target="_blank" href="https://www.firefox.com"><img alt="Firefox Logo" src="assets/img/browser/firefox.png" /></a>-->
        <!--<a target="_blank" href="https://www.opera.com/"><img alt="Opera Logo" src="assets/img/browser/opera.png" /></a>-->
        <!--<a target="_blank" href="https://support.apple.com/downloads/#safari"><img alt="Safari Logo" src="assets/img/browser/safari.png" /></a>-->
      </div>
    </div>
  </div>
</div>
<div *ngIf="ua.status === 'ua/unsupported-browser' && !ua.isMobile" class="row justify-content-md-center _overlay">
  <div class="col-md-8 col-lg-6">
    <div class="browser-message-card">
      <div class="text-center">
        <p>
          {{ 'contentUnsupportedBrowser' | translate }}
        </p>
        <br />
        <a target="_blank" href="https://www.google.com/chrome/"><img alt="Chrome Logo" src="{{ chromeImage }}" /></a>
        <a target="_blank" href="https://www.microsoft.com/en-us/windows/microsoft-edge"><img alt="Microsoft Edge Logo" src="{{ edgeImage }}" /></a>
        <!-- <a target="_blank" href="https://windows.microsoft.com/en-US/internet-explorer/download-ie"
          ><img alt="Internet Explorer Logo" src="assets/img/browser/ie.png"
        /></a> -->
        <!--<a target="_blank" href="https://www.firefox.com"><img alt="Firefox Logo" src="assets/img/browser/firefox.png" /></a>-->
        <!--<a target="_blank" href="https://www.opera.com/"><img alt="Opera Logo" src="assets/img/browser/opera.png" /></a>-->
        <!--<a target="_blank" href="https://support.apple.com/downloads/#safari"><img alt="Safari Logo" src="assets/img/browser/safari.png" /></a>-->
      </div>
    </div>
  </div>
</div>
<div *ngIf="ua.status === 'ua/unsupported-browser' && ua.isMobile" class="row justify-content-md-center _overlay">
  <div class="col-md-8 col-lg-6">
    <div class="browser-message-card">
      <div class="text-center">
        <p>
          You are using an unsupported Internet Browser, please <b>first</b> download one of the supported internet browsers below by clicking on the icon you
          prefer. Then, <b>change your default browser</b> on your {{ ua.isIos ? 'apple' : 'android' }} device to access the site
        </p>
        <br />
        <a target="_blank" href="https://www.google.com/chrome/"><img alt="Chrome Logo" src="{{ chromeImage }}" /></a>
        <a target="_blank" href="https://www.microsoft.com/en-us/windows/microsoft-edge"><img alt="Microsoft Edge Logo" src="{{ edgeImage }}" /></a>
        <!-- <a target="_blank" href="https://windows.microsoft.com/en-US/internet-explorer/download-ie"
          ><img alt="Internet Explorer Logo" src="assets/img/browser/ie.png"
        /></a> -->
        <p>How to change the default browser on my {{ ua.isIos ? 'Apple' : 'Android' }} device:</p>
        <ol *ngIf="!ua.isIos">
          <li>Open the Settings app on your Android.</li>
          <li>Tap "Apps."</li>
          <li>Tap the three dots at the upper-right corner of the screen and, in the drop-down menu, tap "Default apps."</li>
          <li>Tap "Browser app."</li>
          <li>On the Browser app page, tap the browser you want to use as the default web browser.</li>
        </ol>
        <ol *ngIf="ua.isIos">
          <li>Upgrade your operting system.</li>
          <li>Go to Settings and scroll until you find the name of the web browser you want to use.</li>
          <li>Tap the app, then tap Default Browser App.</li>
          <li>Select a web browser to set it as the default. A checkmark should appear next to the browser to confirm it's the default.</li>
        </ol>
      </div>
    </div>
  </div>
</div>
