import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { IBasicLookup } from '../models/basic-lookup';
import { IBusinessUnitLookup } from '../models/business-unit-lookup';
import { IDivisionLookup } from '../models/division-lookup';
import { IAssessmentTypeLookup } from '../models/assessment-type-lookup';
import { IProject } from '../models/project';
import { IProjectLookup } from '../models/project-lookup';
import { IProjectTalentAnalytics } from '../models/project-talent-analytics';
import { IProjectDetailTalentAnalytics } from '../models/project-detail-talent-analytics';
import { IPrescreeningProject } from '../models/prescreening-project';
import { IHurdleConfig } from '../models/hurdle-config';
import { IPositionProfileCutlines } from '../models/position-profile-cutlines';
import { IBulkUploadStatus } from '../models/bulk-upload-status';
import { IDroplinkSettings } from '../models/droplink-settings';
import { IProjectDetail } from '../models/project-detail';
import { IAssessmentDetail } from '../models/assessment-detail';
import { IProjectExport } from '../models/project-export';
import { lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ProjectService {
  constructor(private _httpClient: HttpClient) {}

  public readonly assessmentIntegration = {
    evalex: {
      assessmentTypeList$: () =>
        this._httpClient
          .get<{ AssessmentTypeId: number; AssessmentTypeName: string }[]>(
            '/api/AssessmentIntegration/EvalexAssessmentTypeList'
          )
          .toPromise(),
      jobRoleList$: () =>
        this._httpClient
          .get<
            {
              JobRoleId: number;
              JobRoleName: string;
              _AssessmentTypeId: number;
            }[]
          >('/api/AssessmentIntegration/EvalexJobRoleList')
          .toPromise(),
      divisionList$: () =>
        this._httpClient
          .get<
            { DivisionId: number; DivisionName: string; DivisionType: string }[]
          >('/api/AssessmentIntegration/EvalexDivisionList')
          .toPromise(),
      masterPositionList$: () =>
        this._httpClient
          .get<{ PositionId: number; PositionName: string }[]>(
            '/api/AssessmentIntegration/EvalexMasterPositionList'
          )
          .toPromise(),
    },
  };

  getProjects$(search: string = '') {
    return this._httpClient.get(`/api/projectB/List?search=${search}`).pipe(
      map((p: any, index: number) => {
        let pr: IProject[] = p.map((x: any) => {
          return {
            id: x.Id,
            company: x.CompanyDescription,
            division: x.DivisionDescription,
            businessUnit: x.BusinessUnitDescription,
            name: x.PositionName,
            assessmentType: x.ProductDescription,
            dateOpened: x.DateOpened,
            dateClosed:
              x.DateClosed == '1900-01-01T00:00:00' ? undefined : x.DateClosed,
          } as IProject;
        });
        return pr;
      })
    );
  }

  getProjectLookups$() {
    return this._httpClient
      .get<any[][]>('/api/project/lookups')
      .pipe(
        map((l) => {
          return {
            product: l[0].map((i) => {
              return {
                id: i.Id,
                description: i.Description,
                isHurdle: i.IsHurdle === 1,
                isEvalexVIA: i.IsEvalexVIA === 1,
                isEvalex: i.IsEvalex === 1,
                isEvalexUpgrade: i.IsEvalexUpgrade === 1,
                isEvalexUpgradeB: i.IsEvalexUpgradeB === 1,
                canUpgradeToEvalex: i.CanUpgradeToEvalex === 1,
              };
            }) as IAssessmentTypeLookup[],
            positionProfile: l[1].map((i) => {
              return { id: i.Id, description: i.Description };
            }) as IBasicLookup[],
            division: l[2].map((i) => {
              return {
                id: i.Id,
                description: i.Description,
                countryCode: i.CountryCode,
              };
            }) as IDivisionLookup[],
            businessUnit: l[3].map((i) => {
              return {
                id: i.Id,
                divisionId: i.DivisionId,
                description: i.Description,
              };
            }) as IBusinessUnitLookup[],
            industryType: l[4].map((i) => {
              return { id: i.Id, description: i.Description };
            }) as IBasicLookup[],
            positionType: l[5].map((i) => {
              return { id: i.Id, description: i.Description };
            }) as IBasicLookup[],
            positionLevel: l[6].map((i) => {
              return { id: i.Id, description: i.Description };
            }) as IBasicLookup[],
            qualification: l[7].map((i) => {
              return { id: i.Id, description: i.Description };
            }) as IBasicLookup[],
            province: l[8].map((i) => {
              return { id: i.Id, description: i.Description };
            }) as IBasicLookup[],
          } as IProjectLookup;
        })
      )
      .toPromise();
  }

  getTalentAnalyticsProjects$() {
    return this._httpClient
      .get<any[]>('/api/project/GetTalentAnalyticsProjectList')
      .pipe(
        map((p: any, index: number) => {
          let pr: IProjectTalentAnalytics[] = p.map((x: any) => {
            return {
              assessmentCount: x.AssessmentCount,
              assessmentType: x.AssessmentType,
              businessUnit: {
                id: x.BusinessUnitId,
                description: x.BusinessUnit,
              } as IBasicLookup,
              candidatesAppointed: x.CandidatesAppointed,
              canididatesAssessed: x.CanididatesAssessed,
              company: x.Company,
              dateClosed:
                x.DateClosed == '1900-01-01T00:00:00'
                  ? undefined
                  : x.DateClosed,
              dateOpened: x.DateOpened,
              division: x.Division,
              id: x.Id,
              positionName: x.PositionName,
              prescreeningCount: x.PrescreeningCount,
              sponsorContactNumber: x.SponsorContactNumber,
              sponsorEmail: x.SponsorEmail,
              sponsorName: x.SponsorName,
              completedCandidatesSummary: `${x.CanididatesAssessed} of ${x.AssessmentCount} [${x.PrescreeningCount}]`,
            } as IProjectTalentAnalytics;
          });
          return pr;
        })
      );
  }

  getProjectById$(id: number) {
    return this._httpClient.get<any>(`/api/project/getbyid?id=${id}`).pipe(
      map((x: any, index: number) => {
        return {
          id: id,
          additionalCommunication: x[0].AdditionalCommunication,
          businessUnitId: x[0].BusinessUnitId,
          costCentre: x[0].CostCentre,
          customJson: x[0].CustomJson,
          daysToComplete: x[0].DaysToComplete,
          divisionId: x[0].DivisionId,
          doNotSendProjectUpdates: x[0].DoNotSendProjectUpdates,
          enableProjectSMS: x[0].EnableProjectSMS,
          industryTypeId: x[0].IndustryTypeId,
          positionAssessedFor: x[0].PositionAssessedFor,
          positionId: x[0].PositionId,
          positionLevelId: x[0].PositionLevelId,
          positionProfileId1: x[0].PositionProfileId1,
          positionProfileId2: x[0].PositionProfileId2,
          positionProfileId3: x[0].PositionProfileId3,
          positionTypeId: x[0].PositionTypeId,
          productId: x[0].ProductId,
          projectName: x[0].ProjectName,
          provinceId: x[0].ProvinceId,
          qualificationId: x[0].QualificationId,
          referenceNumber: x[0].ReferenceNumber,
          restrictedAccess: x[0].RestrictedAccess,
          sendNarrativeToCandidate: x[0].SendNarrativeToCandidate,
          sponsorContactNumber: x[0].SponsorContactNumber,
          sponsorEmail: x[0].SponsorEmail,
          sponsorName: x[0].SponsorName,
        } as IProjectDetailTalentAnalytics;
      })
    );
  }

  getPrescreeningprojectList$() {
    return this._httpClient.get<any[]>('/api/prescreening/ProjectList').pipe(
      map((p: any, index: number) => {
        let pr: IPrescreeningProject[] = p.map((x: any) => {
          return {
            communicationName: x.CommunicationName,
            id: x.Id,
            name: x.Name,
            productName: x.Productname,
            questionnaireName: x.QuestionnaireName,
          } as IPrescreeningProject;
        });
        return pr;
      })
    );
  }

  getProjectHurdles$(ProjectId: number | undefined, ProductId: number) {
    return this._httpClient
      .get<any[]>(
        `/api/project/HurdleConfig?projectId=${
          ProjectId ? ProjectId : null
        }&productId=${ProductId}`
      )
      .pipe(
        map((p: any, index: number) => {
          let pr: IHurdleConfig[] = p.map((x: any) => {
            return {
              hurdleType: {
                id: x.HurdleTypeId,
                description: x.HurdleTypeName,
              } as IBasicLookup,
              hurdleGroup: x.HurdleGroup,
              cutline: x.Cutline,
            } as IHurdleConfig;
          });
          return pr;
        })
      );
  }

  createProject$(
    project: IProjectDetailTalentAnalytics,
    additionalCommunication: string,
    divisionCountryCode: string
  ) {
    return this._httpClient
      .post('/api/createproject/CreateProject', {
        AdditionalCommunication: additionalCommunication,
        AssessmentType: {
          Id: project.productId,
        },
        BusinessUnit: { Id: project.businessUnitId },
        CostCentre: project.costCentre,
        CustomJson: project.customJson ? project.customJson : null,
        DaysToComplete: project.daysToComplete,
        Division: { Id: project.divisionId, CountryCode: divisionCountryCode },
        DoNotSendProjectUpdates: project.doNotSendProjectUpdates || false,
        EnableProjectSMS: project.enableProjectSMS || false,
        Id: project.id,
        IndustryType: { Id: project.industryTypeId },
        Position: {
          Id: project.positionId,
          Description: project.positionAssessedFor,
        },
        PositionLevel: { Id: project.positionLevelId },
        PositionName: project.projectName,
        PositionProfile: project.positionProfileId1
          ? { Id: project.positionProfileId1 }
          : null,
        PositionProfile2: project.positionProfileId2
          ? { Id: project.positionProfileId2 }
          : null,
        PositionProfile3: project.positionProfileId3
          ? { Id: project.positionProfileId3 }
          : null,
        PositionType: { Id: project.positionTypeId },
        Province: { Id: project.provinceId },
        Qualification: { Id: project.qualificationId },
        ReferenceNumber: project.referenceNumber,
        RestrictedAccess: project.restrictedAccess || false,
        SponsorContactNumber: project.sponsorContactNumber,
        SponsorEmail: project.sponsorEmail,
        SponsorName: project.sponsorName,
        sendNarrativeToCandidate: project.sendNarrativeToCandidate || false
      })
      .toPromise();
  }

  editProject$(
    project: IProjectDetailTalentAnalytics,
    additionalCommunication: string,
    divisionCountryCode: string
  ) {
    return this._httpClient
      .put('/api/createproject/UpdateProject', {
        AdditionalCommunication: additionalCommunication,
        AssessmentType: {
          Id: project.productId,
        },
        BusinessUnit: { Id: project.businessUnitId },
        CostCentre: project.costCentre,
        CustomJson: project.customJson ? project.customJson : null,
        DaysToComplete: project.daysToComplete,
        Division: { Id: project.divisionId, CountryCode: divisionCountryCode },
        DoNotSendProjectUpdates: project.doNotSendProjectUpdates || false,
        EnableProjectSMS: project.enableProjectSMS || false,
        Id: project.id,
        IndustryType: { Id: project.industryTypeId },
        Position: {
          Id: project.positionId,
          Description: project.positionAssessedFor,
        },
        PositionLevel: { Id: project.positionLevelId },
        PositionName: project.projectName,
        PositionProfile: project.positionProfileId1
          ? { Id: project.positionProfileId1 }
          : null,
        PositionProfile2: project.positionProfileId2
          ? { Id: project.positionProfileId2 }
          : null,
        PositionProfile3: project.positionProfileId3
          ? { Id: project.positionProfileId3 }
          : null,
        PositionType: { Id: project.positionTypeId },
        Province: { Id: project.provinceId },
        Qualification: { Id: project.qualificationId },
        ReferenceNumber: project.referenceNumber,
        RestrictedAccess: project.restrictedAccess || false,
        SponsorContactNumber: project.sponsorContactNumber,
        SponsorEmail: project.sponsorEmail,
        SponsorName: project.sponsorName,
        sendNarrativeToCandidate: project.sendNarrativeToCandidate || false,
      })
      .toPromise();
  }

  setProjectCutlines$(
    projectId: number,
    projectCutlines: IPositionProfileCutlines[]
  ) {
    let cutline = projectCutlines.map((x) => {
      return {
        PositionProfileId: x.positionProfileId,
        CutlineLow: x.cutlineLow,
        CutlineMid: x.cutlineMid,
        CutlineHigh: x.cutlineHigh,
      };
    });
    return this._httpClient
      .put('/api/project/PositionProfileCutlinesWrite', {
        ProjectId: projectId,
        PositionProfileCutlinesList: JSON.stringify(cutline),
      })
      .toPromise();
  }

  async getBulkTemplate$(projectId: number | undefined) {
    const _response = await this._httpClient
      .get(
        `/api/ApplicantBulkUpload/GetBulkUploadTemplateByProjectId/${projectId}`,
        {
          responseType: 'arraybuffer',
          observe: 'response',
        }
      )
      .toPromise();

    if (_response) {
      const type = _response.headers.get('Content-Type');
      const disposition = _response.headers.get('Content-Disposition');
      const excelProtect = _response.headers.get('X-Custom-ExcelProtect');
      const emptyData = _response.headers.get('X-Custom-EmptyData');
      // console.log(disposition);
      let defaultFileName = '';
      if (disposition) {
        const match = disposition.match(/.*filename=\"?([^;\"]+)\"?.*/);
        if (match && match[1]) {
          defaultFileName = match[1];
        }
      }
      defaultFileName = defaultFileName.replace(/[<>:"\/\\|?*]+/g, '_');
      const blob = new Blob(
        [_response.body ? _response.body : new ArrayBuffer(0)],
        { type: type ? type : '' }
      );

      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = defaultFileName;
      link.click();

      return { defaultFileName, excelProtect, emptyData };
    } else {
      return null;
    }
  }

  async getBulkResults$(guid: string){
    const _response = await lastValueFrom(this._httpClient
    .get(
      `/api/ApplicantBulkUpload/DownloadResults/`,
      {
        responseType: 'arraybuffer',
        observe: 'response',
        params: {BulkUploadGuid:guid}
      }
    ))

    if (_response) {
      const type = _response.headers.get('Content-Type');
      const disposition = _response.headers.get('Content-Disposition');
      const excelProtect = _response.headers.get('X-Custom-ExcelProtect');
      const emptyData = _response.headers.get('X-Custom-EmptyData');
      // console.log(disposition);
      let defaultFileName = '';
      if (disposition) {
        const match = disposition.match(/.*filename=\"?([^;\"]+)\"?.*/);
        if (match && match[1]) {
          defaultFileName = match[1];
        }
      }
      defaultFileName = defaultFileName.replace(/[<>:"\/\\|?*]+/g, '_');
      const blob = new Blob(
        [_response.body ? _response.body : new ArrayBuffer(0)],
        { type: type ? type : '' }
      );

      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = defaultFileName;
      link.click();

      return { defaultFileName, excelProtect, emptyData };
    } else {
      return null;
    }
  }

  uploadBulkFile$(projectId: number | undefined, file: File) {
    const endpoint = '/api/ApplicantBulkUpload/BulkUploadByProjectId/';
    const projId: string = String(projectId);
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    formData.append('projectId', projId);
    return this._httpClient.post(endpoint, formData);
  }

  getBulkUploadProcess$() {
    return this._httpClient
      .get<any[]>(`/api/ApplicantBulkUpload/GetBulkUploadProcess/`)
      .pipe(
        map((p: any, index: number) => {
          let pr: IBulkUploadStatus[] = p.map((x: any) => {
            return {
              batchUid: x.BatchUid,
              created: x.Created,
              date: x.Date,
              done: x.Done,
              failed: x.Failed,
              fileName: x.FileName,
              positionName: x.PositionName,
              processing: x.Processing,
              status: x.Status,
              total: x.Total,
            } as IBulkUploadStatus;
          });
          return pr;
        })
      );
  }

  getDroplinkSettings$(projectId: number | undefined) {
    // DropLinkCredits: number
    // DropLinkExpiryDate: Date
    // DropLinkGuid: string
    // DropLinkSelectLanguage: boolean
    // ProjectId: number | null
    return this._httpClient
      .get<any[]>(`/api/project/getdroplinksettings?projectId=${projectId}`)
      .pipe(
        map((p: any, index: number) => {
          return {
            dropLinkCredits: p.DropLinkCredits,
            dropLinkExpiryDate: p.DropLinkExpiryDate
              ? new Date(p.DropLinkExpiryDate)
              : null,
            dropLinkGuid: p.DropLinkGuid,
            dropLinkSelectLanguage: p.DropLinkSelectLanguage,
            projectId: p.ProjectId,
          } as IDroplinkSettings;
        })
      );
  }

  setDroplinkSettings$(settings: IDroplinkSettings) {
    return this._httpClient.post('/api/project/setdroplinksettings', {
      ProjectId: settings.projectId,
      DropLinkCredits: settings.dropLinkCredits,
      DropLinkExpiryDate: settings.dropLinkExpiryDate,
      DropLinkSelectLanguage: settings.dropLinkSelectLanguage,
    });
  }

  getProjectDetail$(
    projectId: number,
    fromDate: Date | null,
    toDate: Date | null
  ) {
    ///api/project/AssessmentList?projectId=6008&filter=%5B%7B%22FromDate%22:%222016-10-01%22,%22ToDate%22:%222022-10-26%22%7D%5D
    return this._httpClient
      .get(`/api/project/AssessmentList`, {
        params: {
          projectId: projectId,
          filter: JSON.stringify([{ FromDate: fromDate, ToDate: toDate }]),
        },
      })
      .pipe(
        map((p: any, index: number) => {
          return {
            isHurdling: p[0][0].IsHurdling == 1 ? true : false,
            isTracking: p[0][0].IsTracking == 1 ? true : false,
            hurdleStatusLookup: p[1].map((x: any) => {
              return {
                id: x.Id,
                description: x.Name,
              } as IBasicLookup;
            }),
            hurdleLookup: p[2].map((x: any) => {
              return {
                id: x.HurdleGroup,
                description: x.Name,
              } as IBasicLookup;
            }),
            assessmentStatusLookup: p[3].map((x: any) => {
              return {
                id: x.Id,
                description: x.Description,
              } as IBasicLookup;
            }),
            assessments: p[4].map((x: any) => {
              let assessmentStatusDescription = p[3].find(
                (y: any) => y.Id == x.AssessmentStatusId
              ).Description;
              let trackingStatusDescription = p[1].find(
                (y: any) => y.Id == x.TrackingStatusId
              )?.Name;
              let hurdleStatusLookup: IBasicLookup[] = [
                {
                  id: 0,
                  description: 'Report Ready',
                },
                {
                  id: 2,
                  description: 'Pass',
                },
                {
                  id: 3,
                  description: 'Fail',
                },
              ];
              return {
                applicantName: x.ApplicantName,
                assessmentDate: x.AssessmentDate,
                assessmentStatus: {
                  id: x.AssessmentStatusId,
                  description: assessmentStatusDescription
                    ? assessmentStatusDescription
                    : '',
                },
                dateAssessed: x.DateAssessed,
                hurdleGroup: x.HurdleGroup,
                resultStatus: [
                  {
                    id: x.ResultStatusHurdle1,
                    description: hurdleStatusLookup.find(
                      (y: any) => y.id == x.ResultStatusHurdle1
                    )?.description,
                  },
                  {
                    id: x.ResultStatusHurdle2,
                    description: hurdleStatusLookup.find(
                      (y: any) => y.id == x.ResultStatusHurdle2
                    )?.description,
                  },
                  {
                    id: x.ResultStatusHurdle3,
                    description: hurdleStatusLookup.find(
                      (y: any) => y.id == x.ResultStatusHurdle3
                    )?.description,
                  },
                  {
                    id: x.ResultStatusHurdle4,
                    description: hurdleStatusLookup.find(
                      (y: any) => y.id == x.ResultStatusHurdle4
                    )?.description,
                  },
                  {
                    id: x.ResultStatusHurdle5,
                    description: hurdleStatusLookup.find(
                      (y: any) => y.id == x.ResultStatusHurdle5
                    )?.description,
                  },
                ],
                trackingDate: x.TrackingDate,
                trackingStatus: {
                  id: x.TrackingStatusId,
                  description: trackingStatusDescription
                    ? trackingStatusDescription
                    : '',
                },
              } as IAssessmentDetail;
            }),
          } as IProjectDetail;
        })
      );
  }

  getExportList$() {
    // /api/exportengine/lookup
    return this._httpClient.get<any[]>(`/api/exportengine/lookup`).pipe(
      map((p: any, index: number) => {
        let pr: IProjectExport[] = p.map((x: any) => {
          return {
            companyName: x.CompanyName,
            description: x.Description,
            id: x.Id,
            linkedToYourAccount: x.LinkedToYourAccount == 1 ? true : false,
            name: x.Name,
          } as IProjectExport;
        });
        return pr;
      })
    );
  }

  async downloadExport$(exportId: number, projectId: number, json: any = {}) {
    json = json ? json : {};
    const _response = await this._httpClient
      .get(
        `/api/exportengine/ExportExcel?exportId=${exportId}&projectId=${projectId}&json=${JSON.stringify(
          json
        )}`,
        {
          responseType: 'arraybuffer',
          observe: 'response',
        }
      )
      .toPromise();

    if (_response) {
      const type = _response.headers.get('Content-Type');
      const disposition = _response.headers.get('Content-Disposition');
      const excelProtect = _response.headers.get('X-Custom-ExcelProtect');
      const emptyData = _response.headers.get('X-Custom-EmptyData');
      // console.log(disposition);
      let defaultFileName = '';
      if (disposition) {
        const match = disposition.match(/.*filename=\"?([^;\"]+)\"?.*/);
        if (match && match[1]) {
          defaultFileName = match[1];
        }
      }
      defaultFileName = defaultFileName.replace(/[<>:"\/\\|?*]+/g, '_');

      const link = document.createElement('a');
      if (_response.body != null && type != null) {
        const blob = new Blob([_response.body], { type: type });
        link.href = window.URL.createObjectURL(blob);
      }
      link.download = defaultFileName;
      link.click();

      return { defaultFileName, excelProtect, emptyData };
    } else {
      return null;
    }
  }

  getCustomMessage$(projectId: number, resultStatusId: number | null = null) {
    return this._httpClient.get(
      `/api/project/CustomMessageGet?projectId=${projectId}&resultStatusId=${resultStatusId}`
    );
  }

  closeProject$(projectId: number){
    return this._httpClient.put(
      `/api/Project/CloseProject`, {ProjectId: projectId}
    )
  }

  getProjectPositionProfilesById$(projectId: number) {
    // DropLinkCredits: number
    // DropLinkExpiryDate: Date
    // DropLinkGuid: string
    // DropLinkSelectLanguage: boolean
    // ProjectId: number | null
    return this._httpClient
      .get(`/api/project/GetProjectPositionProfilesById?id=${projectId}`)
      .pipe(
        map((p: any, index: number) => {
          return {
            projectId: p.ProjectId,
            ProjectName: p.ProjectName,
            PositionProfileId1: p.PositionProfileId1,
            PositionProfileId2: p.PositionProfileId2,
            PositionProfileId3: p.PositionProfileId3
          } as {
            projectId: number,
            ProjectName: string,
            PositionProfileId1: number | undefined,
            PositionProfileId2: number | undefined,
            PositionProfileId3: number | undefined
          };
        })
      );
  }

  reopenProject$(projectId: number){
    return this._httpClient.put(
      `/api/Project/ReopenProject`, {ProjectId: projectId}
    )
  }
}
